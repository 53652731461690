import { Coverage } from "./graphql/CustomTypes";
import GraphQLClient from "./graphql/GraphQLClient";
import { Queries } from "./graphql/Queries";
import * as Gql from "./graphql/types";
import * as CustomTypes from "./graphql/CustomTypes";
import { Mutations } from "./graphql/Mutations";
import { Gtm } from "@utils/gtm/Gtm";

class Api {
    public static async getAppData(departure: string): Promise<{ legalDocuments: Gql.LegalDocument[]; backendEnvironments: Gql.BackendEnvironment[] }> {
        const result = await GraphQLClient.query<Gql.appData, Gql.appDataVariables>({
            query: Queries.appData,
            variables: { at: departure, countryCode: Gql.CountryCode.HU },
        });

        return {
            legalDocuments: [...result.accountLegalDocuments, ...result.nonDomestic],
            backendEnvironments: result.env,
        };
    }

    public static async getLegalDocuments(departure: string): Promise<Gql.LegalDocument[]> {
        const result = await GraphQLClient.query<Gql.legalDocuments, Gql.legalDocumentsVariables>({
            query: Queries.legalDocuments,
            variables: { at: departure, countryCode: Gql.CountryCode.HU },
        });

        return [...result.accountLegalDocuments, ...result.nonDomestic];
    }

    public static async calculateTravelTariff(variables: {
        destinationId: number;
        departureDate: string;
        returnDate: string;
        adultsCount: number;
        childrenCount: number;
    }): Promise<Gql.Amount | null> {
        const result = await GraphQLClient.query<Gql.calculateTravelTariff, Gql.calculateTravelTariffVariables>({
            query: Queries.calculateTravelTariff,
            variables: {
                destinationCategoryId: variables.destinationId,
                firstDayOfTravel: variables.departureDate,
                lastDayOfTravel: variables.returnDate,
                adultsNumber: variables.adultsCount,
                childrenNumber: variables.childrenCount,
                residencyCode: Gql.ResidencyCode.HU,
            },
        });

        return result.calculateTravelTariff ?? null;
    }

    public static async getTravelCoverages(from?: string): Promise<Coverage[]> {
        const result = await GraphQLClient.query<Gql.travelCoverage, Gql.travelCoverageVariables>({
            query: Queries.travelCoverage,
            variables: {
                from,
                languageCode: Gql.LanguageCode.HU,
                residencyCode: Gql.ResidencyCode.HU,
            },
        });

        return result.travelCoverage.map((c): Coverage => {
            return {
                name: c.category.name,
                slug: c.category.coverageCategorySlug,
                iconName: c.category.iconName,
                items: c.coverages,
            };
        });
    }

    public static async getCitiesByZipCode(zipCode: string): Promise<string[]> {
        const result = await GraphQLClient.query<Gql.getCitiesByZipCode, Gql.getCitiesByZipCodeVariables>({
            query: Queries.getCitiesByZipCode,
            variables: { zipCode },
        });
        return result.streets.cities;
    }

    public static async getStreets(zipCode: string, city: string): Promise<string[]> {
        const result = await GraphQLClient.query<Gql.getStreets, Gql.getStreetsVariables>({
            query: Queries.getStreets,
            variables: { zipCode, city },
        });
        return result.streets.streets.map(s => s.streetWithSuffix);
    }

    public static async createContract(
        offer: Gql.InputCreateOfferDataWL,
        accountLegalDocuments: Gql.LegalDocumentRelationActionWLInput[],
        travelLegalDocuments: Gql.LegalDocumentRelationActionWLInput[]
    ): Promise<Gql.Contract> {
        const result = await GraphQLClient.mutate<Gql.createContract, Gql.createContractVariables>({
            mutation: Mutations.createContract,
            variables: { createOfferDataWL: offer, custLegal: accountLegalDocuments, travelLegal: travelLegalDocuments },
        });
        return result.CreateWLHuOffer;
    }

    public static async sendOrResendSMSCode(offerId: string): Promise<Gql.VoidType> {
        const result = await GraphQLClient.mutate<Gql.sendOrResendSMSCode, Gql.sendOrResendSMSCodeVariables>({
            mutation: Mutations.sendOrResendSMSCode,
            variables: { offerId },
        });
        return result.sendOrResendSMSCode;
    }

    public static async validatePhone(offerId: string, code: string): Promise<Gql.Contract> {
        await GraphQLClient.mutate<Gql.validatePhone, Gql.validatePhoneVariables>({
            mutation: Mutations.validatePhone,
            variables: { offerId, code },
        });
        await Api.sendOrResendEmailValidationEmail(offerId);
        const contractResult = await GraphQLClient.query<Gql.getContract, Gql.getContractVariables>({
            query: Queries.getContract,
            variables: { offerId },
        });
        return contractResult.getOffer;
    }

    public static async updatePhone(offerId: string, phoneNumber: string): Promise<Gql.Contract> {
        await GraphQLClient.mutate<Gql.updatePhone, Gql.updatePhoneVariables>({
            mutation: Mutations.updatePhone,
            variables: { offerId, phoneNumber },
        });
        const contractResult = await GraphQLClient.query<Gql.getContract, Gql.getContractVariables>({
            query: Queries.getContract,
            variables: { offerId },
        });
        return contractResult.getOffer;
    }

    public static async sendOrResendEmailValidationEmail(offerId: string): Promise<Gql.VoidType> {
        const result = await GraphQLClient.mutate<Gql.sendOrResendEmailValidationEmail, Gql.sendOrResendEmailValidationEmailVariables>({
            mutation: Mutations.sendOrResendEmailValidationEmail,
            variables: {
                offerId,
                clientId: Gtm.getClientId() || "",
            },
        });
        return result.sendOrResendEmailValidationEmail;
    }

    public static async updateEmail(offerId: string, email: string): Promise<Gql.Contract> {
        await GraphQLClient.mutate<Gql.updateEmail, Gql.updateEmailVariables>({
            mutation: Mutations.updateEmail,
            variables: { offerId, email },
        });
        await Api.sendOrResendEmailValidationEmail(offerId);
        const contractResult = await GraphQLClient.query<Gql.getContract, Gql.getContractVariables>({
            query: Queries.getContract,
            variables: { offerId },
        });
        return contractResult.getOffer;
    }

    public static async validateEmail(offerId: string, code: string): Promise<Gql.Contract> {
        await GraphQLClient.mutate<Gql.validateEmail, Gql.validateEmailVariables>({
            mutation: Mutations.validateEmail,
            variables: { offerId, code },
        });
        const contractResult = await GraphQLClient.query<Gql.getContract, Gql.getContractVariables>({
            query: Queries.getContract,
            variables: { offerId },
        });
        return contractResult.getOffer;
    }

    public static async getContract(offerId: string): Promise<Gql.Contract> {
        const contractResult = await GraphQLClient.query<Gql.getContract, Gql.getContractVariables>({
            query: Queries.getContract,
            variables: { offerId },
        });
        return contractResult.getOffer;
    }

    public static async pay(offerId: string, amount: number): Promise<string> {
        const result = await GraphQLClient.mutate<Gql.pay, Gql.payVariables>({
            mutation: Mutations.pay,
            variables: { offerId, payableAmount: { amount, currency: Gql.Currency.HUF } },
        });

        return result.pay;
    }

    public static async getTransactionResult(offerId: string, transactionId: string): Promise<Gql.TransactionResult> {
        const result = await GraphQLClient.mutate<Gql.getTransactionResult, Gql.getTransactionResultVariables>({
            mutation: Mutations.getTransactionResult,
            variables: { offerId, transactionId },
        });
        return result.getTransactionResult;
    }

    public static async updateCouponCode(offerId: string, updateOfferDataWL: Gql.InputUpdateOfferDataWL) {
        await GraphQLClient.mutate<Gql.updateOffer, Gql.updateOfferVariables>({
            mutation: Mutations.updateOffer,
            variables: { offerId, updateOfferDataWL },
        });

        return Api.getContract(offerId);
    }

    public static async getServerTime(): Promise<string> {
        const result = await GraphQLClient.query<Gql.time>({
            query: Queries.time,
        });

        return result.time;
    }

    public static async canAccountBeInsured(variables: Gql.canAccountBeInsuredVariables): Promise<boolean> {
        const result = await GraphQLClient.query<Gql.canAccountBeInsured, Gql.canAccountBeInsuredVariables>({
            query: Queries.canAccountBeInsured,
            variables,
        });

        return result.canAccountBeInsured;
    }

    public static async canPersonBeInsured(variables: Gql.canPersonBeInsuredVariables): Promise<boolean> {
        const result = await GraphQLClient.query<Gql.canPersonBeInsured, Gql.canPersonBeInsuredVariables>({
            query: Queries.canPersonBeInsured,
            variables,
        });

        return result.canPersonBeInsured;
    }
}

export { Gql, Api, CustomTypes };
