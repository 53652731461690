/* eslint-disable node/no-unpublished-require */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";

import formats from "./formats";
import { BreakPoint, BreakpointUtils, getBreakpointByWindowWidth } from "@style/Theme";
import { IntlMessageObject, LoctoolDescriptor, LoctoolValues, Locale } from "./types";
import { createIntl, createIntlCache } from "react-intl";

export const addUsedIntlKey = (key: string): void => {
    if (process.env.REACT_APP_LOCTOOL_PREVIEW_ENABLED === "true") {
        window._intlKeys = window._intlKeys || new Set();
        window._intlKeys.add(key);
    }
};

export const clearUsedIntlKeys = (): void => {
    if (process.env.REACT_APP_LOCTOOL_PREVIEW_ENABLED === "true") {
        window._intlKeys = new Set();
    }
};

export interface IntlConfig {
    isPreviewMode: boolean;
    forceMobilePreview: boolean;
    locale: Locale;
    formats: any;
}

export type ConfigChangeListener = (intlConfig: IntlConfig) => void;

export class Loctool {
    public static initialized = false;
    public static getInitialConfig(): IntlConfig {
        const locale: Locale = Locale.hu;
        return {
            isPreviewMode: false,
            forceMobilePreview: false,
            locale,
            formats: formats[locale],
        };
    }
    private static config: IntlConfig = Loctool.getInitialConfig();
    private static messages: IntlMessageObject = require("./locales/hu.json");
    public static currentBreakPointMeasured: BreakPoint = BreakPoint.large;
    private static isMobileViewport = () => BreakpointUtils.isLessThan(Loctool.currentBreakPointMeasured, BreakPoint.large);

    static setMessages(locale: Locale, messages: IntlMessageObject): void {
        if (locale !== Loctool.config.locale) {
            console.warn("Got invalid locale from LivePreview, which is not used and not a fallback language, ignoring...");
            return;
        }

        Loctool.messages = messages;
        Loctool.intl = createIntl({ locale: "hu-HU", messages }, createIntlCache());

        Loctool.setConfig({ ...Loctool.config });
    }

    static getMessages(): Readonly<IntlMessageObject> {
        return Loctool.messages;
    }

    static onBreakpointChanged = () => {
        if (typeof window === "undefined") {
            return;
        }
        Loctool.currentBreakPointMeasured = getBreakpointByWindowWidth(window.innerWidth);
        console.debug(`[INTL] Viewport changed, isMobile: ${Loctool.isMobileViewport()}`);
    };

    private static intl = createIntl({ locale: "hu-HU", messages: Loctool.messages }, createIntlCache());

    private static configChangeListeners: ConfigChangeListener[] = [];

    public static addListener(listener: ConfigChangeListener): void {
        Loctool.configChangeListeners.push(listener);
    }

    public static removeListener(listener: ConfigChangeListener): void {
        const index = Loctool.configChangeListeners.findIndex((ccl: ConfigChangeListener): boolean => ccl === listener);
        if (index !== -1) {
            Loctool.configChangeListeners.splice(index, 1);
        }
    }

    public static setPreviewMode(isPreview: boolean): void {
        Loctool.setConfig({ ...Loctool.config, isPreviewMode: isPreview });
    }

    public static setForceMobileView(forceMobilePreview: boolean): void {
        Loctool.setConfig({ ...Loctool.config, forceMobilePreview });
    }

    public static setConfig(newConfig: IntlConfig): void {
        Loctool.config = newConfig;
        Loctool.configChangeListeners.forEach((listener: ConfigChangeListener): void => {
            listener(Loctool.config);
        });
    }

    public static getConfig(): Readonly<IntlConfig> {
        return Loctool.config;
    }

    private static getDescriptorId(id: string): string {
        const mobileId = `${id}:mobile`;
        if ((Loctool.isMobileViewport() || Loctool.getConfig().forceMobilePreview === true) && Loctool.isTranslationExists(mobileId)) {
            return mobileId;
        }
        return id;
    }

    public static isTranslationExists(key: string): boolean {
        return !!Loctool.messages[key];
    }

    public static formatMessage(messageDescriptor: LoctoolDescriptor, values?: LoctoolValues): string {
        const id: string = Loctool.getDescriptorId(messageDescriptor.id);
        addUsedIntlKey(id);
        return Loctool.intl.formatMessage({ ...messageDescriptor, id }, values);
    }

    public static formatHTMLMessage(messageDescriptor: LoctoolDescriptor, values?: LoctoolValues, jsxValues?: { [key: string]: React.ReactNode }): React.ReactNode {
        const id: string = Loctool.getDescriptorId(messageDescriptor.id);
        addUsedIntlKey(id);
        const formattedMessage = Loctool.intl.formatMessage({ ...messageDescriptor, id }, values);

        if (jsxValues) {
            const parts: string[] = formattedMessage.split(/(\{.*?\})/);
            const keys = Object.keys(jsxValues).map(x => `{${x}}`);
            const values = Object.values(jsxValues);
            const outputValues = parts.map((part: string): React.ReactNode => {
                const indexOfValue = keys.indexOf(part);
                if (indexOfValue !== -1) {
                    return values[indexOfValue];
                }
                return part;
            });

            return (
                <>
                    {outputValues.map((value, index) => {
                        if (typeof value === "string") {
                            return <span key={index} dangerouslySetInnerHTML={{ __html: value }} />;
                        }
                        return <React.Fragment key={index}>{value}</React.Fragment>;
                    })}
                </>
            );
        }

        return <span dangerouslySetInnerHTML={{ __html: formattedMessage }} />;
    }

    public static formatNumber(value: number, opts?: { format?: string }): string {
        return Loctool.intl.formatNumber(value, opts);
    }

    public static formatCurrency(amount: number): string {
        return `${amount.toLocaleString("hu")} Ft`;
    }
}

if (Loctool.initialized === false) {
    Loctool.initialized = true;
    console.debug("[INTL] initializing, adding listeners");
    const onWindowSizeChange = () => {
        const newBreakPoint = getBreakpointByWindowWidth(window.innerWidth);
        if (newBreakPoint !== Loctool.currentBreakPointMeasured) {
            Loctool.onBreakpointChanged();
        }
    };
    window.addEventListener("resize", onWindowSizeChange);
}
