import { ActionsUnion, createAction } from "./actionHelpers";
import { Gql } from "@src/api/Api";

export enum LegalDocumentActionTypes {
    UPDATE_LEGAL_DOCUMENTS = "LegalDocumentAction/UPDATE_LEGAL_DOCUMENTS",
}

export const LegalDocumentActions = {
    update: (effectiveFrom: string, legalDocuments: Gql.LegalDocument[]) => createAction(LegalDocumentActionTypes.UPDATE_LEGAL_DOCUMENTS, { legalDocuments, effectiveFrom }),
};

export type LegalDocumentActions = ActionsUnion<typeof LegalDocumentActions>;
