import * as React from "react";
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgIcon32Ski({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg width={32} height={32} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" focusable={false} aria-hidden={true} aria-labelledby={titleId} {...props}>
            {title ? <title id={titleId}>{title}</title> : null}
            <g fill="none" fillRule="evenodd">
                <circle stroke="currentColor" cx={26} cy={6.5} r={2.5} />
                <path
                    d="M29 25.5v.232a.5.5 0 01-.223.416L26.42 27.72a1 1 0 01-.893.11L1.33 19.118a.5.5 0 01-.331-.47V18.5a.351.351 0 01.47-.33L26 27l2.584-1.723A.268.268 0 0129 25.5zM3.472 6.164L26 14v1L3.336 7.117A.5.5 0 013 6.645V6.5a.355.355 0 01.472-.336z"
                    fill="currentColor"
                    fillRule="nonzero"
                />
                <path
                    d="M11.958 22.192l3.909 1.473 4.964-5.203a.5.5 0 00-.053-.754l-3.54-2.654 3.262-3.261v2.765a1.5 1.5 0 001.026 1.424l5.735 1.911A.942.942 0 0028.5 17v-.558a1.5 1.5 0 00-1.026-1.424l-5.106-1.702.826-2.48a1.5 1.5 0 00-.223-1.374L22.1 8.3a2.332 2.332 0 00-3.023-.626L14.3 10.4l-.965.724a2.5 2.5 0 00-.1 3.92l3.535 2.947-4.812 4.201zm7.824-6.48a2.495 2.495 0 01-.282-1.154v-.35l-.739.738 1.02.765z"
                    stroke="currentColor"
                />
            </g>
        </svg>
    );
}

export default SvgIcon32Ski;
