import * as React from "react";
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgLogoUniqaFlat({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={366}
            height={275}
            viewBox="0 0 366 275"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            focusable={false}
            aria-hidden={true}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M365.38 272.693l-29.568-36.199c-.435-.49-.761-.87-1.414-.87 0 0-48.483-.054-60.605-3.479-.108-.054-.162-.162-.054-.217 7.175-7.5 38.048-39.896 38.048-94.793C311.787 72.345 261.564 0 174.706 0 86.164 0 37.626 74.574 37.626 137.081c.815 41.145 16.143 72.127 42.342 98.272.109.108.054.271-.11.271h-61.31c-.598 0-1.141.326-1.413.87L.068 272.857a.768.768 0 00.706 1.087h173.878c20.111 0 39.298-4.077 56.528-11.904h.109c15.056 5.381 31.09 8.642 47.777 9.566 46.309 3.533 85.716 2.338 85.716 2.338.652 0 .979-.761.598-1.251zM74.37 136.972c0-27.992 12.12-56.528 36.036-75.878.109-.109.326.054.217.217-8.533 14.947-16.47 53.539-8.588 84.792 15.002 59.517 60.605 86.097 66.475 89.304.163.054.055.272-.109.272-56.908-3.86-94.031-51.42-94.031-98.707zm148.711 84.411h-.108c-16.143-4.511-86.477-44.244-86.477-114.741 0-57.397 35.221-80.009 67.779-80.009 53.05 0 73.269 60.66 73.269 91.478 0 44.842-27.884 87.999-54.463 103.272z"
                fill="currentColor"
            />
        </svg>
    );
}

export default SvgLogoUniqaFlat;
