import React from "react";
import styled, { ThemeContext } from "styled-components";
import { bp } from "@style/Theme";
import { Container } from "@components/LayoutElements";
import { Flex } from "@components/Flex";
import SvgLogoUniqaHorizontal from "@components/svg/LogoUniqaHorizontal";

interface Props {
    $justifyContent?: "flex-start" | "flex-end" | "center" | "space-between" | "space-around" | "space-evenly";
}

export const AppBar = (props: Props) => {
    const themeContext = React.useContext(ThemeContext);
    const { $justifyContent } = props;

    return (
        <StickyWrapper>
            <AppBarWrapper>
                <Container $maxWidth={1024} $px={16}>
                    <Flex.Container $justifyContent={$justifyContent ?? "center"} $alignItems="center" $style={{ minHeight: 64 }}>
                        <Flex.Item $shrink="shrink" $styleMedium={{ transform: "translateY(-4px)" }}>
                            <SvgLogoUniqaHorizontal title="UNIQA" focusable={undefined} aria-hidden={undefined} color={themeContext.color.blueN} />
                        </Flex.Item>
                    </Flex.Container>
                </Container>
            </AppBarWrapper>
        </StickyWrapper>
    );
};

const AppBarWrapper = styled.div`
    background-color: ${({ theme }) => theme.color.white};
    box-shadow: inset 0px -1px 0px ${({ theme }) => theme.color.greyD};

    ${bp.medium} {
        box-shadow: inset 0px -1px 0px ${({ theme }) => theme.color.greyN};
    }
`;

const StickyWrapper = styled.div`
    position: sticky;
    top: 0;
    z-index: ${({ theme }) => theme.zIndex.appBar};
`;
