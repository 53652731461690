import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { AppStateActions } from "@redux/actions/appStateActions";
import { ApplicationState } from "@redux/reducers";
import { Notification } from "@redux/reducers/appStateReducer";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "styled-components";
import { keyframesSlideDownAndBackWithOpacity } from "@style/GlobalStyle";
import { Flex } from "./Flex";
import { Box } from "./LayoutElements";
import SvgIcon32Tick from "./svg/Icon32Tick";
import SvgIcon32X from "./svg/Icon32X";

const NOTIFICATION_DEFAULT_TIMEOUT = 3000;

export const NotificationBar = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const notifications = useSelector((state: ApplicationState) => state.appState.notifications);
    const [currentNotification, setCurrentNotification] = useState<Notification | null>(notifications[0]);

    useEffect(() => {
        if (!currentNotification && notifications.length > 0) {
            const newNotification = notifications[0];
            setCurrentNotification(newNotification);
            setTimeout(() => {
                dispatch(AppStateActions.removeNotification(newNotification.id));
                setCurrentNotification(null);
            }, newNotification.timeout ?? NOTIFICATION_DEFAULT_TIMEOUT);
        }
    }, [currentNotification, dispatch, notifications]);

    const isSuccess = currentNotification?.type === "success";
    return (
        <div role="alert">
            {currentNotification && (
                <NotificationWrapper $animationDuration={currentNotification.timeout ?? NOTIFICATION_DEFAULT_TIMEOUT}>
                    <Box $style={{ color: theme.color.white, backgroundColor: isSuccess ? theme.color.greenN : theme.color.redN, borderRadius: 2, padding: "12px 16px" }}>
                        <Flex.Container $gutterMargin={16}>
                            <Flex.Item $shrink="shrink" $gutterMargin={16}>
                                <Box as={isSuccess ? SvgIcon32Tick : SvgIcon32X} $style={{ borderRadius: "50%", backgroundColor: theme.color.white, color: theme.color.blueD }} />
                            </Flex.Item>

                            <Flex.Item $shrink="auto" $gutterMargin={16} $style={{ padding: `${(32 - theme.typo.body.lineHeight) / 2}px 0` }}>
                                {currentNotification.message}
                            </Flex.Item>
                        </Flex.Container>
                    </Box>
                </NotificationWrapper>
            )}
        </div>
    );
};

const NotificationWrapper = styled.div<{ $animationDuration: number }>`
    animation: ${keyframesSlideDownAndBackWithOpacity} ${({ $animationDuration }) => $animationDuration}ms forwards;
    left: 0;
    position: fixed;
    right: 0;
    top: ${({ theme }) => theme.layout.appBar.height}px;
    z-index: ${({ theme }) => theme.zIndex.snackbar};
`;
