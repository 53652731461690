import { ActionsUnion, createAction } from "./actionHelpers";

let NOTIFICATION_ID = 1;

export enum AppStateActionTypes {
    ADD_LOADING = "AppStateAction/ADD_LOADING",
    REMOVE_LOADING = "AppStateAction/REMOVE_LOADING",
    ADD_NOTIFICATION = "AppStateAction/ADD_NOTIFICATION",
    REMOVE_NOTIFICATION = "AppStateAction/REMOVE_NOTIFICATION",
    RESET = "AppStateAction/RESET",
}

export const AppStateActions = {
    addLoading: () => createAction(AppStateActionTypes.ADD_LOADING),
    removeLoading: () => createAction(AppStateActionTypes.REMOVE_LOADING),
    addNotification: (message: string, type: "error" | "success", timeout?: number) =>
        createAction(AppStateActionTypes.ADD_NOTIFICATION, { notification: { id: NOTIFICATION_ID++, message, type, timeout } }),
    removeNotification: (id: number) => createAction(AppStateActionTypes.REMOVE_NOTIFICATION, { id }),
    reset: () => createAction(AppStateActionTypes.RESET),
};

export type AppStateActions = ActionsUnion<typeof AppStateActions>;
