import styled, { css, CSSObject } from "styled-components";
import { bp, breakpoints } from "@style/Theme";
import { listCSSReset, tableCSSReset } from "@style/GlobalStyle";

export function sortObjKeysAlphabetically(obj: CSSObject) {
    const ordered: CSSObject = {};
    Object.keys(obj)
        .sort()
        .forEach(key => {
            ordered[key] = obj[key];
        });
    return ordered;
}

// Note: General component for local styling. You may use the $style or css prop ( https://styled-components.com/docs/api#css-prop ) instead of inline styles.
// Warning: When using css prop: every instance creates new className even if the content is the same. Ex.: <><Box css={{ marginBottom: 30 }} /><Box css={{ marginBottom: 30 }} /></> will be <style>.x{margin-bottom:30px;}.y{margin-bottom:30px;}</style>
export interface BoxProps {
    $style?: CSSObject;
    $styleSmall?: CSSObject;
    $styleMedium?: CSSObject;
    $styleLarge?: CSSObject;
    $styleXLarge?: CSSObject;
    $styleXXLarge?: CSSObject;
}

export const Box = styled.div<BoxProps>`
    ${({ $style }) => ($style && Object.keys($style).length !== 0 ? sortObjKeysAlphabetically($style) : {})}

    ${({ $styleSmall }) => ($styleSmall && Object.keys($styleSmall).length !== 0 ? ` ${bp.small} { ${css(sortObjKeysAlphabetically($styleSmall)).join("")} } ` : {})}

    ${({ $styleMedium }) => ($styleMedium && Object.keys($styleMedium).length !== 0 ? ` ${bp.medium} { ${css(sortObjKeysAlphabetically($styleMedium)).join("")} } ` : {})}

    ${({ $styleLarge }) => ($styleLarge && Object.keys($styleLarge).length !== 0 ? ` ${bp.large} { ${css(sortObjKeysAlphabetically($styleLarge)).join("")} } ` : {})}

    ${({ $styleXLarge }) => ($styleXLarge && Object.keys($styleXLarge).length !== 0 ? ` ${bp.xlarge} { ${css(sortObjKeysAlphabetically($styleXLarge)).join("")} } ` : {})}

    ${({ $styleXXLarge }) => ($styleXXLarge && Object.keys($styleXXLarge).length !== 0 ? ` ${bp.xxlarge} { ${css(sortObjKeysAlphabetically($styleXXLarge)).join("")} } ` : {})}
`;

interface ContainerProps {
    $maxWidth?: number;
    // Note: paddingHorizontal
    $px?: number;
}

export const Container = styled(Box)<ContainerProps>`
    margin-left: auto;
    margin-right: auto;
    max-width: ${props => (props.$maxWidth ? props.$maxWidth + (props.$px ? props.$px * 2 : 0) : breakpoints.xlarge + (props.$px ? props.$px * 2 : 0))}px;
    padding-left: ${({ $px }) => ($px ? `${$px}px` : "")};
    padding-right: ${({ $px }) => ($px ? `${$px}px` : "")};
`;

export const UL = styled.ul<{ cssReset?: boolean }>`
    ${({ cssReset }) => (cssReset ? `${listCSSReset}` : "")}
`;

export const Table = styled.table<{ cssReset?: boolean }>`
    ${({ cssReset }) => (cssReset ? `${tableCSSReset}` : "")}
`;

// Note: horizontal top separator lines between first children.
export const SeparatorDecoratorContainer = styled.div`
    > * {
        + * {
            box-shadow: inset 0px 1px 0px ${({ theme }) => theme.color.greyN};
        }
    }
`;
