import * as React from "react";
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgIcon32Death({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg width={32} height={32} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" focusable={false} aria-hidden={true} aria-labelledby={titleId} {...props}>
            {title ? <title id={titleId}>{title}</title> : null}
            <g transform="translate(4 4)" fill="none" fillRule="evenodd">
                <rect stroke="currentColor" x={0.5} y={20.5} width={23} height={3} rx={1} />
                <path d="M2.5 20.5h19V10a9.5 9.5 0 00-19 0v10.5z" stroke="currentColor" />
                <path fill="currentColor" d="M9 7h6v1H9zM6 12h12v1H6z" />
            </g>
        </svg>
    );
}

export default SvgIcon32Death;
