import * as React from "react";
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgIcon32Delete({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg width={33} height={32} viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg" focusable={false} aria-hidden={true} aria-labelledby={titleId} {...props}>
            {title ? <title id={titleId}>{title}</title> : null}
            <path d="M13 8a.5.5 0 01.5-.5h6a.5.5 0 01.5.5v1.5h-7V8z" stroke="currentColor" />
            <rect x={13.5} y={13} width={1} height={8} rx={0.5} fill="currentColor" />
            <rect x={18.5} y={13} width={1} height={8} rx={0.5} fill="currentColor" />
            <path d="M10 10a.5.5 0 01.5-.5h12a.5.5 0 01.5.5v13a1.5 1.5 0 01-1.5 1.5h-10A1.5 1.5 0 0110 23V10z" stroke="currentColor" />
            <rect x={7.5} y={9} width={18} height={1} rx={0.5} fill="currentColor" />
        </svg>
    );
}

export default SvgIcon32Delete;
