// https://css-tricks.com/snippets/css/a-guide-to-flexbox/
import styled from "styled-components";
import { bp } from "@style/Theme";
import { listCSSReset } from "@style/GlobalStyle";
import { Box } from "@components/LayoutElements";

// Item
type ItemShrinkType = "auto" | "shrink" | number;

export type ItemProps = {
    $alignSelf?: "auto" | "flex-start" | "flex-end" | "center" | "baseline" | "stretch";
    $order?: number;
    // Note: $shrink defines Item's width. Number: 0-1 is interpreted in percents. If $shrink > 1, it is interpreted in pixels.
    $shrink?: ItemShrinkType;
    $gutterMargin?: number;
} & {
    $bpSmall?: ItemProps;
    $bpMedium?: ItemProps;
    $bpLarge?: ItemProps;
    $bpXLarge?: ItemProps;
    $bpXXLarge?: ItemProps;
};

const itemWidth = (shrink: ItemShrinkType, $gutterMargin: number) => {
    if (typeof shrink === "number" && shrink > 1) {
        return `width: ${shrink}px;`;
    }

    return `width: ${
        shrink === "shrink"
            ? "auto"
            : shrink !== "auto" && shrink >= 0 && shrink <= 1
            ? $gutterMargin
                ? `calc(${parseFloat((shrink * 100).toFixed(4))}% - ${$gutterMargin}px)`
                : shrink > 0
                ? `${parseFloat((shrink * 100).toFixed(4))}%`
                : ""
            : $gutterMargin
            ? `calc(100% - ${$gutterMargin}px)`
            : "100%"
    };`;
};

const itemBpStyles = (props: ItemProps) => {
    return `
    align-self: ${props.$alignSelf || ""};
    flex: ${props.$shrink === "auto" ? "1 1 0px" : "0 0 auto"};
    ${
        props.$gutterMargin || props.$gutterMargin === 0
            ? `
    margin-left: ${props.$gutterMargin / 2}px;
    margin-right: ${props.$gutterMargin / 2}px;
    `
            : ""
    };
    order: ${props.$order ?? ""};
    ${itemWidth(props.$shrink ?? 0, props.$gutterMargin ?? 0)}
  `;
};

const StyledItem = styled(Box)<ItemProps>`
    align-self: ${({ $alignSelf }) => $alignSelf || ""};
    flex: ${({ $shrink }) => ($shrink === "auto" ? "1 1 0px" : "0 0 auto")};
    ${({ $gutterMargin }) =>
        $gutterMargin
            ? `
    margin-left: ${$gutterMargin / 2}px;
    margin-right: ${$gutterMargin / 2}px;
    `
            : ""};
    min-height: 0;
    min-width: 0;
    order: ${({ $order }) => $order || ""};
    ${({ $shrink, $gutterMargin }) => itemWidth($shrink ?? 0, $gutterMargin ?? 0)}

    /* TODO: refactor breakpoint logic */
    ${({ $bpSmall }) =>
        $bpSmall
            ? `
    ${bp.small} {
      ${itemBpStyles($bpSmall)}
    }
    `
            : ""}

    ${({ $bpMedium }) =>
        $bpMedium
            ? `
    ${bp.medium} {
      ${itemBpStyles($bpMedium)}
    }
    `
            : ""}

    ${({ $bpLarge }) =>
        $bpLarge
            ? `
    ${bp.large} {
      ${itemBpStyles($bpLarge)}
    }
    `
            : ""}

    ${({ $bpXLarge }) =>
        $bpXLarge
            ? `
    ${bp.xlarge} {
      ${itemBpStyles($bpXLarge)}
    }
    `
            : ""}

    ${({ $bpXXLarge }) =>
        $bpXXLarge
            ? `
    ${bp.xxlarge} {
      ${itemBpStyles($bpXXLarge)}
    }
    `
            : ""}
`;

// Container
export type ContainerProps = {
    $alignContent?: "flex-start" | "flex-end" | "center" | "space-between" | "space-around" | "space-evenly";
    $alignItems?: "stretch" | "flex-start" | "flex-end" | "center" | "baseline";
    $display?: "flex" | "inline-flex";
    $flexDirection?: "row" | "row-reverse" | "column" | "column-reverse";
    $flexWrap?: "nowrap" | "wrap" | "wrap-reverse";
    $justifyContent?: "flex-start" | "flex-end" | "center" | "space-between" | "space-around" | "space-evenly";
    $gutterMargin?: number;
} & {
    $bpSmall?: ContainerProps;
    $bpMedium?: ContainerProps;
    $bpLarge?: ContainerProps;
    $bpXLarge?: ContainerProps;
    $bpXXLarge?: ContainerProps;
};

const containerBpStyles = (props: ContainerProps) => {
    return `
    align-content: ${props.$alignContent || ""};
    align-items: ${props.$alignItems || ""};
    display: ${props.$display || "flex"};
    flex-direction: ${props.$flexDirection || ""};
    flex-wrap: ${props.$flexWrap || ""};
    justify-content: ${props.$justifyContent || ""};
    ${
        props.$gutterMargin || props.$gutterMargin === 0
            ? `
    margin-left: ${(props.$gutterMargin / 2) * -1}px;
    margin-right: ${(props.$gutterMargin / 2) * -1}px;
    `
            : ""
    };
    ${
        props.$display === "inline-flex"
            ? `
        vertical-align: bottom;
    `
            : ""
    }
  `;
};

const StyledContainer = styled(Box)<ContainerProps & { as?: unknown }>`
    align-content: ${props => props.$alignContent || ""};
    align-items: ${props => props.$alignItems || ""};
    display: ${props => props.$display || "flex"};
    flex-direction: ${props => props.$flexDirection || ""};
    flex-wrap: ${props => props.$flexWrap || "nowrap"};
    justify-content: ${props => props.$justifyContent || ""};
    ${props =>
        props.$gutterMargin
            ? `
    margin-left: ${(props.$gutterMargin / 2) * -1}px;
    margin-right: ${(props.$gutterMargin / 2) * -1}px;
    `
            : ""};

    ${props =>
        props.$display === "inline-flex"
            ? `
        vertical-align: bottom;
    `
            : ""}

    ${({ as }) => (as === "ol" || as === "ul" ? `${listCSSReset}` : "")}

    ${({ $bpSmall }) =>
        $bpSmall
            ? `
    ${bp.small} {
      ${containerBpStyles($bpSmall)}
    }
    `
            : ""}

    ${({ $bpMedium }) =>
        $bpMedium
            ? `
    ${bp.medium} {
      ${containerBpStyles($bpMedium)}
    }
    `
            : ""}

    ${({ $bpLarge }) =>
        $bpLarge
            ? `
    ${bp.large} {
      ${containerBpStyles($bpLarge)}
    }
    `
            : ""}

    ${({ $bpXLarge }) =>
        $bpXLarge
            ? `
    ${bp.xlarge} {
      ${containerBpStyles($bpXLarge)}
    }
    `
            : ""}

    ${({ $bpXXLarge }) =>
        $bpXXLarge
            ? `
    ${bp.xxlarge} {
      ${containerBpStyles($bpXXLarge)}
    }
    `
            : ""}
`;

export const Flex = {
    Container: StyledContainer,
    Item: StyledItem,
};
