import { Gql } from "@api/Api";
import { ActionsUnion, createAction } from "./actionHelpers";

export type TravelOffer = {
    destinationId: number;
    departure: string;
    return: string;
    adultsCount: number;
    childrenCount: number;
    tariff: Gql.Amount | null;
};

export type AccountData = {
    email: string;
    phoneNumber: string;
    phoneNumberPrefix: string;
    firstName: string;
    lastName: string;
    dateOfBirth: string;
};

export type HomeAddressData = {
    zipCode: string;
    city: string;
    address: string;
    countryCode: Gql.CountryCode;
};

export type Passenger = {
    firstName: string;
    lastName: string;
    dateOfBirth: string;
};

export enum SessionActionTypes {
    UPDATE_TRAVEL_OFFER = "SessionAction/UPDATE_TRAVEL_OFFER",
    UPDATE_ACCOUNT_DATA = "SessionAction/UPDATE_ACCOUNT_DATA",
    UPDATE_HOME_ADDRESS_DATA = "SessionAction/UPDATE_HOME_ADDRESS_DATA",
    UPDATE_ARE_YOU_TRAVELING = "SessionAction/UPDATE_ARE_YOU_TRAVELING",
    UPDATE_PASSENGERS = "SessionAction/UPDATE_PASSENGERS",
    RESET_TRAVEL_OFFER = "SessionAction/RESET_TRAVEL_OFFER",
    UPDATE_SENT_SMS_COUNT = "SessionAction/UPDATE_SENT_SMS_COUNT",
}

export const SessionActions = {
    updateTravelOffer: (travelOffer: TravelOffer, isChanged?: true) => createAction(SessionActionTypes.UPDATE_TRAVEL_OFFER, { travelOffer, isChanged }),
    updateAccountData: (accountData: AccountData) => createAction(SessionActionTypes.UPDATE_ACCOUNT_DATA, { accountData }),
    updateHomeAddressData: (homeAddressData: HomeAddressData) => createAction(SessionActionTypes.UPDATE_HOME_ADDRESS_DATA, { homeAddressData }),
    updateAreYouTraveling: (areYouTraveling: boolean) => createAction(SessionActionTypes.UPDATE_ARE_YOU_TRAVELING, { areYouTraveling }),
    updatePassengers: (adults: Passenger[], children: Passenger[]) => createAction(SessionActionTypes.UPDATE_PASSENGERS, { passengers: { adults, children } }),
    reset: () => createAction(SessionActionTypes.RESET_TRAVEL_OFFER),
    updateSentSmsCount: () => createAction(SessionActionTypes.UPDATE_SENT_SMS_COUNT),
};

export type SessionActions = ActionsUnion<typeof SessionActions>;
