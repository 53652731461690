import { gql } from "@apollo/client";

export const LegalDocumentFragment = gql`
    fragment LegalDocument on LegalDocumentWL {
        category
        interaction
        version
        effectiveFrom
        effectiveUntil
        name
        url
    }
`;
