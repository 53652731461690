import React, { useMemo } from "react";
import { ThemeContext } from "styled-components";
import { Box } from "./LayoutElements";

interface Props {
    id: string;
    message?: React.ReactNode | null;
}

export const ErrorMessage = ({ id, message }: Props) => {
    const theme = React.useContext(ThemeContext);
    const style = useMemo(() => {
        if (message) {
            return { paddingTop: 8, paddingBottom: 16, color: theme.color.redN };
        }
        return {};
    }, [message, theme.color.redN]);
    return (
        <Box id={id} aria-live="assertive" $style={style}>
            {message}
        </Box>
    );
};
