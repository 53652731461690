import React from "react";
import { Accordion } from "@components/Accordion";
import { Card } from "@components/Card";
import { Box } from "@components/LayoutElements";
import SvgIcon32TravelAbroad from "@components/svg/Icon32TravelAbroad";
import { ApplicationState } from "@redux/reducers";
import { DateUtils, DateFormat } from "@utils/DateUtils";
import { useSelector } from "react-redux";
import { LoctoolMessage } from "@i18n/i18n";

export const OverviewTravelCard = () => {
    const travelOffer = useSelector((state: ApplicationState) => state.session.travelOffer);
    return (
        <Card.Wrapper>
            <Accordion
                id="travel-offer"
                headingText={<LoctoolMessage id="page.overview.card.travel.title" />}
                headingIconElement={<SvgIcon32TravelAbroad />}
                variant="blueN"
                isInitiallyOpened
            >
                <Card.Table>
                    <thead>
                        <tr>
                            <th colSpan={2} scope="colgroup">
                                <Box $style={{ fontWeight: 700 }}>
                                    <LoctoolMessage id="page.overview.card.travel.details" />
                                </Box>
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <Box as="th" $style={{ width: "50%" }} scope="row">
                                <LoctoolMessage id="page.overview.card.travel.departure" />
                            </Box>
                            <Box as="td" $style={{ width: "50%" }}>
                                {DateUtils.format(DateUtils.parse(travelOffer.departure), DateFormat.medium)}
                            </Box>
                        </tr>

                        <tr>
                            <Box as="th" $style={{ width: "50%" }} scope="row">
                                <LoctoolMessage id="page.overview.card.travel.return" />
                            </Box>
                            <Box as="td" $style={{ width: "50%" }}>
                                {DateUtils.format(DateUtils.parse(travelOffer.return), DateFormat.medium)}
                            </Box>
                        </tr>

                        <tr>
                            <Box as="th" $style={{ width: "50%" }} scope="row">
                                <LoctoolMessage id="page.overview.card.travel.type" />
                            </Box>
                            <Box as="td" $style={{ width: "50%" }}>
                                <LoctoolMessage id="page.overview.card.travel.insuranceType" />
                            </Box>
                        </tr>
                    </tbody>
                </Card.Table>
            </Accordion>
        </Card.Wrapper>
    );
};
