import React from "react";
import { ThemeContext } from "styled-components";
import { Box, Container } from "@components/LayoutElements";
import { Flex } from "@components/Flex";
import { Text } from "@components/Text";
import SvgIcon32Envelope from "@components/svg/Icon32Envelope";
import SvgIcon32SpeechBubble from "@components/svg/Icon32SpeechBubble";
import { LegalDocumentSelectors } from "@redux/selectors/LegalDocumentSelectors";
import { Gql } from "@api/Api";
import { useSelector } from "react-redux";
import { Loctool, LoctoolMessage } from "@i18n/i18n";
import { Helpers } from "@utils/Helpers";
import { GtmUtils } from "@utils/gtm/GtmUtils";

export const Footer = () => {
    const themeContext = React.useContext(ThemeContext);
    const privacyPolicyDocument = useSelector(LegalDocumentSelectors.getDocumentByCategory(Gql.legalDocumentCategory.WL_HU_PRIVACY_POLICY));
    const complaintDocument = useSelector(LegalDocumentSelectors.getDocumentByCategory(Gql.legalDocumentCategory.WL_HU_COMPLAINT));
    return (
        <Box as="footer" $style={{ marginTop: "auto" }}>
            <Container $maxWidth={504} $style={{ paddingTop: 32, paddingBottom: 16 }}>
                <Flex.Container $alignItems="center" $justifyContent="space-around" $style={{ position: "relative" }}>
                    <Flex.Item $shrink="shrink" $style={{ maxWidth: "50%" }}>
                        <Box
                            $style={{ display: "table-caption", marginLeft: 8, marginRight: 8, padding: "8px 16px" }}
                            $styleMedium={{ display: "block", marginLeft: 12, marginRight: 12 }}
                        >
                            <Box
                                as="a"
                                target="_blank"
                                href={Helpers.getLegalDocumentPdfLink(privacyPolicyDocument)}
                                $style={{ display: "inline-block", color: themeContext.color.blueN }}
                                className={GtmUtils.className.privacyPolicy}
                            >
                                <LoctoolMessage id={`enum.legalDocumentCategory.${Gql.legalDocumentCategory.WL_HU_PRIVACY_POLICY}`} />
                            </Box>
                        </Box>
                    </Flex.Item>

                    <Box $style={{ position: "absolute", top: 8, bottom: 8, marginLeft: "auto", marginRight: "auto", width: 1, backgroundColor: themeContext.color.blueN }} />

                    <Flex.Item $shrink="shrink" $style={{ maxWidth: "50%" }}>
                        <Box
                            $style={{ display: "table-caption", marginLeft: 8, marginRight: 8, padding: "8px 16px" }}
                            $styleMedium={{ display: "block", marginLeft: 12, marginRight: 12 }}
                        >
                            <Box
                                as="a"
                                target="_blank"
                                href={Helpers.getLegalDocumentPdfLink(complaintDocument)}
                                $style={{ display: "inline-block", color: themeContext.color.blueN }}
                                className={GtmUtils.className.complaint}
                            >
                                <LoctoolMessage id={`enum.legalDocumentCategory.${Gql.legalDocumentCategory.WL_HU_COMPLAINT}`} />
                            </Box>
                        </Box>
                    </Flex.Item>
                </Flex.Container>
            </Container>

            <Box $style={{ backgroundColor: themeContext.color.white }}>
                <Container $maxWidth={504} $px={16} $styleMedium={{ textAlign: "center" }}>
                    <Box $style={{ padding: 16 }}>
                        <Text as="h2" $fontSize="text16" $style={{ margin: 0 }}>
                            <LoctoolMessage id="component.footer.canWeHelp" />
                        </Text>

                        <Text as="p" $fontSize="text16" $style={{ margin: 0 }}>
                            <LoctoolMessage id="component.footer.contactUs" />
                        </Text>
                    </Box>

                    <Flex.Container $flexWrap="wrap" $styleMedium={{ boxShadow: `inset 0px -1px 0px ${themeContext.color.greyN}` }}>
                        <Flex.Item $shrink={1} $style={{ padding: "12px 16px", boxShadow: `inset 0px -1px 0px ${themeContext.color.greyN}` }} $bpMedium={{ $shrink: "auto" }}>
                            <Flex.Container $alignItems="center" $gutterMargin={16} $bpMedium={{ $gutterMargin: 8 }}>
                                <Flex.Item $shrink="shrink" $gutterMargin={16} $bpMedium={{ $shrink: "shrink", $gutterMargin: 8 }}>
                                    <SvgIcon32Envelope />
                                </Flex.Item>

                                <Flex.Item $shrink="shrink" $gutterMargin={16} $bpMedium={{ $shrink: "shrink", $gutterMargin: 8 }}>
                                    <a href={`mailto:${Loctool.formatMessage({ id: "component.footer.uniqaTravelEmail" })}`} className={GtmUtils.className.email}>
                                        <LoctoolMessage id="component.footer.uniqaTravelEmail" />
                                    </a>
                                </Flex.Item>
                            </Flex.Container>
                        </Flex.Item>

                        <Flex.Item $shrink={1} $style={{ padding: "12px 16px" }} $bpMedium={{ $shrink: "auto" }}>
                            <Flex.Container $alignItems="center" $gutterMargin={16} $bpMedium={{ $gutterMargin: 8 }}>
                                <Flex.Item $shrink="shrink" $gutterMargin={16} $bpMedium={{ $shrink: "shrink", $gutterMargin: 8 }}>
                                    <SvgIcon32SpeechBubble />
                                </Flex.Item>

                                <Flex.Item $shrink="shrink" $gutterMargin={16} $bpMedium={{ $shrink: "shrink", $gutterMargin: 8 }}>
                                    <a
                                        href={`tel:${Loctool.formatMessage({ id: "component.footer.uniqaTravelTel" }).replaceAll(" ", "")}`}
                                        className={GtmUtils.className.phoneNumber}
                                    >
                                        <LoctoolMessage id="component.footer.uniqaTravelTel" />
                                    </a>
                                </Flex.Item>
                            </Flex.Container>
                        </Flex.Item>
                    </Flex.Container>

                    <Box $style={{ padding: "24px 16px" }} $styleMedium={{ paddingTop: 48, paddingBottom: 32 }}>
                        <Box as="span" $style={{ display: "inline-block" }}>
                            <LoctoolMessage id="component.footer.poweredBy" />
                        </Box>{" "}
                        <Box as="span" $style={{ display: "inline-block", fontWeight: 700 }}>
                            <LoctoolMessage id="component.footer.cherriskTechnologies" />
                        </Box>
                    </Box>
                </Container>
            </Box>
        </Box>
    );
};
