import React from "react";
import { Accordion } from "@components/Accordion";
import { Card } from "@components/Card";
import { Box } from "@components/LayoutElements";
import SvgIcon32Adult from "@components/svg/Icon32Adult";
import { useSelector } from "react-redux";
import { ApplicationState } from "@redux/reducers";
import { DateUtils, DateFormat } from "@utils/DateUtils";
import { LoctoolHTMLMessage, LoctoolMessage } from "@i18n/i18n";
import { Helpers } from "@utils/Helpers";

export const OverviewUserCard = () => {
    const session = useSelector((state: ApplicationState) => state.session);
    return (
        <Card.Wrapper>
            {/* Note: Accordion slugifies the id. But needs to be unique. */}
            <Accordion
                id="contractor"
                headingLevel={3}
                headingText={`${session.accountData.lastName} ${session.accountData.firstName}`}
                headingIconElement={<SvgIcon32Adult />}
                variant="white"
                isInitiallyOpened
            >
                <Card.Table>
                    <thead>
                        <tr>
                            <th colSpan={2} scope="colgroup">
                                <Box $style={{ fontWeight: 700 }}>
                                    <LoctoolMessage id="page.overview.card.contractor.title" />
                                </Box>
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <Box as="th" $style={{ width: "50%" }} scope="row">
                                <LoctoolMessage id="page.overview.card.contractor.lastName" />
                            </Box>
                            <Box as="td" $style={{ width: "50%" }}>
                                {session.accountData.lastName}
                            </Box>
                        </tr>

                        <tr>
                            <Box as="th" $style={{ width: "50%" }} scope="row">
                                <LoctoolMessage id="page.overview.card.contractor.firstName" />
                            </Box>
                            <Box as="td" $style={{ width: "50%" }}>
                                {session.accountData.firstName}
                            </Box>
                        </tr>

                        <tr>
                            <Box as="th" $style={{ width: "50%" }} scope="row">
                                <LoctoolMessage id="page.overview.card.contractor.dateOfBirth" />
                            </Box>
                            <Box as="td" $style={{ width: "50%" }}>
                                {DateUtils.format(DateUtils.parse(session.accountData.dateOfBirth), DateFormat.medium)}
                            </Box>
                        </tr>

                        <tr>
                            <Box as="th" $style={{ width: "50%" }} scope="row">
                                <LoctoolMessage id="page.overview.card.contractor.email" />
                            </Box>
                            <Box as="td" $style={{ width: "50%" }}>
                                <Box $style={{ wordBreak: "break-all" }}>{session.accountData.email}</Box>
                            </Box>
                        </tr>

                        <tr>
                            <Box as="th" $style={{ width: "50%" }} scope="row">
                                <LoctoolHTMLMessage id="page.overview.card.contractor.phoneNumber" />
                            </Box>
                            <Box as="td" $style={{ width: "50%" }}>
                                {Helpers.formatPhoneNumber(session.accountData.phoneNumberPrefix, session.accountData.phoneNumber)}
                            </Box>
                        </tr>

                        <tr>
                            <Box as="th" $style={{ width: "50%" }} scope="row">
                                <LoctoolMessage id="page.overview.card.contractor.zipCode" />
                            </Box>
                            <Box as="td" $style={{ width: "50%" }}>
                                {session.homeAddressData.zipCode}
                            </Box>
                        </tr>

                        <tr>
                            <Box as="th" $style={{ width: "50%" }} scope="row">
                                <LoctoolMessage id="page.overview.card.contractor.city" />
                            </Box>
                            <Box as="td" $style={{ width: "50%" }}>
                                {session.homeAddressData.city}
                            </Box>
                        </tr>

                        <tr>
                            <Box as="th" $style={{ width: "50%" }} scope="row">
                                <LoctoolMessage id="page.overview.card.contractor.address" />
                            </Box>
                            <Box as="td" $style={{ width: "50%" }}>
                                {session.homeAddressData.address}
                            </Box>
                        </tr>

                        <tr>
                            <Box as="th" $style={{ width: "50%" }} scope="row">
                                <LoctoolMessage id="page.overview.card.contractor.countryCode" />
                            </Box>
                            <Box as="td" $style={{ width: "50%" }}>
                                <LoctoolMessage id={`common.countries.${session.homeAddressData.countryCode}`} />
                            </Box>
                        </tr>
                    </tbody>
                </Card.Table>
            </Accordion>
        </Card.Wrapper>
    );
};
