import React from "react";
import { RemoveScrollBar } from "react-remove-scroll-bar";
import styled, { withTheme, DefaultTheme, keyframes } from "styled-components";
import { bp } from "@style/Theme";
import { connect } from "react-redux";
import { throttle } from "lodash";

import { ApplicationState } from "@redux/reducers";

import { Box } from "@components/LayoutElements";
import { Portal } from "@components/Portal";
import SvgLogoUniqaFlat from "@components/svg/LogoUniqaFlat";
import SvgLogoUniqaHorizontal from "@components/svg/LogoUniqaHorizontal";
import LoctoolMessage from "@i18n/LoctoolMessage";

interface ReduxProps {
    loaderCount: number;
}

interface Props extends ReduxProps {
    theme: DefaultTheme;
}

interface State {
    isVisible: boolean;
}

class Loader extends React.Component<Props, State> {
    private static readonly LOADER_SHOW_THRESHOLD = 150; // ms
    private static readonly LOADER_MIN_VISIBILITY = 1500; // ms

    public state: Readonly<State> = {
        isVisible: this.props.loaderCount > 0,
    };

    private hide = (): void => {
        this.setState({ isVisible: false });
    };

    private throttledHide = throttle(this.hide, Loader.LOADER_MIN_VISIBILITY, { leading: false });

    private show = (): void => {
        this.setState({ isVisible: true });
    };

    private throttledShow = throttle(this.show, Loader.LOADER_SHOW_THRESHOLD, { leading: false });

    public componentWillUnmount(): void {
        this.throttledShow.cancel();
        this.throttledHide.cancel();
    }

    public componentDidUpdate(prevProps: Props): void {
        if (prevProps.loaderCount === 0 && this.props.loaderCount > 0) {
            this.throttledHide.cancel();
            this.throttledShow();
        } else if (prevProps.loaderCount > 0 && this.props.loaderCount === 0) {
            this.throttledShow.cancel();
            this.throttledHide();
        }
    }

    public render() {
        const { theme } = this.props;

        return (
            <div aria-live="polite" aria-atomic="true" aria-busy={this.state.isVisible}>
                {this.state.isVisible && (
                    <>
                        <RemoveScrollBar />

                        <div className="show-for-sr">
                            <LoctoolMessage id="common.pleaseWait" />
                        </div>

                        <Portal>
                            <LoaderOverlay aria-hidden="true">
                                <Box
                                    $style={{ position: "relative", overflow: "hidden" }}
                                    $styleMedium={{ maxWidth: 504, marginLeft: "auto", marginRight: "auto", borderRadius: 2 }}
                                >
                                    <Box
                                        $style={{
                                            color: theme.color.blueN,
                                            backgroundColor: theme.color.white,
                                            paddingTop: 80,
                                            paddingBottom: 324,
                                        }}
                                        $styleMedium={{ paddingTop: 60, paddingBottom: 210 }}
                                    >
                                        <Box as={SvgLogoUniqaHorizontal} $style={{ margin: "0 auto 60px" }} $styleMedium={{ marginBottom: 78 }} />

                                        <Box
                                            $style={{
                                                maxWidth: 232,
                                                marginLeft: "auto",
                                                marginRight: "auto",
                                                height: 2,
                                                position: "relative",
                                                overflow: "hidden",
                                                backgroundColor: theme.color.blueXL,
                                            }}
                                            $styleMedium={{ maxWidth: 256 }}
                                        >
                                            <LoaderProgress $animationDuration={Loader.LOADER_MIN_VISIBILITY} />
                                        </Box>
                                    </Box>

                                    <Box
                                        $style={{ position: "absolute", left: "100%", bottom: -2, transform: "translateX(-212px)", color: theme.color.blueN, opacity: 0.1 }}
                                        $styleMedium={{ bottom: -88, transform: "translateX(-204px)" }}
                                    >
                                        <SvgLogoUniqaFlat />
                                    </Box>
                                </Box>
                            </LoaderOverlay>
                        </Portal>
                    </>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state: ApplicationState): ReduxProps => {
    return { loaderCount: state.appState.loaderCount };
};

export default connect(mapStateToProps)(withTheme(Loader));

const LoaderOverlay = styled.div`
    background-color: ${({ theme }) => theme.color.white};
    bottom: 0;
    left: 0;
    overflow: auto;
    position: fixed;
    right: 0;
    top: 0;
    z-index: ${({ theme }) => theme.zIndex.tooltip + 1};

    ${bp.medium} {
        background-color: ${({ theme }) => theme.layout.body.backgroundColor};
        padding-bottom: 72px;
        padding-top: 72px;
    }
`;

const keyframesLoading = keyframes`
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0);
    }
`;

const LoaderProgress = styled.div<{ $animationDuration: number }>`
    animation: ${keyframesLoading} ${({ $animationDuration }) => $animationDuration}ms 1 cubic-bezier(0.04, 0.94, 0.04, 0.94) forwards;
    background-color: ${({ theme }) => theme.color.blueN};
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
`;
