import { default as Icon32AddressHome } from "./Icon32AddressHome";
import { default as Icon32Adult } from "./Icon32Adult";
import { default as Icon32ArrowBack } from "./Icon32ArrowBack";
import { default as Icon32ArrowForward } from "./Icon32ArrowForward";
import { default as Icon32Bag } from "./Icon32Bag";
import { default as Icon32BrokenPipe } from "./Icon32BrokenPipe";
import { default as Icon32Calendar } from "./Icon32Calendar";
import { default as Icon32CarSharing } from "./Icon32CarSharing";
import { default as Icon32ChevronDown } from "./Icon32ChevronDown";
import { default as Icon32ChevronUp } from "./Icon32ChevronUp";
import { default as Icon32Child } from "./Icon32Child";
import { default as Icon32Coupon } from "./Icon32Coupon";
import { default as Icon32Crime } from "./Icon32Crime";
import { default as Icon32Death } from "./Icon32Death";
import { default as Icon32Delete } from "./Icon32Delete";
import { default as Icon32Emergency } from "./Icon32Emergency";
import { default as Icon32Envelope } from "./Icon32Envelope";
import { default as Icon32Etc } from "./Icon32Etc";
import { default as Icon32Fire } from "./Icon32Fire";
import { default as Icon32Fractures } from "./Icon32Fractures";
import { default as Icon32GlassDamage } from "./Icon32GlassDamage";
import { default as Icon32HealthReason } from "./Icon32HealthReason";
import { default as Icon32Helicopter } from "./Icon32Helicopter";
import { default as Icon32Lifebelt } from "./Icon32Lifebelt";
import { default as Icon32Minus } from "./Icon32Minus";
import { default as Icon32MyResponsibility } from "./Icon32MyResponsibility";
import { default as Icon32NaturalDisaster } from "./Icon32NaturalDisaster";
import { default as Icon32NoIncome } from "./Icon32NoIncome";
import { default as Icon32Notify } from "./Icon32Notify";
import { default as Icon32OtherTravelAssistance } from "./Icon32OtherTravelAssistance";
import { default as Icon32PhysicalDamage } from "./Icon32PhysicalDamage";
import { default as Icon32Plus } from "./Icon32Plus";
import { default as Icon32ProductsTravelEu } from "./Icon32ProductsTravelEu";
import { default as Icon32ProductsTravelDestinationWorldwide } from "./Icon32ProductsTravelDestinationWorldwide";
import { default as Icon32ProductsTravelInternational } from "./Icon32ProductsTravelInternational";
import { default as Icon32Rehabilitation } from "./Icon32Rehabilitation";
import { default as Icon32Ski } from "./Icon32Ski";
import { default as Icon32SpeechBubble } from "./Icon32SpeechBubble";
import { default as Icon32Surgery } from "./Icon32Surgery";
import { default as Icon32Terrorism } from "./Icon32Terrorism";
import { default as Icon32Tick } from "./Icon32Tick";
import { default as Icon32TravelAbroad } from "./Icon32TravelAbroad";
import { default as Icon32TripCancellation } from "./Icon32TripCancellation";
import { default as Icon32VehicleAssistance } from "./Icon32VehicleAssistance";
import { default as Icon32Water } from "./Icon32Water";
import { default as Icon32Wheelchair } from "./Icon32Wheelchair";
import { default as Icon32X } from "./Icon32X";
import { default as Icon64Notify } from "./Icon64Notify";
import { default as LogoMaestro } from "./LogoMaestro";
import { default as LogoMastercard } from "./LogoMastercard";
import { default as LogoPaypalFull } from "./LogoPaypalFull";
import { default as LogoPaypal } from "./LogoPaypal";
import { default as LogoSepaDebit } from "./LogoSepaDebit";
import { default as LogoSepa } from "./LogoSepa";
import { default as LogoUniqaFlat } from "./LogoUniqaFlat";
import { default as LogoUniqaHorizontal } from "./LogoUniqaHorizontal";
import { default as LogoUniqaVertical } from "./LogoUniqaVertical";
import { default as LogoVisa } from "./LogoVisa";
import { default as Skyline1440Travel } from "./Skyline1440Travel";

export const Svg = {
    Icon32AddressHome,
    Icon32Adult,
    Icon32ArrowBack,
    Icon32ArrowForward,
    Icon32Bag,
    Icon32BrokenPipe,
    Icon32Calendar,
    Icon32CarSharing,
    Icon32ChevronDown,
    Icon32ChevronUp,
    Icon32Child,
    Icon32Coupon,
    Icon32Crime,
    Icon32Death,
    Icon32Delete,
    Icon32Emergency,
    Icon32Envelope,
    Icon32Etc,
    Icon32Fire,
    Icon32Fractures,
    Icon32GlassDamage,
    Icon32HealthReason,
    Icon32Helicopter,
    Icon32Lifebelt,
    Icon32Minus,
    Icon32MyResponsibility,
    Icon32NaturalDisaster,
    Icon32NoIncome,
    Icon32Notify,
    Icon32OtherTravelAssistance,
    Icon32PhysicalDamage,
    Icon32Plus,
    Icon32ProductsTravelEu,
    Icon32ProductsTravelDestinationWorldwide,
    Icon32ProductsTravelInternational,
    Icon32Rehabilitation,
    Icon32Ski,
    Icon32SpeechBubble,
    Icon32Surgery,
    Icon32Terrorism,
    Icon32Tick,
    Icon32TravelAbroad,
    Icon32TripCancellation,
    Icon32VehicleAssistance,
    Icon32Water,
    Icon32Wheelchair,
    Icon32X,
    Icon64Notify,
    LogoMaestro,
    LogoMastercard,
    LogoPaypalFull,
    LogoPaypal,
    LogoSepaDebit,
    LogoSepa,
    LogoUniqaFlat,
    LogoUniqaHorizontal,
    LogoUniqaVertical,
    LogoVisa,
    Skyline1440Travel,
};
