export type PhoneNumberRule = {
    countryCode: string;
    regexp: RegExp;
    prefix: string;
    minLength: number;
    maxLength: number;
    type: "single" | "range";
};

export class PhoneNumberUtils {
    public static readonly phoneNumberRules = [
        { countryCode: "AT", prefix: "43", minLength: 10, maxLength: 11 },
        { countryCode: "BE", prefix: "32", minLength: 9, maxLength: 9 },
        { countryCode: "BG", prefix: "359", minLength: 9, maxLength: 9 },
        { countryCode: "CY", prefix: "357", minLength: 8, maxLength: 8 },
        { countryCode: "CZ", prefix: "420", minLength: 9, maxLength: 9 },
        { countryCode: "DK", prefix: "45", minLength: 8, maxLength: 8 },
        { countryCode: "EE", prefix: "372", minLength: 8, maxLength: 10 },
        { countryCode: "FI", prefix: "358", minLength: 10, maxLength: 10 },
        { countryCode: "FR", prefix: "33", minLength: 9, maxLength: 9 },
        { countryCode: "GR", prefix: "30", minLength: 10, maxLength: 10 },
        { countryCode: "NL", prefix: "31", minLength: 9, maxLength: 9 },
        { countryCode: "HR", prefix: "385", minLength: 9, maxLength: 9 },
        { countryCode: "IE", prefix: "353", minLength: 9, maxLength: 9 },
        { countryCode: "LV", prefix: "371", minLength: 8, maxLength: 8 },
        { countryCode: "PL", prefix: "48", minLength: 9, maxLength: 9 },
        { countryCode: "LT", prefix: "370", minLength: 8, maxLength: 8 },
        { countryCode: "LU", prefix: "352", minLength: 9, maxLength: 9 },
        { countryCode: "HU", prefix: "36", minLength: 9, maxLength: 9 },
        { countryCode: "MT", prefix: "356", minLength: 8, maxLength: 8 },
        { countryCode: "DE", prefix: "49", minLength: 10, maxLength: 11 },
        { countryCode: "IT", prefix: "39", minLength: 9, maxLength: 13 },
        { countryCode: "PT", prefix: "351", minLength: 9, maxLength: 9 },
        { countryCode: "RO", prefix: "40", minLength: 10, maxLength: 11 },
        { countryCode: "ES", prefix: "34", minLength: 9, maxLength: 9 },
        { countryCode: "SE", prefix: "46", minLength: 7, maxLength: 10 },
        { countryCode: "SK", prefix: "421", minLength: 9, maxLength: 9 },
        { countryCode: "SI", prefix: "386", minLength: 7, maxLength: 7 },
        { countryCode: "CH", prefix: "41", minLength: 9, maxLength: 10 },
        { countryCode: "MA", prefix: "212", minLength: 8, maxLength: 9 },
        { countryCode: "TN", prefix: "216", minLength: 8, maxLength: 8 },
        { countryCode: "DZ", prefix: "213", minLength: 8, maxLength: 9 },
        { countryCode: "RS", prefix: "381", minLength: 7, maxLength: 10 },
        { countryCode: "AL", prefix: "355", minLength: 8, maxLength: 9 },
        { countryCode: "LI", prefix: "423", minLength: 7, maxLength: 9 },
        { countryCode: "SY", prefix: "963", minLength: 7, maxLength: 9 },
        { countryCode: "BY", prefix: "375", minLength: 9, maxLength: 10 },
        { countryCode: "UA", prefix: "380", minLength: 9, maxLength: 10 },
        { countryCode: "BA", prefix: "387", minLength: 8, maxLength: 9 },
        { countryCode: "ME", prefix: "382", minLength: 9, maxLength: 9 },
        { countryCode: "RU", prefix: "7", minLength: 9, maxLength: 10 },
        { countryCode: "TR", prefix: "90", minLength: 10, maxLength: 10 },
    ];

    public static phoneNumberCountryCodes = Object.keys(PhoneNumberUtils.phoneNumberRules);

    public static availablePhoneNumberPrefixes = PhoneNumberUtils.phoneNumberRules.map(r => `+${r.prefix}`);

    public static getRuleByPrefix(prefix: string): PhoneNumberRule | null {
        const rule = PhoneNumberUtils.phoneNumberRules.find(r => `+${r.prefix}` === prefix);
        if (!rule) {
            return null;
        }

        return {
            regexp: new RegExp(`^(\\+${rule.prefix}[\\d]{${rule.minLength},${rule.maxLength}})$`),
            countryCode: rule.countryCode,
            prefix: `+${rule.prefix}`,
            minLength: rule.minLength,
            maxLength: rule.maxLength,
            type: rule.minLength === rule.maxLength ? "single" : "range",
        };
    }
}
