import * as React from "react";
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgIcon32Lifebelt({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" focusable={false} aria-hidden={true} aria-labelledby={titleId} {...props}>
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                clipRule="evenodd"
                d="M21.402 3.5c2.294 0 4.31.872 5.749 2.328 1.442 1.459 2.303 3.5 2.303 5.825 0 6.32-10.861 14.779-13.454 16.723C13.406 26.43 2.546 17.973 2.546 11.653c0-2.325.861-4.366 2.303-5.825C6.29 4.372 8.304 3.5 10.6 3.5c1.74 0 3.663.857 4.884 1.648 2.197-.77 4.15-1.648 5.919-1.648z"
                stroke="currentColor"
            />
            <path d="M18.793 16.207a1 1 0 10-1.414-1.414 1 1 0 001.414 1.414z" fill="currentColor" />
            <path d="M20.207 17.621a1 1 0 10-1.414-1.414 1 1 0 001.414 1.414zM20.207 14.793a1 1 0 10-1.414-1.414 1 1 0 001.414 1.414z" fill="currentColor" />
            <path d="M21.621 16.207a1 1 0 10-1.414-1.414 1 1 0 001.414 1.414z" fill="currentColor" />
            <path
                clipRule="evenodd"
                d="M23.035 9.136c.391-.39.903-.586 1.415-.586s1.023.196 1.414.586c.39.39.586.903.586 1.414 0 .512-.196 1.024-.586 1.415l-2.829 2.828-2.828-2.828 2.828-2.829zM13.136 19.035c-.39.391-.586.903-.586 1.415s.195 1.023.586 1.414c.39.39.902.586 1.414.586.512 0 1.024-.196 1.414-.586l2.829-2.829-2.829-2.828-2.828 2.828z"
                stroke="currentColor"
            />
            <path d="M18.793 11.964l-2.829 2.829a1 1 0 000 1.414l2.829 2.828a1 1 0 001.414 0l2.828-2.828a1 1 0 000-1.414l-2.828-2.829a1 1 0 00-1.414 0z" stroke="currentColor" />
        </svg>
    );
}

export default SvgIcon32Lifebelt;
