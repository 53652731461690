import { Button } from "@components/Button";
import { CalculatorDateInput } from "@components/input/date/CalculatorDateInput";
import { CalculatorSimpleInput } from "@components/calculator/CalculatorSimpleInput";
import { Card } from "@components/Card";
import { Flex } from "@components/Flex";
import { Box } from "@components/LayoutElements";
import SvgIcon32Adult from "@components/svg/Icon32Adult";
import SvgIcon32Child from "@components/svg/Icon32Child";
import SvgIcon32Delete from "@components/svg/Icon32Delete";
import { Loctool } from "@i18n/Loctool";
import LoctoolMessage from "@i18n/LoctoolMessage";
import { Passenger } from "@redux/actions/sessionActions";
import { ApplicationState } from "@redux/reducers";
import { DateUtils } from "@utils/DateUtils";
import { FormHelpers } from "@utils/FormHelpers";
import { Validator, ValidatorConstants } from "@utils/Validator";
import React, { useMemo } from "react";
import { UseFormRegister, FieldError } from "react-hook-form";
import { useSelector } from "react-redux";
import { ThemeContext } from "styled-components";
import { GtmHooks } from "@utils/gtm/GtmHooks";
import { GtmUtils } from "@utils/gtm/GtmUtils";
import SvgIcon32Notify from "@components/svg/Icon32Notify";

type Props = {
    isAdult: boolean;
    index: number;
    register: UseFormRegister<{ adults: Passenger[]; children: Passenger[] }>;
    errors?: { firstName?: FieldError | undefined; lastName?: FieldError | undefined; dateOfBirth?: FieldError | undefined };
    onRemove: () => void;
    memberValues: Passenger;
    removeEnabled: boolean;
    canBeInsured: boolean;
};

export const PassengerCard = ({ isAdult, index, register, errors, onRemove, memberValues, removeEnabled, canBeInsured }: Props) => {
    const theme = React.useContext(ThemeContext);
    const name = useMemo(() => (isAdult ? "adults" : "children"), [isAdult]);
    const departureDate = useSelector((state: ApplicationState) => DateUtils.parse(state.session.travelOffer.departure));
    const defaultSelectedDate = useMemo(() => {
        if (isAdult) {
            return DateUtils.format(DateUtils.subYears(DateUtils.startOfDay(departureDate), 30));
        }
        return DateUtils.format(DateUtils.startOfToday());
    }, [departureDate, isAdult]);

    GtmHooks.useHookFormError("contract-InsuredPersonData", { ...errors });
    return (
        <Card.Wrapper as="fieldset" $style={{ marginTop: 24 }}>
            <Box
                as="legend"
                $style={{
                    backgroundColor: theme.color.blueN,
                    boxShadow: `inset 0px 0px 0px 1px ${theme.color.greyN}`,
                    color: theme.color.white,
                }}
            >
                <Flex.Container as="span" $style={{ padding: "12px 16px" }}>
                    <Flex.Item as="span" $shrink="shrink" $style={{ marginRight: 16 }}>
                        {isAdult ? (
                            <SvgIcon32Adult title={Loctool.formatMessage({ id: "page.passengers.card.insured.adult" })} focusable={undefined} aria-hidden={undefined} />
                        ) : (
                            <SvgIcon32Child title={Loctool.formatMessage({ id: "page.passengers.card.insured.child" })} focusable={undefined} aria-hidden={undefined} />
                        )}
                    </Flex.Item>

                    <Flex.Item as="span" $shrink="auto" $alignSelf="center">
                        <LoctoolMessage id="page.passengers.card.insured" />
                        <span className="show-for-sr">
                            <LoctoolMessage id="page.passengers.card.insured" /> {index}
                        </span>
                    </Flex.Item>
                </Flex.Container>
            </Box>
            {!canBeInsured && (
                <Box $styleMedium={{ padding: "1px" }}>
                    <Flex.Container
                        as="span"
                        $flexDirection="column"
                        $alignItems="center"
                        $style={{ padding: "12px 16px", backgroundColor: theme.color.blueXL, color: theme.color.blueN, textAlign: "center" }}
                    >
                        <Flex.Item as="span" $shrink="shrink" $style={{ marginRight: 16 }}>
                            <SvgIcon32Notify />
                        </Flex.Item>
                        <Flex.Item as="span" $shrink="shrink" $style={{ marginRight: 16 }}>
                            <LoctoolMessage id="page.passengers.card.cannotBeInsured" />
                        </Flex.Item>
                    </Flex.Container>
                </Box>
            )}
            <Box $styleMedium={{ padding: "12px 0 12px 8px" }}>
                <CalculatorSimpleInput
                    label={<LoctoolMessage id="page.passengers.card.lastName.label" />}
                    name={`${name}.${index}.lastName`}
                    error={FormHelpers.getError(errors?.lastName, "passengerLastName")}
                    placeholder={Loctool.formatMessage({ id: "page.passengers.card.lastName.placeholder" })}
                    autoComplete="family-name"
                    register={register(`${name}.${index}.lastName` as const, { validate: Validator.passengerName })}
                    className={GtmUtils.className.inputs.passengerLastName}
                />

                <CalculatorSimpleInput
                    label={<LoctoolMessage id="page.passengers.card.firstName.label" />}
                    name={`${name}.${index}.firstName`}
                    error={FormHelpers.getError(errors?.firstName, "passengerFirstName")}
                    placeholder={Loctool.formatMessage({ id: "page.passengers.card.firstName.placeholder" })}
                    autoComplete="given-name"
                    register={register(`${name}.${index}.firstName` as const, { validate: Validator.passengerName })}
                    className={GtmUtils.className.inputs.passengerFirstName}
                />

                <CalculatorDateInput
                    id="i-dateOfBirth"
                    label={<LoctoolMessage id="page.passengers.card.dateOfBirth.label" />}
                    placeholder={Loctool.formatMessage({ id: "page.passengers.card.dateOfBirth.placeholder" })}
                    disabledDays={
                        isAdult
                            ? { before: ValidatorConstants.passengerAdultBirthMax(departureDate), after: ValidatorConstants.passengerAdultBirthMin(departureDate) }
                            : { before: ValidatorConstants.passengerChildBirthMax(departureDate), after: ValidatorConstants.passengerChildBirthMin() }
                    }
                    {...register(`${name}.${index}.dateOfBirth` as const, { validate: isAdult ? Validator.passengerAdultDateOfBirth : Validator.passengerChildDateOfBirth })}
                    error={FormHelpers.getError(errors?.dateOfBirth, "passengerDateOfBirth")}
                    defaultSelectedDate={defaultSelectedDate}
                    defaultValue={memberValues.dateOfBirth}
                    className={GtmUtils.className.inputs.passengerDateOfBirth}
                />
            </Box>

            {(!canBeInsured || removeEnabled) && (
                <Box $style={{ paddingTop: 8, paddingBottom: 8 }}>
                    <Box
                        $style={{ maxWidth: 256 + 2 * 16, margin: "0 auto", paddingLeft: 16, paddingRight: 16, transform: "translateX(-6px)" }}
                        $styleMedium={{ maxWidth: 228 + 2 * 16 }}
                    >
                        <Button.Text btnLabel={<LoctoolMessage id="page.passengers.card.remove" />} icon={<SvgIcon32Delete />} iconPosition="right" isExpanded onClick={onRemove} />
                    </Box>
                </Box>
            )}
        </Card.Wrapper>
    );
};
