import * as React from "react";
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgIcon32OtherTravelAssistance({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" focusable={false} aria-hidden={true} aria-labelledby={titleId} {...props}>
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18 5a2 2 0 012 2v2.999L26 10a2 2 0 012 2v6l-1-.5V12a1 1 0 00-1-1l-16-.001v15l9.103.002-.117-.211C18.33 24.552 18 23.122 18 21.5v-1.882a1 1 0 01.553-.894L22 16.999V11h1v5.499l.553-.275a1 1 0 01.894 0l5 2.5a1 1 0 01.553.894V21.5c0 4-2 6.833-6 8.5-1.939-.808-3.408-1.89-4.407-3.246.062.084.125.166.19.247L6 27a2 2 0 01-2-2V12a2 2 0 012-2l6-.001V7a2 2 0 012-2h4zm5.776 12.23l-4.5 2.25a.5.5 0 00-.276.447V21.5c0 3.457 1.625 5.882 5 7.41 3.375-1.528 5-3.953 5-7.41v-1.573a.5.5 0 00-.276-.447l-4.5-2.25a.5.5 0 00-.448 0zM9 10.999L6 11a1 1 0 00-1 1v13a1 1 0 001 1l3-.001v-15zM18 6h-4a1 1 0 00-1 1v2.999h6V7a1 1 0 00-1-1z"
                fill="#003667"
            />
        </svg>
    );
}

export default SvgIcon32OtherTravelAssistance;
