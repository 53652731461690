import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useTheme } from "styled-components";
import { Box, Container } from "@components/LayoutElements";
import { Text } from "@components/Text";
import { AppBar } from "@components/AppBar";
import { Button } from "@components/Button";
import { Validator } from "@utils/Validator";
import SvgIcon32Notify from "@components/svg/Icon32Notify";
import { SubmitHandler, useForm } from "react-hook-form";
import { FormHelpers } from "@utils/FormHelpers";
import { useDispatch, useSelector } from "react-redux";
import { Path } from "@utils/Path";
import { ApplicationState } from "@redux/reducers";
import { Redirect } from "react-router-dom";
import { Api } from "@api/Api";
import { GraphQLClientError } from "@api/graphql/GraphQLClient";
import { AppStateActions } from "@redux/actions/appStateActions";
import { Loctool, LoctoolMessage } from "@i18n/i18n";
import { ContractActions } from "@redux/actions/contractActions";
import { batchActions } from "redux-batched-actions";
import { CalculatorSimpleInput } from "@components/calculator/CalculatorSimpleInput";

type Inputs = {
    email: string;
};

const EmailChangePage = () => {
    const contract = useSelector((state: ApplicationState) => state.contract);
    const dispatch = useDispatch();
    const history = useHistory();
    const theme = useTheme();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<Inputs>({ defaultValues: { email: "" }, mode: "onTouched" });

    const onSubmit: SubmitHandler<Inputs> = useCallback(
        async (data: Inputs) => {
            try {
                const result = await Api.updateEmail(contract!.id, data.email);
                dispatch(
                    batchActions([
                        ContractActions.update(result),
                        AppStateActions.addNotification(Loctool.formatMessage({ id: "page.emailChange.changePhoneEmail.succeed" }), "success"),
                    ])
                );
                history.push(Path.phoneNumberVerification);
            } catch (error) {
                if (error instanceof GraphQLClientError) {
                    dispatch(AppStateActions.addNotification(Loctool.formatMessage({ id: "page.emailChange.changePhoneEmail.failed" }), "error"));
                }
            }
        },
        [contract, dispatch, history]
    );

    if (!contract) {
        return <Redirect to={Path.calculatorIndex} />;
    }

    return (
        <Box $style={{ minHeight: "100vh", backgroundColor: theme.color.white }}>
            <AppBar />

            <Container as="main" $maxWidth={504} $px={16} $style={{ paddingTop: 32, paddingBottom: 32 }} $styleMedium={{ paddingTop: 48, paddingBottom: 48 }}>
                <Box
                    $style={{
                        width: 72,
                        height: 72,
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        margin: "0 auto 24px",
                        backgroundColor: theme.color.blueN,
                        color: theme.color.white,
                    }}
                >
                    <SvgIcon32Notify />
                </Box>

                <Box $style={{ textAlign: "center" }}>
                    <Text as="h1" $fontFamily="firaSans" $fontSize="text24" $style={{ margin: "4px 0 16px", fontWeight: 600 }}>
                        <LoctoolMessage id="page.emailChange.title" />
                    </Text>
                </Box>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <fieldset>
                        <Box
                            $style={{ marginTop: 16, borderRadius: 2, boxShadow: `inset 0px 0px 0px 1px ${theme.color.greyN}`, padding: "8px 16px" }}
                            $styleMedium={{ marginTop: 0 }}
                        >
                            <CalculatorSimpleInput
                                label={<LoctoolMessage id="page.emailChange.form.email.label" />}
                                placeholder={Loctool.formatMessage({ id: "page.emailChange.form.email.placeholder" })}
                                inputMode="email"
                                autoComplete="email"
                                name="email"
                                register={register("email", { validate: Validator.email })}
                                error={FormHelpers.getError(errors.email)}
                            />
                        </Box>

                        <Box $style={{ maxWidth: 288, margin: "0 auto" }} $styleMedium={{ maxWidth: 248 }}>
                            <Box $style={{ marginTop: 16 }}>
                                <Button.Primary type="submit" btnLabel={<LoctoolMessage id="page.emailChange.changePhoneEmail" />} isExpanded />
                            </Box>
                        </Box>
                    </fieldset>
                </form>
            </Container>
        </Box>
    );
};

export default EmailChangePage;
