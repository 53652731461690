import { Loctool } from "./Loctool";
import { IPMType, IPMMessage, OPMMessage, OPMAction } from "./windowMessages";
export { OPMAction };

type IncomingMessageWithData = {
    data: IPMMessage;
};

class LivePreviewHelperClass {
    private isPreviewEnabled = false;

    public postMessage = (message: OPMMessage) => {
        if (process.env.REACT_APP_LOCTOOL_PREVIEW_ENABLED === "true") {
            parent.postMessage(message, "*");
        }
    };

    public initialize() {
        if (process.env.REACT_APP_LOCTOOL_PREVIEW_ENABLED === "true") {
            window.addEventListener("message", this.receiveMessage, false);
        }
    }

    public destruct() {
        if (process.env.REACT_APP_LOCTOOL_PREVIEW_ENABLED === "true") {
            window.removeEventListener("message", this.receiveMessage);
        }
    }

    public sendPageReady() {
        this.postMessage(OPMAction.onPageReady());
    }

    private receiveMessage = (message: IncomingMessageWithData) => {
        if (message.data && message.data.type) {
            switch (message.data.type) {
                case IPMType.setPreview: {
                    Loctool.setPreviewMode(message.data.payload.enabled);
                    this.isPreviewEnabled = message.data.payload.enabled;
                    this.postMessage(this.isPreviewEnabled ? OPMAction.onPreviewReady(Loctool.getConfig().locale) : OPMAction.onPreviewEnded());
                    break;
                }
                case IPMType.getUsedKeys: {
                    this.postMessage(OPMAction.sendUsedKeys(Array.from((window._intlKeys || new Set()).values())));
                    break;
                }
                case IPMType.setMessages: {
                    message.data.payload.messages.forEach(msg => {
                        Loctool.setMessages(msg.locale, msg.messages);
                    });
                    break;
                }
                case IPMType.setMobileView: {
                    Loctool.setForceMobileView(message.data.payload.enabled);
                    break;
                }
            }
        }
    };
}

export const LivePreviewHelper = new LivePreviewHelperClass();
