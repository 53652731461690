import React from "react";
import { Color } from "@style/Theme";

interface Props {}

interface State {
    orderByValue: boolean;
}

export class ColorList extends React.Component<Props, State> {
    state = {
        orderByValue: false,
    };

    hexToVBColor(rrggbb: string): number {
        const bbggrr = `${rrggbb.substr(5, 3)}${rrggbb.substr(3, 3)}${rrggbb.substr(1, 3)}`;
        return parseInt(bbggrr, 16);
    }

    renderColorListItem(item: string, i: number): JSX.Element {
        const itemKey = item as keyof typeof Color;
        return (
            <span key={`color-${i}`} style={{ display: "flex", alignItems: "center", justifyContent: "center", flex: 1, backgroundColor: Color[itemKey] }}>
                <span
                    style={{
                        display: "block",
                        fontFamily: "monospace, 'Courier New'",
                        padding: 10,
                        textShadow: "-1px -1px #000, 1px 1px #fff",
                    }}
                >{`${item}: ${Color[itemKey]}`}</span>
            </span>
        );
    }

    private toggleOrderBy = (): void => {
        const { orderByValue } = this.state;

        this.setState({
            orderByValue: !orderByValue,
        });
    };

    render(): JSX.Element {
        const { ...otherProps } = this.props;
        const { orderByValue } = this.state;

        return (
            <div {...otherProps}>
                <button type="button" onClick={this.toggleOrderBy} style={{ display: "flex", flexWrap: "wrap", alignItems: "stretch" }}>
                    {orderByValue
                        ? Object.keys(Color)
                              .sort((c1: string, c2: string): number => {
                                  const c1v = Color[c1 as keyof typeof Color];
                                  const c2v = Color[c2 as keyof typeof Color];

                                  return this.hexToVBColor(c2v) - this.hexToVBColor(c1v);
                              })
                              .map((item: string, i: number) => this.renderColorListItem(item, i))
                        : Object.keys(Color).map((item: string, i: number) => this.renderColorListItem(item, i))}
                </button>
            </div>
        );
    }
}
