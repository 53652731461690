import * as React from "react";
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgIcon32Surgery({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg width={32} height={32} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" focusable={false} aria-hidden={true} aria-labelledby={titleId} {...props}>
            {title ? <title id={titleId}>{title}</title> : null}
            <g fill="none" fillRule="evenodd">
                <path
                    d="M16 28.376c2.263-1.698 4.92-3.935 7.347-6.415 3.85-3.932 6.107-7.502 6.107-10.308C29.454 6.995 26 3.5 21.402 3.5c-1.518 0-3.402.687-4.885 1.648L16 5.483l-.272-.177-.245-.158C14 4.187 12.116 3.5 10.598 3.5 6 3.5 2.546 6.995 2.546 11.653c0 2.806 2.257 6.376 6.107 10.308 2.427 2.48 5.084 4.717 7.347 6.415z"
                    stroke="currentColor"
                />
                <g transform="rotate(-45 30.192 -3.071)">
                    <circle fill="currentColor" cx={8.5} cy={1.5} r={1} />
                    <circle fill="currentColor" cx={8.5} cy={3.5} r={1} />
                    <circle fill="currentColor" cx={10.5} cy={1.5} r={1} />
                    <circle fill="currentColor" cx={10.5} cy={3.5} r={1} />
                    <path d="M12.5.5v4h4a2 2 0 100-4h-4zM6.5.5v4h-4a2 2 0 110-4h4z" stroke="currentColor" />
                    <rect stroke="currentColor" x={6.5} y={-0.5} width={6} height={6} rx={1} />
                </g>
            </g>
        </svg>
    );
}

export default SvgIcon32Surgery;
