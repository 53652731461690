import React from "react";
import { InputHTMLAttributes } from "react";
import styled from "styled-components";
import { Flex } from "@components/Flex";
import SvgIcon32Tick from "@components/svg/Icon32Tick";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    id: string;
}

export const Checkbox: React.FC<Props> = ({ id, name, children, ...props }) => {
    return (
        <>
            <StyledInput id={id} className="show-for-sr" type="checkbox" {...props} />

            <Flex.Container as="label" htmlFor={id} $display="inline-flex">
                <StyledCheckbox as="span" $shrink={32}>
                    <SvgIcon32Tick />
                </StyledCheckbox>

                {children && (
                    <StyledChildrenWrapper as="span" $shrink="auto">
                        {children}
                    </StyledChildrenWrapper>
                )}
            </Flex.Container>
        </>
    );
};

const StyledChildrenWrapper = styled(Flex.Item)`
    display: block;
    padding-left: 16px;
    user-select: none;
`;

const StyledCheckbox = styled(Flex.Item).attrs(() => ({ ["aria-hidden"]: true }))`
    border-radius: 2px;
    box-shadow: inset 0px 0px 0px 1px ${({ theme }) => theme.color.blueN};
    color: ${({ theme }) => theme.color.greyD};
    cursor: pointer;
    display: block;
    height: ${({ $shrink }) => $shrink}px;
    overflow: hidden;
`;

const StyledInput = styled.input`
    &:checked {
        & + label {
            ${StyledCheckbox} {
                background-color: ${({ theme }) => theme.color.blueN};
                color: ${({ theme }) => theme.color.white};
            }
        }
    }

    &:disabled {
        & + label {
            ${StyledCheckbox} {
                background-color: transparent;
                box-shadow: inset 0px 0px 0px 1px ${({ theme }) => theme.color.greyD};
                color: ${({ theme }) => theme.color.greyD};
                cursor: not-allowed;
            }
        }
    }
`;
