import React, { Component } from "react";
import { Loctool } from "./Loctool";
import PreviewMessage from "./PreviewMessage";
import { LoctoolDescriptor, LoctoolValues } from "./types";

type Props = LoctoolDescriptor & {
    values?: LoctoolValues;
    jsxValues?: { [key: string]: JSX.Element };
};

class FormattedHTMLMessage extends Component<Props> {
    render(): React.ReactNode {
        const { id, values, jsxValues } = this.props;
        const intlMessage = Loctool.formatHTMLMessage({ id }, values, jsxValues);
        if (Loctool.getConfig().isPreviewMode) {
            return <PreviewMessage messageId={id} message={intlMessage} />;
        }

        return <>{intlMessage}</>;
    }
}

export default FormattedHTMLMessage;
