import React from "react";
import { Box, Container } from "@components/LayoutElements";
import { AppBar } from "@components/AppBar";
import { Footer } from "@components/Footer";
import { CalculatorHeadingSteps } from "@components/calculator/CalculatorHeadingSteps";
import { AccountDataForm } from "@pages/AccountDataPage/AccountDataForm";
import { Path } from "@utils/Path";
import { LoctoolMessage } from "@i18n/i18n";
import { WithData } from "@utils/withData/withData";
import { EmptyPage } from "@components/EmptyPage";
import { useSelector } from "react-redux";
import { SessionSelectors } from "@redux/selectors/SessionSelectors";
import { Redirect } from "react-router";
import withUpdatedLegalDocuments from "@utils/withData/withLegalDocuments";

type Props = WithData<{}>;

export const AccountDataPage = (props: Props) => {
    const stat = useSelector(SessionSelectors.getStat);
    const stepsCount = useSelector(SessionSelectors.getStepsCount);

    if (!stat.isCalculationDone) {
        return <Redirect to={Path.calculatorIndex} />;
    }

    if (props.loading) {
        return <EmptyPage />;
    }

    return (
        <Box $style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
            <AppBar />

            <Container as="main" $maxWidth={504} $px={16} $style={{ width: "100%" }}>
                <CalculatorHeadingSteps currentStep={1} stepsCount={stepsCount} text={<LoctoolMessage id="page.accountData.title" />} backLink={Path.calculatorIndex} />

                <AccountDataForm />
            </Container>

            <Footer />
        </Box>
    );
};

export default withUpdatedLegalDocuments(AccountDataPage);
