import * as React from "react";
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgIcon32NaturalDisaster({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg width={32} height={32} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" focusable={false} aria-hidden={true} aria-labelledby={titleId} {...props}>
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M11 3c3.597 0 6.7 2.11 8.142 5.159a7 7 0 114.1 12.837l-.242.004h-1v7.5a.5.5 0 11-1 0V21h-4.71l-1.428 2.499L18 23.5a.5.5 0 01.446.727l-.046.073-3 4a.5.5 0 01-.847-.523l.047-.077 2.4-3.201-3 .001a.5.5 0 01-.47-.67l.036-.078L15.137 21H11v7.5a.5.5 0 11-1 0v-7.514A8.5 8.5 0 012 12.5V12a9 9 0 019-9zm0 1a8 8 0 00-7.996 7.75L3 12v.5a7.501 7.501 0 006.812 7.469l.246.018.114.013h5.814a.5.5 0 01.021 0h6.976l.224-.003a6 6 0 10-3.513-11.004 1 1 0 01-1.457-.407A8 8 0 0011 4z"
                fill="currentColor"
                fillRule="evenodd"
            />
        </svg>
    );
}

export default SvgIcon32NaturalDisaster;
