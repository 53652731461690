import * as React from "react";
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgIcon32Terrorism({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg width={32} height={32} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" focusable={false} aria-hidden={true} aria-labelledby={titleId} {...props}>
            {title ? <title id={titleId}>{title}</title> : null}
            <g fill="none" fillRule="evenodd">
                <circle stroke="currentColor" cx={16} cy={16} r={11.5} />
                <path d="M16 21c1.306 0 2.418.835 2.83 2h-1.098a2 2 0 00-3.464 0H13.17A3.001 3.001 0 0116 21z" fill="currentColor" fillRule="nonzero" />
                <path
                    d="M21 21.5a2.5 2.5 0 01-2.5-2.5c0-.917.73-2.01 2.156-3.363l.344-.326.344.326C22.77 16.99 23.5 18.083 23.5 19a2.5 2.5 0 01-2.5 2.5z"
                    stroke="currentColor"
                    strokeLinejoin="round"
                />
                <path d="M14.964 13a3.5 3.5 0 01-6.928 0H9.05a2.5 2.5 0 004.9 0zM23.929 13A3.5 3.5 0 0117 13h1.014a2.5 2.5 0 004.9 0z" fill="currentColor" fillRule="nonzero" />
            </g>
        </svg>
    );
}

export default SvgIcon32Terrorism;
