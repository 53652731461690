export class DebugUtils {
    /**
     * Returns true, if runs in development mode
     * @returns {boolean} - is development mode
     */
    public static isDevelopment(): boolean {
        return !process.env.NODE_ENV || process.env.NODE_ENV === "development";
    }

    /**
     * Return true, if if .env file REACT_APP_DEBUG set to true
     * @returns {boolean} - is debug
     */
    public static isDebug(): boolean {
        return process.env.REACT_APP_DEBUG === "true";
    }

    /**
     * Returns true, if runs in integration environment
     * @returns {boolean} - is int environment
     */
    public static isINTEnv(): boolean {
        return process.env.REACT_APP_DEPLOYMENT_ENVIRONMENT === "int";
    }

    /**
     * Returns true, if runs in user acceptance environment
     * @returns {boolean} - is uat environment
     */
    public static isUATEnv(): boolean {
        return process.env.REACT_APP_DEPLOYMENT_ENVIRONMENT === "uat";
    }

    /**
     * Returns true, if runs in production environment
     * @returns {boolean} - is uat environment
     */
    public static isProdEnv(): boolean {
        return process.env.REACT_APP_DEPLOYMENT_ENVIRONMENT === "production";
    }

    public static isDevToolsEnabled = (): boolean => {
        return (DebugUtils.isDebug() && DebugUtils.isDevelopment()) || DebugUtils.isINTEnv() || DebugUtils.isUATEnv();
    };

    public static getEnvironmentDecorator(): string {
        if (DebugUtils.isINTEnv()) {
            return "INTEGRATION TEST";
        } else if (DebugUtils.isUATEnv()) {
            return "USER ACCEPTANCE ENV";
        } else if (DebugUtils.isDevelopment()) {
            return "DEVELOPMENT ENV";
        }
        return "UNKNOWN";
    }
}
