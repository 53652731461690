import React from "react";
import styled from "styled-components";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Svg } from "@components/svg";

export class SvgList extends React.Component {
    renderSvgListItem(item: string, i: number): JSX.Element {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const SvgComponent = Svg[item];

        return (
            <StyledSvgContainer key={`svg-${i}`}>
                <div style={{ display: "flex", flex: 1, alignItems: "center", justifyContent: "center" }}>
                    <SvgComponent />
                </div>

                <div style={{ textAlign: "center" }}>Svg{item}</div>
            </StyledSvgContainer>
        );
    }

    render(): JSX.Element {
        const { ...otherProps } = this.props;

        return (
            <div {...otherProps} style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
                {Object.keys(Svg).map((item: string, i: number) => this.renderSvgListItem(item, i))}
            </div>
        );
    }
}

const StyledSvgContainer = styled.div`
    background-color: ${p => p.theme.color.white};
    color: ${p => p.theme.color.black};
    display: flex;
    flex-direction: column;
    max-width: 50%;
    padding: 16px;
    user-select: all;

    &:active,
    &:hover {
        background-color: ${p => p.theme.color.black};
        color: ${p => p.theme.color.white};
    }
`;
