import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import { useTheme } from "styled-components";
import { Box, Container } from "@components/LayoutElements";
import { Flex } from "@components/Flex";
import { Text } from "@components/Text";
import { AppBar } from "@components/AppBar";
import { Button } from "@components/Button";
import { Input } from "@components/Input";
import { Validator, ValidatorConstants } from "@utils/Validator";
import SvgIcon32Notify from "@components/svg/Icon32Notify";
import { SubmitHandler, useForm } from "react-hook-form";
import { FormHelpers } from "@utils/FormHelpers";
import { useDispatch, useSelector } from "react-redux";
import { Path } from "@utils/Path";
import { ApplicationState } from "@redux/reducers";
import { Redirect } from "react-router-dom";
import { Api } from "@api/Api";
import { GraphQLClientError } from "@api/graphql/GraphQLClient";
import { AppStateActions } from "@redux/actions/appStateActions";
import { Loctool, LoctoolMessage } from "@i18n/i18n";
import { ContractActions } from "@redux/actions/contractActions";
import { batchActions } from "redux-batched-actions";
import { SessionActions } from "@redux/actions/sessionActions";

type Inputs = {
    phoneNumberActivationCode: string;
};

const PhoneNumberVerificationPage = () => {
    const contract = useSelector((state: ApplicationState) => state.contract);
    const sentSmsCount = useSelector((state: ApplicationState) => state.session.sentSmsCount);
    const dispatch = useDispatch();
    const theme = useTheme();
    const { register, handleSubmit, setError, formState, watch } = useForm<Inputs>({ defaultValues: { phoneNumberActivationCode: "" }, mode: "onTouched" });

    const onSubmit: SubmitHandler<Inputs> = useCallback(
        async (data: Inputs) => {
            try {
                const result = await Api.validatePhone(contract!.id, data.phoneNumberActivationCode);
                dispatch(
                    batchActions([
                        AppStateActions.addNotification(Loctool.formatMessage({ id: "page.phoneNumberVerification.verification.succeed" }), "success"),
                        ContractActions.update(result),
                    ])
                );
            } catch (error) {
                if (error instanceof GraphQLClientError) {
                    setError("phoneNumberActivationCode", { type: "api", message: error.message });
                }
            }
        },
        [contract, dispatch, setError]
    );

    const onResendClick = useCallback(async (): Promise<void> => {
        if (sentSmsCount >= ValidatorConstants.maxSmsResendPerPhoneNumber) {
            dispatch(AppStateActions.addNotification(Loctool.formatMessage({ id: "page.phoneNumberVerification.resend.limitReached" }), "error"));
            return;
        }
        try {
            dispatch(SessionActions.updateSentSmsCount());
            await Api.sendOrResendSMSCode(contract!.id);
            dispatch(AppStateActions.addNotification(Loctool.formatMessage({ id: "page.phoneNumberVerification.resend.succeed" }), "success"));
        } catch (error) {
            if (error instanceof GraphQLClientError) {
                dispatch(AppStateActions.addNotification(Loctool.formatMessage({ id: "page.phoneNumberVerification.resend.failed" }), "error"));
            }
        }
    }, [sentSmsCount, contract, dispatch]);

    const codeError = FormHelpers.getError(formState.errors.phoneNumberActivationCode);
    const phoneNumberActivationCode = watch("phoneNumberActivationCode");
    if (!contract) {
        return <Redirect to={Path.calculatorIndex} />;
    }
    if (contract.accountData.phoneValidated) {
        return <Redirect to={Path.emailVerification} push={false} />;
    }

    return (
        <Box $style={{ minHeight: "100vh", backgroundColor: theme.color.white }}>
            <AppBar />

            <Container as="main" $maxWidth={504} $px={16} $style={{ paddingTop: 32, paddingBottom: 32 }} $styleMedium={{ paddingTop: 48, paddingBottom: 48 }}>
                <Box
                    $style={{
                        width: 72,
                        height: 72,
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        margin: "0 auto 24px",
                        backgroundColor: theme.color.blueN,
                        color: theme.color.white,
                    }}
                >
                    <SvgIcon32Notify />
                </Box>

                <Box $style={{ textAlign: "center" }}>
                    <Text as="h1" $fontFamily="firaSans" $fontSize="text24" $style={{ margin: "4px 0", fontWeight: 600 }}>
                        <LoctoolMessage id="page.phoneNumberVerification.title" />
                    </Text>

                    <Text id="i-code-desc" as="p" $style={{ margin: "4px 0", minHeight: 3 * theme.typo.size.text16.lineHeight }}>
                        <LoctoolMessage id="page.phoneNumberVerification.description" />
                    </Text>
                </Box>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <fieldset>
                        <Box
                            $style={{ marginTop: 16, borderRadius: 2, boxShadow: `inset 0px 0px 0px 1px ${theme.color.greyN}`, padding: "8px 16px" }}
                            $styleMedium={{ marginTop: 0 }}
                        >
                            <Flex.Container $alignItems="center" $gutterMargin={8}>
                                <Flex.Item as="label" id="i-code-label" htmlFor="i-code" $shrink="shrink" $gutterMargin={8}>
                                    <LoctoolMessage id="page.phoneNumberVerification.form.phoneNumberActivationCode.label" />
                                </Flex.Item>

                                <Flex.Item $shrink="auto" $gutterMargin={8}>
                                    <Input
                                        id="i-code"
                                        placeholder={Loctool.formatMessage({ id: "page.phoneNumberVerification.form.phoneNumberActivationCode.placeholder" })}
                                        aria-describedby="i-code-desc i-code-error"
                                        autoComplete="off"
                                        {...register("phoneNumberActivationCode", { validate: Validator.required })}
                                        $hasError={!!codeError}
                                    />
                                </Flex.Item>
                            </Flex.Container>

                            <Box id="i-code-error" aria-live="assertive" $style={codeError ? { paddingTop: 12, color: theme.color.redN } : {}}>
                                {codeError}
                            </Box>
                        </Box>

                        <Box $style={{ maxWidth: 288, margin: "0 auto" }} $styleMedium={{ maxWidth: 248 }}>
                            <Box $style={{ marginTop: 16 }}>
                                <Button.Primary
                                    type="submit"
                                    btnLabel={<LoctoolMessage id="page.phoneNumberVerification.activate" />}
                                    isExpanded
                                    disabled={!!codeError || phoneNumberActivationCode.length === 0}
                                />
                            </Box>

                            <Box $style={{ marginTop: 16 }}>
                                <Button.Hollow btnLabel={<LoctoolMessage id="page.phoneNumberVerification.resend" />} isExpanded onClick={onResendClick} />
                            </Box>

                            <Box $style={{ marginTop: 36 }}>
                                <Box $style={{ textAlign: "center" }}>
                                    <Text id="current-phone-number-desc" $fontSize="text12" $style={{ fontStyle: "italic" }}>
                                        {contract.accountData.phoneNumber}
                                    </Text>

                                    <Link
                                        to={Path.phoneNumberChange}
                                        component={React.forwardRef((props, ref) => (
                                            <Button.Text
                                                ref={ref}
                                                btnLabel={<LoctoolMessage id="page.phoneNumberVerification.changePhoneNumber" />}
                                                aria-describedby="current-phone-number-desc"
                                                isExpanded
                                                {...props}
                                            />
                                        ))}
                                    />

                                    {process.env.NODE_ENV === "development" && (
                                        <Box $style={{ marginTop: 16 }}>
                                            <Button.Hollow
                                                btnLabel={"DEV aktiválás"}
                                                isExpanded
                                                onClick={async () => {
                                                    try {
                                                        const result = await Api.validatePhone(contract!.id, "00000000");
                                                        dispatch(
                                                            batchActions([
                                                                AppStateActions.addNotification(
                                                                    Loctool.formatMessage({ id: "page.phoneNumberVerification.verification.succeed" }),
                                                                    "success"
                                                                ),
                                                                ContractActions.update(result),
                                                            ])
                                                        );
                                                    } catch (error) {
                                                        if (error instanceof GraphQLClientError) {
                                                            setError("phoneNumberActivationCode", { type: "api", message: error.message });
                                                        }
                                                    }
                                                }}
                                            />
                                        </Box>
                                    )}
                                </Box>
                            </Box>
                        </Box>
                    </fieldset>
                </form>
            </Container>
        </Box>
    );
};

export default PhoneNumberVerificationPage;
