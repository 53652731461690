import { useCallback, useEffect } from "react";
import { FieldError } from "react-hook-form";
import { usePrevious } from "../hooks/usePrevious";
import { Gtm } from "./Gtm";

type FormName = "calculator" | "contract-PersonalData" | "contract-HomeAddress" | "contract-InsuredPersonData";

export const useFormError = <T extends string>(formName: FormName, errors: { [key: string]: T | null | undefined }) => {
    const prevErrors = usePrevious(errors);
    useEffect(() => {
        Object.keys(errors).forEach((field: string) => {
            const error = errors[field];
            if (prevErrors && error && error !== prevErrors[field]) {
                Gtm.pushFormError({ form: formName, field, error });
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errors]);
};

export const useHookFormError = (formName: FormName, errors: { [key: string]: FieldError | undefined } | undefined) => {
    const prevErrors = usePrevious(errors);
    const getMessage = useCallback((fieldError: FieldError | undefined) => {
        if (!fieldError?.message || !fieldError.type) {
            return undefined;
        }
        return fieldError.message;
    }, []);

    useEffect(() => {
        if (!errors) {
            return;
        }
        Object.keys(errors).forEach((field: string) => {
            const error = getMessage(errors[field]);
            const prevError = getMessage(prevErrors?.[field]);
            if (error && error !== prevError) {
                Gtm.pushFormError({ form: formName, field, error });
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errors, formName, getMessage]);
};
