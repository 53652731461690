import { Locale, IntlMessageObject } from "./types";

// Supported messages coming from the loctool livepreview application
export enum IPMType {
    setPreview = "setPreview", // Enabled/disable preview mode
    setMessages = "setMessages", // Incoming translations for a specific locale
    getUsedKeys = "getUsedKeys", // Ask for used translation ids
    setMobileView = "setMobileView", // Enable/disable the mobile view
}

type IPMSetPreview = PreviewMessage<IPMType.setPreview, { enabled: boolean }>;
type IPMSetMessages = PreviewMessage<IPMType.setMessages, { messages: Array<{ locale: Locale; messages: IntlMessageObject }> }>;
type IPMGetUsedKeys = PreviewMessage<IPMType.getUsedKeys>;
type IPMSetMobileView = PreviewMessage<IPMType.setMobileView, { enabled: boolean }>;

// IPM = Incoming Preview Message
export type IPMMessage = IPMSetPreview | IPMSetMessages | IPMGetUsedKeys | IPMSetMobileView;

// Outgoing messages going to the loctool livepreview application
export enum OPMType {
    pageReady = "pageReady", // Sent when the page is loaded completly
    previewReady = "previewReady", // Sent when preview mode is initialized and can accept other messages
    previewEnded = "previewEnded", // Sent when preview mode is ended and not accepting other messages
    sendUsedKeys = "sendUsedKeys", // Send used keys in page
    localeChanged = "localeChanged", // Sent when the application locale is changed
    highlightKey = "scrollTo", // Sent when user clicks the scrollTo icon
    editKey = "editKey", // Sent when user clicks the Edit icon
}

type OPMPageReady = PreviewMessage<OPMType.pageReady>;
type OPMPreviewReady = PreviewMessage<OPMType.previewReady, { currentLocale: Locale }>;
type OPMPreviewEnded = PreviewMessage<OPMType.previewEnded>;
type OPMSendUsedKeys = PreviewMessage<OPMType.sendUsedKeys, { keys: string[] }>;
type OPMLocaleChanged = PreviewMessage<OPMType.localeChanged, { locale: Locale }>;
type OPMHighlightKey = PreviewMessage<OPMType.highlightKey, { key: string }>;
type OPMEditKey = PreviewMessage<OPMType.editKey, { key: string }>;

export type OPMMessage = OPMPageReady | OPMPreviewReady | OPMPreviewEnded | OPMSendUsedKeys | OPMLocaleChanged | OPMHighlightKey | OPMEditKey;

export const OPMAction = {
    onPageReady: () => createPreviewMessage(OPMType.pageReady),
    onPreviewReady: (currentLocale: Locale) => createPreviewMessage(OPMType.previewReady, { currentLocale }),
    onPreviewEnded: () => createPreviewMessage(OPMType.previewEnded),
    onLocaleChanged: (locale: Locale) => createPreviewMessage(OPMType.localeChanged, { locale }),
    sendUsedKeys: (keys: string[]) => createPreviewMessage(OPMType.sendUsedKeys, { keys }),
    highlightKey: (key: string) => createPreviewMessage(OPMType.highlightKey, { key }),
    editKey: (key: string) => createPreviewMessage(OPMType.editKey, { key }),
};

export interface PreviewMessage<T extends string> {
    type: T;
}
export interface PreviewMessage<T extends string, P = undefined> {
    type: T;
    payload: P;
}

export function createPreviewMessage<T extends string>(type: T): PreviewMessage<T>;
export function createPreviewMessage<T extends string, P>(type: T, payload: P): PreviewMessage<T, P>;
export function createPreviewMessage<T extends string, P>(type: T, payload?: P) {
    return payload === undefined ? { type } : { type, payload };
}
