import React, { useCallback, useEffect, useRef, useState } from "react";
import { DayModifiers, Modifier } from "react-day-picker";
import { Button } from "@components/Button";
import { DayPicker, DayPickerInput } from "@components/input/date/DayPicker";
import { Flex } from "@components/Flex";
import { Popup } from "@components/Popup";
import SvgIcon32Calendar from "@components/svg/Icon32Calendar";
import { DateUtils, DateFormat } from "@utils/DateUtils";
import { LoctoolMessage } from "@i18n/i18n";
import { YearMonthForm } from "@components/input/date/YearMonthForm";

interface Props {
    id: string;
    name: string;
    value: Date;
    onChange: (value: Date) => void;
    disabledDays?: Modifier;
}

export const TravelCalculationDateInput = ({ id, name, value, onChange, disabledDays }: Props) => {
    const ref = useRef<Popup | null>(null);
    const [month, setMonth] = useState(value || undefined);
    const onDayClick = useCallback(
        (day: Date, modifiers: DayModifiers, event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            if (modifiers.disabled || modifiers.selected) {
                return;
            }
            onChange(day);
            ref.current && ref.current.close();
        },
        [onChange]
    );

    useEffect(() => {
        setMonth(value || undefined);
    }, [value]);

    return (
        <>
            <Flex.Item $shrink="auto" $gutterMargin={16}>
                <DayPickerInput as="input" id={id} name={name} value={DateUtils.format(value, DateFormat.medium)} readOnly />
            </Flex.Item>

            <Flex.Item $shrink="shrink" $gutterMargin={16}>
                <Popup
                    ref={ref}
                    toggleElement={
                        <Button.Text btnLabel={<LoctoolMessage id="component.dateInput.daypicker.toggleButton" />} icon={<SvgIcon32Calendar />} btnSize={32} aria-controls={id} />
                    }
                    popperOptions={{
                        placement: "bottom-end",
                        modifiers: [
                            {
                                name: "offset",
                                options: {
                                    offset: [24, 0],
                                },
                            },
                        ],
                    }}
                >
                    <DayPicker
                        month={month}
                        selectedDays={value}
                        onDayClick={onDayClick}
                        disabledDays={disabledDays}
                        captionElement={({ date, localeUtils }) => (
                            <YearMonthForm date={date} disabledDays={disabledDays} onChange={(month: Date) => setMonth(month)} getMonths={localeUtils.getMonths} />
                        )}
                    />
                </Popup>
            </Flex.Item>
        </>
    );
};
