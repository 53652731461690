/* eslint-disable prettier/prettier */
import { store } from "@redux/store";

export enum BackendEnvironmentName {
    accountMinAge = "account.min.age",
    phoneNumberValidationSMSLimit = "phoneNumber.validationSMSLimit",
    sessionPreRegTimeoutSeconds = "session.preRegTimeoutSeconds",
    sessionPreRegWarningSeconds = "session.preRegWarningSeconds",
    travelInsuranceForwardDateMaxDays = "travelInsurance.forwardDateMaxDays",
    travelInsuranceMaxDays = "travelInsurance.maxDays",
    travelInsuranceMaxMembers = "travelInsurance.maxMembers",
    validatorEmail = "validator.email",
    validatorName = "validator.name",
    validatorPhoneNumber = "validator.phone.number",
    validatorPostalCode = "validator.postal_code",
}

const defaultBackendEnvironments: { [key in BackendEnvironmentName]: string } = {
    [BackendEnvironmentName.accountMinAge]: "18",
    [BackendEnvironmentName.phoneNumberValidationSMSLimit]: "5",
    [BackendEnvironmentName.sessionPreRegTimeoutSeconds]: "1800",
    [BackendEnvironmentName.sessionPreRegWarningSeconds]: "1500",
    [BackendEnvironmentName.travelInsuranceForwardDateMaxDays]: "20",
    [BackendEnvironmentName.travelInsuranceMaxDays]: "30",
    [BackendEnvironmentName.travelInsuranceMaxMembers]: "10",
    [BackendEnvironmentName.validatorEmail]: "^[a-zA-Z0-9\\.!#$%&\\'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$",
    [BackendEnvironmentName.validatorName]: "^(?:[\\u00c0-\\u01ffa-zA-Z\\'-\\s\\.]){2,}$",
    [BackendEnvironmentName.validatorPhoneNumber]: "^(\\+43[\\d]{10,11})|(\\+32[\\d]{9})|(\\+359[\\d]{9})|(\\+357[\\d]{8})|(\\+420[\\d]{9})|(\\+45[\\d]{8})|(\\+372[\\d]{8,10})|(\\+358[\\d]{10})|(\\+33[\\d]{9})|(\\+30[\\d]{10})|(\\+31[\\d]{9})|(\\+385[\\d]{9})|(\\+353[\\d]{9})|(\\+371[\\d]{8})|(\\+48[\\d]{9})|(\\+370[\\d]{8})|(\\+352[\\d]{9})|(\\+36[\\d]{9})|(\\+356[\\d]{8})|(\\+49[\\d]{10,11})|(\\+39[\\d]{9,13})|(\\+351[\\d]{9})|(\\+40[\\d]{10,11})|(\\+34[\\d]{9})|(\\+46[\\d]{7,10})|(\\+421[\\d]{9})|(\\+386[\\d]{7})|(\\+41[\\d]{9,10})|(\\+212[\\d]{8,9})|(\\+216[\\d]{8})|(\\+213[\\d]{8,9})|(\\+381[\\d]{7,10})|(\\+355[\\d]{8,9})|(\\+423[\\d]{7,9})|(\\+963[\\d]{7,9})|(\\+375[\\d]{9,10})|(\\+380[\\d]{9,10})|(\\+387[\\d]{8,9})|(\\+382[\\d]{9})|(\\+7[\\d]{9,10})|(\\+90[\\d]{10})$",
    [BackendEnvironmentName.validatorPostalCode]: "^[\\d]{4}$",
};

export class BackendEnvironmentUtils {
    private static get(envName: BackendEnvironmentName): string {
        return store.getState().backendEnvironments.find(env => env.name === envName)?.value ?? defaultBackendEnvironments[envName];
    }

    public static accountMinAge(): number {
        const accountMinAge = BackendEnvironmentUtils.get(BackendEnvironmentName.accountMinAge);
        return Number.parseInt(accountMinAge);
    }

    public static phoneNumberValidationSMSLimit(): number {
        const phoneNumberValidationSMSLimit = BackendEnvironmentUtils.get(BackendEnvironmentName.phoneNumberValidationSMSLimit);
        return Number.parseInt(phoneNumberValidationSMSLimit);
    }

    public static sessionPreRegTimeoutSeconds(): number {
        const sessionPreRegTimeoutSeconds = BackendEnvironmentUtils.get(BackendEnvironmentName.sessionPreRegTimeoutSeconds);
        return Number.parseInt(sessionPreRegTimeoutSeconds);
    }

    public static sessionPreRegWarningSeconds(): number {
        const sessionPreRegWarningSeconds = BackendEnvironmentUtils.get(BackendEnvironmentName.sessionPreRegWarningSeconds);
        return Number.parseInt(sessionPreRegWarningSeconds);
    }

    public static travelInsuranceForwardDateMaxDays(): number {
        const travelInsuranceForwardDateMaxDays = BackendEnvironmentUtils.get(BackendEnvironmentName.travelInsuranceForwardDateMaxDays);
        return Number.parseInt(travelInsuranceForwardDateMaxDays);
    }

    public static travelInsuranceMaxDays(): number {
        const travelInsuranceMaxDays = BackendEnvironmentUtils.get(BackendEnvironmentName.travelInsuranceMaxDays);
        return Number.parseInt(travelInsuranceMaxDays);
    }

    public static travelInsuranceMaxMembers(): number {
        const travelInsuranceMaxMembers = BackendEnvironmentUtils.get(BackendEnvironmentName.travelInsuranceMaxMembers);
        return Number.parseInt(travelInsuranceMaxMembers);
    }

    public static validatorEmail(): RegExp {
        const validatorEmail = BackendEnvironmentUtils.get(BackendEnvironmentName.validatorEmail);
        return new RegExp(validatorEmail);
    }

    public static validatorName(): RegExp {
        const validatorName = BackendEnvironmentUtils.get(BackendEnvironmentName.validatorName);
        return new RegExp(validatorName);
    }

    public static validatorPhoneNumber(): RegExp {
        const validatorPhoneNumber = BackendEnvironmentUtils.get(BackendEnvironmentName.validatorPhoneNumber);
        return new RegExp(validatorPhoneNumber);
    }

    public static validatorPostalCode(): RegExp {
        const validatorPostalCode = BackendEnvironmentUtils.get(BackendEnvironmentName.validatorPostalCode);
        return new RegExp(validatorPostalCode);
    }
}
