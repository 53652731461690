import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import { persistor, store } from "@redux/store";
import { Theme } from "@style/Theme";
import { GlobalStyle } from "@style/GlobalStyle";
import App from "./App";
import Loader from "@components/Loader";
import { NotificationBar } from "@components/NotificationBar";
import { printAppInfo } from "@utils/printAppInfo";

printAppInfo();

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <ThemeProvider theme={Theme}>
                <GlobalStyle />
                <Provider store={store}>
                    <PersistGate persistor={persistor}>
                        <>
                            <NotificationBar />
                            <App />
                            <Loader />
                        </>
                    </PersistGate>
                </Provider>
            </ThemeProvider>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById("root")
);
