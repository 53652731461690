import { Gql } from "@api/Api";
import { AppStateActionTypes } from "@redux/actions/appStateActions";
import { ContractActionTypes } from "@redux/actions/contractActions";
import { ReduxAction } from "@redux/actions/ReduxAction";
import { AccountData, HomeAddressData, SessionActionTypes, Passenger, TravelOffer } from "@redux/actions/sessionActions";
import { DateFormat, DateUtils } from "@utils/DateUtils";
import { TravelDestinations } from "@utils/TravelCategories";

export type SessionReducerType = {
    travelOffer: TravelOffer;
    accountData: AccountData;
    homeAddressData: HomeAddressData;
    areYouTraveling: boolean;
    passengers: {
        adults: Passenger[];
        children: Passenger[];
    };

    // After closed browser reopens closed tabs, user would be able to continue the flow.
    // We make sure user visited calculation page
    // Default value is false.
    // We set this flag, after first tariff calculation
    isChanged: boolean;
    sentSmsCount: number; // sent sms count for current phoneNumber
};

const initialState: SessionReducerType = {
    travelOffer: {
        destinationId: TravelDestinations.EU.id,
        departure: DateUtils.format(DateUtils.addDays(DateUtils.startOfToday(), 0), DateFormat.api),
        return: DateUtils.format(DateUtils.addDays(DateUtils.startOfToday(), 0), DateFormat.api),
        adultsCount: 1,
        childrenCount: 0,
        tariff: null,
    },
    accountData: {
        email: "",
        phoneNumber: "",
        phoneNumberPrefix: "",
        firstName: "",
        lastName: "",
        dateOfBirth: "",
    },
    homeAddressData: {
        zipCode: "",
        address: "",
        city: "",
        countryCode: Gql.CountryCode.HU,
    },
    areYouTraveling: true,
    passengers: {
        adults: [],
        children: [],
    },
    isChanged: false,
    sentSmsCount: 0,
};

export default function (state: SessionReducerType = initialState, action: ReduxAction): SessionReducerType {
    switch (action.type) {
        case SessionActionTypes.UPDATE_TRAVEL_OFFER:
            return { ...state, travelOffer: { ...action.payload.travelOffer }, isChanged: action.payload.isChanged ?? state.isChanged };
        case SessionActionTypes.UPDATE_ACCOUNT_DATA:
            return { ...state, accountData: { ...action.payload.accountData } };
        case SessionActionTypes.UPDATE_HOME_ADDRESS_DATA:
            return { ...state, homeAddressData: { ...action.payload.homeAddressData } };
        case SessionActionTypes.UPDATE_ARE_YOU_TRAVELING:
            return { ...state, areYouTraveling: action.payload.areYouTraveling };
        case SessionActionTypes.UPDATE_PASSENGERS:
            return { ...state, passengers: action.payload.passengers };
        case SessionActionTypes.UPDATE_SENT_SMS_COUNT:
            return { ...state, sentSmsCount: state.sentSmsCount + 1 };
        case ContractActionTypes.UPDATE:
            return { ...state, sentSmsCount: 0 };
        case SessionActionTypes.RESET_TRAVEL_OFFER:
        case AppStateActionTypes.RESET:
            return { ...initialState };
        default:
            return state;
    }
}
