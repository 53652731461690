import { css, createGlobalStyle, keyframes } from "styled-components";

export const showForSr = css`
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    overflow: hidden;
    padding: 0;
    position: absolute !important;
    white-space: nowrap;
    width: 1px;
`;

export const listCSSReset = css`
    list-style: none;
    margin-bottom: 0;
    margin-top: 0;
    padding-left: 0;
`;

export const tableCSSReset = css`
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;

    caption {
        text-align: left;
    }

    th,
    td {
        font-weight: 400;
        padding: 0;
        text-align: left;
    }

    th {
        vertical-align: bottom;
    }
`;

export const GlobalStyle = createGlobalStyle`
    html {
        -webkit-tap-highlight-color: rgba(0,0,0,0);
        box-sizing: border-box;
    }

    *,
    *::before,
    *::after {
        box-sizing: inherit;
    }

    /* TODO: remember to define focus styles! http://www.outlinenone.com/ */
    :focus {
        -webkit-tap-highlight-color: rgba(0,0,0,0);
        outline: 0;
        /* outline: 4px solid red !important; */
    }

    body {
        background-color: ${({ theme }) => theme.layout.body.backgroundColor};
        color: ${({ theme }) => theme.typo.body.color};
        font-family: ${({ theme }) => theme.typo.body.fontFamily};
        font-size: ${({ theme }) => (typeof theme.typo.body.fontSize === "number" ? `${theme.typo.body.fontSize}px` : theme.typo.body.fontSize)};
        line-height: ${({ theme }) => (typeof theme.typo.body.lineHeight === "number" ? `${theme.typo.body.lineHeight}px` : theme.typo.body.lineHeight)};
        margin: 0;
        min-height: 100vh;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
    }

    a {
        color: inherit;
        text-decoration: none;

        &:active,
        &:hover {
            color: ${({ theme }) => theme.color.greenN};
        }
    }

    button {
        background-color: transparent;
        border: 0;
        color: inherit;
        cursor: pointer;
        display: block;
        font-family: inherit;
        padding: 0;
    }

    fieldset {
        border: 0;
        margin: 0;
        padding: 0;
    }

    legend {
        display: block;
        float: left;
        padding: 0;
        width: 100%;

        & + * {
            clear: both;
        }
    }

    article,
    aside,
    main,
    nav,
    footer {
        display: block;
    }

    svg {
        display: block;
    }

    .show-for-sr {
        ${showForSr}
    }

    /* Visibility Classes */
    

    /* https://css-tricks.com/introduction-reduced-motion-media-query/ */
    @media (prefers-reduced-motion: reduce) {
        * {
            animation-duration: 0.01ms !important;
            animation-iteration-count: 1 !important;
            transition-duration: 0.01ms !important;
            scroll-behavior: auto !important;
        }
    }

    /* TODO: remove this after cookiebot is not in TEST mode. */
    /* Note: temporary fix for "TEST" text inserted before cookiebot's #CookieDeclaration */
    #root+div {
        overflow: hidden;
        position: sticky;
        bottom: 0;
    }
`;

export const keyframesSlideDownAndBackWithOpacity = keyframes`
    0% {
        opacity: 0;
        transform: translateY(-100%);
    }
    10% {
        opacity: 1;
        transform: translateY(0);
    }
    90% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(-100%);
    }
`;
