import * as React from "react";
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgIcon32Emergency({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg width={32} height={32} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" focusable={false} aria-hidden={true} aria-labelledby={titleId} {...props}>
            {title ? <title id={titleId}>{title}</title> : null}
            <g fill="none" fillRule="evenodd">
                <path
                    d="M18.679 7.5H3a.5.5 0 00-.5.5v16a.5.5 0 00.5.5l2.525.001.117.333a2.501 2.501 0 004.716 0l.118-.333h11.048l.118.333a2.501 2.501 0 004.716 0l.117-.333h.354L29 24.5a.5.5 0 00.5-.5v-8a1.5 1.5 0 00-1.5-1.5h-3.29l-2.992-5.236a3.5 3.5 0 00-3.04-1.764z"
                    stroke="currentColor"
                />
                <path d="M14.5 7.5h3V6a1.5 1.5 0 00-3 0v1.5z" stroke="currentColor" />
                <path d="M8 10v2h2v1H8v2H7v-2.001L5 13v-1l2-.001V10h1z" fill="currentColor" />
                <path stroke="currentColor" strokeLinecap="square" d="M2.5 18.5h27.019" />
                <circle stroke="currentColor" cx={8} cy={24} r={2.5} />
                <circle stroke="currentColor" cx={24} cy={24} r={2.5} />
            </g>
        </svg>
    );
}

export default SvgIcon32Emergency;
