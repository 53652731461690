import React from "react";
import { Switch, Route, RouteComponentProps, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { batchActions } from "redux-batched-actions";

import { Api, Gql } from "@api/Api";
import { Path } from "@utils/Path";
import { LivePreviewHelper, clearUsedIntlKeys, Loctool } from "@i18n/i18n";
import RouteContainer from "@components/RouteContainer";
import { ApplicationState } from "@redux/reducers";
import { DispatchProp } from "@redux/types";
import { LegalDocumentActions } from "@redux/actions/legalDocumentActions";
import { AppStateActions } from "@redux/actions/appStateActions";
import { SessionActions, TravelOffer } from "@redux/actions/sessionActions";
import { BackendEnvironmentActions } from "@redux/actions/backendEnvironmentActions";
import { ContractActions } from "@redux/actions/contractActions";

import SandboxPage from "@pages/Sandbox/SandboxPage";

import TravelCoveragesPage from "@pages/TravelCoverages/TravelCoveragesPage";
import TravelCalculationPage from "@pages/TravelCalculation/TravelCalculationPage";
import AccountDataPage from "@pages/AccountDataPage/AccountDataPage";
import HomeAddressPage from "@pages/HomeDataPage/HomeAddressPage";
import AreYouTravelingPage from "@pages/AreYouTravelingPage/AreYouTravelingPage";
import PassengersPage from "@pages/PassengersPage/PassengersPage";
import PaymentSettingsPage from "@pages/PaymentSettingsPage/PaymentSettingsPage";
import OverviewPage from "@pages/OverviewPage/OverviewPage";
import PhoneNumberVerificationPage from "@pages/PhoneNumberVerificationPage/PhoneNumberVerificationPage";
import PhoneNumberChangePage from "@pages/PhoneNumberChangePage/PhoneNumberChangePage";
import EmailVerificationPage from "@pages/EmailVerificationPage/EmailVerificationPage";
import EmailChangePage from "@pages/EmailChangePage/EmailChangePage";
import EmailActivationPage from "@pages/EmailActivationPage/EmailActivationPage";
import PaymentSummaryPage from "@pages/PaymentSummaryPage/PaymentSummaryPage";
import PaymentResultPage from "@pages/PaymentResultPage/PaymentResultPage";
import Page404 from "@pages/Page400";
import { Page500 } from "@pages/Page500";
import { ServerDate } from "@utils/ServerDate";

type ReduxProps = {
    travelOffer: TravelOffer;
    isSessionChanged: boolean;
    contract: Gql.Contract | null;
};

type Props = ReduxProps & DispatchProp & RouteComponentProps;

interface State {
    isLoading: boolean;
    currentKey: number;
    isErrorOccurred: boolean;
}

class App extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        if (process.env.REACT_APP_LOCTOOL_PREVIEW_ENABLED === "true") {
            window.setPreview = Loctool.setPreviewMode;
            window.getIntlConfig = Loctool.getConfig;
        }

        if (process.env.REACT_APP_FEATURE_TIME_TRAVEL_ENABLED === "true") {
            window.serverDate = ServerDate;
            window.appRef = this;
        }

        this.state = {
            isLoading: true,
            currentKey: 0,
            isErrorOccurred: false,
        };
    }

    public componentDidMount(): void {
        Loctool.addListener(this.onIntlConfigChange);
        LivePreviewHelper.initialize();
        LivePreviewHelper.sendPageReady();

        this.updateAppData();
    }

    private updateAppData = async (): Promise<void> => {
        try {
            await ServerDate.syncDateFromServer();
            try {
                const tariff = await Api.calculateTravelTariff({
                    destinationId: this.props.travelOffer.destinationId,
                    departureDate: this.props.travelOffer.departure,
                    returnDate: this.props.travelOffer.return,
                    adultsCount: this.props.travelOffer.adultsCount,
                    childrenCount: this.props.travelOffer.childrenCount,
                });
                this.props.dispatch(SessionActions.updateTravelOffer({ ...this.props.travelOffer, tariff }));
            } catch (error) {
                console.log("Session not valid - dispatch reset");
                this.props.dispatch(SessionActions.reset());
            }

            const appData = await Api.getAppData(this.props.travelOffer.departure);
            this.props.dispatch(
                batchActions([LegalDocumentActions.update(this.props.travelOffer.departure, appData.legalDocuments), BackendEnvironmentActions.update(appData.backendEnvironments)])
            );

            if (this.props.contract) {
                try {
                    const contract = await Api.getContract(this.props.contract.id);
                    this.props.dispatch(ContractActions.update(contract));
                } catch (error) {
                    this.props.dispatch(ContractActions.update(null));
                }
            }

            this.setState({ isLoading: false });
            setTimeout(() => {
                this.props.dispatch(AppStateActions.removeLoading());
            }, 50);
        } catch (error) {
            this.props.dispatch(AppStateActions.removeLoading());
            this.setState({ isErrorOccurred: true, isLoading: false });
        }
    };

    public componentWillUnmount(): void {
        clearUsedIntlKeys();
        LivePreviewHelper.destruct();
    }

    private onIntlConfigChange = (): void => {
        this.setState({ currentKey: this.state.currentKey + 1 });
    };

    public render() {
        if (this.state.isLoading) {
            return null;
        }
        if (this.state.isErrorOccurred) {
            return <Page500 />;
        }

        return (
            <RouteContainer key={this.state.currentKey}>
                <Switch>
                    <Route exact path={Path.calculatorIndex} component={TravelCalculationPage} />
                    <Route exact path={Path.travelCoverages} component={TravelCoveragesPage} />
                    <Route exact path={Path.accountData} component={AccountDataPage} />
                    <Route exact path={Path.homeAddress} component={HomeAddressPage} />
                    <Route exact path={Path.areYouTraveling} component={AreYouTravelingPage} />
                    <Route exact path={Path.passengers} component={PassengersPage} />
                    <Route exact path={Path.paymentSettings} component={PaymentSettingsPage} />
                    <Route exact path={Path.overview} component={OverviewPage} />
                    <Route exact path={Path.phoneNumberVerification} component={PhoneNumberVerificationPage} />
                    <Route exact path={Path.phoneNumberChange} component={PhoneNumberChangePage} />
                    <Route exact path={Path.emailVerification} component={EmailVerificationPage} />
                    <Route exact path={Path.emailChange} component={EmailChangePage} />
                    <Route exact path={`${Path.emailActivation}/:activationCode`} component={EmailActivationPage} />
                    <Route exact path={Path.paymentSummary} component={PaymentSummaryPage} />
                    <Route exact path={`${Path.paymentResult}/:contractId/`} component={PaymentResultPage} />

                    {process.env.NODE_ENV === "development" && <Route exact path={Path.sandbox} component={SandboxPage} />}
                    {/* TODO: https://v5.reactrouter.com/web/guides/server-rendering/404-401-or-any-other-status */}
                    <Route path="*" component={Page404} />
                </Switch>
            </RouteContainer>
        );
    }
}

const mapStateToProps = (state: ApplicationState): ReduxProps => {
    return { travelOffer: state.session.travelOffer, isSessionChanged: state.session.isChanged, contract: state.contract };
};

export default withRouter(connect(mapStateToProps)(App));
