import * as React from "react";
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgIcon32Adult({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" focusable={false} aria-hidden={true} aria-labelledby={titleId} {...props}>
            {title ? <title id={titleId}>{title}</title> : null}
            <path d="M16 29.5c7.456 0 13.5-6.044 13.5-13.5S23.456 2.5 16 2.5 2.5 8.544 2.5 16 8.544 29.5 16 29.5z" stroke="currentColor" />
            <path
                d="M16 16a6 6 0 016 6v.5a.5.5 0 01-1 0V22a5 5 0 00-10 0v.5a.5.5 0 01-1 0V22a6 6 0 016-6zm0-7a3 3 0 110 6 3 3 0 010-6zm0 1a2 2 0 100 4 2 2 0 000-4z"
                fill="currentColor"
            />
        </svg>
    );
}

export default SvgIcon32Adult;
