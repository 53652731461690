export default {
    hu: {
        number: {
            default: {
                style: "currency",
                currency: "HUF",
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
            },
            HUF: {
                style: "currency",
                currency: "HUF",
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
            },
            decimal: {
                style: "decimal",
                useGrouping: true,
            },
        },
    },
};
