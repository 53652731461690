import React, { useCallback } from "react";

import { Flex } from "@components/Flex";
import { ButtonAsRadio } from "@components/ButtonAsRadio";
import { Text } from "@components/Text";
import { TravelDestination, TravelDestinations } from "@utils/TravelCategories";
import { LoctoolMessage } from "@i18n/i18n";
import { GtmUtils } from "@utils/gtm/GtmUtils";

interface Props {
    value: TravelDestination;
    onChange: (value: TravelDestination) => void;
}

type SelectOption = TravelDestination & { help?: React.ReactNode; className: string };

export const TravelDestinationSelect = ({ value, onChange }: Props) => {
    const options: SelectOption[] = React.useMemo(() => {
        const { EU, NON_EU, USA_CANADA } = TravelDestinations.asObject();
        return [
            { ...EU, className: GtmUtils.className.destination.eu },
            { ...NON_EU, help: <LoctoolMessage id={"page.travelCalculation.form.destination.NON_EU.hint"} />, className: GtmUtils.className.destination.nonEu },
            { ...USA_CANADA, help: <LoctoolMessage id={"page.travelCalculation.form.destination.USA_CANADA.hint"} />, className: GtmUtils.className.destination.america },
        ];
    }, []);

    const _onChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const travelDestination = TravelDestinations.getById(Number.parseInt(event.target.value));
            if (travelDestination) {
                onChange(travelDestination);
            }
        },
        [onChange]
    );

    return (
        <Flex.Container $flexWrap="wrap" $bpSmall={{ $justifyContent: "space-around" }} $styleSmall={{ padding: "16px 8px 20px" }}>
            {options.map(option => {
                return (
                    <Flex.Item
                        key={option.id}
                        $shrink={1}
                        $style={{ padding: "12px 16px" }}
                        $bpSmall={{ $shrink: "shrink" }}
                        $styleSmall={{ padding: "0 8px" }}
                        $bpMedium={{ $shrink: "auto" }}
                    >
                        <ButtonAsRadio
                            id={option.category}
                            name="destination"
                            className={option.className}
                            radioIcon={option.icon}
                            value={`${option.id}`}
                            checked={option.id === value.id}
                            onChange={_onChange}
                        >
                            <LoctoolMessage id={`enum.travelDestinationCategory.${option.category}`} defaultMessage={option.category} />
                            {option.help && (
                                <Text as="span" $fontSize="text12" $style={{ display: "block", marginTop: 2 }} $styleSmall={{ marginTop: 6 }}>
                                    {option.help}
                                </Text>
                            )}
                        </ButtonAsRadio>
                    </Flex.Item>
                );
            })}
        </Flex.Container>
    );
};
