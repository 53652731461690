import React from "react";
import { Dispatch } from "redux";
import { Api } from "@api/Api";
import { AppStateActions } from "@redux/actions/appStateActions";
import { LegalDocumentActions } from "@redux/actions/legalDocumentActions";
import { ApplicationState } from "@redux/reducers";
import { WithData, withData } from "./withData";
import { GraphQLClientError } from "@api/graphql/GraphQLClient";
import { Loctool } from "@i18n/Loctool";

const withUpdatedLegalDocuments = <ApiData, Props extends WithData<ApiData>>(pageComponent: React.ComponentType<Props>) => {
    return withData(pageComponent, async (_, state: ApplicationState, dispatch: Dispatch): Promise<{}> => {
        const { departure } = state.session.travelOffer;
        if (state.legalDocuments.effectiveFrom !== departure) {
            try {
                const legalDocuments = await Api.getLegalDocuments(departure);
                dispatch(LegalDocumentActions.update(departure, legalDocuments));
            } catch (error) {
                if (error instanceof GraphQLClientError) {
                    dispatch(AppStateActions.addNotification(Loctool.formatMessage({ id: "errors.api.legalDocumentsUpdateFailed" }), "error"));
                }
            }
        }
        return {};
    });
};

export default withUpdatedLegalDocuments;
