import React from "react";
import { Box, Container } from "@components/LayoutElements";
import { Text } from "@components/Text";
import { AppBar } from "@components/AppBar";
import { Footer } from "@components/Footer";
import { PaymentSettingsForm } from "@pages/PaymentSettingsPage/PaymentSettingsForm";
import { PaymentSteps } from "@components/payment/PaymentSteps";
import { LoctoolMessage } from "@i18n/i18n";
import { SessionSelectors } from "@redux/selectors/SessionSelectors";
import { Path } from "@utils/Path";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

const PaymentSettingsPage = () => {
    const stat = useSelector(SessionSelectors.getStat);
    if (!stat.isCalculationDone) {
        return <Redirect to={Path.calculatorIndex} />;
    }
    if (!stat.isAccountDataDone) {
        return <Redirect to={Path.accountData} />;
    }
    if (!stat.isPassengersDone) {
        return <Redirect to={Path.passengers} />;
    }
    return (
        <Box $style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
            <AppBar />

            <PaymentSteps currentStep={1} />

            <Container as="main" $maxWidth={504} $px={16} $style={{ width: "100%" }}>
                <Text as="h1" $fontFamily="firaSans" $fontSize="text40" $style={{ margin: "44px 16px 20px" }}>
                    <LoctoolMessage id="page.paymentSettings.title" />
                </Text>

                <PaymentSettingsForm />
            </Container>

            <Footer />
        </Box>
    );
};

export default PaymentSettingsPage;
