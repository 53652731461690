import React from "react";
import { useTheme } from "styled-components";
import { Box, Container } from "@components/LayoutElements";
import { Text } from "@components/Text";
import { AppBar } from "@components/AppBar";
import SvgLogoUniqaFlat from "@components/svg/LogoUniqaFlat";

interface Props {}

const Page404 = (props: Props) => {
    const theme = useTheme();

    return (
        <>
            <AppBar $justifyContent="flex-start" />

            <Container $maxWidth={504} $px={16} $style={{ paddingTop: 48, paddingBottom: 48 }} $styleMedium={{ paddingTop: 40 }}>
                <Box
                    $style={{ backgroundColor: theme.color.white, borderRadius: 2, padding: "40px 16px 274px", textAlign: "center", position: "relative", overflow: "hidden" }}
                    $styleMedium={{ paddingTop: 44, paddingBottom: 168 }}
                >
                    <Text as="h1" $fontSize="text24" $style={{ marginTop: 8, marginBottom: 8 }} $styleMedium={{ marginTop: 4, marginBottom: 4 }}>
                        <Box
                            as="span"
                            $style={{ display: "block", marginBottom: 24, fontSize: 96, lineHeight: 1, letterSpacing: "0.04em", color: theme.color.blueL, opacity: 0.2 }}
                            $styleMedium={{ marginBottom: 32 }}
                        >
                            404
                        </Box>

                        <Box as="span" $style={{ display: "block", fontFamily: theme.font.firaSans, fontWeight: 600 }}>
                            Nem található
                        </Box>
                    </Text>

                    <Box as="p" $style={{ marginTop: 8, marginBottom: 8 }} $styleMedium={{ marginTop: 4, marginBottom: 4 }}>
                        A keresett oldalt nem tudjuk betölteni.
                    </Box>

                    <Box
                        $style={{ position: "absolute", left: "100%", bottom: -8, transform: "translateX(-172px)", color: theme.color.blueN, opacity: 0.1 }}
                        $styleMedium={{ bottom: -100, transform: "translateX(-184px)" }}
                    >
                        <SvgLogoUniqaFlat />
                    </Box>
                </Box>
            </Container>
        </>
    );
};

export default Page404;
