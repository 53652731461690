import React from "react";
import SvgIcon32ProductsTravelDestinationWorldwide from "@components/svg/Icon32ProductsTravelDestinationWorldwide";
import SvgIcon32ProductsTravelEu from "@components/svg/Icon32ProductsTravelEu";
import SvgIcon32ProductsTravelInternational from "@components/svg/Icon32ProductsTravelInternational";

export enum TravelDestinationCategory {
    EU = "EU",
    NON_EU = "NON_EU",
    USA_CANADA = "USA_CANADA",
}

export type TravelDestination = {
    id: number;
    category: TravelDestinationCategory;
    icon: React.ReactNode;
};

export class TravelDestinations {
    public static readonly EU: TravelDestination = {
        id: 1,
        category: TravelDestinationCategory.EU,
        icon: <SvgIcon32ProductsTravelEu />,
    };

    public static readonly NON_EU: TravelDestination = {
        id: 2,
        category: TravelDestinationCategory.NON_EU,
        icon: <SvgIcon32ProductsTravelInternational />,
    };

    public static readonly USA_CANADA: TravelDestination = {
        id: 4,
        category: TravelDestinationCategory.USA_CANADA,
        icon: <SvgIcon32ProductsTravelDestinationWorldwide />,
    };

    public static asArray(): TravelDestination[] {
        return [TravelDestinations.EU, TravelDestinations.NON_EU, TravelDestinations.USA_CANADA];
    }

    public static asObject(): { [key in TravelDestinationCategory]: TravelDestination } {
        return { EU: TravelDestinations.EU, NON_EU: TravelDestinations.NON_EU, USA_CANADA: TravelDestinations.USA_CANADA };
    }

    public static getByCategory(category: TravelDestinationCategory): TravelDestination {
        return TravelDestinations.asObject()[category];
    }

    public static getById(id: number): TravelDestination {
        const destination = TravelDestinations.asArray().find(destination => destination.id === id);
        if (!destination) {
            console.warn("TravelDestinations.getById: destination not found for id: ", id);
            return TravelDestinations.EU;
        }
        return destination;
    }
}
