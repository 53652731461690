import React from "react";
import styled from "styled-components";
import useResizeObserver from "@react-hook/resize-observer";

const useSize = (target: React.MutableRefObject<HTMLElement | null>) => {
    const [size, setSize] = React.useState<DOMRect | undefined>(undefined);

    React.useEffect(() => {
        if (target.current) {
            setSize(target.current.getBoundingClientRect());
        }
    }, [target]);

    useResizeObserver(target, entry => setSize(entry.target.getBoundingClientRect()));
    return size;
};

interface Props {
    isOpened?: boolean;
}

export const FoldingView: React.FC<Props> = props => {
    const target = React.useRef<HTMLDivElement | null>(null);
    const size = useSize(target);
    const { children, isOpened, ...otherProps } = props;

    return (
        <TransitionElement style={size ? (isOpened ? { height: size.height } : { height: 0 }) : {}} {...otherProps} $isInert={!isOpened}>
            <div ref={target}>{children}</div>
        </TransitionElement>
    );
};

export const TransitionElement = styled.div<{ $isInert: boolean }>`
    overflow: hidden;
    transition: height 240ms;

    /* TODO: use inert in the future */
    ${({ $isInert }) =>
        $isInert
            ? `
      cursor: default;
      pointer-events: none;
      user-select: none;

      & * {
          cursor: default;
          pointer-events: none;
          user-select: none;
          visibility: hidden;
      }
    `
            : ""}
`;
