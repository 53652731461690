import * as React from "react";
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgIcon32TravelAbroad({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" focusable={false} aria-hidden={true} aria-labelledby={titleId} {...props}>
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24.5 2C27.538 2 30 4.455 30 7.483c0 1.104-.549 2.455-1.647 4.05l-.19.273c.486 1.314.774 2.724.828 4.194H29v1l-.01.001c-.256 7.053-5.924 12.725-12.976 12.99l-.423.009H15.5C8.212 30 2.273 24.225 2.01 17.001L2 17v-1C2.266 8.942 7.942 3.266 15 3.01V3h1a13.7 13.7 0 014.343.895l.056-.065A5.494 5.494 0 0124.5 2zm-1.915 15H16v11.978C19.466 28.6 22.44 23.484 22.586 17zM15 17H8.597C8.74 23.372 11.614 28.423 15 28.955L15 17zm-7.404.001H3.01c.214 5.427 3.888 9.965 8.878 11.47-2.4-2.12-4.085-6.236-4.279-11.044l-.013-.426zm20.394 0h-4.404c-.107 4.924-1.776 9.175-4.196 11.383 4.848-1.587 8.39-6.057 8.6-11.383zM16 4.022V16h6.584v-.056a21.445 21.445 0 00-.162-2.152l.06.067C20.16 11.214 19 9.089 19 7.483c0-.458.056-.902.162-1.327l.076-.275c-.964-1.07-2.073-1.732-3.238-1.859zm7.585 11.979h4.405a12.472 12.472 0 00-.557-3.234l.016-.019a34.657 34.657 0 01-1.925 2.201l-.33.345a1 1 0 01-1.322.058l-.092-.085-.237-.248c.021.324.035.651.043.982zM11.89 4.529l-.228.072C6.787 6.172 3.22 10.655 3.01 16L7.596 16C7.701 11.152 9.32 6.957 11.68 4.72l.21-.192zM8.597 16H15V4.046C11.615 4.578 8.74 9.63 8.598 16zM24.5 3C22.014 3 20 5.008 20 7.483c0 1.49 1.382 3.795 4.2 6.776l.3.314c3.019-3.134 4.5-5.546 4.5-7.09C29 5.008 26.986 3 24.5 3z"
                fill="currentColor"
            />
        </svg>
    );
}

export default SvgIcon32TravelAbroad;
