import * as React from "react";
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgIcon32Bag({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg width={32} height={32} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" focusable={false} aria-hidden={true} aria-labelledby={titleId} {...props}>
            {title ? <title id={titleId}>{title}</title> : null}
            <g fill="none" fillRule="evenodd">
                <path d="M6 3.5a.5.5 0 00-.5.5v23A1.5 1.5 0 007 28.5h18a1.5 1.5 0 001.5-1.5V4a.5.5 0 00-.5-.5H6z" stroke="currentColor" />
                <path d="M6 3.5a.5.5 0 00-.5.5v9A1.5 1.5 0 007 14.5h18a1.5 1.5 0 001.5-1.5V4a.5.5 0 00-.5-.5H6z" stroke="currentColor" />
                <path stroke="currentColor" strokeLinecap="square" d="M9.5 14.5v14M21.5 14.5v14" />
                <path fill="currentColor" d="M14 10h4v1h-4z" />
            </g>
        </svg>
    );
}

export default SvgIcon32Bag;
