import { Passenger } from "@redux/actions/sessionActions";
import { ApplicationState } from "@redux/reducers";
import { Validator } from "@utils/Validator";

export const SessionSelectors = Object.freeze({
    meAsPassenger: (state: ApplicationState): Passenger | null => {
        if (state.session.areYouTraveling) {
            return {
                firstName: state.session.accountData.firstName,
                lastName: state.session.accountData.lastName,
                dateOfBirth: state.session.accountData.dateOfBirth,
            };
        }
        return null;
    },
    passengers: (state: ApplicationState) => {
        const children = state.session.passengers.children;
        const childrenCount = state.session.travelOffer.childrenCount;
        const adults = state.session.passengers.adults;
        let adultsCount = state.session.travelOffer.adultsCount;
        if (state.session.areYouTraveling) {
            adultsCount--;
        }

        if (adults.length !== adultsCount || children.length !== childrenCount) {
            return {
                adults: Array.from({ length: adultsCount }).map((): Passenger => {
                    return { firstName: "", lastName: "", dateOfBirth: "" };
                }),
                children: Array.from({ length: childrenCount }).map((): Passenger => {
                    return { firstName: "", lastName: "", dateOfBirth: "" };
                }),
            };
        }

        return { adults, children };
    },
    getStat: (state: ApplicationState) => {
        const passengers = SessionSelectors.passengers(state);
        return {
            isCalculationDone: !!state.session.travelOffer.tariff && state.session.isChanged,
            isAccountDataDone: !!state.session.accountData.dateOfBirth,
            isHomeAddressDone: !!state.session.homeAddressData.zipCode,
            isPassengersDone: !(passengers.adults.some(a => a.dateOfBirth === "") || passengers.children.some(a => a.dateOfBirth === "")),
            isContractCreated: !!state.contract,
            isPhoneValidated: !!state.contract?.accountData.phoneValidated,
            isEmailValidated: !!state.contract?.accountData.emailValidated,
        };
    },
    getStepsCount: (state: ApplicationState): number => {
        const areYouTravelVisible =
            state.session.travelOffer.adultsCount > 0 && (!state.session.accountData.dateOfBirth || !Validator.passengerAdultDateOfBirth(state.session.accountData.dateOfBirth));
        return areYouTravelVisible ? 4 : 3;
    },
});
