import { AppStateActions } from "@redux/actions/appStateActions";
import { ApplicationState } from "@redux/reducers";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";

export type WithData<T = undefined> = { data?: T; loading: boolean; error?: Error };

/**
 * Load data and show loading, until data processed
 */
export function withData<ApiData, Props extends WithData<ApiData>>(
    WrappedComponent: React.ComponentType<Props>,
    apiCall: (props: Omit<Props, keyof ApiData>, state: ApplicationState, dispatch: Dispatch) => Promise<ApiData>
) {
    return (props: Omit<Props, keyof ApiData>): React.ReactElement | null => {
        const [data, setData] = useState<ApiData | null>(null);
        const [apiError, setApiError] = useState<Error | null>(null);
        const [isLoading, setLoading] = useState<boolean>(false);
        const dispatch = useDispatch();
        const state = useSelector((state: ApplicationState) => state);

        const updateData = useCallback(async () => {
            dispatch(AppStateActions.addLoading());
            try {
                const result = await apiCall(props, state, dispatch);
                setLoading(false);
                setData(result);
            } catch (error) {
                setApiError(error as Error);
            }
            dispatch(AppStateActions.removeLoading());

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [dispatch]);

        useEffect(() => {
            updateData();
        }, [updateData]);

        return <WrappedComponent {...(props as Props)} data={data} isLoading={isLoading} error={apiError} />;
    };
}
