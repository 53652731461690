import { gql } from "@apollo/client";

export const TransactionResultFragment = gql`
    fragment TransactionResult on TransactionResult {
        transactionId
        resultCode
        resultMessage
        orderId
        providerTransactionId
        providerName
        userId
    }
`;
