import React, { useCallback, useState, useRef, useEffect } from "react";
import { Box } from "@components/LayoutElements";
import { Popup } from "@components/Popup";
import LoctoolMessage from "@i18n/LoctoolMessage";
import { DayPicker } from "./DayPicker";
import { CalculatorSimpleInput } from "@components/calculator/CalculatorSimpleInput";
import { useTheme } from "styled-components";
import { DayModifiers, Modifier } from "react-day-picker";
import { DateFormat, DateUtils } from "@utils/DateUtils";
import { YearMonthForm } from "./YearMonthForm";

type Props = {
    id?: string;
    name: string;
    label: React.ReactNode;
    placeholder: string;
    error?: React.ReactNode | undefined;
    disabledDays?: Modifier;
    defaultSelectedDate?: string;
    defaultValue?: string;
    onChange: (event: { target: { name: string; value: string } }) => void;
    className?: string;
};

export const CalculatorDateInput = React.forwardRef((props: Props, cref) => {
    const { id, name, onChange, disabledDays, error, label, placeholder, defaultValue, defaultSelectedDate, className } = props;
    const theme = useTheme();
    const [month, setMonth] = useState(defaultValue || defaultSelectedDate || undefined);
    const ref = useRef<Popup | null>(null);
    const [value, setValue] = useState(defaultValue ? DateUtils.parse(defaultValue) : null);

    useEffect(() => {
        setMonth(defaultValue || defaultSelectedDate || undefined);
    }, [defaultValue, defaultSelectedDate]);

    const onDayClick = (day: Date, modifiers: DayModifiers, e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (modifiers.disabled || modifiers.selected) {
            return;
        }
        onChange({ target: { name, value: DateUtils.format(day) } });
        setValue(day);
        ref.current && ref.current.close();
    };

    const onFocus = useCallback(() => {
        ref.current?.open();
    }, []);

    return (
        <CalculatorSimpleInput
            id={id}
            label={label}
            name={name}
            error={error}
            placeholder={placeholder}
            autoComplete="birthday"
            readOnly
            onFocus={onFocus}
            // defaultValue={defaultValue}
            value={value ? DateUtils.format(value, DateFormat.medium) : ""}
            className={className}
            popupElement={
                <Popup
                    ref={ref}
                    toggleElement={
                        <Box
                            as="button"
                            type="button"
                            aria-controls={`i-${name}`}
                            $style={{
                                display: "block",
                                width: "100%",
                                position: "absolute",
                                top: 0,
                                right: 0,
                                left: 0,
                                height: 40,
                                backgroundColor: "transparent",
                                borderRadius: 3,
                                boxShadow: `inset 0px 0px 0px 1px ${theme.color.greyN}`,
                            }}
                            className={className}
                        >
                            <span className="show-for-sr">
                                <LoctoolMessage id="component.dateInput.daypicker.toggleButton" />
                            </span>
                        </Box>
                    }
                    popperOptions={{
                        placement: "bottom-end",
                        modifiers: [
                            {
                                name: "offset",
                                options: {
                                    offset: [24, 0],
                                },
                            },
                        ],
                    }}
                >
                    <DayPicker
                        month={month ? DateUtils.parse(month) : undefined}
                        selectedDays={value ?? undefined}
                        onDayClick={onDayClick}
                        disabledDays={disabledDays}
                        captionElement={({ date, localeUtils }) => (
                            <YearMonthForm
                                date={date}
                                disabledDays={disabledDays}
                                onChange={(month: Date) => setMonth(DateUtils.format(month))}
                                getMonths={localeUtils.getMonths}
                            />
                        )}
                    />
                </Popup>
            }
        />
    );
});
