import styled from "styled-components";
import { Box } from "@components/LayoutElements";

const TooltipArrow = styled.span`
    display: block;
    height: 16px;
    pointer-events: none;
    position: relative;
    visibility: hidden;
    width: 16px;

    &::before {
        background-color: ${({ theme }) => theme.color.blueD};
        border-radius: 2px;
        bottom: 0;
        content: "";
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        transform-origin: center;
    }
`;

const TooltipContainer = styled(Box).attrs(() => ({ tabIndex: 0 }))`
    background-color: ${({ theme }) => theme.color.blueD};
    border-radius: 2px;
    color: ${({ theme }) => theme.color.white};
    max-width: 520px;
    min-width: 80px;
    padding: 16px;
    pointer-events: all;
`;

export const Tooltip = {
    Arrow: TooltipArrow,
    Container: TooltipContainer,
};
