import * as React from "react";
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgIcon32ProductsTravelDestinationWorldwide({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" focusable={false} aria-hidden={true} aria-labelledby={titleId} {...props}>
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16 30C8.268 30 2 23.732 2 16a13.935 13.935 0 012.701-8.268A14.085 14.085 0 016.995 5.28l-.247.212A14.058 14.058 0 017.6 4.8 13.938 13.938 0 0116 2c7.732 0 14 6.268 14 14 0 6.687-4.688 12.28-10.957 13.669l-.042.009-.016.003c-.154.034-.309.064-.465.093l.48-.096a14.005 14.005 0 01-1.478.24l-.05.006c-.147.015-.295.028-.443.039l-.029.002-.067.004c-.308.02-.62.031-.933.031zM10 9H8l-1 1-1.967-.983A12.94 12.94 0 003 16c0 7.18 5.82 13 13 13 .337 0 .67-.013 1-.038V25l-1.447-.724a1 1 0 01-.553-.894V22l-.707-.707a1 1 0 01-.293-.707V19l-3.242-.81a1 1 0 01-.758-.97V17l-2-3v-2a1 1 0 011-1h1V9zm8.667 10H15v1.586l.707.707A1 1 0 0116 22v1.382l1.447.724A1 1 0 0118 25v3.847l.014-.002a.98.98 0 01.186-.445l2.8-3.733V24a1 1 0 01.293-.707l.707-.707V21.5L18.667 19zM16 3c-2.642 0-5.1.788-7.15 2.141L14 6l.724-1.447A1 1 0 0115.618 4H18a1 1 0 011 1v1l.357.357 2.35 2.35a1 1 0 01.293.708l-.001.866a1 1 0 01-.684.948L19 12v1l-2 1-.488 1.465a1 1 0 01-1.656.39L13 14v2l2 2h3.667a1 1 0 01.6.2l3.333 2.5a1 1 0 01.4.8v1.086a1 1 0 01-.293.707L22 24v.667a1 1 0 01-.2.6l-2.478 3.305C24.892 27.104 29 22.032 29 16c0-7.18-5.82-13-13-13zm2 2h-2.382l-.724 1.447a1 1 0 01-1.058.54l-6-1a.996.996 0 01-.1-.022A13.05 13.05 0 005.61 8.186l1.194.597.49-.49A1 1 0 018 8h2a1 1 0 011 1v2a1 1 0 01-1 1H9v1.697l1.832 2.748A1 1 0 0111 17v.22l2.407.6-1.114-1.113A1 1 0 0112 16v-2c0-.89 1.077-1.337 1.707-.707l1.856 1.856.488-1.465a1 1 0 01.502-.578L18 12.382V12a1 1 0 01.684-.949l2.315-.771v-.866l-.999-1-1.713-1.74A1 1 0 0118 5.972V5z"
                fill="currentColor"
            />
        </svg>
    );
}

export default SvgIcon32ProductsTravelDestinationWorldwide;
