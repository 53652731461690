import React from "react";
import { Gql } from "@api/Api";
import { DateFormat, DateUtils } from "./DateUtils";
import { LoctoolMessage } from "@i18n/i18n";
import { LegalDocumentSelectors } from "@redux/selectors/LegalDocumentSelectors";
import { Box } from "@components/LayoutElements";
import { useTheme } from "styled-components";
import { useSelector } from "react-redux";
import { Helpers } from "./Helpers";

export const LegalDocuments = Object.freeze({
    renderValidFrom: (document: Gql.LegalDocument) => {
        return <LoctoolMessage id="common.validFrom" values={{ validFrom: DateUtils.format(DateUtils.parse(document.effectiveFrom), DateFormat.medium) }} />;
    },

    TravelTermsAndIpid: () => {
        const themeContext = useTheme();
        const terms = useSelector(LegalDocumentSelectors.getDocumentByCategory(Gql.legalDocumentCategory.WL_HU_TERMS));
        const ipid = useSelector(LegalDocumentSelectors.getDocumentByCategory(Gql.legalDocumentCategory.WL_HU_IPID));
        return (
            <>
                <Box as="span" $style={{ display: "block", paddingTop: 4, paddingBottom: 4 }}>
                    <Box as="a" target="_blank" href={Helpers.getLegalDocumentPdfLink(terms)} $style={{ color: themeContext.color.blueN }}>
                        <LoctoolMessage id={`enum.legalDocumentCategory.${Gql.legalDocumentCategory.WL_HU_TERMS}`} />
                    </Box>{" "}
                    <LoctoolMessage id="common.and" />{" "}
                    <Box as="a" target="_blank" href={Helpers.getLegalDocumentPdfLink(ipid)} $style={{ color: themeContext.color.blueN }}>
                        <LoctoolMessage id={`enum.legalDocumentCategory.${Gql.legalDocumentCategory.WL_HU_IPID}`} />
                    </Box>{" "}
                    {LegalDocuments.renderValidFrom(terms!)}
                </Box>
            </>
        );
    },

    PrivacyPolicy: () => {
        const themeContext = useTheme();
        const terms = useSelector(LegalDocumentSelectors.getDocumentByCategory(Gql.legalDocumentCategory.WL_HU_PRIVACY_POLICY));
        return (
            <Box as="span" $style={{ display: "block", paddingTop: 4, paddingBottom: 4 }}>
                <Box as="a" target="_blank" href={Helpers.getLegalDocumentPdfLink(terms)} $style={{ color: themeContext.color.blueN }}>
                    <LoctoolMessage id={`enum.legalDocumentCategory.${Gql.legalDocumentCategory.WL_HU_PRIVACY_POLICY}`} />
                </Box>{" "}
                {LegalDocuments.renderValidFrom(terms!)}
            </Box>
        );
    },

    UniqaMarketingPolicy: () => {
        const themeContext = useTheme();
        const ppLink = (
            <Box as="a" target="_blank" href="https://www.uniqa.hu/adatkezeles" $style={{ color: themeContext.color.blueN }}>
                <LoctoolMessage id="enum.legalDocumentCategory.WL_HU_MARKETING_POLICY.ppLink" />
            </Box>
        );

        return (
            <Box as="span" $style={{ display: "block", paddingTop: 4, paddingBottom: 4 }}>
                <LoctoolMessage id={`enum.legalDocumentCategory.${Gql.legalDocumentCategory.WL_HU_MARKETING_POLICY}`} values={{ ppLink }} />
            </Box>
        );
    },

    CherryHubMarketingPolicy: () => {
        const themeContext = useTheme();
        const cpp = useSelector(LegalDocumentSelectors.getDocumentByCategory(Gql.legalDocumentCategory.WL_HU_PRIVACY_POLICY));
        const ppLink = (
            <Box as="a" target="_blank" href={Helpers.getLegalDocumentPdfLink(cpp)} $style={{ color: themeContext.color.blueN }}>
                <LoctoolMessage id="enum.legalDocumentCategory.WL_HU_SCALE_MARKETING_POLICY.ppLink" />
            </Box>
        );

        return (
            <Box as="span" $style={{ display: "block", paddingTop: 4, paddingBottom: 4 }}>
                <LoctoolMessage id={`enum.legalDocumentCategory.${Gql.legalDocumentCategory.WL_HU_SCALE_MARKETING_POLICY}`} values={{ ppLink }} />
            </Box>
        );
    },
});
