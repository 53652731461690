import * as React from "react";
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgIcon32SpeechBubble({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" focusable={false} aria-hidden={true} aria-labelledby={titleId} {...props}>
            {title ? <title id={titleId}>{title}</title> : null}
            <mask id="icon-32-speech-bubble_svg__a" fill="#fff">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M18.208 23.792C22.668 22.938 26 19.55 26 15.5 26 10.806 21.523 7 16 7S6 10.806 6 15.5c0 4.112 3.436 7.542 8 8.33v2.963a.5.5 0 00.854.353l3.354-3.354z"
                />
            </mask>
            <path
                d="M18.208 23.792l-.188-.982-.302.058-.217.217.707.707zM14 23.83h1v-.842l-.83-.143-.17.985zm.854 3.316l-.708-.707.708.707zM25 15.5c0 3.454-2.867 6.522-6.98 7.31l.376 1.964C23.203 23.854 27 20.145 27 15.5h-2zM16 8c5.13 0 9 3.504 9 7.5h2C27 10.107 21.917 6 16 6v2zm-9 7.5C7 11.504 10.87 8 16 8V6c-5.917 0-11 4.107-11 9.5h2zm7.17 7.345C9.957 22.117 7 19.006 7 15.5H5c0 4.718 3.914 8.467 8.83 9.315l.34-1.97zm.83 3.948V23.83h-2v2.963h2zm-.854-.354a.5.5 0 01.854.354h-2c0 1.336 1.616 2.006 2.56 1.06l-1.414-1.414zm3.355-3.354l-3.355 3.354 1.415 1.415 3.354-3.355-1.414-1.414z"
                fill="currentColor"
                mask="url(#icon-32-speech-bubble_svg__a)"
            />
        </svg>
    );
}

export default SvgIcon32SpeechBubble;
