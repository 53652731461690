import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { bp } from "@style/Theme";
import { Container } from "@components/LayoutElements";
import { Flex } from "@components/Flex";
import { Button } from "@components/Button";
import SvgIcon32ArrowBack from "@components/svg/Icon32ArrowBack";
import { LoctoolMessage } from "@i18n/i18n";

interface Props {
    backLinkTo: string;
}

export const CoverageBackBar = ({ backLinkTo }: Props) => {
    return (
        <CoverageBackBarWrapper>
            <Container $maxWidth={1024 - 2 * 16} $px={16}>
                <Flex.Container $alignItems="center" $gutterMargin={8}>
                    <Flex.Item $shrink="auto" $gutterMargin={8}>
                        <LoctoolMessage id="page.travelCoverages.header.details" />
                    </Flex.Item>

                    <BackButtonWrapperItem $shrink="shrink" $gutterMargin={8}>
                        <Link
                            to={backLinkTo}
                            component={React.forwardRef((props, ref) => (
                                <Button.TextBlueD
                                    ref={ref}
                                    btnLabel={<LoctoolMessage id="page.travelCoverages.header.back" />}
                                    btnSize={32}
                                    icon={<SvgIcon32ArrowBack />}
                                    iconPosition="left"
                                    {...props}
                                />
                            ))}
                        />
                    </BackButtonWrapperItem>
                </Flex.Container>
            </Container>
        </CoverageBackBarWrapper>
    );
};

const BackButtonWrapperItem = styled(Flex.Item)`
    ${Button.ButtonContentWrapper} {
        > ${Flex.Container} {
            padding-left: 0;
            padding-right: 0;

            > ${Flex.Item} {
                & + ${Flex.Item} {
                    margin-left: 8px;
                }
            }
        }
    }
`;

const CoverageBackBarWrapper = styled.div`
    background-color: ${({ theme }) => theme.color.white};
    box-shadow: inset 0px -1px 0px ${({ theme }) => theme.color.greyD};
    padding-bottom: 12px;
    padding-top: 12px;
    position: sticky;
    top: ${({ theme }) => theme.layout.appBar.height}px;
    z-index: ${({ theme }) => theme.zIndex.appBar};

    ${bp.medium} {
        box-shadow: inset 0px -1px 0px ${({ theme }) => theme.color.greyN};
    }
`;
