import * as React from "react";
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgIcon64Notify({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg width={64} height={64} viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" focusable={false} aria-hidden={true} aria-labelledby={titleId} {...props}>
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M28.143 6.517a7.825 7.825 0 017.714 0 7.884 7.884 0 012.865 2.79l.007.013L60.925 46.6l.021.036a7.947 7.947 0 01.022 7.871c-.68 1.2-1.66 2.2-2.843 2.9a7.826 7.826 0 01-3.9 1.093H9.776a7.825 7.825 0 01-3.901-1.092 7.886 7.886 0 01-2.843-2.9 7.946 7.946 0 01.022-7.872l.02-.037L25.279 9.307a7.883 7.883 0 012.865-2.79zM32 10.773a2.608 2.608 0 00-2.237 1.264l-22.18 37.25a2.65 2.65 0 00.95 3.576c.39.232.834.357 1.288.364h44.358a2.608 2.608 0 001.288-.364 2.649 2.649 0 00.95-3.575L34.24 12.042a2.628 2.628 0 00-.955-.93 2.608 2.608 0 00-1.286-.34zm0 10.817c1.447 0 2.62 1.18 2.62 2.636v10.546A2.629 2.629 0 0132 37.408a2.629 2.629 0 01-2.62-2.636V24.226A2.628 2.628 0 0132 21.59zm-2.62 23.728A2.629 2.629 0 0132 42.68h.026c1.447 0 2.62 1.18 2.62 2.637a2.628 2.628 0 01-2.62 2.636H32a2.628 2.628 0 01-2.62-2.636z"
                fill="currentColor"
            />
        </svg>
    );
}

export default SvgIcon64Notify;
