import * as React from "react";
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgLogoVisa({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg width={66} height={46} viewBox="0 0 66 46" fill="none" xmlns="http://www.w3.org/2000/svg" focusable={false} aria-hidden={true} aria-labelledby={titleId} {...props}>
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M30.022 29.744h-3.54l2.215-13.332h3.54l-2.215 13.332zM42.854 16.738a8.93 8.93 0 00-3.175-.568c-3.495 0-5.957 1.816-5.972 4.411-.029 1.915 1.762 2.978 3.102 3.617 1.37.652 1.835 1.078 1.835 1.66-.014.893-1.106 1.305-2.125 1.305-1.414 0-2.17-.213-3.322-.71l-.466-.213-.495 2.993c.83.369 2.36.695 3.948.71 3.714 0 6.132-1.788 6.16-4.553.015-1.518-.931-2.681-2.97-3.631-1.239-.61-1.997-1.022-1.997-1.646.014-.567.641-1.148 2.039-1.148a6.15 6.15 0 012.637.51l.32.142.481-2.88z"
                fill="#0742A6"
            />
            <path
                d="M47.559 25.02c.291-.765 1.413-3.73 1.413-3.73-.015.03.29-.78.466-1.276l.247 1.149s.67 3.191.816 3.858h-2.942zm4.369-8.608H49.19c-.844 0-1.486.24-1.85 1.106l-5.258 12.226h3.714l.743-2h4.545c.101.468.422 2 .422 2h3.278l-2.856-13.332z"
                fill="#0742A6"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23.526 16.412l-3.466 9.091-.38-1.844c-.64-2.127-2.65-4.439-4.893-5.588l3.175 11.659h3.743l5.564-13.318h-3.743z"
                fill="#0742A6"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.84 16.412h-5.694l-.059.27c4.443 1.106 7.385 3.772 8.594 6.978l-1.238-6.127c-.204-.852-.83-1.093-1.602-1.121z"
                fill="#0742A6"
            />
        </svg>
    );
}

export default SvgLogoVisa;
