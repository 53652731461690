export const Path = Object.freeze({
    sandbox: "/sandbox",

    calculatorIndex: "/",
    travelCoverages: "/travel-coverages",
    accountData: "/account-data",
    homeAddress: "/home-address",
    areYouTraveling: "/are-you-traveling",
    passengers: "/passengers",
    paymentSettings: "/payment-settings",
    overview: "/overview",
    phoneNumberVerification: "/phone-number-verification",
    phoneNumberChange: "/phone-number-change",
    emailVerification: "/email-address-verification",
    emailChange: "/email-address-change",
    emailActivation: "/email-address-activation",
    paymentSummary: "/payment-summary",
    paymentResult: "/payment-result",
});
