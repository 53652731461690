import React, { useCallback } from "react";
import { Box, Container } from "@components/LayoutElements";
import { Flex } from "@components/Flex";
import { Text } from "@components/Text";
import { AppBar } from "@components/AppBar";
import { Footer } from "@components/Footer";
import { Button } from "@components/Button";
import { Card } from "@components/Card";
import { PaymentStickyPriceBox, PaymentStickyPriceBoxMobileFooterPlaceholder } from "@components/payment/PaymentStickyPriceBox";
import { PaymentSteps } from "@components/payment/PaymentSteps";
import { CouponInput } from "./CouponInput";
import { DateFormat, DateUtils } from "@utils/DateUtils";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "@redux/reducers";
import { Redirect } from "react-router";
import { Path } from "@utils/Path";
import { Loctool } from "@i18n/Loctool";
import { useTheme } from "styled-components";
import { Api } from "@api/Api";
import { GraphQLClientError } from "@api/graphql/GraphQLClient";
import { AppStateActions } from "@redux/actions/appStateActions";
import { TravelDestinations } from "@utils/TravelCategories";
import { LoctoolMessage, LoctoolHTMLMessage } from "@i18n/i18n";
import { SessionActions } from "@redux/actions/sessionActions";
import { withData } from "@utils/withData/withData";
import { Dispatch } from "redux";
import { ContractUtils } from "@redux/utils/ContractUtils";

const PaymentSummaryPage = () => {
    const theme = useTheme();
    const contract = useSelector((state: ApplicationState) => state.contract);
    const appliedCoupons = contract?.pricing?.couponApplied ?? [];
    const dispatch = useDispatch();
    const onPayClick = useCallback(
        async (event: React.MouseEvent<HTMLAnchorElement>): Promise<void> => {
            event.preventDefault();
            try {
                const paymentUrl = await Api.pay(contract!.id, contract?.pricing?.price.amount);
                window.location.href = paymentUrl;
            } catch (error) {
                if (error instanceof GraphQLClientError) {
                    dispatch(AppStateActions.addNotification(error.intlMessage, "error"));
                }
            }
        },
        [contract, dispatch]
    );

    if (!contract) {
        return <Redirect to={Path.calculatorIndex} />;
    }

    return (
        <Box $style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
            <AppBar />

            <PaymentSteps currentStep={3} />

            <Container as="main" $maxWidth={504} $px={16} $style={{ width: "100%" }}>
                <Text as="h1" $fontFamily="firaSans" $fontSize="text40" $style={{ margin: "44px 16px 20px" }}>
                    <LoctoolMessage id="page.paymentSummary.title" />
                </Text>

                <Card.Wrapper $style={{ padding: "10px 16px 16px" }}>
                    <Text as="h2" $fontSize="text16" $style={{ margin: 0 }}>
                        <Flex.Container as="span" $alignItems="center" $gutterMargin={16}>
                            <Flex.Item as="span" $shrink="shrink" $gutterMargin={16}>
                                {TravelDestinations.getById(contract.travelData.destinationCategoryId).icon}
                            </Flex.Item>

                            <Flex.Item as="span" $shrink="auto" $gutterMargin={16}>
                                <LoctoolHTMLMessage id="page.paymentSummary.travelCard.title" />
                            </Flex.Item>

                            <Flex.Item as="span" $shrink="shrink" $gutterMargin={16}>
                                {Loctool.formatCurrency(contract.pricing?.priceWithoutDiscount.amount as number)}
                            </Flex.Item>
                        </Flex.Container>
                    </Text>

                    <Box $style={{ padding: "14px 0 0 48px" }}>
                        <Box as="dl" $style={{ margin: 0, color: theme.color.blueL }}>
                            <Box as="dt">
                                <LoctoolMessage id="page.paymentSummary.travelCard.insuranceInterval" />
                            </Box>

                            <Box as="dd" $style={{ margin: 0 }}>
                                {DateUtils.format(DateUtils.parse(contract.travelData.firstDayOfTravel), DateFormat.medium)} -{" "}
                                {DateUtils.format(DateUtils.parse(contract.travelData.lastDayOfTravel), DateFormat.medium)}
                            </Box>
                        </Box>
                    </Box>
                </Card.Wrapper>

                <CouponInput />

                <Text as="h2" $fontFamily="firaSans" $fontSize="text24" $style={{ margin: "40px 16px 16px" }}>
                    <LoctoolMessage id="page.paymentSummary.summary" />
                </Text>

                <Card.Wrapper>
                    <Card.Table $noSeparator>
                        <tbody>
                            <tr>
                                <th scope="row">
                                    <LoctoolMessage id="page.paymentSummary.travelInsurance" />
                                </th>
                                <td>
                                    <Box $style={{ textAlign: "right" }}>{Loctool.formatCurrency(contract.pricing?.priceWithoutDiscount.amount as number)}</Box>
                                </td>
                            </tr>

                            {appliedCoupons.length > 0 && (
                                <tr>
                                    <th scope="row">
                                        <LoctoolMessage id="page.paymentSummary.summaryCoupon" />
                                        <>&nbsp;</>
                                        {-appliedCoupons[0]?.discountPercent}%
                                    </th>
                                    <td>
                                        <Box $style={{ textAlign: "right", color: theme.color.greenN }}>{Loctool.formatCurrency(-contract.pricing?.discount.amount as number)}</Box>
                                    </td>
                                </tr>
                            )}

                            <Box as="tr" $style={{ boxShadow: `0px -1px 0px ${theme.color.greyN}` }}>
                                <th scope="row">
                                    <LoctoolMessage id="page.paymentSummary.summaryPay" />
                                </th>
                                <td>
                                    <Box $style={{ textAlign: "right", fontWeight: 700 }}>{Loctool.formatCurrency(contract.pricing?.price.amount as number)}</Box>
                                </td>
                            </Box>
                        </tbody>
                    </Card.Table>
                </Card.Wrapper>

                <PaymentStickyPriceBox
                    priceText={Loctool.formatCurrency(contract.pricing?.price.amount as number)}
                    ctaElement={<Button.Primary btnLabel={<LoctoolMessage id="page.paymentSummary.pay" />} onClick={onPayClick} />}
                />
            </Container>

            <Footer />

            {/* Note: sticky pricebox placeholder maxWidth: medium-1 */}
            <PaymentStickyPriceBoxMobileFooterPlaceholder />
        </Box>
    );
};

const PaymentSummaryPageWithData = withData(PaymentSummaryPage, async (_, { contract }: ApplicationState, dispatch: Dispatch): Promise<{}> => {
    if (contract) {
        try {
            const travelOffer = ContractUtils.getTravelOffer(contract);
            travelOffer.tariff = await Api.calculateTravelTariff({
                adultsCount: travelOffer.adultsCount,
                childrenCount: travelOffer.childrenCount,
                departureDate: travelOffer.departure,
                returnDate: travelOffer.return,
                destinationId: travelOffer.destinationId,
            });
            dispatch(SessionActions.updateTravelOffer(travelOffer, true));
        } catch (error) {
            if (error instanceof GraphQLClientError) {
                dispatch(AppStateActions.addNotification(error.intlMessage, "error"));
            }
        }
    }
    dispatch(AppStateActions.removeLoading());
    return {};
});

export default PaymentSummaryPageWithData;
