import { Api, Gql } from "@api/Api";
import { AppStateActions } from "@redux/actions/appStateActions";
import { ApplicationState } from "@redux/reducers";
import { LegalDocumentSelectors } from "@redux/selectors/LegalDocumentSelectors";
import { SessionSelectors } from "@redux/selectors/SessionSelectors";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

export const useContractCreate = (isUniqaMarketingPolicyAccepted: boolean, isCherryHubMarketingPolicyAccepted: boolean) => {
    const dispatch = useDispatch();
    const { session } = useSelector((state: ApplicationState) => state);
    const meAsPassenger = useSelector(SessionSelectors.meAsPassenger);
    const docRelActions = useSelector(LegalDocumentSelectors.getDocumentRelationActions(isUniqaMarketingPolicyAccepted, isCherryHubMarketingPolicyAccepted));

    const createContract = useCallback(async (): Promise<Gql.Contract> => {
        dispatch(AppStateActions.addLoading());
        let travelMembers = [...session.passengers.adults, ...session.passengers.children];
        if (meAsPassenger) {
            travelMembers = [meAsPassenger, ...travelMembers];
        }
        const contract = await Api.createContract(
            {
                email: session.accountData.email,
                phoneNumber: `${session.accountData.phoneNumberPrefix}${session.accountData.phoneNumber}`,
                name: {
                    firstName: session.accountData.firstName,
                    lastName: session.accountData.lastName,
                },
                countryCode: session.homeAddressData.countryCode,
                languageCode: Gql.LanguageCode.HU,
                city: session.homeAddressData.city,
                address: session.homeAddressData.address,
                zipCode: session.homeAddressData.zipCode,
                dateOfBirth: session.accountData.dateOfBirth,
                firstDayOfTravel: session.travelOffer.departure,
                lastDayOfTravel: session.travelOffer.return,
                destinationCategoryId: session.travelOffer.destinationId,
                travelMembers,
            },
            docRelActions.custLegal,
            docRelActions.travelLegal
        );
        await Api.sendOrResendSMSCode(contract.id);
        dispatch(AppStateActions.removeLoading());
        return contract;
    }, [dispatch, docRelActions, session, meAsPassenger]);

    return createContract;
};
