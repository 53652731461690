import { Gql } from "@api/Api";
import { TravelOffer } from "@redux/actions/sessionActions";
import { DateUtils } from "@utils/DateUtils";
import { ValidatorConstants } from "@utils/Validator";

export class ContractUtils {
    public static getTravelOffer(contract: Gql.Contract): TravelOffer {
        const adults = contract.travelData.travelMembers.filter(m => {
            return DateUtils.isBefore(DateUtils.parse(m.dateOfBirth), ValidatorConstants.passengerChildBirthMax(DateUtils.parse(contract.travelData.firstDayOfTravel)));
        });

        return {
            adultsCount: adults.length,
            childrenCount: contract.travelData.travelMembers.length - adults.length,
            departure: contract.travelData.firstDayOfTravel,
            return: contract.travelData.lastDayOfTravel,
            destinationId: contract.travelData.destinationCategoryId,
            tariff: null,
        };
    }
}
