import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useRouteMatch, useLocation } from "react-router";
import { Helpers } from "@utils/Helpers";
import { PaymentDetails } from "@pages/PaymentResultPage/PaymentDetails";
import { Gql, Api } from "@api/Api";
import { AppStateActions } from "@redux/actions/appStateActions";
import { useDispatch } from "react-redux";
import { GraphQLClientError } from "@api/graphql/GraphQLClient";
import { PaymentInProgressPage, PaymentSuccessfulPage, PaymentUnsuccessfulPage } from "./PaymentResult";
import { ContractUtils } from "@redux/utils/ContractUtils";
import { Gtm } from "@utils/gtm/Gtm";

interface RouteParams {
    contractId?: string;
}

const UPDATE_PAYMENT_RESULT_TIMEOUT = 3000;

const PaymentResultPage = () => {
    const location = useLocation();
    const match = useRouteMatch<RouteParams>();
    const dispatch = useDispatch();
    const transactionId = useMemo(() => Helpers.getLocationParameterByName("TransactionId", location.search), [location]);
    const [transactionResult, setTransactionResult] = useState<Gql.TransactionResult | null>(null);
    const [contract, setContract] = useState<Gql.Contract | null>(null);
    const [tariff, setTariff] = useState<Gql.Amount | null>(null);
    const updateTransactionResult = useCallback(
        async (transactionId: string, contractId: string) => {
            try {
                if (!contract) {
                    const newContract = await Api.getContract(contractId);
                    setContract(newContract);
                    const travelOffer = ContractUtils.getTravelOffer(newContract);
                    try {
                        const newTariff = await Api.calculateTravelTariff({
                            adultsCount: travelOffer.adultsCount,
                            childrenCount: travelOffer.childrenCount,
                            departureDate: travelOffer.departure,
                            returnDate: travelOffer.return,
                            destinationId: travelOffer.destinationId,
                        });
                        setTariff(newTariff);
                    } catch (error) {
                        console.warn("tariff calculation failed");
                    }
                }
                const result = await Api.getTransactionResult(contractId, transactionId);
                setTransactionResult(result);
                if (result.resultCode === Gql.TransactionStatus.PENDING) {
                    setTimeout(() => updateTransactionResult(transactionId, contractId), UPDATE_PAYMENT_RESULT_TIMEOUT);
                }
            } catch (error) {
                if (error instanceof GraphQLClientError) {
                    dispatch(AppStateActions.addNotification(error.intlMessage, "error"));
                }
                setTimeout(() => updateTransactionResult(transactionId, contractId), UPDATE_PAYMENT_RESULT_TIMEOUT);
            }
        },
        [dispatch, contract]
    );

    useEffect(() => {
        dispatch(AppStateActions.reset());
        if (transactionId && match.params.contractId) {
            updateTransactionResult(transactionId, match.params.contractId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!contract || !transactionId || !transactionResult || transactionResult.resultCode === Gql.TransactionStatus.PENDING) {
            return;
        }
        if (transactionResult.resultCode === Gql.TransactionStatus.SUCCESSFUL) {
            Gtm.pushPaymentSucceed({
                orderId: transactionResult.orderId,
                price: tariff?.amount,
                email: contract.accountData.email,
                phoneNumber: contract.accountData.phoneNumber,
                userId: transactionResult.userId,
            });
        } else {
            Gtm.pushPaymentFailed({ orderId: transactionResult.orderId, error: transactionResult.resultMessage ?? undefined });
        }
    }, [contract, tariff?.amount, transactionId, transactionResult]);

    const onRetryClick = useCallback(async () => {
        try {
            const contract = await Api.getContract(match.params.contractId!);
            const travelOffer = ContractUtils.getTravelOffer(contract);
            const tariff = await Api.calculateTravelTariff({
                adultsCount: travelOffer.adultsCount,
                childrenCount: travelOffer.childrenCount,
                departureDate: travelOffer.departure,
                returnDate: travelOffer.return,
                destinationId: travelOffer.destinationId,
            });
            if (tariff) {
                const paymentUrl = await Api.pay(contract.id, tariff.amount);
                window.location.href = paymentUrl;
            }
        } catch (error) {
            if (error instanceof GraphQLClientError) {
                dispatch(AppStateActions.addNotification(error.intlMessage, "error"));
            }
        }
    }, [dispatch, match.params.contractId]);

    if (!transactionResult || transactionResult.resultCode === Gql.TransactionStatus.PENDING) {
        return <PaymentInProgressPage />;
    }
    if (transactionResult.resultCode === Gql.TransactionStatus.SUCCESSFUL) {
        return (
            <PaymentSuccessfulPage contract={contract!}>
                <PaymentDetails transactionResult={transactionResult} />
            </PaymentSuccessfulPage>
        );
    }
    return (
        <PaymentUnsuccessfulPage onRetryClick={onRetryClick}>
            <PaymentDetails transactionResult={transactionResult} />
        </PaymentUnsuccessfulPage>
    );
};

export default PaymentResultPage;
