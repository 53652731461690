import * as React from "react";
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgIcon32Rehabilitation({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg width={32} height={32} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" focusable={false} aria-hidden={true} aria-labelledby={titleId} {...props}>
            {title ? <title id={titleId}>{title}</title> : null}
            <g fillRule="evenodd">
                <path d="M14.5 14H11v4h3.5m-.5 0v3h4v-3m-.5 0H21v-4h-3.5m.5 0v-3h-4v3" fill="none" stroke="currentColor" strokeLinejoin="round" />
                <path
                    d="M16.11 4c4.843 0 9.016 2.869 10.912 7h-1.111a11 11 0 00-9.8-6c-6.062 0-10.978 4.902-11 10.958l1.296-1.036a.5.5 0 01.625.78l-2.11 1.688a.5.5 0 01-.624 0l-2.11-1.687a.5.5 0 01.625-.781l1.297 1.037.004-.264C4.275 9.209 9.584 4 16.11 4zM15.912 28C11.069 28 6.896 25.131 5 21h1.111a11 11 0 009.8 6c6.062 0 10.978-4.902 11-10.958l-1.296 1.036a.5.5 0 01-.625-.78l2.11-1.688a.5.5 0 01.624 0l2.11 1.687a.5.5 0 01-.625.781l-1.297-1.037-.004.264C27.747 22.791 22.438 28 15.912 28z"
                    fill="currentColor"
                />
            </g>
        </svg>
    );
}

export default SvgIcon32Rehabilitation;
