import React from "react";
import { Card } from "@components/Card";
import { Flex } from "@components/Flex";
import { Box } from "@components/LayoutElements";
import SvgIcon32Adult from "@components/svg/Icon32Adult";
import { DateUtils, DateFormat } from "@utils/DateUtils";
import { useSelector } from "react-redux";
import { SessionSelectors } from "@redux/selectors/SessionSelectors";
import { LoctoolMessage } from "@i18n/i18n";

interface Props {}

export const UserPassengerCard = (props: Props) => {
    const meAsPassenger = useSelector(SessionSelectors.meAsPassenger);
    if (!meAsPassenger) {
        return null;
    }
    return (
        <Card.Wrapper $style={{ marginTop: 24 }}>
            <Card.Table>
                <thead>
                    <tr>
                        <th colSpan={2} scope="colgroup">
                            <Flex.Container $style={{ marginTop: -4, marginBottom: -4 }}>
                                <Flex.Item $shrink="shrink" $style={{ marginRight: 16 }}>
                                    <SvgIcon32Adult />
                                </Flex.Item>

                                <Flex.Item $shrink="auto" $alignSelf="center">
                                    <LoctoolMessage id="page.passengers.card.insured.contractor" />
                                </Flex.Item>
                            </Flex.Container>
                        </th>
                    </tr>
                </thead>

                <tbody>
                    <tr>
                        <Box as="th" $style={{ width: "50%" }} scope="row">
                            <LoctoolMessage id="page.passengers.card.lastName.label" />
                        </Box>
                        <Box as="td" $style={{ width: "50%" }}>
                            {meAsPassenger.lastName}
                        </Box>
                    </tr>

                    <tr>
                        <Box as="th" $style={{ width: "50%" }} scope="row">
                            <LoctoolMessage id="page.passengers.card.firstName.label" />
                        </Box>
                        <Box as="td" $style={{ width: "50%" }}>
                            {meAsPassenger.firstName}
                        </Box>
                    </tr>

                    <tr>
                        <Box as="th" $style={{ width: "50%" }} scope="row">
                            <LoctoolMessage id="page.passengers.card.dateOfBirth.label" />
                        </Box>
                        <Box as="td" $style={{ width: "50%" }}>
                            {DateUtils.format(DateUtils.parse(meAsPassenger.dateOfBirth), DateFormat.medium)}
                        </Box>
                    </tr>
                </tbody>
            </Card.Table>
        </Card.Wrapper>
    );
};
