import React from "react";
import { ThemeContext } from "styled-components";
import { Box } from "@components/LayoutElements";
import { Flex } from "@components/Flex";
import { Text } from "@components/Text";
import { LoctoolMessage } from "@i18n/i18n";

interface Props {
    priceText: string;
    ctaElement?: JSX.Element;
}

export const PaymentStickyPriceBox = ({ priceText, ctaElement }: Props) => {
    const themeContext = React.useContext(ThemeContext);

    return (
        <Box
            $style={{
                position: "fixed",
                bottom: 0,
                left: 0,
                right: 0,
                minHeight: 64,
                padding: "10px 32px 6px",
                backgroundColor: themeContext.color.white,
                boxShadow: `inset 0px 1px 0px ${themeContext.color.greyN}`,
                zIndex: themeContext.zIndex.modal,
            }}
            $styleMedium={{ margin: "48px -16px 0", borderRadius: 2, boxShadow: `inset 0px 0px 0px 1px ${themeContext.color.greyN}`, position: "sticky", minHeight: 80 }}
        >
            <Flex.Container $gutterMargin={16}>
                <Flex.Item as="dl" $shrink="auto" $gutterMargin={16} id="amount-to-be-paid-desc" aria-atomic="true" aria-live="polite" $style={{ margin: 0 }}>
                    <Text as="dt" $fontSize="text12" $style={{ fontStyle: "italic" }}>
                        <LoctoolMessage id="component.stickyPriceBox.label" />
                    </Text>

                    <Text as="dd" $fontFamily="firaSans" $fontSize="text24" $fontSizeMedium="text40" $style={{ margin: 0, fontWeight: 600 }}>
                        {priceText}
                    </Text>
                </Flex.Item>

                {ctaElement && (
                    <Flex.Item $shrink="shrink" $gutterMargin={16} $style={{ marginTop: 2 }} $styleMedium={{ marginTop: 10 }}>
                        {React.cloneElement(ctaElement, {
                            ["aria-describedby"]: "amount-to-be-paid-desc",
                        })}
                    </Flex.Item>
                )}
            </Flex.Container>
        </Box>
    );
};

// Note: insert this after Footer
export function PaymentStickyPriceBoxMobileFooterPlaceholder() {
    return <Box $style={{ height: 64 }} $styleMedium={{ height: "auto" }} />;
}
