import React from "react";
import { useTheme } from "styled-components";
import { Box } from "@components/LayoutElements";
import { Text } from "@components/Text";
import { AppBar } from "@components/AppBar";
import SvgIcon64Notify from "@components/svg/Icon64Notify";
import SvgLogoUniqaFlat from "@components/svg/LogoUniqaFlat";
import { Button } from "./Button";
import LoctoolMessage from "@i18n/LoctoolMessage";

type Props = {
    onCloseClick: () => void;
};

export const BlacklistedPage = (props: Props) => {
    const theme = useTheme();

    return (
        <Box $style={{ display: "flex", flexDirection: "column", minHeight: "100vh", backgroundColor: theme.color.white }}>
            <AppBar $justifyContent="flex-start" />

            <Box
                $style={{ position: "relative", zIndex: 2, maxWidth: 560 + 2 * 32, margin: "0 auto", padding: "38px 32px", textAlign: "center", color: theme.color.blueNightshade }}
                $styleMedium={{ paddingTop: 92, paddingBottom: 0 }}
            >
                <Box as={SvgIcon64Notify} color={theme.color.blueL} $style={{ margin: "0 auto -8px", opacity: 0.2 }} $styleMedium={{ width: 88, height: 88, marginBottom: 36 }} />

                <Text as="h1" $fontFamily="firaSans" $fontSize="text24" $style={{ marginTop: 0, marginBottom: 0, fontSize: 28, fontWeight: 600 }}>
                    <LoctoolMessage id="components.blacklistedPage.h1" />
                </Text>

                <Box as="p" $style={{ marginTop: 24, marginBottom: 24 }} $styleMedium={{ marginTop: 5, marginBottom: 5 }}>
                    <Box as="span" $style={{ display: "block" }}>
                        <LoctoolMessage id="components.blacklistedPage.description1" />
                    </Box>{" "}
                    <Box as="span" $style={{ display: "block" }}>
                        <LoctoolMessage id="components.blacklistedPage.description2" />
                    </Box>
                </Box>

                <Box $style={{ marginTop: 24, marginBottom: -86 }} $styleMedium={{ marginTop: 52, marginBottom: -217 }}>
                    <Box $style={{ maxWidth: 156, margin: "0 auto", ">button": { background: "linear-gradient(180deg, #FFFFFF 30.21%, #F5F5FA 100%)", borderRadius: 4 } }}>
                        <Button.Hollow type="button" btnLabel={<LoctoolMessage id="components.blacklistedPage.closeButtonLabel" />} isExpanded onClick={props.onCloseClick} />
                    </Box>
                </Box>
            </Box>

            <Box
                $style={{ pointerEvents: "none", position: "relative", zIndex: 1, overflow: "hidden", marginTop: "auto", height: 275, width: "100%" }}
                $styleMedium={{ height: 520 }}
            >
                <Box
                    as={SvgLogoUniqaFlat}
                    $style={{
                        position: "absolute",
                        left: "100%",
                        bottom: -16,
                        transform: "translate3d(-224px, 0, 0)",
                        color: theme.color.blueN,
                        opacity: 0.1,
                    }}
                    $styleMedium={{ width: 694, height: 520, bottom: -26, left: "auto", right: -244, transform: "none" }}
                />
            </Box>
        </Box>
    );
};
