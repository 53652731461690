import * as React from "react";
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgIcon32HealthReason({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={32}
            height={32}
            viewBox="0 0 32 32"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            focusable={false}
            aria-hidden={true}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <defs>
                <path id="icon-32-health-reason_svg__b" d="M.954 0h14v28h-14z" />
                <path
                    d="M-13 9.653C-13 4.72-9.323 1-4.447 1-2.612 1-.58 1.893.709 2.728l.245.159.245-.159C2.49 1.893 4.52 1 6.356 1c4.876 0 8.553 3.72 8.553 8.653 0 6.73-12.015 15.91-13.955 17.347C-.986 25.563-13 16.383-13 9.653z"
                    id="icon-32-health-reason_svg__a"
                />
                <path id="icon-32-health-reason_svg__e" d="M0 0h14v28H0z" />
                <mask id="icon-32-health-reason_svg__d" maskContentUnits="userSpaceOnUse" maskUnits="objectBoundingBox" x={0} y={0} width={27.909} height={26} fill="#fff">
                    <use xlinkHref="#icon-32-health-reason_svg__a" />
                </mask>
            </defs>
            <g fill="none" fillRule="evenodd">
                <g transform="translate(15.046 2)">
                    <mask id="icon-32-health-reason_svg__c" fill="#fff">
                        <use xlinkHref="#icon-32-health-reason_svg__b" />
                    </mask>
                    <g strokeDasharray={3} mask="url(#icon-32-health-reason_svg__c)" stroke="currentColor" strokeWidth={2}>
                        <use mask="url(#icon-32-health-reason_svg__d)" xlinkHref="#icon-32-health-reason_svg__a" />
                    </g>
                </g>
                <g transform="translate(2 2)">
                    <mask id="icon-32-health-reason_svg__f" fill="#fff">
                        <use xlinkHref="#icon-32-health-reason_svg__e" />
                    </mask>
                    <path
                        d="M19.402 1.5c-1.77 0-3.722.877-5.919 1.648C12.263 2.357 10.34 1.5 8.598 1.5c-2.294 0-4.31.872-5.749 2.328C1.407 5.287.546 7.328.546 9.653c0 6.32 10.86 14.778 13.454 16.723 2.593-1.944 13.454-10.402 13.454-16.723 0-2.325-.861-4.366-2.303-5.825C23.71 2.372 21.696 1.5 19.4 1.5z"
                        stroke="currentColor"
                        mask="url(#icon-32-health-reason_svg__f)"
                    />
                </g>
            </g>
        </svg>
    );
}

export default SvgIcon32HealthReason;
