import * as React from "react";
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgIcon32Water({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg width={32} height={32} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" focusable={false} aria-hidden={true} aria-labelledby={titleId} {...props}>
            {title ? <title id={titleId}>{title}</title> : null}
            <path d="M16 3.726C9.66 10.435 6.5 15.554 6.5 19a9.5 9.5 0 0019 0c0-3.446-3.16-8.565-9.5-15.274z" stroke="currentColor" fill="none" fillRule="evenodd" />
        </svg>
    );
}

export default SvgIcon32Water;
