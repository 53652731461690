import React from "react";
import { ThemeContext } from "styled-components";
import { Box } from "@components/LayoutElements";
import { Flex } from "@components/Flex";
import { Input } from "@components/Input";
import { UseFormRegisterReturn } from "react-hook-form";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    label: React.ReactNode;
    name: string;
    error?: React.ReactNode;
    popupElement?: JSX.Element;
    numberOnly?: boolean;
    register?: UseFormRegisterReturn;
}

export const CalculatorSimpleInput = React.forwardRef(({ label: labelText, name: nameText, error: errorText, popupElement, register, ...props }: Props, ref) => {
    const themeContext = React.useContext(ThemeContext);
    return (
        <Box $style={{ padding: "8px 16px" }} $styleMedium={{ paddingTop: 4, paddingBottom: 4 }}>
            <Flex.Container $gutterMargin={8} $bpMedium={{ $gutterMargin: 16 }}>
                <Flex.Item
                    as="label"
                    htmlFor={`i-${nameText}`}
                    $shrink={112}
                    $alignSelf="center"
                    $gutterMargin={8}
                    $style={{ marginTop: -4, marginBottom: -4 }}
                    $bpMedium={{ $shrink: 133, $gutterMargin: 16 }}
                >
                    {labelText}
                </Flex.Item>

                <Flex.Item $shrink="auto" $gutterMargin={8} $bpMedium={{ $shrink: "auto", $gutterMargin: 16 }} $style={{ position: "relative" }}>
                    <Input
                        id={`i-${nameText}`}
                        ref={ref}
                        aria-describedby={`i-${nameText}-error`}
                        name={nameText}
                        $hasError={!!errorText}
                        {...props}
                        {...register}
                        value={props.value}
                    />
                    {popupElement}
                </Flex.Item>
            </Flex.Container>

            <Box id={`i-${nameText}-error`} aria-live="assertive" $style={errorText ? { paddingTop: 8, paddingBottom: 16, color: themeContext.color.redN } : {}}>
                {errorText}
            </Box>
        </Box>
    );
});
