// eslint-disable-next-line node/no-unpublished-import
import translationDataJSON from "@i18n/locales/.loctool.cache.json";

export const printAppInfo = () => {
    const buildTime = process.env.REACT_APP_BUILD_TIME || null;
    const buildSHA = process.env.REACT_APP_BUILD_SHA || null;
    if (buildTime !== null) {
        let translationData = "";
        if (process.env.REACT_APP_LOCTOOL_DRAFT_ID) {
            translationData = `Draft version: ${process.env.REACT_APP_LOCTOOL_DRAFT_ID.padEnd(31, " ")}`;
        } else if (process.env.REACT_APP_LOCTOOL_PRODUCT_ID) {
            translationData = `${process.env.REACT_APP_LOCTOOL_PRODUCT_ID}:${process.env.REACT_APP_LOCTOOL_TRANSLATION_FLAG.toLowerCase()}, version: ${
                translationDataJSON.systemVersion || "N/A"
            }`.padEnd(43, " ");
        }

        console.log(
            `%c                                                            
     **     ** **      ** **     **   **       ** **        
     /**    /**/**     /**/**    /**  /**      /**/**       
     /**    /**/**     /**/**    /**  /**   *  /**/**       
     /**    /**/**********/**    /**  /**  *** /**/**       
     /**    /**/**//////**/**    /**  /** **/**/**/**       
     /**    /**/**     /**/**    /**  /**** //****/**       
     //******* /**     /**//*******   /**/   ///**/******** 
      ///////  //      //  ///////    //       // ////////  
    ${"".padEnd(56, " ")}
     Build date: ${buildTime.padEnd(56 - 13, " ")}
     ${buildSHA !== null ? `Build SHA: ${buildSHA.padEnd(56 - 12, " ")}` : "".padEnd(55, " ")}
     Loctool: ${translationData.padEnd(56 - 10, " ")}
    ${"".padEnd(56, " ")}`,
            "background-color:#005ca9;color:white;font-family:monospace;white-space:pre"
        );
    }
};
