import React from "react";
import { useTheme } from "styled-components";
import { Box, Container } from "@components/LayoutElements";
import { Text } from "@components/Text";
import { AppBar } from "@components/AppBar";
import { Footer } from "@components/Footer";
import { Card } from "@components/Card";
import { PaymentSteps } from "@components/payment/PaymentSteps";
import { OverviewConsent } from "./OverviewConsent";
import { OverviewTravelCard } from "./OverviewTravelCard";
import { OverviewUserCard } from "./OverviewUserCard";
import { ApplicationState } from "@redux/reducers";
import { useSelector } from "react-redux";
import { LoctoolMessage, Loctool } from "@i18n/i18n";
import { SessionSelectors } from "@redux/selectors/SessionSelectors";
import { OverviewPassengerCard } from "./OverviewPassengerCard";
import { Path } from "@utils/Path";
import { Redirect } from "react-router-dom";
import withUpdatedLegalDocuments from "@utils/withData/withLegalDocuments";

interface Props {}

const OverviewPage = (props: Props) => {
    const theme = useTheme();
    const session = useSelector((state: ApplicationState) => state.session);
    const passengers = useSelector(SessionSelectors.passengers);
    const stat = useSelector(SessionSelectors.getStat);
    if (!stat.isCalculationDone) {
        return <Redirect to={Path.calculatorIndex} />;
    }
    if (!stat.isAccountDataDone) {
        return <Redirect to={Path.accountData} />;
    }
    if (!stat.isPassengersDone) {
        return <Redirect to={Path.passengers} />;
    }

    return (
        <Box $style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
            <AppBar />

            <PaymentSteps currentStep={2} />

            <Container as="main" $maxWidth={504} $px={16} $style={{ width: "100%" }}>
                <Text as="h1" $fontFamily="firaSans" $fontSize="text40" $style={{ margin: "44px 16px 20px" }}>
                    <LoctoolMessage id="page.overview.title" />
                </Text>

                <OverviewTravelCard />

                <Text as="h2" $fontFamily="firaSans" $fontSize="text24" $style={{ margin: "40px 16px 16px" }}>
                    <LoctoolMessage id={session.areYouTraveling ? "page.overview.contractorAndPassenger" : "page.overview.contractor"} />
                </Text>

                <OverviewUserCard />

                {passengers.adults.map((passenger, index) => {
                    return <OverviewPassengerCard key={`a-${index}`} id={`${index}`} passenger={passenger} isAdult={true} />;
                })}

                {passengers.children.map((passenger, index) => {
                    return <OverviewPassengerCard key={`c-${index}`} id={`${index}`} passenger={passenger} isAdult={false} />;
                })}

                <Text as="h2" $fontFamily="firaSans" $fontSize="text24" $style={{ margin: "40px 16px 16px" }}>
                    <LoctoolMessage id="page.overview.payment" />
                </Text>

                <Card.Wrapper>
                    <Card.Table>
                        <tbody>
                            <tr>
                                <Box as="th" $style={{ width: "50%" }} scope="row">
                                    <Box $style={{ fontWeight: 700 }}>
                                        <LoctoolMessage id="page.overview.paymentMethod" />
                                    </Box>
                                </Box>
                                <Box as="td" $style={{ width: "50%" }}>
                                    <Box $style={{ fontWeight: 700 }}>
                                        <LoctoolMessage id="page.overview.bankcard" />
                                    </Box>
                                </Box>
                            </tr>

                            <tr>
                                <Box as="th" $style={{ width: "50%" }} scope="row">
                                    <LoctoolMessage id="page.overview.paymentType" />
                                </Box>
                                <Box as="td" $style={{ width: "50%" }}>
                                    <LoctoolMessage id="page.overview.oneTime" />
                                </Box>
                            </tr>
                        </tbody>
                    </Card.Table>
                </Card.Wrapper>

                <Box
                    $style={{
                        marginTop: 32,
                        marginBottom: 24,
                        borderRadius: 2,
                        padding: 16,
                        textAlign: "center",
                        backgroundColor: theme.color.greenN,
                        color: theme.color.white,
                    }}
                    $styleMedium={{ marginBottom: 90 }}
                >
                    <Text as="h3" $fontSize="text16" $style={{ margin: 0 }}>
                        <LoctoolMessage id="page.overview.tariff" />
                    </Text>

                    <Text $fontFamily="firaSans" $fontSize="text40" $style={{ fontWeight: 600 }}>
                        {Loctool.formatCurrency(session.travelOffer.tariff?.amount)}
                    </Text>
                </Box>
            </Container>

            <OverviewConsent />

            <Footer />
        </Box>
    );
};

export default withUpdatedLegalDocuments(OverviewPage);
