import * as React from "react";
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgIcon32Child({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" focusable={false} aria-hidden={true} aria-labelledby={titleId} {...props}>
            {title ? <title id={titleId}>{title}</title> : null}
            <path d="M16 29.5c7.456 0 13.5-6.044 13.5-13.5S23.456 2.5 16 2.5 2.5 8.544 2.5 16 8.544 29.5 16 29.5z" stroke="currentColor" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14 16c1.884 0 3.565.868 4.665 2.227A4 4 0 0124 22v.5a.5.5 0 01-1 0V22a3 3 0 10-6 0v.5a.5.5 0 01-1 0V22c0-1.375.693-2.587 1.75-3.307A5 5 0 009 22v.5a.5.5 0 01-1 0V22a6 6 0 016-6zm6-3a2 2 0 110 4 2 2 0 010-4zm0 1a1 1 0 100 2 1 1 0 000-2zm-6-5a3 3 0 110 6 3 3 0 010-6zm0 1a2 2 0 100 4 2 2 0 000-4z"
                fill="currentColor"
            />
        </svg>
    );
}

export default SvgIcon32Child;
