import { BackendEnvironment } from "@api/graphql/types";
import { ActionsUnion, createAction } from "./actionHelpers";

export enum BackendEnvironmentActionTypes {
    UPDATE_BACKEND_ENVIRONMENTS = "BackendEnvironmentAction/UPDATE_BACKEND_ENVIRONMENTS",
}

export const BackendEnvironmentActions = {
    update: (backendEnvironments: BackendEnvironment[]) => createAction(BackendEnvironmentActionTypes.UPDATE_BACKEND_ENVIRONMENTS, { backendEnvironments }),
};

export type BackendEnvironmentActions = ActionsUnion<typeof BackendEnvironmentActions>;
