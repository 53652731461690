import React from "react";
import { Link } from "react-router-dom";
import { useTheme } from "styled-components";
import { Box, Container } from "@components/LayoutElements";
import { Text } from "@components/Text";
import { AppBar } from "@components/AppBar";
import { Button } from "@components/Button";
import SvgIcon32Notify from "@components/svg/Icon32Notify";
import { ApplicationState } from "@redux/reducers";
import { Redirect } from "react-router-dom";
import { Path } from "@utils/Path";
import { useSelector } from "react-redux";
import { LoctoolMessage } from "@i18n/i18n";

const EmailVerificationPage = () => {
    const theme = useTheme();
    const contract = useSelector((state: ApplicationState) => state.contract);
    if (!contract) {
        return <Redirect to={Path.calculatorIndex} />;
    }
    return (
        <Box $style={{ minHeight: "100vh", backgroundColor: theme.color.white }}>
            <AppBar />

            <Container as="main" $maxWidth={504} $px={16} $style={{ paddingTop: 32, paddingBottom: 32 }} $styleMedium={{ paddingTop: 48, paddingBottom: 48 }}>
                <Box
                    $style={{
                        width: 72,
                        height: 72,
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        margin: "0 auto 24px",
                        backgroundColor: theme.color.blueN,
                        color: theme.color.white,
                    }}
                >
                    <SvgIcon32Notify />
                </Box>

                <Box $style={{ textAlign: "center" }}>
                    <Text as="h1" $fontFamily="firaSans" $fontSize="text24" $style={{ margin: "4px 0", fontWeight: 600 }}>
                        <LoctoolMessage id="page.emailVerification.title" />
                    </Text>

                    <Text as="p" $style={{ margin: "4px 0" }}>
                        <LoctoolMessage id="page.emailVerification.description" />
                    </Text>
                </Box>

                <Box $style={{ maxWidth: 288, margin: "0 auto" }} $styleMedium={{ maxWidth: 248 }}>
                    <Box $style={{ marginTop: 48 }}>
                        <Box $style={{ textAlign: "center" }}>
                            <Text id="current-email-address-desc" $fontSize="text12" $style={{ fontStyle: "italic" }}>
                                {contract.accountData.email}
                            </Text>

                            <Link
                                to={Path.emailChange}
                                component={React.forwardRef((props, ref) => (
                                    <Button.Text
                                        ref={ref}
                                        btnLabel={<LoctoolMessage id="page.emailVerification.changeEmail" />}
                                        aria-describedby="current-email-address-desc"
                                        isExpanded
                                        {...props}
                                    />
                                ))}
                            />
                        </Box>
                    </Box>
                </Box>

                {process.env.NODE_ENV === "development" && (
                    <Link
                        to={`${Path.emailActivation}/${contract.id}:00000000`}
                        component={React.forwardRef((props, ref) => (
                            <Button.Text ref={ref} btnLabel="DEV aktiválás" isExpanded {...props} />
                        ))}
                    />
                )}
            </Container>
        </Box>
    );
};

export default EmailVerificationPage;
