import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { bp } from "@style/Theme";
import { Flex } from "@components/Flex";
import { Text } from "@components/Text";
import { Button } from "@components/Button";
import SvgIcon32ArrowBack from "@components/svg/Icon32ArrowBack";
import { LoctoolMessage } from "@i18n/i18n";

interface Props {
    currentStep: number;
    stepsCount: number;
    text: React.ReactNode;
    backLink: string;
}

export const CalculatorHeadingSteps = ({ stepsCount, currentStep, text, backLink }: Props) => {
    return (
        <CalculatorHeadingStepsWrapper>
            <Flex.Container $alignItems="center">
                <Flex.Item $shrink="auto" role="presentation">
                    <Flex.Container as="ol" $gutterMargin={8}>
                        {Array.from({ length: stepsCount }).map((_, i) => {
                            const step = i + 1;
                            const isActive = step === currentStep;
                            return (
                                <CalculatorHeadingStepsListItem
                                    key={step}
                                    aria-current={currentStep === step ? "step" : undefined}
                                    $shrink="shrink"
                                    $gutterMargin={8}
                                    $isActive={isActive}
                                    $isFinished={step < currentStep}
                                >
                                    <span className="show-for-sr">
                                        <LoctoolMessage id="components.calculatorHeadingSteps.step" values={{ step }} />
                                    </span>
                                </CalculatorHeadingStepsListItem>
                            );
                        })}
                    </Flex.Container>
                </Flex.Item>

                <Flex.Item $shrink="shrink" $style={{ marginRight: -24 }}>
                    <Link
                        to={backLink}
                        component={React.forwardRef((props, ref) => (
                            <Button.Text
                                ref={ref}
                                btnLabel={<LoctoolMessage id="components.calculatorHeadingSteps.back" />}
                                icon={<SvgIcon32ArrowBack />}
                                iconPosition="left"
                                {...props}
                            />
                        ))}
                    />
                </Flex.Item>
            </Flex.Container>

            <H1Text as="h1" $fontSize="text16">
                {text}
            </H1Text>
        </CalculatorHeadingStepsWrapper>
    );
};

const CalculatorHeadingStepsListItem = styled(Flex.Item).attrs(() => ({ as: "li" }))<{ $isActive: boolean; $isFinished?: boolean }>`
    background-color: ${({ theme }) => theme.color.greyN};
    border-radius: 50%;
    counter-increment: calculator-steps-counter;
    height: 8px;
    width: 8px;

    ${({ theme, $isActive }) =>
        $isActive
            ? `
        background-color: ${theme.color.blueN};
    `
            : ""}

    ${({ theme, $isFinished }) =>
        $isFinished
            ? `
        background-color: ${theme.color.greenN};
    `
            : ""}
`;

const H1Text = styled(Text)`
    margin: 16px 0 0;
    min-height: ${({ theme }) => theme.typo.size.text16.lineHeight * 2}px;
    text-align: center;
`;

const CalculatorHeadingStepsWrapper = styled.div`
    border-radius: 2px;
    box-shadow: inset 0px 0px 0px 1px ${({ theme }) => theme.color.blueL};
    margin-bottom: 16px;
    margin-top: 16px;
    padding: 8px 16px 16px;

    ${bp.medium} {
        margin-bottom: 32px;
        margin-top: 24px;
    }
`;
