import * as React from "react";
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgIcon32Minus({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" focusable={false} aria-hidden={true} aria-labelledby={titleId} {...props}>
            {title ? <title id={titleId}>{title}</title> : null}
            <rect x={22} y={16.5} width={12} height={1} rx={0.5} transform="rotate(-180 22 16.5)" fill="currentColor" />
        </svg>
    );
}

export default SvgIcon32Minus;
