import * as React from "react";
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgIcon32CarSharing({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg width={32} height={32} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" focusable={false} aria-hidden={true} aria-labelledby={titleId} {...props}>
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M21.945 4a4 4 0 013.859 2.948L27.727 14H29a1 1 0 01.993.883L30 15v2a1 1 0 01-.883.993L29 18h-1v8a2 2 0 01-2 2h-2a2 2 0 01-2-2v-1H10v1a2 2 0 01-2 2H6a2 2 0 01-2-2v-8H3a1 1 0 01-1-1v-2a1 1 0 011-1h1.273l1.923-7.052A4 4 0 0110.056 4h11.889zm0 1h-11.89a3 3 0 00-2.84 2.035l-.054.176-2.024 7.42a.5.5 0 01-.482.369H3v2h1.5a.5.5 0 01.5.5V26a1 1 0 00.883.993L6 27h2a1 1 0 00.993-.883L9 26v-1.5a.5.5 0 01.5-.5h13a.5.5 0 01.5.5V26a1 1 0 00.883.993L24 27h2a1 1 0 00.993-.883L27 26v-8.5a.5.5 0 01.41-.492L27.5 17l1.442.002.058-.003.002-1.941-.003-.058h-1.654a.5.5 0 01-.482-.368L24.839 7.21a3 3 0 00-2.71-2.205L21.944 5zM11 17a2 2 0 110 4H9a2 2 0 110-4h2zm12 0a2 2 0 110 4h-2a2 2 0 110-4h2zm-12 1H9a1 1 0 00-.117 1.993L9 20h2a1 1 0 00.117-1.993L11 18zm12 0h-2a1 1 0 00-.117 1.993L21 20h2a1 1 0 00.117-1.993L23 18zm-5.5-6a.5.5 0 110 1h-6.001l.901 1.2.047.077a.5.5 0 01-.787.59l-.06-.067-1.5-2-.049-.08a.5.5 0 010-.44l.049-.08 1.5-2 .06-.067a.5.5 0 01.563-.08l.077.047.067.06a.5.5 0 01.08.563l-.047.077-.901 1.2H17.5zm2.84-4.867l.06.067 1.5 2a.5.5 0 01.049.52l-.049.08-1.5 2a.5.5 0 01-.847-.523l.047-.077.9-1.2h-6a.5.5 0 110-1h6l-.9-1.2a.5.5 0 01.033-.64l.067-.06a.5.5 0 01.64.033z"
                fill="currentColor"
                fillRule="nonzero"
            />
        </svg>
    );
}

export default SvgIcon32CarSharing;
