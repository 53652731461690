import React from "react";
import styled from "styled-components";
import { bp, breakpoints } from "@style/Theme";
import { showForSr } from "@style/GlobalStyle";
import { Flex } from "@components/Flex";
import { Text } from "@components/Text";
import SvgIcon32Tick from "@components/svg/Icon32Tick";
import LoctoolMessage from "@i18n/LoctoolMessage";

type Props = {
    currentStep: number;
};

const Steps = [
    <LoctoolMessage key={1} id="components.paymentSteps.settings" />,
    <LoctoolMessage key={2} id="components.paymentSteps.overview" />,
    <LoctoolMessage key={3} id="components.paymentSteps.summary" />,
];

export const PaymentSteps = ({ currentStep }: Props) => {
    return (
        <PaymentStepsList $justifyContent="center" $style={{ counterReset: "payment-steps-counter" }}>
            {Steps.map((step, i) => {
                const isActive = i + 1 === currentStep;
                return (
                    <PaymentStepsListItem
                        key={i}
                        aria-current={isActive ? "step" : undefined}
                        $shrink="shrink"
                        $isActive={isActive}
                        $isFinished={i + 1 < currentStep}
                        $hasSeparator={i + 1 < Steps.length}
                    >
                        <LabelText $isActive={isActive}>{step}</LabelText>

                        <TickElement />
                    </PaymentStepsListItem>
                );
            })}
        </PaymentStepsList>
    );
};

const TickElement = styled(SvgIcon32Tick)`
    color: ${({ theme }) => theme.color.greenN};
    display: none;
    left: -4px;
    pointer-events: none;
    position: absolute;
    top: -4px;
`;

const LabelText = styled(Text)<{ $isActive: boolean }>`
    color: ${({ theme }) => theme.color.greyD};
    margin-left: 8px;

    ${({ theme, $isActive }) =>
        $isActive
            ? `
        color: ${theme.color.blueD};
            `
            : `
        ${bp.custom(`max-width: ${breakpoints.medium - 0.02}px`)} {
            ${showForSr}
        }
    `}
`;

const PaymentStepsListItem = styled(Flex.Item).attrs(() => ({ as: "li" }))<{ $isActive: boolean; $isFinished?: boolean; $hasSeparator: boolean }>`
    counter-increment: payment-steps-counter;
    display: flex;
    flex: 0 1 auto;
    position: relative;

    &::before {
        background-color: ${({ theme }) => theme.color.greyD};
        border-radius: 50%;
        color: ${({ theme }) => theme.color.white};
        content: counter(payment-steps-counter);
        display: block;
        flex: 0 0 auto;
        height: 24px;
        text-align: center;
        width: 24px;
    }

    ${({ theme, $hasSeparator }) =>
        $hasSeparator
            ? `
        &::after {
            background-color: ${theme.color.greyD};
            border-radius: 3px;
            content: "";
            flex: 1 1 0px;
            height: 2px;
            margin: 11px 16px;
            min-width: 22px;
        }

        ${bp.medium} {
            &::after {
                min-width: 14px;
            }
        }
    `
            : ""}

    ${({ theme, $isActive }) =>
        $isActive
            ? `
        &::before {
            background-color: ${theme.color.greenN};
        }
    `
            : ""}

    ${({ theme, $isFinished }) =>
        $isFinished
            ? `
        ${TickElement} {
            display: block;
        }

        &::before {
            background-color: ${theme.color.white};
            box-shadow: inset 0px 0px 0px 1px ${theme.color.greenN};
        }

        &::after {
            background-color: ${theme.color.greenN};
        }
    `
            : ""}
`;

const PaymentStepsList = styled(Flex.Container).attrs(() => ({ as: "ol" }))`
    padding: 24px 16px 0;

    ${bp.custom(`max-width: ${breakpoints.medium - 0.02}px`)} {
        background-color: ${({ theme }) => theme.color.white};
        box-shadow: inset 0px -1px 0px ${({ theme }) => theme.color.greyD};
        padding: 16px;
        position: sticky;
        top: ${({ theme }) => theme.layout.appBar.height}px;
        z-index: ${({ theme }) => theme.zIndex.appBar};
    }
`;
