import * as React from "react";
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgIcon32Helicopter({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg width={32} height={32} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" focusable={false} aria-hidden={true} aria-labelledby={titleId} {...props}>
            {title ? <title id={titleId}>{title}</title> : null}
            <g fill="none" fillRule="evenodd">
                <path d="M18.268 13.5l-1.706 2.559 1.777 4.441H29.5V19a5.5 5.5 0 00-5.5-5.5h-5.732z" stroke="currentColor" />
                <path stroke="currentColor" strokeLinecap="square" d="M16.5 15.5h-14" />
                <path d="M2.5 9.5v6h4.837l-1.714-6H2.5z" stroke="currentColor" />
                <path fill="currentColor" d="M11 9h19v1H11zM17 23h13v1H17zM21 10v4h-1v-4zM21 20v3h-1v-3zM27 20v3h-1v-3zM21 15v1h1v1h-1v1h-1v-1.001L19 17v-1l1-.001V15h1z" />
            </g>
        </svg>
    );
}

export default SvgIcon32Helicopter;
