import React from "react";
import { InputHTMLAttributes } from "react";
import styled from "styled-components";
import { bp } from "@style/Theme";
import { Box } from "@components/LayoutElements";
import { Flex } from "@components/Flex";
import { Button, ButtonVariant } from "@components/Button";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    id: string;
    name: string;
    radioIcon: React.ReactNode;
    $isVerticalOnly?: boolean;
}

export const ButtonAsRadio: React.FC<Props> = ({ id, name, className, radioIcon, children, $isVerticalOnly, ...props }) => {
    return (
        <Box $styleSmall={{ display: "table-caption" }} $styleMedium={{ display: "block" }}>
            <StyledInput id={id} className={`show-for-sr ${className}`} name={name} type="radio" {...props} />

            <Flex.Container
                as="label"
                htmlFor={id}
                $flexWrap={$isVerticalOnly ? "wrap" : undefined}
                $alignItems="center"
                $justifyContent={$isVerticalOnly ? "center" : undefined}
                $bpSmall={$isVerticalOnly ? undefined : { $flexWrap: "wrap", $justifyContent: "center" }}
            >
                <StyledRadio>{radioIcon}</StyledRadio>

                {children && (
                    <>
                        {$isVerticalOnly ? (
                            <StyledChildrenWrapperVerticalOnly as="span" $shrink={1}>
                                {children}
                            </StyledChildrenWrapperVerticalOnly>
                        ) : (
                            <StyledChildrenWrapper as="span" $shrink="auto" $bpSmall={{ $shrink: 1 }}>
                                {children}
                            </StyledChildrenWrapper>
                        )}
                    </>
                )}
            </Flex.Container>
        </Box>
    );
};

const StyledChildrenWrapper = styled(Flex.Item)`
    display: block;
    padding-left: 16px;
    user-select: none;

    ${bp.small} {
        padding: 6px 0 0;
        text-align: center;
    }
`;

const StyledChildrenWrapperVerticalOnly = styled(Flex.Item)`
    display: block;
    padding-left: 16px;
    padding: 6px 0 0;
    text-align: center;
    user-select: none;
`;

const StyledRadio = styled(Button.Styled).attrs(() => ({
    as: "span",
    $color: { borderColor: "greyN", labelColor: "blueD" },
    $activeColor: { borderColorOverlay: "greyD" },
    $disabledColor: { labelColor: "greyN" },
    $hasIcon: true,
    $isIconOnly: true,
    $isCircle: true,
    $isExpanded: false,
    $isDisabled: false,
    $btnSize: 56,
    $variant: ButtonVariant.hollow,
    ["aria-hidden"]: true,
}))`
    background-color: ${({ theme }) => theme.color.white};
`;

const StyledInput = styled.input`
    &:checked {
        & + label {
            ${StyledRadio} {
                background-color: ${({ theme }) => theme.color.blueN};
                box-shadow: none;
                color: ${({ theme }) => theme.color.white};

                &::before {
                    content: normal;
                }
            }
        }
    }
`;
