import React, { useMemo, useCallback, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Path } from "@utils/Path";
import { ThemeContext } from "styled-components";
import { Box, SeparatorDecoratorContainer } from "@components/LayoutElements";
import { Flex } from "@components/Flex";
import { Text } from "@components/Text";
import { Button } from "@components/Button";
import { Card } from "@components/Card";
import { Popup } from "@components/Popup";
import { Tooltip } from "@components/Tooltip";
import { NumberSpinner } from "@components/NumberSpinner";
import { PaymentStickyPriceBox } from "@components/payment/PaymentStickyPriceBox";
import SvgIcon32TravelAbroad from "@components/svg/Icon32TravelAbroad";
import { TravelDestinationSelect } from "./TravelDestinationSelect";
import { TravelDestination, TravelDestinations } from "@utils/TravelCategories";
import { Api, Gql } from "@api/Api";
import { TravelCalculationDateInput } from "./TravelCalculationDateInput";
import { BackendEnvironmentUtils } from "@redux/utils/BackendEnvironmentUtils";
import { DateFormat, DateUtils } from "@utils/DateUtils";
import { Validator, ValidatorConstants } from "@utils/Validator";
import { ValidationErrorMessage } from "@components/ValidationErrorMessage";
import { Loctool, LoctoolHTMLMessage, LoctoolMessage } from "@i18n/i18n";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "@redux/reducers";
import { SessionActions } from "@redux/actions/sessionActions";
import { batchActions } from "redux-batched-actions";
import { AppStateActions } from "@redux/actions/appStateActions";
import { GtmHooks } from "@utils/gtm/GtmHooks";
import { GtmUtils } from "@utils/gtm/GtmUtils";

export type TravelCalculationFormValues = {
    destinationId: number;
    departure: Date;
    return: Date;
    adultsCount: number;
    childrenCount: number;
};

export const TravelCalculationForm = () => {
    const themeContext = React.useContext(ThemeContext);
    const defaultValues = useSelector((state: ApplicationState) => state.session.travelOffer);
    const history = useHistory();
    const dispatch = useDispatch();
    const [tariff, setTariff] = useState<Gql.Amount | null>(defaultValues.tariff);
    const [formValues, setFormValues] = React.useState<TravelCalculationFormValues>({
        destinationId: defaultValues.destinationId,
        departure: DateUtils.parse(defaultValues.departure),
        return: DateUtils.parse(defaultValues.return),
        adultsCount: defaultValues.adultsCount,
        childrenCount: defaultValues.childrenCount,
    });

    const onDepartureDateChange = useCallback(
        (departureDate: Date): void => {
            if (DateUtils.isAfter(departureDate, formValues.return)) {
                setFormValues({ ...formValues, departure: departureDate, return: departureDate });
            } else {
                setFormValues({ ...formValues, departure: departureDate });
            }
        },
        [formValues]
    );

    const onReturnDateChange = useCallback((returnDate: Date): void => setFormValues({ ...formValues, return: returnDate }), [formValues]);
    const onAdultCountChange = useCallback(value => setFormValues({ ...formValues, adultsCount: value }), [formValues]);
    const onChildrenCountChange = useCallback(value => setFormValues({ ...formValues, childrenCount: value }), [formValues]);

    const onSubmitClick = useCallback(
        (e: React.MouseEvent<HTMLFormElement>): void => {
            e.preventDefault();
            history.push(Path.accountData);
        },
        [history]
    );

    const updateTariff = useCallback(async () => {
        const departureDate = DateUtils.format(formValues.departure, DateFormat.api);
        const returnDate = DateUtils.format(formValues.return, DateFormat.api);
        let newTariff: Gql.Amount | null = null;
        try {
            newTariff = await Api.calculateTravelTariff({
                destinationId: formValues.destinationId,
                departureDate,
                returnDate,
                adultsCount: formValues.adultsCount,
                childrenCount: formValues.childrenCount,
            });
        } catch (error) {
            dispatch(AppStateActions.addNotification(Loctool.formatMessage({ id: "page.travelCalculation.calculationFailed" }), "error"));
        }
        setTariff(newTariff);
        dispatch(
            batchActions([
                SessionActions.updateTravelOffer(
                    {
                        destinationId: formValues.destinationId,
                        departure: departureDate,
                        return: returnDate,
                        adultsCount: formValues.adultsCount,
                        childrenCount: formValues.childrenCount,
                        tariff: newTariff,
                    },
                    true
                ),
                SessionActions.updateAreYouTraveling(formValues.adultsCount > 0),
            ])
        );
    }, [formValues, dispatch]);

    useEffect(() => {
        updateTariff();
    }, [updateTariff]);

    const minMaxValues = useMemo(() => {
        const maxMemberCount = BackendEnvironmentUtils.travelInsuranceMaxMembers();
        return {
            departureDate: { min: ValidatorConstants.travelDepartureDateMin(), max: ValidatorConstants.travelDepartureDateMax() },
            returnDate: {
                min: ValidatorConstants.travelReturnDateMin(formValues.departure),
                max: ValidatorConstants.travelReturnDateMax(formValues.departure),
            },
            adultsCount: { min: 0, max: maxMemberCount - formValues.childrenCount },
            childrenCount: { min: 0, max: maxMemberCount - formValues.adultsCount },
        };
    }, [formValues.adultsCount, formValues.childrenCount, formValues.departure]);

    const errors = useMemo(() => {
        return {
            departureDate: Validator.travelDepartureDate(formValues.departure, formValues.return),
            returnDate: Validator.travelReturnDate(formValues.departure, formValues.return),
        };
    }, [formValues.departure, formValues.return]);

    const lastValidDepartureDate = DateUtils.format(ValidatorConstants.travelDepartureDateMax(), DateFormat.medium);
    const monthName = useMemo(
        () => DateUtils.getMonthName(DateUtils.subDays(formValues.departure, BackendEnvironmentUtils.travelInsuranceForwardDateMaxDays())),
        [formValues.departure]
    );

    const isNextDisabled = useMemo(() => !tariff || tariff.amount <= 0, [tariff]);

    GtmHooks.useFormError("calculator", errors);
    return (
        <form>
            <Card.Wrapper as="fieldset">
                <legend>
                    <Flex.Container as="span" $style={{ padding: "12px 16px", backgroundColor: themeContext.color.blueN, color: themeContext.color.white }}>
                        <Flex.Item as="span" $shrink="shrink" $style={{ marginRight: 16 }}>
                            <SvgIcon32TravelAbroad />
                        </Flex.Item>

                        <Flex.Item as="span" $shrink="auto" $alignSelf="center">
                            <LoctoolMessage id="page.travelCalculation.form.cardTitle" />
                        </Flex.Item>
                    </Flex.Container>
                </legend>

                <SeparatorDecoratorContainer>
                    <SeparatorDecoratorContainer as="fieldset">
                        <Box as="legend" $style={{ padding: "12px 16px" }}>
                            <Flex.Container as="span" $gutterMargin={8}>
                                <Flex.Item as="span" $shrink="auto" $gutterMargin={8}>
                                    <Text as="span" id="i-destination" $style={{ display: "block", marginTop: 4, marginBottom: 4 }}>
                                        <LoctoolMessage id="page.travelCalculation.form.destination" />
                                    </Text>
                                </Flex.Item>

                                <Flex.Item as="span" $shrink="shrink" $gutterMargin={8}>
                                    <Popup
                                        arrowElement={<Tooltip.Arrow />}
                                        toggleElement={
                                            // Note: btnLabel="", but aria-labelledby not empty string
                                            <Button.HollowBlueD
                                                btnLabel=""
                                                className={GtmUtils.className.tooltip.destination}
                                                aria-labelledby="i-destination"
                                                icon={
                                                    <Text as="span" $fontSize="text16" $style={{ display: "block" }}>
                                                        ?
                                                    </Text>
                                                }
                                                isCircle
                                                btnSize={32}
                                            />
                                        }
                                        popperOptions={{ strategy: "absolute" }}
                                        $fullWidth
                                    >
                                        <Tooltip.Container $style={{ marginLeft: "auto", marginRight: "auto" }}>
                                            <LoctoolHTMLMessage id="page.travelCalculation.form.destination.help" />
                                        </Tooltip.Container>
                                    </Popup>
                                </Flex.Item>
                            </Flex.Container>
                        </Box>

                        <TravelDestinationSelect
                            value={TravelDestinations.getById(formValues.destinationId)}
                            onChange={(travelDestination: TravelDestination): void => setFormValues({ ...formValues, destinationId: travelDestination.id })}
                        />
                    </SeparatorDecoratorContainer>

                    <Box $style={{ padding: "12px 16px" }}>
                        <Flex.Container $gutterMargin={16}>
                            <Flex.Item as="label" htmlFor="i-departuredate" $shrink="auto" $gutterMargin={16}>
                                <Text as="span" $style={{ display: "block", marginTop: 4, marginBottom: 4 }}>
                                    <LoctoolMessage id="page.travelCalculation.form.departure" />
                                </Text>
                            </Flex.Item>

                            <TravelCalculationDateInput
                                id="i-departuredate"
                                name="departureDate"
                                value={formValues.departure}
                                onChange={onDepartureDateChange}
                                disabledDays={{ before: minMaxValues.departureDate.min, after: minMaxValues.departureDate.max }}
                            />
                        </Flex.Container>
                        <ValidationErrorMessage id="i-departuredate-error" error={errors.departureDate} values={{ lastValidDepartureDate, monthName }} />
                    </Box>

                    <Box $style={{ padding: "12px 16px" }}>
                        <Flex.Container $gutterMargin={16}>
                            <Flex.Item as="label" htmlFor="i-returndate" $shrink="auto" $gutterMargin={16}>
                                <Text as="span" $style={{ display: "block", marginTop: 4, marginBottom: 4 }}>
                                    <LoctoolMessage id="page.travelCalculation.form.return" />
                                </Text>
                            </Flex.Item>

                            <TravelCalculationDateInput
                                id="i-returndate"
                                name="returnDate"
                                value={formValues.return}
                                onChange={onReturnDateChange}
                                disabledDays={{ before: minMaxValues.returnDate.min, after: minMaxValues.returnDate.max }}
                            />
                        </Flex.Container>
                        <ValidationErrorMessage id="i-returndate-error" error={errors.returnDate} />
                    </Box>

                    <Box $style={{ padding: "12px 16px" }}>
                        <Flex.Container $gutterMargin={8}>
                            <Flex.Item $shrink="auto" $gutterMargin={8}>
                                <Flex.Container $flexWrap="wrap" $gutterMargin={8}>
                                    <Flex.Item as="label" htmlFor="i-number-of-adults" aria-describedby="i-number-of-adults-desc" $shrink="shrink" $gutterMargin={8}>
                                        <Text as="span" $style={{ display: "block", marginTop: 4, marginBottom: 4 }}>
                                            <LoctoolMessage id="page.travelCalculation.form.adultsCount" />
                                        </Text>
                                    </Flex.Item>

                                    <Flex.Item $shrink="shrink" $gutterMargin={8}>
                                        <Text
                                            id="i-number-of-adults-desc"
                                            $fontSize="text12"
                                            $style={{
                                                marginTop: 4,
                                                marginBottom: 4,
                                                borderRadius: 8,
                                                boxShadow: `inset 0px 0px 0px 1px ${themeContext.color.blueL}`,
                                                padding: "4px 8px",
                                                color: themeContext.color.blueL,
                                                fontStyle: "italic",
                                            }}
                                        >
                                            <LoctoolMessage id="page.travelCalculation.form.adultsCount.help" />
                                        </Text>
                                    </Flex.Item>
                                </Flex.Container>
                            </Flex.Item>

                            <Flex.Item $shrink="shrink" $gutterMargin={8}>
                                <NumberSpinner
                                    id="i-number-of-adults"
                                    min={minMaxValues.adultsCount.min}
                                    max={minMaxValues.adultsCount.max}
                                    step={1}
                                    value={formValues.adultsCount}
                                    onValueChange={onAdultCountChange}
                                />
                            </Flex.Item>
                        </Flex.Container>
                    </Box>

                    <Box $style={{ padding: "12px 16px" }}>
                        <Flex.Container $gutterMargin={8}>
                            <Flex.Item $shrink="auto" $gutterMargin={8}>
                                <Flex.Container $flexWrap="wrap" $gutterMargin={8}>
                                    <Flex.Item as="label" htmlFor="i-number-of-children" aria-describedby="i-number-of-children-desc" $shrink="shrink" $gutterMargin={8}>
                                        <Text as="span" $style={{ display: "block", marginTop: 4, marginBottom: 4 }}>
                                            <LoctoolMessage id="page.travelCalculation.form.childrenCount" />
                                        </Text>
                                    </Flex.Item>

                                    <Flex.Item $shrink="shrink" $gutterMargin={8}>
                                        <Text
                                            id="i-number-of-children-desc"
                                            $fontSize="text12"
                                            $style={{
                                                marginTop: 4,
                                                marginBottom: 4,
                                                borderRadius: 8,
                                                boxShadow: `inset 0px 0px 0px 1px ${themeContext.color.blueL}`,
                                                padding: "4px 8px",
                                                color: themeContext.color.blueL,
                                                fontStyle: "italic",
                                            }}
                                        >
                                            <LoctoolMessage id="page.travelCalculation.form.childrenCount.help" />
                                        </Text>
                                    </Flex.Item>

                                    <Flex.Item $shrink="shrink" $gutterMargin={8}>
                                        <Popup
                                            arrowElement={<Tooltip.Arrow />}
                                            toggleElement={
                                                // Note: btnLabel="", but aria-labelledby not empty string
                                                <Button.HollowBlueD
                                                    btnLabel=""
                                                    aria-labelledby="i-number-of-children-desc"
                                                    icon={
                                                        <Text as="span" $fontSize="text16" $style={{ display: "block" }}>
                                                            ?
                                                        </Text>
                                                    }
                                                    isCircle
                                                    btnSize={32}
                                                />
                                            }
                                            $fullWidth
                                        >
                                            <Tooltip.Container $style={{ marginLeft: "auto", marginRight: "auto" }}>
                                                <LoctoolMessage id="page.travelCalculation.form.childrenCount.tooltip" />
                                            </Tooltip.Container>
                                        </Popup>
                                    </Flex.Item>
                                </Flex.Container>
                            </Flex.Item>

                            <Flex.Item $shrink="shrink" $gutterMargin={8}>
                                <NumberSpinner
                                    id="i-number-of-children"
                                    step={1}
                                    min={minMaxValues.childrenCount.min}
                                    max={minMaxValues.childrenCount.max}
                                    value={formValues.childrenCount}
                                    onValueChange={onChildrenCountChange}
                                />
                            </Flex.Item>
                        </Flex.Container>
                    </Box>

                    <Box
                        $style={{
                            padding: 16,
                            textAlign: "center",
                        }}
                    >
                        <Link
                            to={Path.travelCoverages}
                            className={GtmUtils.className.coverageButton}
                            component={React.forwardRef((props, ref) => (
                                <Button.Text ref={ref} btnLabel={<LoctoolMessage id="page.travelCalculation.form.coverages" />} {...props} />
                            ))}
                        />
                    </Box>
                </SeparatorDecoratorContainer>
            </Card.Wrapper>

            <PaymentStickyPriceBox
                priceText={tariff && tariff.amount > 0 ? Loctool.formatCurrency(tariff.amount) : "-"}
                ctaElement={
                    <Button.Primary
                        type="submit"
                        btnLabel={<LoctoolMessage id="page.travelCalculation.form.next" />}
                        className={GtmUtils.className.nextButton}
                        disabled={isNextDisabled}
                        onClick={onSubmitClick}
                    />
                }
            />
        </form>
    );
};
