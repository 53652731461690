import React, { useEffect, useLayoutEffect } from "react";
import { useHistory } from "react-router";
import { clearUsedIntlKeys } from "@i18n/i18n";
import { usePrevious } from "@utils/hooks/usePrevious";

type Props = { children: React.ReactNode | undefined };

const RouteContainer = (props: Props) => {
    const history = useHistory();
    const prevPathname = usePrevious(history.location.pathname);
    useLayoutEffect(() => {
        if (prevPathname && history.location.pathname !== prevPathname) {
            clearUsedIntlKeys();
        }
    }, [history.location.pathname, prevPathname]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [history.location.pathname]);

    return <>{props.children}</>;
};

export default RouteContainer;
