import { Api } from "@api/Api";
import { Validator } from "@utils/Validator";
import { useCallback, useEffect, useState } from "react";

export const useCities = (zipCode: string) => {
    const [cities, setCities] = useState<string[]>([]);

    const updateCities = useCallback(async (zipCode: string) => {
        try {
            const result = await Api.getCitiesByZipCode(zipCode);
            setCities(result);
        } catch (error) {
            setCities([]);
        }
    }, []);

    useEffect(() => {
        if (zipCode && !Validator.zipCode(zipCode)) {
            updateCities(zipCode);
        } else {
            setCities([]);
        }
    }, [zipCode, updateCities]);

    return cities;
};
