import { DefaultTheme } from "styled-components";

export function hexToRGBA(h: string, opacity = 1): string {
    let r = "";
    let g = "";
    let b = "";

    if (h.length === 4) {
        r = `0x${h[1]}${h[1]}`;
        g = `0x${h[2]}${h[2]}`;
        b = `0x${h[3]}${h[3]}`;
    } else if (h.length === 7) {
        r = `0x${h[1]}${h[2]}`;
        g = `0x${h[3]}${h[4]}`;
        b = `0x${h[5]}${h[6]}`;
    }

    return `rgba(${parseInt(r, 16)}, ${parseInt(g, 16)}, ${parseInt(b, 16)}, ${opacity})`;
}

// Note: Named after color styles in Figma.
export const Color = {
    black: "#000000",
    blueL: "#7fadd4",
    blueN: "#005ca9",
    blueD: "#003667",
    blueXL: "#eef2fa",
    blueSapphire: "#3888d6",
    blueMuted: "#617384",
    greenN: "#04b675",
    greyD: "#b2b2b2",
    greyL: "#f4f4f4",
    greyN: "#c6c7c7",
    redN: "#be001e",
    white: "#ffffff",
    // https://chir.ag/projects/name-that-color/#63F5E4
    customSpray: "#63F5E4",
    blueNightshade: "#1B1B1B",
};

export const Font = {
    arial: "Arial, sans-serif",
    firaSans: "'Fira Sans', sans-serif",
};

export const TypoSize = {
    text12: {
        fontSize: 12,
        lineHeight: 16,
    },
    text14: {
        fontSize: 14,
        lineHeight: 16,
    },
    text16: {
        fontSize: 16,
        lineHeight: 24,
    },
    text24: {
        fontSize: 24,
        lineHeight: 32,
    },
    text40: {
        fontSize: 40,
        lineHeight: 48,
    },
};

export const Shadow = {};

export enum BreakPoint {
    small = "small",
    medium = "medium",
    large = "large",
    xlarge = "xlarge",
    xxlarge = "xxlarge",
}

export const breakpoints: { [key in BreakPoint]: number } = {
    small: 375,
    medium: 768,
    large: 1024,
    xlarge: 1280,
    xxlarge: 1920,
};

export class BreakpointUtils {
    static isGreater(a: BreakPoint, b: BreakPoint): boolean {
        return breakpoints[a] > breakpoints[b];
    }

    static isLessThan(a: BreakPoint, b: BreakPoint): boolean {
        return breakpoints[a] < breakpoints[b];
    }

    static isMobile(a: BreakPoint): boolean {
        return breakpoints[a] < breakpoints.medium;
    }
}

export const getBreakpointByWindowWidth = (width: number): BreakPoint => {
    if (width >= breakpoints.xxlarge) {
        return BreakPoint.xxlarge;
    }
    if (width >= breakpoints.xlarge) {
        return BreakPoint.xlarge;
    }
    if (width >= breakpoints.large) {
        return BreakPoint.large;
    }
    if (width >= breakpoints.medium) {
        return BreakPoint.medium;
    }
    return BreakPoint.small;
};

function customMediaQuery(mediaQuery: string): string {
    return `@media (${mediaQuery})`;
}

export const bp: { [key in BreakPoint]: string } & {
    custom: (mediaQuery: string) => string;
} = {
    custom: customMediaQuery,
    small: customMediaQuery(`min-width: ${breakpoints.small}px`),
    medium: customMediaQuery(`min-width: ${breakpoints.medium}px`),
    large: customMediaQuery(`min-width: ${breakpoints.large}px`),
    xlarge: customMediaQuery(`min-width: ${breakpoints.xlarge}px`),
    xxlarge: customMediaQuery(`min-width: ${breakpoints.xxlarge}px`),
};

export const Theme: DefaultTheme = {
    color: Color,
    font: Font,
    shadow: Shadow,
    layout: {
        body: {
            backgroundColor: Color.blueXL,
        },
        appBar: {
            height: 64,
        },
    },
    typo: {
        body: {
            color: Color.blueD,
            fontFamily: Font.arial,
            fontSize: TypoSize.text16.fontSize,
            lineHeight: TypoSize.text16.lineHeight,
        },
        size: TypoSize,
    },
    zIndex: {
        appBar: 1100,
        modal: 1300,
        snackbar: 1400,
        tooltip: 1500,
    },
};
