import React, { Component } from "react";
import { Loctool } from "./Loctool";
import PreviewMessage from "./PreviewMessage";
import { LoctoolValues } from "./types";

type Props = {
    id: string;
    description?: string;
    defaultMessage?: string;
    values?: LoctoolValues;
};

class FormattedMessage extends Component<Props> {
    render(): React.ReactNode {
        const { id, values, defaultMessage } = this.props;
        const intlMessage = Loctool.formatMessage({ id, defaultMessage }, values);
        if (Loctool.getConfig().isPreviewMode) {
            return <PreviewMessage messageId={id} message={intlMessage} />;
        }

        return intlMessage;
    }
}

export default FormattedMessage;
