import React, { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useTheme } from "styled-components";
import { Box, Container } from "@components/LayoutElements";
import { Flex } from "@components/Flex";
import { Text } from "@components/Text";
import { AppBar } from "@components/AppBar";
import { Button } from "@components/Button";
import { Input } from "@components/Input";
import { Validator } from "@utils/Validator";
import SvgIcon32Notify from "@components/svg/Icon32Notify";
import { SubmitHandler, useForm } from "react-hook-form";
import { FormHelpers } from "@utils/FormHelpers";
import { useDispatch, useSelector } from "react-redux";
import { Path } from "@utils/Path";
import { ApplicationState } from "@redux/reducers";
import { Redirect } from "react-router-dom";
import { Api } from "@api/Api";
import { GraphQLClientError } from "@api/graphql/GraphQLClient";
import { AppStateActions } from "@redux/actions/appStateActions";
import { Loctool, LoctoolHTMLMessage, LoctoolMessage } from "@i18n/i18n";
import { ErrorMessage } from "@components/ErrorMessage";
import { PhoneNumberUtils } from "@utils/PhoneNumberUtils";
import { ContractActions } from "@redux/actions/contractActions";
import { batchActions } from "redux-batched-actions";
import AutocompleteInput, { AutocompleteItem } from "@components/AutocompleteInput";

type Inputs = {
    phoneNumberPrefix: string;
    phoneNumber: string;
};

const PhoneNumberChangePage = () => {
    const contract = useSelector((state: ApplicationState) => state.contract);
    const dispatch = useDispatch();
    const history = useHistory();
    const theme = useTheme();
    const {
        register,
        watch,
        handleSubmit,
        setError,
        getValues,
        setFocus,
        formState: { errors },
    } = useForm<Inputs>({ defaultValues: { phoneNumberPrefix: "+36", phoneNumber: "" }, mode: "onTouched" });

    const prefix = watch("phoneNumberPrefix");
    const rule = useMemo(() => PhoneNumberUtils.getRuleByPrefix(prefix), [prefix]);
    const phoneNumberPrefixRegister = register("phoneNumberPrefix", { validate: Validator.phoneNumberPrefix });

    const onSubmit: SubmitHandler<Inputs> = useCallback(
        async (data: Inputs) => {
            try {
                const result = await Api.updatePhone(contract!.id, `${data.phoneNumberPrefix}${data.phoneNumber}`);
                dispatch(
                    batchActions([
                        ContractActions.update(result),
                        AppStateActions.addNotification(Loctool.formatMessage({ id: "page.phoneNumberChange.changePhoneNumber.succeed" }), "success"),
                    ])
                );
                await Api.sendOrResendSMSCode(contract!.id);
                history.push(Path.phoneNumberVerification);
            } catch (error) {
                if (error instanceof GraphQLClientError) {
                    dispatch(AppStateActions.addNotification(Loctool.formatMessage({ id: "page.phoneNumberChange.changePhoneNumber.failed" }), "error"));
                }
            }
        },
        [contract, dispatch, history]
    );

    const onBackClick = useCallback(async (): Promise<void> => {
        history.push(Path.phoneNumberVerification);
    }, [history]);

    if (!contract) {
        return <Redirect to={Path.calculatorIndex} />;
    }
    if (contract.accountData.phoneValidated) {
        return <Redirect to={Path.emailVerification} push={false} />;
    }

    return (
        <Box $style={{ minHeight: "100vh", backgroundColor: theme.color.white }}>
            <AppBar />

            <Container as="main" $maxWidth={504} $px={16} $style={{ paddingTop: 32, paddingBottom: 32 }} $styleMedium={{ paddingTop: 48, paddingBottom: 48 }}>
                <Box
                    $style={{
                        width: 72,
                        height: 72,
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        margin: "0 auto 24px",
                        backgroundColor: theme.color.blueN,
                        color: theme.color.white,
                    }}
                >
                    <SvgIcon32Notify />
                </Box>

                <Box $style={{ textAlign: "center" }}>
                    <Text as="h1" $fontFamily="firaSans" $fontSize="text24" $style={{ margin: "4px 0", fontWeight: 600 }}>
                        <LoctoolMessage id="page.phoneNumberChange.title" />
                    </Text>

                    <Text id="i-code-desc" as="p" $style={{ margin: "4px 0", minHeight: 3 * theme.typo.size.text16.lineHeight }}>
                        <LoctoolMessage id="page.phoneNumberChange.description" values={{ phoneNumber: contract.accountData.phoneNumber }} />
                    </Text>

                    <Text as="p" $style={{ margin: "4px 0", minHeight: 2 * theme.typo.size.text16.lineHeight }}>
                        <LoctoolMessage id="page.phoneNumberChange.description2" />
                    </Text>
                </Box>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <fieldset>
                        <Box
                            $style={{ marginTop: 16, borderRadius: 2, boxShadow: `inset 0px 0px 0px 1px ${theme.color.greyN}`, padding: "8px 16px" }}
                            $styleMedium={{ marginTop: 0 }}
                        >
                            <Flex.Container $gutterMargin={8} $bpMedium={{ $gutterMargin: 16 }}>
                                <Flex.Item
                                    as="label"
                                    htmlFor="i-telNational"
                                    $shrink={112}
                                    $alignSelf="center"
                                    $gutterMargin={8}
                                    $style={{ marginTop: -4, marginBottom: -4 }}
                                    $bpMedium={{ $shrink: 133, $gutterMargin: 16 }}
                                >
                                    <LoctoolHTMLMessage id="page.phoneNumberChange.form.phoneNumber.label" />
                                </Flex.Item>

                                <Flex.Item $shrink="auto" $gutterMargin={8} $bpMedium={{ $shrink: "auto", $gutterMargin: 16 }}>
                                    <Flex.Container $gutterMargin={8}>
                                        <Flex.Item $shrink={70} $gutterMargin={8}>
                                            <label htmlFor="i-CountryCode" className="show-for-sr">
                                                <LoctoolMessage id="page.phoneNumberChange.form.phoneNumberPrefix.label" />
                                            </label>

                                            <AutocompleteInput
                                                id="phoneNumberPrefix"
                                                items={PhoneNumberUtils.phoneNumberRules.map((rule): AutocompleteItem => {
                                                    return {
                                                        title: `+${rule.prefix}`,
                                                        value: `+${rule.prefix}`,
                                                        titleInfo: <LoctoolMessage id={`common.countries.${rule.countryCode}`} />,
                                                    };
                                                })}
                                                {...phoneNumberPrefixRegister}
                                                value={prefix}
                                                onChange={(event: { target: { name: string; value: string } }) => {
                                                    phoneNumberPrefixRegister.onChange(event);
                                                    setError("phoneNumber", {});
                                                    setFocus("phoneNumber");
                                                }}
                                                placeholder={Loctool.formatMessage({ id: "page.phoneNumberChange.form.phoneNumberPrefix.placeholder" })}
                                                variant="select"
                                                selectListWrapperBoxProps={{ $style: { left: -112 }, $styleSmall: { left: -56 } }}
                                            />
                                        </Flex.Item>

                                        <Flex.Item $shrink="auto" $gutterMargin={8}>
                                            <Input
                                                id="i-telNational"
                                                aria-describedby="i-telNational-error"
                                                $hasError={!!FormHelpers.getError(errors.phoneNumber)}
                                                placeholder={Loctool.formatMessage({ id: "page.phoneNumberChange.form.phoneNumber.placeholder" })}
                                                inputMode="tel"
                                                autoComplete="tel-national"
                                                maxLength={rule?.maxLength}
                                                numberOnly
                                                register={register("phoneNumber", {
                                                    validate: Validator.phoneNumber(() => {
                                                        return getValues().phoneNumberPrefix;
                                                    }),
                                                })}
                                            />
                                        </Flex.Item>
                                    </Flex.Container>
                                </Flex.Item>
                            </Flex.Container>

                            <ErrorMessage
                                id="i-telCountryCode-error"
                                message={FormHelpers.getError(errors.phoneNumberPrefix || errors.phoneNumber, undefined, { min: rule?.minLength, max: rule?.maxLength })}
                            />
                        </Box>

                        <Box $style={{ maxWidth: 288, margin: "0 auto" }} $styleMedium={{ maxWidth: 248 }}>
                            <Box $style={{ marginTop: 16 }}>
                                <Button.Primary type="submit" btnLabel={<LoctoolMessage id="page.phoneNumberChange.changePhoneNumber" />} isExpanded />
                            </Box>

                            <Box $style={{ marginTop: 16 }}>
                                <Button.Hollow btnLabel={<LoctoolMessage id="page.phoneNumberChange.back" />} isExpanded onClick={onBackClick} />
                            </Box>
                        </Box>
                    </fieldset>
                </form>
            </Container>
        </Box>
    );
};

export default PhoneNumberChangePage;
