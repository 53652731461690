import styled from "styled-components";
import { Font, TypoSize } from "@style/Theme";
import { Box, BoxProps } from "@components/LayoutElements";

interface Props extends BoxProps {
    $fontFamily?: keyof typeof Font;
    $fontSize?: keyof typeof TypoSize;
    $fontSizeMedium?: keyof typeof TypoSize;
}

export const Text = styled(Box).attrs<Props>(props => {
    const size = props.$fontSize ? props.theme.typo.size[props.$fontSize] : null;
    const sizeMedium = props.$fontSizeMedium ? props.theme.typo.size[props.$fontSizeMedium] : null;

    return {
        $style: {
            fontFamily: props.theme.font[props.$fontFamily],
            ...(size ? { fontSize: size.fontSize, lineHeight: `${size.lineHeight}px` } : {}),
            ...props.$style,
        },
        $styleMedium: {
            ...(sizeMedium ? { fontSize: sizeMedium.fontSize, lineHeight: `${sizeMedium.lineHeight}px` } : {}),
            ...props.$styleMedium,
        },
    };
})<Props>``;
