import * as React from "react";
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgIcon32Fractures({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg width={32} height={32} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" focusable={false} aria-hidden={true} aria-labelledby={titleId} {...props}>
            {title ? <title id={titleId}>{title}</title> : null}
            <g fill="none" fillRule="evenodd">
                <path
                    d="M21 3.5a3.5 3.5 0 00-3 5.305l.203.335-9.063 9.063L8.805 18A3.5 3.5 0 003.5 21a3.498 3.498 0 003.313 3.494l.687.017V25a3.5 3.5 0 106.677-1.47l-.147-.318 9.182-9.182.317.147A3.5 3.5 0 1025 7.5h-.499V7A3.5 3.5 0 0021 3.5z"
                    stroke="currentColor"
                />
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M15 12.5L14 16l4-3-1 5" />
                <rect fill="currentColor" x={12} y={4} width={1} height={4} rx={0.5} />
                <rect fill="currentColor" transform="rotate(-90 6 11.5)" x={5.5} y={9.5} width={1} height={4} rx={0.5} />
                <rect fill="currentColor" transform="rotate(-45 7.5 7)" x={7} y={5} width={1} height={4} rx={0.5} />
            </g>
        </svg>
    );
}

export default SvgIcon32Fractures;
