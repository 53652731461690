import { gql } from "@apollo/client";
import { AmountFragment } from "./AmountFragment";

export const ContractFragment = gql`
    fragment Contract on FullDataWL {
        id
        accountData {
            email
            emailValidated
            name {
                firstName
                lastName
            }
            countryCode
            languageCode
            city
            address
            zipCode
            dateOfBirth
            phoneNumber
            phoneValidated
        }
        travelData {
            firstDayOfTravel
            lastDayOfTravel
            destinationCategoryId
            travelMembers {
                id
                firstName
                lastName
                dateOfBirth
            }
        }
        pricing {
            price {
                ...Amount
            }
            priceWithoutDiscount {
                ...Amount
            }
            discount {
                ...Amount
            }
            couponApplied {
                couponCode
                discountAmount
                discountPercent
            }
        }
    }
    ${AmountFragment}
`;
