import * as React from "react";
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgIcon32MyResponsibility({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg width={32} height={32} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" focusable={false} aria-hidden={true} aria-labelledby={titleId} {...props}>
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M15.5 2A2.5 2.5 0 0118 4.5l.024-.018A2.5 2.5 0 0122 6.5v8.248l1.25-2.163a2.5 2.5 0 114.33 2.5l-3.618 6.268A9.5 9.5 0 015 20.5v-11a2.5 2.5 0 014.001-2L9 6.5a2.5 2.5 0 013.84-2.111L13 4.5A2.5 2.5 0 0115.5 2zm0 1a1.5 1.5 0 00-1.493 1.356L14 4.5v11a.5.5 0 11-1 0v-9a1.5 1.5 0 00-1.356-1.493L11.5 5a1.5 1.5 0 00-1.493 1.356L10 6.5v9a.5.5 0 11-1 0v-6a1.5 1.5 0 00-1.356-1.493L7.5 8a1.5 1.5 0 00-1.493 1.356L6 9.5v11a8.5 8.5 0 008.245 8.496L14.5 29a8.501 8.501 0 008.457-7.637l.021-.253c.003-.04 1.248-2.214 3.736-6.525a1.5 1.5 0 00-.427-1.97l-.122-.08a1.5 1.5 0 00-1.97.428l-.079.122L21.278 18l-.29.5a1 1 0 01-.865.5H20a5 5 0 00-5 5v.5a.5.5 0 11-1 0V24a6 6 0 015.775-5.996h.063a.5.5 0 00.427-.25L21 16.48V6.5a1.5 1.5 0 00-1.356-1.493L19.5 5a1.5 1.5 0 00-1.493 1.356L18 6.5v9a.5.5 0 11-1 0v-11a1.5 1.5 0 00-1.356-1.493L15.5 3z"
                fill="currentColor"
                fillRule="evenodd"
            />
        </svg>
    );
}

export default SvgIcon32MyResponsibility;
