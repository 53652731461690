import React from "react";
import slugify from "slugify";
import { ThemeContext } from "styled-components";
import { Box } from "@components/LayoutElements";
import { Flex } from "@components/Flex";
import { Text } from "@components/Text";
import { FoldingView } from "@components/FoldingView";
import SvgIcon32ChevronDown from "@components/svg/Icon32ChevronDown";

interface Props {
    id: string;
    headingLevel?: 2 | 3 | 4 | 5 | 6;
    headingText: string | JSX.Element;
    headingIconElement?: JSX.Element;
    isOpened?: boolean;
    isInitiallyOpened?: boolean;
    noBorderradius?: boolean;
    variant: "blueN" | "white";
    onOpen?: () => void;
}

export const Accordion: React.FC<Props> = props => {
    const themeContext = React.useContext(ThemeContext);
    const [openedState, setOpenedState] = React.useState(false);
    const { id, headingLevel, children, headingText, headingIconElement, isOpened, isInitiallyOpened, noBorderradius, variant } = props;
    const slugifiedId = slugify(id, { lower: true });
    const isExpanded = isOpened ?? (isInitiallyOpened && !openedState) ?? openedState;

    const renderVariantStyles = () => {
        let variantStyles = {};

        if (variant === "blueN") {
            variantStyles = {
                backgroundColor: themeContext.color.blueN,
                boxShadow: `inset 0px 0px 0px 1px ${themeContext.color.greyN}`,
                color: themeContext.color.white,
            };
        }

        if (variant === "white") {
            variantStyles = {
                backgroundColor: themeContext.color.white,
                boxShadow: `inset 0px 0px 0px 1px ${themeContext.color.greyN}`,
                color: themeContext.color.blueD,
            };
        }

        return variantStyles;
    };

    return (
        <>
            <Box as={headingLevel ? `h${headingLevel}` : undefined} $style={{ margin: 0 }}>
                <Flex.Container
                    as="button"
                    id={`${slugifiedId}-header`}
                    type="button"
                    aria-expanded={isExpanded}
                    aria-controls={`${slugifiedId}-panel`}
                    onClick={() => {
                        if (!openedState && props.onOpen) {
                            props.onOpen();
                        }
                        setOpenedState(!openedState);
                    }}
                    $style={{
                        width: "100%",
                        borderTopLeftRadius: !noBorderradius ? 2 : "",
                        borderTopRightRadius: !noBorderradius ? 2 : "",
                        padding: "12px 16px",
                        textAlign: "left",
                        ...renderVariantStyles(),
                    }}
                >
                    {headingIconElement && (
                        <Flex.Item as="span" $shrink="shrink" $style={{ marginRight: 16 }}>
                            {headingIconElement}
                        </Flex.Item>
                    )}

                    <Flex.Item as="span" $shrink="auto" $alignSelf="center">
                        <Text as="span" $fontSize="text16" $style={{ display: "block" }}>
                            {headingText}
                        </Text>
                    </Flex.Item>

                    <Flex.Item as="span" $shrink="shrink" $style={{ marginLeft: 8 }}>
                        <Box
                            as="span"
                            $style={{
                                display: "block",
                                transform: `rotate(${isExpanded ? 180 : 0}deg)`,
                                transformOrigin: "center",
                                transition: "transform 240ms",
                            }}
                        >
                            <SvgIcon32ChevronDown />
                        </Box>
                    </Flex.Item>
                </Flex.Container>
            </Box>

            <div id={`${slugifiedId}-panel`} role="region" aria-labelledby={`${slugifiedId}-header`} aria-hidden={!isExpanded}>
                <FoldingView isOpened={isExpanded}>{children}</FoldingView>
            </div>
        </>
    );
};
