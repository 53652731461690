import * as React from "react";
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgIcon32Etc({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg width={32} height={32} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" focusable={false} aria-hidden={true} aria-labelledby={titleId} {...props}>
            {title ? <title id={titleId}>{title}</title> : null}
            <g fill="none" fillRule="evenodd" stroke="currentColor">
                <circle cx={16} cy={16} r={11.5} />
                <circle cx={16} cy={16} r={1.5} />
                <circle cx={10} cy={16} r={1.5} />
                <circle cx={22} cy={16} r={1.5} />
            </g>
        </svg>
    );
}

export default SvgIcon32Etc;
