import React, { useState } from "react";
import { Box, Container } from "@components/LayoutElements";
import { AppBar } from "@components/AppBar";
import { Footer } from "@components/Footer";
import { CalculatorHeadingSteps } from "@components/calculator/CalculatorHeadingSteps";
import { HomeAddressForm } from "@pages/HomeDataPage/HomeAddressForm";
import { Path } from "@utils/Path";
import { LoctoolMessage } from "@i18n/i18n";
import { SessionSelectors } from "@redux/selectors/SessionSelectors";
import { useSelector } from "react-redux";
import { Redirect } from "react-router";
import { BlacklistedPage } from "@components/BlacklistedPage";

const HomeAddressPage = () => {
    const stat = useSelector(SessionSelectors.getStat);
    const stepsCount = useSelector(SessionSelectors.getStepsCount);
    const [isBlacklisted, setBlacklisted] = useState(false);

    if (!stat.isCalculationDone) {
        return <Redirect to={Path.calculatorIndex} />;
    }
    if (!stat.isAccountDataDone) {
        return <Redirect to={Path.accountData} />;
    }

    if (isBlacklisted) {
        return <BlacklistedPage onCloseClick={() => setBlacklisted(false)} />;
    }

    return (
        <Box $style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
            <AppBar />

            <Container as="main" $maxWidth={504} $px={16} $style={{ width: "100%" }}>
                <CalculatorHeadingSteps currentStep={2} stepsCount={stepsCount} text={<LoctoolMessage id="page.homeData.title" />} backLink={Path.accountData} />

                <HomeAddressForm onBlacklisted={() => setBlacklisted(true)} />
            </Container>

            <Footer />
        </Box>
    );
};

export default HomeAddressPage;
