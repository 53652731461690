import { Button } from "@components/Button";
import { Flex } from "@components/Flex";
import { Text } from "@components/Text";
import { Container, Box } from "@components/LayoutElements";
import { Modal } from "@components/Modal";
import SvgIcon32ArrowForward from "@components/svg/Icon32ArrowForward";
import SvgLogoUniqaFlat from "@components/svg/LogoUniqaFlat";
import { Loctool, LoctoolMessage } from "@i18n/i18n";
import { ApplicationState } from "@redux/reducers";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "styled-components";
import { Api, Gql } from "@api/Api";
import { SessionActions } from "@redux/actions/sessionActions";
import { batchActions } from "redux-batched-actions";
import { AppStateActions } from "@redux/actions/appStateActions";

export type PassengerToRemove = {
    index: number;
    isAdult: boolean;
    isImmediate?: boolean;
};

type Props = {
    passengerToRemove: PassengerToRemove | null;
    onCloseClick: () => void;
    removePassenger: () => void;
};

export const RemovePassengerModal = (props: Props) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const { travelOffer, passengers } = useSelector((state: ApplicationState) => state.session);
    const currentTariff = travelOffer.tariff;
    const [newTariff, setNewTariff] = useState(travelOffer.tariff);

    const calculateOffer = useCallback(async (): Promise<Gql.Amount | null> => {
        if (!props.passengerToRemove) {
            return null;
        }

        const result = await Api.calculateTravelTariff({
            destinationId: travelOffer.destinationId,
            departureDate: travelOffer.departure,
            returnDate: travelOffer.return,
            adultsCount: travelOffer.adultsCount - (props.passengerToRemove.isAdult ? 1 : 0),
            childrenCount: travelOffer.childrenCount - (props.passengerToRemove.isAdult ? 0 : 1),
        });
        if (result === null) {
            throw new Error();
        }
        return result;
    }, [travelOffer, props.passengerToRemove]);

    const [isOpen, setOpen] = useState(false);

    const hide = useCallback(() => {
        setOpen(false);
        setNewTariff(null);
        props.onCloseClick();
    }, [props]);

    const onRemove = useCallback(() => {
        if (!props.passengerToRemove) {
            return;
        }
        const adults = [...passengers.adults];
        const children = [...passengers.children];
        if (props.passengerToRemove.isAdult) {
            adults.splice(props.passengerToRemove.index, 1);
        } else {
            children.splice(props.passengerToRemove.index, 1);
        }
        dispatch(
            batchActions([
                SessionActions.updateTravelOffer({
                    destinationId: travelOffer.destinationId,
                    departure: travelOffer.departure,
                    return: travelOffer.return,
                    adultsCount: travelOffer.adultsCount - (props.passengerToRemove!.isAdult ? 1 : 0),
                    childrenCount: travelOffer.childrenCount - (props.passengerToRemove!.isAdult ? 0 : 1),
                    tariff: newTariff,
                }),
                SessionActions.updatePassengers(adults, children),
            ])
        );
        props.removePassenger();
        hide();
    }, [dispatch, hide, newTariff, passengers, props, travelOffer]);

    const show = useCallback(async () => {
        try {
            const newTariff = await calculateOffer();
            if (props.passengerToRemove!.isImmediate) {
                if (!props.passengerToRemove) {
                    return;
                }
                const adults = [...passengers.adults];
                const children = [...passengers.children];
                if (props.passengerToRemove.isAdult) {
                    adults.splice(props.passengerToRemove.index, 1);
                } else {
                    children.splice(props.passengerToRemove.index, 1);
                }
                dispatch(
                    batchActions([
                        SessionActions.updateTravelOffer({
                            destinationId: travelOffer.destinationId,
                            departure: travelOffer.departure,
                            return: travelOffer.return,
                            adultsCount: travelOffer.adultsCount - (props.passengerToRemove!.isAdult ? 1 : 0),
                            childrenCount: travelOffer.childrenCount - (props.passengerToRemove!.isAdult ? 0 : 1),
                            tariff: newTariff,
                        }),
                        SessionActions.updatePassengers(adults, children),
                    ])
                );
                props.removePassenger();
            } else {
                setOpen(true);
                setNewTariff(newTariff);
            }
        } catch (error) {
            dispatch(AppStateActions.addNotification(Loctool.formatMessage({ id: "page.passengers.removeModal.calculationFailed" }), "error"));
            hide();
        }
    }, [calculateOffer, props, passengers.adults, passengers.children, dispatch, travelOffer, hide]);

    useEffect(() => {
        if (props.passengerToRemove) {
            show();
        } else {
            hide();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.passengerToRemove]);

    return (
        <Modal alert={true} mounted={isOpen} underlayClickExits={false} onExit={hide} titleText={Loctool.formatMessage({ id: "page.passengers.removeModal.title" })}>
            <Container $maxWidth={504} $px={16} $style={{ paddingTop: 48, paddingBottom: 48 }} $styleMedium={{ paddingTop: 40 }} tabIndex={0}>
                <Box
                    $style={{
                        backgroundColor: theme.color.white,
                        borderRadius: 2,
                        padding: "40px 16px 274px",
                        textAlign: "center",
                        position: "relative",
                        overflow: "hidden",
                    }}
                    $styleMedium={{ paddingTop: 44, paddingBottom: 168 }}
                >
                    <Text as="h1" id="modal-title" $fontSize="text24" $style={{ marginTop: 8, marginBottom: 8 }} $styleMedium={{ marginTop: 4, marginBottom: 4 }}>
                        <LoctoolMessage id="page.passengers.removeModal.title" />
                    </Text>

                    <Box as="p" $style={{ marginTop: 8, marginBottom: 8 }} $styleMedium={{ marginTop: 4, marginBottom: 4 }}>
                        <LoctoolMessage id="page.passengers.removeModal.description" />
                    </Box>

                    <Flex.Container $flexDirection="column" $justifyContent="center" $gutterMargin={16} $style={{ marginTop: 24 }} $bpMedium={{ $flexDirection: "row" }}>
                        <Flex.Item $shrink={1} $gutterMargin={16} $bpMedium={{ $shrink: "auto" }}>
                            <Text as="s" $fontSize="text24" $style={{ display: "block", textDecoration: "none" }} $styleMedium={{ textAlign: "right" }}>
                                {currentTariff && Loctool.formatCurrency(currentTariff.amount)}
                            </Text>
                        </Flex.Item>

                        <Flex.Item $shrink={1} $gutterMargin={16} $bpMedium={{ $shrink: "shrink" }}>
                            <Box
                                as={SvgIcon32ArrowForward}
                                $style={{ margin: "auto", transformOrigin: "center", transform: "rotate(90deg)" }}
                                $styleMedium={{ transform: "none" }}
                            />
                        </Flex.Item>

                        <Flex.Item $shrink={1} $gutterMargin={16} $bpMedium={{ $shrink: "auto" }}>
                            <Text $fontFamily="firaSans" $fontSize="text24" $style={{ fontWeight: 600 }} $styleMedium={{ textAlign: "left" }}>
                                {newTariff && Loctool.formatCurrency(newTariff.amount)}
                            </Text>
                        </Flex.Item>
                    </Flex.Container>

                    <Box $style={{ marginLeft: "auto", marginRight: "auto", maxWidth: 244 }} $styleMedium={{ maxWidth: 2 * 244 }}>
                        <Flex.Container $flexDirection="column" $justifyContent="center" $gutterMargin={16} $style={{ marginTop: 24 }} $bpMedium={{ $flexDirection: "row" }}>
                            <Flex.Item $shrink={1} $gutterMargin={16} $bpMedium={{ $shrink: "auto" }}>
                                <Button.Hollow btnLabel={<LoctoolMessage id="page.passengers.removeModal.cancel" />} isExpanded onClick={hide} />
                            </Flex.Item>

                            <Flex.Item $shrink={1} $gutterMargin={16} $bpMedium={{ $shrink: "auto" }} $style={{ marginTop: 16 }} $styleMedium={{ marginTop: 0 }}>
                                <Button.Primary btnLabel={<LoctoolMessage id="page.passengers.removeModal.delete" />} isExpanded onClick={onRemove} />
                            </Flex.Item>
                        </Flex.Container>
                    </Box>

                    <Box
                        $style={{ position: "absolute", left: "100%", bottom: -8, transform: "translateX(-172px)", color: theme.color.blueN, opacity: 0.1 }}
                        $styleMedium={{ bottom: -100, transform: "translateX(-184px)" }}
                    >
                        <SvgLogoUniqaFlat />
                    </Box>
                </Box>

                <Box $styleMedium={{ width: 504 }} />
            </Container>
        </Modal>
    );
};
