import { gql } from "@apollo/client";
import { AmountFragment } from "./fragments/AmountFragment";
import { BackendEnvironmentFragment } from "./fragments/BackendEnvironmentFragment";
import { CoverageItemFragment } from "./fragments/CoverageItemFragment";
import { LegalDocumentFragment } from "./fragments/LegalDocumentFragment";
import { ContractFragment } from "./fragments/ContractFragment";

export const Queries = Object.freeze({
    appData: gql`
        query appData($at: LocalDate, $countryCode: CountryCode!) {
            accountLegalDocuments: getEffectiveLegalDocuments(at: $at, useCase: accountCreation, countryCode: $countryCode) {
                ...LegalDocument
            }

            nonDomestic: getEffectiveLegalDocuments(at: $at, useCase: travelNonDomestic, countryCode: $countryCode) {
                ...LegalDocument
            }

            env(country: $countryCode) {
                ...BackendEnvironment
            }
        }
        ${LegalDocumentFragment}
        ${BackendEnvironmentFragment}
    `,

    legalDocuments: gql`
        query legalDocuments($at: LocalDate, $countryCode: CountryCode!) {
            accountLegalDocuments: getEffectiveLegalDocuments(at: $at, useCase: accountCreation, countryCode: $countryCode) {
                ...LegalDocument
            }

            nonDomestic: getEffectiveLegalDocuments(at: $at, useCase: travelNonDomestic, countryCode: $countryCode) {
                ...LegalDocument
            }
        }
        ${LegalDocumentFragment}
    `,

    calculateTravelTariff: gql`
        query calculateTravelTariff(
            $firstDayOfTravel: LocalDate!
            $lastDayOfTravel: LocalDate!
            $residencyCode: ResidencyCode!
            $destinationCategoryId: Long!
            $adultsNumber: Short!
            $childrenNumber: Short!
        ) {
            calculateTravelTariff(
                firstDayOfTravel: $firstDayOfTravel
                lastDayOfTravel: $lastDayOfTravel
                residencyCode: $residencyCode
                destinationCategoryId: $destinationCategoryId
                adultsNumber: $adultsNumber
                childrenNumber: $childrenNumber
            ) {
                ...Amount
            }
        }
        ${AmountFragment}
    `,

    travelCoverage: gql`
        query travelCoverage($languageCode: LanguageCode!, $residencyCode: ResidencyCode!, $from: LocalDate) {
            travelCoverage(languageCode: $languageCode, residencyCode: $residencyCode, from: $from) {
                category {
                    name
                    iconName
                    coverageCategorySlug
                }
                coverages {
                    ...CoverageItem
                }
            }
        }
        ${CoverageItemFragment}
    `,

    getCitiesByZipCode: gql`
        query getCitiesByZipCode($zipCode: String!) {
            streets(zipCode: $zipCode) {
                cities
            }
        }
    `,

    getStreets: gql`
        query getStreets($zipCode: String!, $city: String!) {
            streets(zipCode: $zipCode, city: $city) {
                streets {
                    streetWithSuffix
                }
            }
        }
    `,

    getContract: gql`
        query getContract($offerId: Id!) {
            getOffer(offerId: $offerId) {
                ...Contract
            }
        }
        ${ContractFragment}
    `,

    time: gql`
        query time {
            time
        }
    `,

    canAccountBeInsured: gql`
        query canAccountBeInsured($firstName: String!, $lastName: String!, $dateOfBirth: LocalDate!, $zipCode: String!, $address: String!) {
            canAccountBeInsured(firstname: $firstName, lastname: $lastName, dateOfBirth: $dateOfBirth, country: HU, zipCode: $zipCode, address: $address)
        }
    `,

    canPersonBeInsured: gql`
        query canPersonBeInsured($firstName: String!, $lastName: String!, $dateOfBirth: LocalDate!) {
            canPersonBeInsured(firstname: $firstName, lastname: $lastName, dateOfBirth: $dateOfBirth)
        }
    `,
});
