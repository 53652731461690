import React from "react";
import { AppBar } from "@components/AppBar";
import { Button } from "@components/Button";
import { Container, Box } from "@components/LayoutElements";
import SvgIcon32Tick from "@components/svg/Icon32Tick";
import SvgIcon32X from "@components/svg/Icon32X";
import { useTheme } from "styled-components";
import { Text } from "@components/Text";
import { LoctoolMessage } from "@i18n/i18n";
import { Gql } from "@api/Api";

type PaymentSuccessfulPageProps = React.PropsWithChildren<{
    contract: Gql.Contract;
}>;

export const PaymentSuccessfulPage = ({ contract, children }: PaymentSuccessfulPageProps) => {
    const theme = useTheme();
    return (
        <>
            <AppBar />

            <Container as="main" $maxWidth={764} $px={16} $style={{ paddingTop: 40, paddingBottom: 40 }} $styleMedium={{ paddingTop: 48, paddingBottom: 48 }}>
                <Box
                    $style={{
                        width: 72,
                        height: 72,
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        margin: "0 auto 24px",
                        backgroundColor: theme.color.greenN,
                        color: theme.color.white,
                    }}
                    $styleMedium={{ marginBottom: 32 }}
                >
                    <Box as={SvgIcon32Tick} $style={{ strokeWidth: 2 }} />
                </Box>

                <Box $style={{ textAlign: "center" }}>
                    <Text
                        as="h1"
                        $fontFamily="firaSans"
                        $fontSize="text24"
                        $fontSizeMedium="text40"
                        $style={{ margin: "12px 0", fontWeight: 600 }}
                        $styleMedium={{ margin: "4px 0" }}
                    >
                        <LoctoolMessage id="page.paymentResult.succeed.title" />
                    </Text>

                    <Text as="p" $style={{ margin: "12px 0", minHeight: 3 * theme.typo.size.text16.lineHeight }} $styleMedium={{ margin: "4px 0" }}>
                        <LoctoolMessage id="page.paymentResult.succeed.description" values={{ email: contract.accountData.email }} />
                    </Text>
                </Box>

                {children}
            </Container>
        </>
    );
};

type PaymentUnsuccessfulPageProps = React.PropsWithChildren<{
    onRetryClick: () => void;
}>;

export const PaymentUnsuccessfulPage = ({ onRetryClick, children }: PaymentUnsuccessfulPageProps) => {
    const theme = useTheme();

    return (
        <>
            <AppBar />

            <Container as="main" $maxWidth={764} $px={16} $style={{ paddingTop: 40, paddingBottom: 40 }} $styleMedium={{ paddingTop: 48, paddingBottom: 48 }}>
                <Box
                    $style={{
                        width: 72,
                        height: 72,
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        margin: "0 auto 24px",
                        backgroundColor: theme.color.redN,
                        color: theme.color.white,
                    }}
                >
                    <Box as={SvgIcon32X} $style={{ strokeWidth: 2 }} />
                </Box>

                <Box $style={{ textAlign: "center" }}>
                    <Text
                        as="h1"
                        $fontFamily="firaSans"
                        $fontSize="text24"
                        $fontSizeMedium="text40"
                        $style={{ margin: "12px 0", fontWeight: 600 }}
                        $styleMedium={{ margin: "4px 0" }}
                    >
                        <LoctoolMessage id="page.paymentResult.failed.title" />
                    </Text>

                    <Text as="p" $style={{ margin: "12px 0", minHeight: 3 * theme.typo.size.text16.lineHeight }} $styleMedium={{ margin: "4px 0" }}>
                        <LoctoolMessage id="page.paymentResult.failed.description" />
                    </Text>

                    <Box $style={{ marginTop: 24, marginBottom: (56 - 48) * -1 }}>
                        <Button.Primary btnLabel={<LoctoolMessage id="page.paymentResult.failed.retry" />} onClick={onRetryClick} />
                    </Box>
                </Box>

                {children}
            </Container>
        </>
    );
};

export const PaymentInProgressPage = () => {
    return (
        <>
            <AppBar />

            <Container
                as="main"
                $maxWidth={764}
                $px={16}
                $style={{ paddingTop: 48, paddingBottom: 48, textAlign: "center" }}
                $styleMedium={{ paddingTop: 116, paddingBottom: 116 }}
            >
                <Text as="h1" $fontFamily="firaSans" $fontSize="text24" $fontSizeMedium="text40" $style={{ margin: 0, fontWeight: 600 }}>
                    <LoctoolMessage id="page.paymentResult.inProgress.title" />
                </Text>

                <Text as="p" $style={{ marginTop: 4, marginBottom: 4 }}>
                    <LoctoolMessage id="page.paymentResult.inProgress.description" />
                </Text>
            </Container>
        </>
    );
};
