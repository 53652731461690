import { Gql } from "@api/Api";
import { LegalDocument } from "@api/graphql/types";
import { ApplicationState } from "@redux/reducers";

export const LegalDocumentSelectors = Object.freeze({
    getDocumentByCategory:
        (category: Gql.legalDocumentCategory) =>
        (state: ApplicationState): LegalDocument | null => {
            return state.legalDocuments.data.find(document => document.category === category) || null;
        },

    getDocumentRelationActions:
        (isMarketingPolicyAccepted: boolean, isCherryHubMarketingPolicyAccepted: boolean) =>
        (state: ApplicationState): { custLegal: Gql.LegalDocumentRelationActionWLInput[]; travelLegal: Gql.LegalDocumentRelationActionWLInput[] } => {
            const privacyPolicy = LegalDocumentSelectors.getDocumentByCategory(Gql.legalDocumentCategory.WL_HU_PRIVACY_POLICY)(state);
            const uniqaMarketingPolicy = LegalDocumentSelectors.getDocumentByCategory(Gql.legalDocumentCategory.WL_HU_MARKETING_POLICY)(state);
            const terms = LegalDocumentSelectors.getDocumentByCategory(Gql.legalDocumentCategory.WL_HU_TERMS)(state);
            const ipid = LegalDocumentSelectors.getDocumentByCategory(Gql.legalDocumentCategory.WL_HU_IPID)(state);
            const cherryHubMarketingPolicy = LegalDocumentSelectors.getDocumentByCategory(Gql.legalDocumentCategory.WL_HU_SCALE_MARKETING_POLICY)(state);

            const custLegal = [];
            if (privacyPolicy) {
                custLegal.push({ countryCode: Gql.CountryCode.HU, version: privacyPolicy.version, category: privacyPolicy.category, action: Gql.LegalDocumentRelAction.ACCEPTED });
            }
            if (uniqaMarketingPolicy) {
                custLegal.push({
                    countryCode: Gql.CountryCode.HU,
                    version: uniqaMarketingPolicy.version,
                    category: uniqaMarketingPolicy.category,
                    action: isMarketingPolicyAccepted ? Gql.LegalDocumentRelAction.ACCEPTED : Gql.LegalDocumentRelAction.NOTIFIED,
                });
            }
            if (cherryHubMarketingPolicy) {
                custLegal.push({
                    countryCode: Gql.CountryCode.HU,
                    version: cherryHubMarketingPolicy.version,
                    category: cherryHubMarketingPolicy.category,
                    action: isCherryHubMarketingPolicyAccepted ? Gql.LegalDocumentRelAction.ACCEPTED : Gql.LegalDocumentRelAction.NOTIFIED,
                });
            }

            const travelLegal = [];
            if (terms) {
                travelLegal.push({ countryCode: Gql.CountryCode.HU, version: terms.version, category: terms.category, action: Gql.LegalDocumentRelAction.ACCEPTED });
            }
            if (ipid) {
                travelLegal.push({ countryCode: Gql.CountryCode.HU, version: ipid.version, category: ipid.category, action: Gql.LegalDocumentRelAction.ACCEPTED });
            }

            return { custLegal, travelLegal };
        },
});
