import * as React from "react";
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgIcon32Envelope({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" focusable={false} aria-hidden={true} aria-labelledby={titleId} {...props}>
            {title ? <title id={titleId}>{title}</title> : null}
            <rect x={4.5} y={7.5} width={23} height={17} rx={0.5} stroke="currentColor" />
            <path d="M4.5 7.5l10.793 10.793a1 1 0 001.414 0L27.5 7.5" stroke="currentColor" />
        </svg>
    );
}

export default SvgIcon32Envelope;
