import * as React from "react";
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgIcon32Coupon({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" focusable={false} aria-hidden={true} aria-labelledby={titleId} {...props}>
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M15.768 5.94a1.5 1.5 0 011.06-.44H27A1.5 1.5 0 0128.5 7v10.172a1.5 1.5 0 01-.44 1.06l-12 12a1.5 1.5 0 01-2.12 0L3.767 20.061a1.5 1.5 0 010-2.122l12-12z"
                stroke="currentColor"
            />
            <circle cx={22.506} cy={11.728} r={2.5} transform="rotate(45 22.506 11.728)" stroke="currentColor" />
            <path stroke="currentColor" d="M6.354 15.646l12 12" />
        </svg>
    );
}

export default SvgIcon32Coupon;
