import React from "react";
import { useTheme } from "styled-components";
import { Box, Container } from "@components/LayoutElements";
import { Text } from "@components/Text";
import { AppBar } from "@components/AppBar";
import { Footer } from "@components/Footer";
import { TravelCalculationForm } from "@pages/TravelCalculation/TravelCalculationForm";
import { PaymentStickyPriceBoxMobileFooterPlaceholder } from "@components/payment/PaymentStickyPriceBox";
import SvgSkyline1440Travel from "@components/svg/Skyline1440Travel";
import { LoctoolMessage } from "@i18n/i18n";

interface Props {}

const TravelCalculationPage = (props: Props) => {
    const theme = useTheme();

    return (
        <Box $style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
            <AppBar />

            <Container as="main" $maxWidth={504} $px={16} $style={{ width: "100%" }}>
                <Box
                    $style={{
                        margin: `16px -16px ${(25 - 40) * -1}px`,
                        padding: "0 16px 25px",
                        position: "relative",
                        overflow: "hidden",
                    }}
                    $styleSmall={{ marginBottom: (25 - 16) * -1, paddingBottom: 25 }}
                    $styleMedium={{ marginBottom: (60 - 16) * -1, paddingBottom: 60, overflow: "visible" }}
                >
                    <Text
                        $fontSize="text14"
                        $style={{
                            textAlign: "center",
                            color: theme.color.blueMuted,
                            fontWeight: 400,
                            marginBottom: 20,
                            maxWidth: 350,
                            margin: "0 auto",
                        }}
                    >
                        <LoctoolMessage id="page.travelCalculation.insuredBy" />
                    </Text>
                    <Box $style={{ pointerEvents: "none", position: "absolute", bottom: 0, left: "50%", transform: "translateX(-50%)", zIndex: -1 }}>
                        <Box
                            as={SvgSkyline1440Travel}
                            $style={{ margin: "auto", width: 393, height: 200, transform: "translateX(-12px)" }}
                            $styleSmall={{ width: 459, height: 170 }}
                            $styleMedium={{ width: 569, height: 246, transform: "none" }}
                        />
                    </Box>

                    <Text
                        as="h1"
                        $fontSize="text16"
                        $style={{
                            margin: 0,
                            borderRadius: 2,
                            boxShadow: `inset 0px 0px 0px 1px ${theme.color.blueSapphire}`,
                            padding: 16,
                            textAlign: "center",
                        }}
                        $styleMedium={{ paddingTop: 32, paddingBottom: 32 }}
                    >
                        <LoctoolMessage id="page.travelCalculation.title" />
                    </Text>
                </Box>

                <TravelCalculationForm />
            </Container>

            <Footer />

            <PaymentStickyPriceBoxMobileFooterPlaceholder />
        </Box>
    );
};

export default TravelCalculationPage;
