import styled from "styled-components";
import { Box, Table } from "@components/LayoutElements";

const CardTable = styled(Table).attrs(() => ({ cssReset: true }))<{ $noSeparator?: boolean }>`
    overflow: hidden;

    th,
    td {
        padding: 16px 4px;

        &:first-child {
            padding-left: 16px;
        }

        &:last-child {
            padding-right: 16px;
        }
    }

    ${({ theme, $noSeparator }) =>
        $noSeparator
            ? ""
            : `
    tr {
        box-shadow: 0px -1px 0px ${theme.color.greyN};
    }`}

    tbody {
        th,
        td {
            vertical-align: top;
        }
    }
`;

const CardWrapper = styled(Box)`
    background-color: ${({ theme, $style }) => ($style?.backgroundColor ? "" : theme.color.white)};
    border-radius: 2px;
    box-shadow: inset 0px 0px 0px 1px ${({ theme }) => theme.color.greyN};
    /* overflow: hidden; */
    position: relative;
`;

export const Card = {
    Table: CardTable,
    Wrapper: CardWrapper,
};
