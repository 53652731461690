import * as React from "react";
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgIcon32Crime({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg width={32} height={32} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" focusable={false} aria-hidden={true} aria-labelledby={titleId} {...props}>
            {title ? <title id={titleId}>{title}</title> : null}
            <g fill="none" fillRule="evenodd">
                <path fill="currentColor" d="M5 27h23v1H5z" />
                <path d="M8.5 27.5h16v-11a8 8 0 10-16 0v11z" stroke="currentColor" />
                <path fill="currentColor" d="M7 6.707L7.707 6l2.121 2.121-.707.707zM25.829 6.707L25.122 6 23 8.121l.707.707zM16 3h1v3h-1z" />
            </g>
        </svg>
    );
}

export default SvgIcon32Crime;
