import React, { useMemo } from "react";
import styled from "styled-components";
import ReactDayPicker, { DayPickerProps, LocaleUtils } from "react-day-picker";
import "react-day-picker/lib/style.css";
import { InputStyles } from "@components/Input";
import { DateFormat, DateUtils } from "@utils/DateUtils";
import dateFnsFormat from "date-fns/format";
import dateFnsParse from "date-fns/parse";
import { Locale } from "date-fns";
import { Loctool } from "@i18n/Loctool";

type MonthArray = [string, string, string, string, string, string, string, string, string, string, string, string];

export const DayPicker = (props: DayPickerProps) => {
    const past = useMemo(() => {
        return {
            before: DateUtils.now(),
        };
    }, []);

    const localeUtils: LocaleUtils = useMemo(() => {
        const months = Loctool.formatMessage({ id: "common.date.months" }).split(",");
        const weekdaysLong = Loctool.formatMessage({ id: "common.date.weekdaysLong" }).split(",");
        const weekdaysShort = Loctool.formatMessage({ id: "common.date.weekdaysShort" }).split(",");
        return {
            formatDate: (date: Date | number, format: string, locale?: string) => {
                return dateFnsFormat(date, format, { locale: locale as unknown as Locale });
            },
            parseDate: (str: string, format?: string, locale?: string) => {
                const parsed = dateFnsParse(str, format as DateFormat, DateUtils.now(), { locale: locale as unknown as Locale });
                if (DateUtils.isDate(parsed)) {
                    return parsed;
                }
                return DateUtils.now();
            },
            formatDay: (day: Date, locale?: string) => DateUtils.format(day, DateFormat.medium),
            formatMonthTitle: (month: Date, locale?: string) => {
                const monthName = DateUtils.format(month, DateFormat.monthName);
                return monthName[0].toLocaleUpperCase() + monthName.slice(1);
            },
            formatWeekdayLong: (weekday: number, locale?: string) => weekdaysLong[weekday],
            formatWeekdayShort: (weekday: number, locale?: string) => weekdaysShort[weekday],
            getFirstDayOfWeek: (locale?: string) => 1,
            getMonths: (locale?: string): MonthArray => months as MonthArray,
        };
    }, []);
    return (
        <StyledDayPickerWrapper>
            <ReactDayPicker firstDayOfWeek={1} disabledDays={past} localeUtils={localeUtils} {...props} locale="hu" />
        </StyledDayPickerWrapper>
    );
};

export const DayPickerInput = styled(InputStyles.Input)`
    color: ${({ theme }) => theme.color.blueN};
    font-weight: 700;
    padding: 4px 0;
    text-align: right;
`;

const StyledDayPickerWrapper = styled.div`
    /* Note: Figma UI missing */
    background-color: ${({ theme }) => theme.color.white};
    border-radius: 2px;
    box-shadow: inset 0px 0px 0px 1px ${({ theme }) => theme.color.greyN};
    pointer-events: all;

    .DayPicker-wrapper {
        padding-bottom: 0;
    }

    .DayPicker-Month {
        margin: 12px 16px;
    }

    .DayPicker-Caption {
        margin-bottom: 8px;
        padding: 0;

        > div {
            font-family: ${({ theme }) => theme.font.firaSans};
            font-size: ${({ theme }) => theme.typo.size.text24.fontSize}px;
            font-weight: 600;
            line-height: ${({ theme }) => theme.typo.size.text24.lineHeight}px;
        }
    }

    .DayPicker-NavButton {
        background-size: 32px;
        border-radius: 50%;
        color: ${({ theme }) => theme.color.blueD};
        height: 40px;
        margin-top: 0;
        right: 16px;
        top: 8px;
        width: 40px;
    }

    .DayPicker-NavButton:hover {
        box-shadow: inset 0px 0px 0px 4px ${({ theme }) => theme.color.greyN};
    }

    .DayPicker-NavButton--prev {
        margin-right: 40px;
        background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIGZpbGw9Im5vbmUiIHZlcnNpb249IjEuMSIgdmlld0JveD0iMCAwIDMyIDMyIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogPHBhdGggZD0ibTE3LjUgMjAuNS00LTQuNSA0LTQuNSIgc3Ryb2tlPSIjMDAzNjY3IiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=");
    }

    .DayPicker-NavButton--next {
        background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIGZpbGw9Im5vbmUiIHZlcnNpb249IjEuMSIgdmlld0JveD0iMCAwIDMyIDMyIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogPHBhdGggZD0ibTEzLjM3NCAyMC41IDQtNC41LTQtNC41IiBzdHJva2U9IiMwMDM2NjciIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==");
    }

    .DayPicker-Weekdays {
        margin-top: 16px;
    }

    .DayPicker-Day {
        width: 40px;
    }

    .DayPicker-Day--today {
        color: ${({ theme }) => theme.color.greenN};
    }

    .DayPicker-Day--disabled {
        color: ${({ theme }) => theme.color.greyN};
    }

    .DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
        background-color: transparent;
        box-shadow: inset 0px 0px 0px 4px ${({ theme }) => theme.color.greyN};
        color: ${({ theme }) => theme.color.blueD};
    }

    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
        background-color: ${({ theme }) => theme.color.blueN};
    }

    .DayPicker-Weekday {
        color: ${({ theme }) => theme.color.greyD};
        padding: 0;
    }
`;
