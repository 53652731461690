import sessionStorage from "redux-persist/es/storage/session";
import { persistCombineReducers } from "redux-persist";
import { PersistConfig } from "redux-persist/es/types";
import { ReduxAction } from "@redux/actions/ReduxAction";

import appStateReducer, { AppStateReducerType } from "./appStateReducer";
import backendEnvironmentReducer, { BackendEnvironmentReducerType } from "./backendEnvReducer";
import legalDocumentReducer, { LegalDocumentReducerType } from "./legalDocumentReducer";
import sessionReducer, { SessionReducerType } from "./sessionReducer";
import contractReducer, { ContractReducerType } from "./contractReducer";

export interface ApplicationState {
    legalDocuments: LegalDocumentReducerType;
    backendEnvironments: BackendEnvironmentReducerType;
    appState: AppStateReducerType;
    session: SessionReducerType;
    contract: ContractReducerType;
}

const config: PersistConfig<ApplicationState> = {
    key: "root",
    storage: sessionStorage,
    debug: process.env.REACT_APP_DEBUG_MODE === "true",
    whitelist: ["session", "contract"],
};

const rootReducer = persistCombineReducers<ApplicationState, ReduxAction>(config, {
    appState: appStateReducer,
    backendEnvironments: backendEnvironmentReducer,
    legalDocuments: legalDocumentReducer,
    session: sessionReducer,
    contract: contractReducer,
});

export default rootReducer;
