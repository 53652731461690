import React from "react";
import { Accordion } from "@components/Accordion";
import { Card } from "@components/Card";
import { Box } from "@components/LayoutElements";
import SvgIcon32Adult from "@components/svg/Icon32Adult";
import SvgIcon32Child from "@components/svg/Icon32Child";
import { Passenger } from "@redux/actions/sessionActions";
import { DateUtils, DateFormat } from "@utils/DateUtils";
import { LoctoolMessage } from "@i18n/i18n";

type Props = {
    id: string;
    isAdult: boolean;
    passenger: Passenger;
};

export const OverviewPassengerCard = ({ id, isAdult, passenger }: Props) => {
    return (
        <Card.Wrapper $style={{ marginTop: 16 }}>
            <Accordion
                id={id}
                headingLevel={3}
                headingText={`${passenger.lastName} ${passenger.firstName}`}
                headingIconElement={isAdult ? <SvgIcon32Adult /> : <SvgIcon32Child />}
                variant="blueN"
                isInitiallyOpened
            >
                <Card.Table>
                    <thead>
                        <tr>
                            <th colSpan={2} scope="colgroup">
                                <Box $style={{ fontWeight: 700 }}>
                                    <LoctoolMessage id="page.overview.card.insured" />
                                </Box>
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <Box as="th" $style={{ width: "50%" }} scope="row">
                                <LoctoolMessage id="page.overview.card.lastName" />
                            </Box>
                            <Box as="td" $style={{ width: "50%" }}>
                                {passenger.lastName}
                            </Box>
                        </tr>

                        <tr>
                            <Box as="th" $style={{ width: "50%" }} scope="row">
                                <LoctoolMessage id="page.overview.card.firstName" />
                            </Box>
                            <Box as="td" $style={{ width: "50%" }}>
                                {passenger.firstName}
                            </Box>
                        </tr>

                        <tr>
                            <Box as="th" $style={{ width: "50%" }} scope="row">
                                <LoctoolMessage id="page.overview.card.dateOfBirth" />
                            </Box>
                            <Box as="td" $style={{ width: "50%" }}>
                                {DateUtils.format(DateUtils.parse(passenger.dateOfBirth), DateFormat.medium)}
                            </Box>
                        </tr>
                    </tbody>
                </Card.Table>
            </Accordion>
        </Card.Wrapper>
    );
};
