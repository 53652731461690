import React from "react";
import { Box, Container } from "@components/LayoutElements";
import { AppBar } from "@components/AppBar";
import { Footer } from "@components/Footer";
import { CalculatorHeadingSteps } from "@components/calculator/CalculatorHeadingSteps";
import { PassengersForm } from "@pages/PassengersPage/PassengersForm";
import { Path } from "@utils/Path";
import { useSelector } from "react-redux";
import { ApplicationState } from "@redux/reducers";
import { UserPassengerCard } from "./UserPassengerCard";
import { LoctoolMessage } from "@i18n/i18n";
import { SessionSelectors } from "@redux/selectors/SessionSelectors";
import { Redirect } from "react-router-dom";

const PassengersPage = () => {
    const stepsCount = useSelector(SessionSelectors.getStepsCount);
    const isMeTravel = useSelector((state: ApplicationState): boolean => state.session.areYouTraveling);
    const stat = useSelector(SessionSelectors.getStat);
    if (!stat.isCalculationDone) {
        return <Redirect to={Path.calculatorIndex} />;
    }
    if (!stat.isAccountDataDone) {
        return <Redirect to={Path.accountData} />;
    }

    return (
        <Box $style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
            <AppBar />

            <Container as="main" $maxWidth={504} $px={16} $style={{ width: "100%" }}>
                <CalculatorHeadingSteps
                    currentStep={stepsCount}
                    stepsCount={stepsCount}
                    text={<LoctoolMessage id="page.passengers.title" />}
                    backLink={stepsCount === 3 ? Path.homeAddress : Path.areYouTraveling}
                />

                {isMeTravel && <UserPassengerCard />}

                <PassengersForm />
            </Container>

            <Footer />
        </Box>
    );
};

export default PassengersPage;
