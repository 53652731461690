import * as React from "react";
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgIcon32TripCancellation({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg width={32} height={32} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" focusable={false} aria-hidden={true} aria-labelledby={titleId} {...props}>
            {title ? <title id={titleId}>{title}</title> : null}
            <g fill="none" fillRule="evenodd">
                <path
                    d="M14 5c.788 0 1.552.101 2.28.292a6.027 6.027 0 00-.861.833A8 8 0 006 14c0 2.838 2.648 7.08 8 12.575 4.345-4.46 6.908-8.097 7.719-10.826.401-.119.786-.28 1.148-.477-.613 3.05-3.33 7.051-8.15 12.001a1 1 0 01-1.433 0C7.76 21.603 5 17.18 5 14a9 9 0 019-9z"
                    fill="currentColor"
                    fillRule="nonzero"
                />
                <path stroke="currentColor" strokeLinecap="square" d="M16.5 13.5l9-9" />
                <path
                    d="M14 10.5c.055 0 .11.001.163.004.077.359.183.706.314 1.042a2.5 2.5 0 102 2.795c.276.235.571.449.882.639A3.5 3.5 0 1114 10.5z"
                    fill="currentColor"
                    fillRule="nonzero"
                />
                <circle stroke="currentColor" cx={21} cy={9} r={6.5} />
            </g>
        </svg>
    );
}

export default SvgIcon32TripCancellation;
