import * as DateFns from "date-fns";
import hu from "date-fns/locale/hu";
import { ObjectUtils } from "./ObjectUtils";
import { ServerDate } from "./ServerDate";

export enum DateFormat {
    api = "yyyy-MM-dd",
    medium = "yyyy. MM. dd.",
    long = "yyyy. MM. dd. HH:mm:ss",
    monthName = "MMMM",
}

export class DateUtils {
    public static format(date: Date | number | null | undefined, format: DateFormat = DateFormat.api): string {
        if (!date) {
            return "";
        }
        return DateFns.format(date, format, { locale: hu });
    }

    public static parse(dateString: string, currentFormat: DateFormat = DateFormat.api): Date {
        return DateFns.parse(dateString, currentFormat, DateUtils.now());
    }

    public static now(): Date {
        return ServerDate.getDate();
    }

    public static startOfToday(): Date {
        return DateUtils.startOfDay(DateUtils.now());
    }

    public static addDays(date: Date, amount: number): Date {
        return DateFns.addDays(date, amount);
    }

    public static subDays(date: Date, amount: number): Date {
        return DateFns.subDays(date, amount);
    }

    public static addYears(date: Date, amount: number): Date {
        return DateFns.addYears(date, amount);
    }

    public static subYears(date: Date, amount: number): Date {
        return DateFns.subYears(date, amount);
    }

    public static startOfDay(date: Date): Date {
        return DateFns.startOfDay(date);
    }

    public static isSameDay(date1: Date, date2: Date): boolean {
        return DateFns.isSameDay(date1, date2);
    }

    public static isBefore(date: Date, dateToCompare: Date): boolean {
        return DateFns.isBefore(date, dateToCompare);
    }

    public static isAfter(date: Date, dateToCompare: Date): boolean {
        return DateFns.isAfter(date, dateToCompare);
    }

    public static isDate(date: Date | null | undefined): date is Date {
        return ObjectUtils.hasValue(date) && DateFns.isDate(date);
    }

    public static lastDayOfMonth(date: Date): Date {
        return DateFns.lastDayOfMonth(date);
    }

    public static addMonths(date: Date, amount: number): Date {
        return DateFns.addMonths(date, amount);
    }

    public static getMonthName(date: Date): string {
        return DateUtils.format(date, DateFormat.monthName).toLocaleLowerCase("hu");
    }

    public static getAgeByDate(dateOfBirth: Date): number {
        const ageDiffInMs = DateFns.startOfToday().getTime() - dateOfBirth.getTime();
        const ageDate = new Date(ageDiffInMs);
        return Math.abs(ageDate.getUTCFullYear() - 1970);
    }
}
