import React from "react";
import SvgLogoMaestro from "@components/svg/LogoMaestro";
import SvgLogoMastercard from "@components/svg/LogoMastercard";
import SvgLogoPaypal from "@components/svg/LogoPaypal";
import SvgLogoPaypalFull from "@components/svg/LogoPaypalFull";
import SvgLogoSepa from "@components/svg/LogoSepa";
import SvgLogoSepaDebit from "@components/svg/LogoSepaDebit";
import SvgLogoVisa from "@components/svg/LogoVisa";

enum PaymentOptionVariant {
    maestro = "maestro",
    mastercard = "mastercard",
    paypal = "paypal",
    paypalfull = "paypalfull",
    sepa = "sepa",
    sepadebit = "sepadebit",
    visa = "visa",
}

const paymentOptions = {
    [PaymentOptionVariant.maestro]: <SvgLogoMaestro title="Maestro" focusable={undefined} aria-hidden={undefined} />,
    [PaymentOptionVariant.mastercard]: <SvgLogoMastercard title="MasterCard" focusable={undefined} aria-hidden={undefined} />,
    [PaymentOptionVariant.paypal]: <SvgLogoPaypal title="PayPal" focusable={undefined} aria-hidden={undefined} />,
    [PaymentOptionVariant.paypalfull]: <SvgLogoPaypalFull title="PayPal" focusable={undefined} aria-hidden={undefined} />,
    [PaymentOptionVariant.sepa]: <SvgLogoSepa title="Single Euro Payments Area (SEPA)" focusable={undefined} aria-hidden={undefined} />,
    [PaymentOptionVariant.sepadebit]: <SvgLogoSepaDebit title="Single Euro Payments Area (SEPA) Direct Debits" focusable={undefined} aria-hidden={undefined} />,
    [PaymentOptionVariant.visa]: <SvgLogoVisa title="Visa" focusable={undefined} aria-hidden={undefined} />,
};

export const PaymentOptionLogo = {
    Maestro: () => paymentOptions[PaymentOptionVariant.maestro],
    MasterCard: () => paymentOptions[PaymentOptionVariant.mastercard],
    PayPal: () => paymentOptions[PaymentOptionVariant.paypal],
    PayPalFull: () => paymentOptions[PaymentOptionVariant.paypalfull],
    Sepa: () => paymentOptions[PaymentOptionVariant.sepa],
    SepaDebit: () => paymentOptions[PaymentOptionVariant.sepadebit],
    Visa: () => paymentOptions[PaymentOptionVariant.visa],
};
