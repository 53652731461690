import React from "react";
import { Link } from "react-router-dom";
import { Path } from "@utils/Path";
import styled from "styled-components";
import { bp } from "@style/Theme";
import { Box, Container } from "@components/LayoutElements";
import { Text } from "@components/Text";
import { AppBar } from "@components/AppBar";
import { Footer } from "@components/Footer";
import { Button } from "@components/Button";
import { CoverageBackBar } from "./CoverageBackBar";
import { CoverageList } from "@pages/TravelCoverages/CoverageList";
import { Coverage } from "@api/graphql/CustomTypes";
import { withData, WithData } from "@utils/withData/withData";
import { LoctoolMessage } from "@i18n/i18n";
import { EmptyPage } from "@components/EmptyPage";
import withUpdatedLegalDocuments from "@utils/withData/withLegalDocuments";
import { Api } from "@api/Api";
import { ApplicationState } from "@redux/reducers";
import { Dispatch } from "redux";

type ApiData = { coverages: Coverage[] };

type Props = WithData<ApiData>;

const CoverageDetailsPage = ({ data, loading }: Props) => {
    if (loading || !data) {
        return <EmptyPage />;
    }

    const coverages = data?.coverages ?? [];
    return (
        <Box $style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
            <AppBar />

            <CoverageBackBar backLinkTo={Path.calculatorIndex} />

            <Container as="main" $maxWidth={504} $px={16} $style={{ width: "100%" }}>
                <Text as="h1" $fontFamily="firaSans" $fontSize="text24" $style={{ margin: "32px 0 16px" }} $styleMedium={{ margin: "40px 16px 16px" }}>
                    <LoctoolMessage id="page.travelCoverages.title" />
                </Text>

                <CoverageList coverages={coverages} />

                <BottomButtonWrapper>
                    <Link
                        to={Path.calculatorIndex}
                        component={React.forwardRef((props, ref) => (
                            <Button.Secondary ref={ref} btnLabel={<LoctoolMessage id="page.travelCoverages.back" />} {...props} />
                        ))}
                    />
                </BottomButtonWrapper>
            </Container>

            <Footer />
        </Box>
    );
};

const BottomButtonWrapper = styled.div`
    margin-top: 32px;
    text-align: center;

    ${bp.medium} {
        ${Button.Styled} {
            min-width: 156px;
        }
    }
`;

const CoverageDetailPageWithData = withData(CoverageDetailsPage, async (_, state: ApplicationState, dispatch: Dispatch): Promise<ApiData> => {
    const { departure } = state.session.travelOffer;
    const coverages = await Api.getTravelCoverages(departure);
    return { coverages };
});

export default withUpdatedLegalDocuments(CoverageDetailPageWithData);
