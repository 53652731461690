import React from "react";
import LoctoolMessage from "@i18n/LoctoolMessage";
import { LoctoolValues } from "@i18n/types";
import { FieldError } from "react-hook-form";

export class FormHelpers {
    public static getError(field: FieldError | undefined, nameOverride?: string | undefined, values?: LoctoolValues) {
        if (!field?.type) {
            return undefined;
        }
        if (field.type === "api" && field?.message) {
            return <LoctoolMessage id={`errors.api.${nameOverride ?? field.ref?.name}.${field.message}`} values={values} defaultMessage={field.message} />;
        }
        if (field.type === "validate") {
            return <LoctoolMessage id={`errors.form.${nameOverride ?? field.ref?.name}.${field.message}`} values={values} />;
        }
        return <LoctoolMessage id={`errors.form.${field.type}`} values={values} />;
    }
}
