import * as React from "react";
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgIcon32ChevronDown({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" focusable={false} aria-hidden={true} aria-labelledby={titleId} {...props}>
            {title ? <title id={titleId}>{title}</title> : null}
            <path d="M20 14l-4.5 4-4.5-4" stroke="currentColor" strokeLinejoin="round" />
        </svg>
    );
}

export default SvgIcon32ChevronDown;
