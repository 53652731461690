import * as React from "react";
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgIcon32Notify({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" focusable={false} aria-hidden={true} aria-labelledby={titleId} {...props}>
            {title ? <title id={titleId}>{title}</title> : null}
            <path d="M15.578 19.156l-.43-6.07v-2.54h1.743v2.54l-.407 6.07h-.906zM15.211 22v-1.602h1.617V22h-1.617z" fill="currentColor" />
            <path d="M15.567 4.75a.5.5 0 01.866 0l10.392 18a.5.5 0 01-.433.75H5.608a.5.5 0 01-.433-.75l10.392-18z" stroke="currentColor" />
        </svg>
    );
}

export default SvgIcon32Notify;
