import { Color } from "@style/Theme";
import React from "react";
import styled from "styled-components";
import { LivePreviewHelper, OPMAction } from "./LivePreviewHelper";
import { Portal } from "@components/Portal";

interface Props {
    message: React.ReactNode;
    messageId: string;
}

interface State {
    iconWrapperLeft: number;
    isButtonsVisible: boolean;
    showIconBottom: boolean;
    rect: Omit<DOMRect, "toJSON">;
}

class PreviewMessage extends React.Component<Props, State> {
    public readonly state: Readonly<State> = {
        iconWrapperLeft: 0,
        isButtonsVisible: false,
        showIconBottom: false,
        rect: { height: 0, width: 0, x: 0, y: 0, bottom: 0, left: 0, right: 0, top: 0 },
    };
    private containerRef: HTMLDivElement | null = null;

    private onEditClick = (event: React.MouseEvent<SVGElement>): void => {
        event.stopPropagation();
        event.preventDefault();
        LivePreviewHelper.postMessage(OPMAction.editKey(this.props.messageId));
    };

    private onScrollToClick = (event: React.MouseEvent<SVGElement>): void => {
        event.stopPropagation();
        event.preventDefault();
        LivePreviewHelper.postMessage(OPMAction.highlightKey(this.props.messageId));
    };

    public componentDidMount(): void {
        if (this.containerRef) {
            if (this.containerRef.getBoundingClientRect().width < 49) {
                this.setState({ iconWrapperLeft: -2 });
            }
            const rect = this.containerRef.getClientRects();
            if (rect && rect[0] && rect[0].y < 58) {
                this.setState({ showIconBottom: true });
            }
        }
    }

    public render() {
        return (
            <RefContainer
                ref={(ref: HTMLDivElement | null) => {
                    this.containerRef = ref;
                }}
                onMouseEnter={() => {
                    this.setState({ isButtonsVisible: true });
                    if (this.containerRef && window) {
                        this.setState({
                            rect: {
                                height: this.containerRef.getBoundingClientRect().height,
                                width: this.containerRef.getBoundingClientRect().width,
                                x: this.containerRef.getBoundingClientRect().x + window.scrollX,
                                y: this.containerRef.getBoundingClientRect().y + window.scrollY,
                                bottom: 0,
                                left: 0,
                                right: 0,
                                top: 0,
                            },
                        });
                    }
                }}
                onMouseLeave={() => {
                    this.setState({ isButtonsVisible: false });
                }}
            >
                {this.props.message}

                <Portal>
                    <Container
                        isButtonsVisible={this.state.isButtonsVisible}
                        showIconBottom={this.state.showIconBottom}
                        style={{
                            height: Math.round(this.state.rect.height),
                            width: Math.round(this.state.rect.width),
                            left: Math.round(this.state.rect.x),
                            top: Math.round(this.state.rect.y),
                        }}
                    >
                        {this.state.isButtonsVisible && (
                            <IconWrapper
                                onClick={e => e.preventDefault()}
                                left={this.state.iconWrapperLeft}
                                showIconBottom={this.state.showIconBottom}
                                style={{ left: this.state.iconWrapperLeft ? this.state.iconWrapperLeft : undefined }}
                            >
                                <IconArrowRightTop width={18} height={18} onClick={this.onScrollToClick} />
                                <IconEdit width={18} height={18} onClick={this.onEditClick} />
                            </IconWrapper>
                        )}
                    </Container>
                </Portal>
            </RefContainer>
        );
    }
}

const RefContainer = styled.span`
    box-sizing: border-box;
    display: inline-block;
    position: relative;
    z-index: 1;
`;

const Container = styled.span<{ isButtonsVisible: boolean; showIconBottom: boolean }>`
    position: absolute;
    box-shadow: ${props => (props.isButtonsVisible ? "0px 0px 0px 2px #ff7657" : "none")};
    box-sizing: border-box;
    border-radius: ${props => (props.showIconBottom ? "5px 5px 0px 5px" : "5px 0 5px 5px")};
    /* ${props => (props.isButtonsVisible ? "margin: -2px;" : "")}; */
    z-index: 1500;
    display: block;
    pointer-events: none;
`;

const IconWrapper = styled.span<{ left: number; showIconBottom: boolean }>`
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 78px;
    height: 33px;
    ${props => (props.showIconBottom ? "bottom: -33px;" : "top: -33px;")}
    right: -2px;
    border-radius: ${props => (props.showIconBottom ? "0 0 5px 5px" : "5px 5px 0 0")};
    background: #ff7657;
    pointer-events: all;

    svg {
        transform-origin: center;
        transform: scale(1);
        transition: 125ms transform ease;
        color: ${Color.white};
        pointer-events: all;
        flex: 1;
        display: block;
        cursor: pointer;

        :hover {
            transform: scale(1.35);
        }
    }
`;

function IconEdit(props: { width: number; height: number; onClick: (event: React.MouseEvent<SVGElement>) => void }) {
    const width = props.width || "11";
    const height = props.height || "11";
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 11 11" onClick={props.onClick}>
            <g fill="none" fillRule="evenodd" stroke="currentColor">
                <path d="M1.165 7.281L.516 9.877a.5.5 0 0 0 .607.607l2.596-.65 6.477-6.477a1.037 1.037 0 0 0 0-1.466L9.11.804A1.03 1.03 0 0 0 8.377.5a1.03 1.03 0 0 0-.733.303l-6.48 6.479z" />
                <path strokeLinejoin="round" d="M1 7l1.518 1.518L4 10m2-8l1.518 1.518L9 5" />
            </g>
        </svg>
    );
}

const IconArrowRightTop = (props: { width: number; height: number; onClick: (event: React.MouseEvent<SVGElement>) => void }) => (
    <svg viewBox="0 0 19 19" fill="none" {...props}>
        <path d="M1.515.515v2.99l11.847.011L.454 16.425l2.121 2.12L15.484 5.639l-.011 11.847h3.012V.515H1.515z" fill="currentColor" />
    </svg>
);
export default PreviewMessage;
