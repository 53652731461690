import { store } from "@redux/store";
import { BackendEnvironmentUtils } from "@redux/utils/BackendEnvironmentUtils";
import { PhoneNumberUtils } from "@utils/PhoneNumberUtils";
import { DateUtils } from "./DateUtils";

export enum ValidatorMessage {
    /** Date is null or undefined */
    invalidDate = "invalidDate",

    /** Trave first day is before first valid day */
    invalidTravelDepartureDateBeforeInterval = "invalidTravelDepartureDateBeforeInterval",

    /** Travel first day is after last valid day */
    invalidTravelDepartureDateAfterInterval = "invalidTravelDepartureDateAfterInterval",

    /** Travel first day is after last day */
    invalidTravelDepartureDateAfterReturnDate = "invalidTravelDepartureDateAfterReturnDate",

    /** Travel last day is before first day */
    invalidTravelReturnDateBeforeDepartureDate = "invalidTravelReturnDateBeforeDepartureDate",

    /** Travel last day is after last valid day */
    invalidTravelReturnDateAfterInterval = "invalidTravelReturnDateAfterInterval",

    /** nor null, undefined or empty string */
    required = "required",

    /** email not match with backend given regex */
    invalidEmail = "invalidEmail",

    /** name not match with backend given regex */
    invalidName = "invalidName",

    invalidNameSpecialCharacters = "invalidNameSpecialCharacters",

    /** prefix not in PhoneNumberUtils prefix list */
    invalidPhoneNumberPrefix = "invalidPhoneNumberPrefix",

    /** phone number length not match */
    invalidPhoneNumberSingle = "invalidPhoneNumberSingle",

    /** phone number length range not match */
    invalidPhoneNumberRange = "invalidPhoneNumberRange",

    invalidAccountDateOfBirth = "invalidAccountDateOfBirth",

    invalidZipCode = "invalidZipCode",

    /** City length too short {@link ValidatorConstants.cityMinLength} */
    invalidCity = "invalidCity",

    invalidAddressMissingHouseNumber = "invalidAddressMissingHouseNumber",
    invalidAddressMissingStreetName = "invalidAddressMissingStreetName",

    passengerTooYoung = "passengerTooYoung",
    passengerTooOld = "passengerTooOld",
}

export class Validator {
    public static required<T>(value: T): ValidatorMessage | undefined {
        if (value === null || typeof value === "undefined" || (typeof value === "string" && value === "")) {
            return ValidatorMessage.required;
        }
        return undefined;
    }

    public static travelDepartureDate(departureDate: Date, returnDate: Date): ValidatorMessage | undefined {
        const startOfDepartureDate = DateUtils.startOfDay(departureDate);
        const firstValidDay = ValidatorConstants.travelDepartureDateMin();
        if (DateUtils.isBefore(startOfDepartureDate, firstValidDay)) {
            return ValidatorMessage.invalidTravelDepartureDateBeforeInterval;
        }

        const lastValidDepartureDate = ValidatorConstants.travelDepartureDateMax();
        if (DateUtils.isAfter(startOfDepartureDate, lastValidDepartureDate)) {
            return ValidatorMessage.invalidTravelDepartureDateAfterInterval;
        }

        const startOfReturnDate = DateUtils.startOfDay(returnDate);
        if (DateUtils.isAfter(startOfDepartureDate, startOfReturnDate)) {
            return ValidatorMessage.invalidTravelDepartureDateAfterReturnDate;
        }

        return undefined;
    }

    public static travelReturnDate(departureDate: Date, returnDate: Date): ValidatorMessage | undefined {
        const startOfDepartureDate = DateUtils.startOfDay(departureDate);
        const startOfReturnDate = DateUtils.startOfDay(returnDate);
        if (DateUtils.isBefore(startOfReturnDate, startOfDepartureDate)) {
            return ValidatorMessage.invalidTravelReturnDateBeforeDepartureDate;
        }

        const lastValidReturnDate = ValidatorConstants.travelReturnDateMax(startOfDepartureDate);
        if (DateUtils.isAfter(startOfReturnDate, lastValidReturnDate)) {
            return ValidatorMessage.invalidTravelReturnDateAfterInterval;
        }
        return undefined;
    }

    public static email(email: string): ValidatorMessage | undefined {
        const requiredError = Validator.required(email);
        if (requiredError) {
            return requiredError;
        }
        if (!email.match(BackendEnvironmentUtils.validatorEmail())) {
            return ValidatorMessage.invalidEmail;
        }
        return undefined;
    }

    public static accountName(name: string): ValidatorMessage | undefined {
        const requiredError = Validator.required(name);
        if (requiredError) {
            return requiredError;
        }

        if (!/^[A-Za-záÁéÉőŐűŰúÚóÓüÜöÖíÍ. -]+$/.test(name)) {
            return ValidatorMessage.invalidNameSpecialCharacters;
        }

        if (!name.match(BackendEnvironmentUtils.validatorName())) {
            return ValidatorMessage.invalidName;
        }
        return undefined;
    }

    public static phoneNumberPrefix(prefix: string): ValidatorMessage | undefined {
        const requiredError = Validator.required(prefix);
        if (requiredError) {
            return requiredError;
        }

        if (!PhoneNumberUtils.availablePhoneNumberPrefixes.includes(prefix)) {
            return ValidatorMessage.invalidPhoneNumberPrefix;
        }
        return undefined;
    }

    public static phoneNumber(getPrefix: () => string) {
        return function (phoneNumber: string): ValidatorMessage | undefined {
            const prefix = getPrefix();
            return Validator.phoneNumberWithPrefix(prefix, phoneNumber);
        };
    }

    public static phoneNumberWithPrefix(prefix: string, otherPart: string): ValidatorMessage | undefined {
        const phoneNumber = `${prefix}${otherPart}`;
        const requiredError = Validator.required(otherPart);
        if (requiredError) {
            return requiredError;
        }

        const rule = PhoneNumberUtils.getRuleByPrefix(prefix);
        if (!rule) {
            return ValidatorMessage.invalidPhoneNumberPrefix;
        }
        if (!phoneNumber.match(rule.regexp)) {
            return rule.type === "single" ? ValidatorMessage.invalidPhoneNumberSingle : ValidatorMessage.invalidPhoneNumberRange;
        }
        return undefined;
    }

    public static accountDateOfBirth(dateOfBirthString: string | null): ValidatorMessage | undefined {
        const requiredError = Validator.required(dateOfBirthString);
        if (requiredError) {
            return requiredError;
        }

        const dateOfBirth = DateUtils.parse(dateOfBirthString!);
        if (DateUtils.getAgeByDate(dateOfBirth!) < BackendEnvironmentUtils.accountMinAge()) {
            return ValidatorMessage.invalidAccountDateOfBirth;
        }
        return undefined;
    }

    public static zipCode(zipCode: string): ValidatorMessage | undefined {
        const requiredError = Validator.required(zipCode);
        if (requiredError) {
            return requiredError;
        }

        const zipCodeAsNumber = Number.parseInt(zipCode, 10);
        if (zipCode.length !== 4 || !Number.isInteger(zipCodeAsNumber) || zipCodeAsNumber < 1000) {
            return ValidatorMessage.invalidZipCode;
        }
        return undefined;
    }

    public static city(city: string): ValidatorMessage | undefined {
        const trimmedCity = city.trim();
        const requiredError = Validator.required(trimmedCity);
        if (requiredError) {
            return requiredError;
        }
        if (trimmedCity.length < ValidatorConstants.cityMinLength) {
            return ValidatorMessage.invalidCity;
        }
        return undefined;
    }

    public static address(address: string): ValidatorMessage | undefined {
        const trimmedAddress = address.trim();
        const requiredError = Validator.required(trimmedAddress);
        if (requiredError) {
            return requiredError;
        }
        const hasHouseNumber = /[1-9]/.test(trimmedAddress);
        if (!hasHouseNumber) {
            return ValidatorMessage.invalidAddressMissingHouseNumber;
        }

        const hasStreetName = /[A-Za-záÁéÉőŐűŰúÚóÓüÜöÖíÍ]/.test(trimmedAddress);
        if (!hasStreetName) {
            return ValidatorMessage.invalidAddressMissingStreetName;
        }

        return undefined;
    }

    public static passengerName(name: string): ValidatorMessage | undefined {
        return Validator.accountName(name);
    }

    public static passengerAdultDateOfBirth(dateOfBirthString: string | null): ValidatorMessage | undefined {
        const requiredError = Validator.required(dateOfBirthString);
        if (requiredError) {
            return requiredError;
        }
        const dateOfBirth = DateUtils.parse(dateOfBirthString!);
        const departureDate = DateUtils.startOfDay(DateUtils.parse(store.getState().session.travelOffer.departure));
        const firstValidDay = ValidatorConstants.passengerAdultBirthMin(departureDate);
        if (DateUtils.isAfter(dateOfBirth!, firstValidDay)) {
            return ValidatorMessage.passengerTooYoung;
        } else if (DateUtils.isBefore(dateOfBirth!, ValidatorConstants.passengerAdultBirthMax(departureDate))) {
            return ValidatorMessage.passengerTooOld;
        }
        return undefined;
    }

    public static passengerChildDateOfBirth(dateOfBirthString: string | null): ValidatorMessage | undefined {
        const requiredError = Validator.required(dateOfBirthString);
        if (requiredError) {
            return requiredError;
        }
        const dateOfBirth = DateUtils.parse(dateOfBirthString!);
        const departureDate = DateUtils.startOfDay(DateUtils.parse(store.getState().session.travelOffer.departure));
        const firstValidDay = ValidatorConstants.passengerChildBirthMax(departureDate);
        if (DateUtils.isBefore(dateOfBirth!, firstValidDay)) {
            return ValidatorMessage.passengerTooOld;
        }
        return undefined;
    }

    public static couponCode(code: string) {
        return !!code.match(/^[a-zA-Z0-9]+$/);
    }
}

export class ValidatorConstants {
    public static travelDepartureDateMin(): Date {
        return DateUtils.startOfToday();
    }

    public static travelDepartureDateMax(): Date {
        return DateUtils.lastDayOfMonth(DateUtils.addMonths(DateUtils.startOfToday(), 11));
    }

    public static travelReturnDateMin(departureDate: Date): Date {
        return departureDate;
    }

    public static travelReturnDateMax(departureDate: Date): Date {
        return DateUtils.addDays(departureDate, BackendEnvironmentUtils.travelInsuranceMaxDays() - 1);
    }

    public static travelMaxMemberCount(): number {
        return BackendEnvironmentUtils.travelInsuranceMaxMembers();
    }

    public static accountDateOfBirthMin(): Date {
        return DateUtils.subYears(DateUtils.startOfToday(), BackendEnvironmentUtils.accountMinAge());
    }

    public static accountDateOfBirthMax(): Date {
        return DateUtils.subYears(DateUtils.startOfToday(), 100);
    }

    public static passengerChildBirthMin(): Date {
        return DateUtils.startOfToday();
    }

    public static passengerChildBirthMax(departureDate: Date): Date {
        return DateUtils.addDays(DateUtils.subYears(departureDate, 18), 1);
    }

    public static passengerAdultBirthMin(departureDate: Date): Date {
        return DateUtils.subYears(departureDate, 18);
    }

    public static passengerAdultBirthMax(departureDate: Date): Date {
        return DateUtils.addDays(DateUtils.subYears(departureDate, 76), 1);
    }

    public static readonly cityMinLength = 2;

    public static readonly maxSmsResendPerPhoneNumber = 5;
}
