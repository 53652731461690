import React from "react";
import styled from "styled-components";
import { Button } from "@components/Button";
import { Flex } from "@components/Flex";
import SvgIcon32Minus from "@components/svg/Icon32Minus";
import SvgIcon32Plus from "@components/svg/Icon32Plus";

type NumberSpinnerProps = {
    min?: number;
    max: number;
    step: number;
    value: number;
    id?: string;
    inputLabel?: string;
    inputLabelledby?: string;
    onValueChange: (newValue: number) => void;
};

export const NumberSpinner = ({ min, max, step, value, id, inputLabel, inputLabelledby, onValueChange }: NumberSpinnerProps) => {
    const minCondition = value - step >= (min !== undefined ? min : 0);
    const maxCondition = value + step <= max;

    return (
        <Flex.Container $gutterMargin={8}>
            <Flex.Item $shrink="shrink" $gutterMargin={8}>
                <Button.Secondary
                    btnLabel="Csökkent"
                    btnSize={32}
                    icon={<SvgIcon32Minus />}
                    isCircle
                    disabled={!minCondition}
                    onClick={() => onValueChange(minCondition ? value - step : 0)}
                />
            </Flex.Item>

            <Flex.Item $shrink="auto" $gutterMargin={8}>
                <StyledInput id={id} type="text" inputMode="numeric" aria-label={inputLabel} aria-labelledby={inputLabelledby} value={value} readOnly />
            </Flex.Item>

            <Flex.Item $shrink="shrink" $gutterMargin={8}>
                <Button.Secondary
                    btnLabel="Növel"
                    btnSize={32}
                    icon={<SvgIcon32Plus />}
                    isCircle
                    disabled={!maxCondition}
                    onClick={() => onValueChange(maxCondition ? value + step : max)}
                />
            </Flex.Item>
        </Flex.Container>
    );
};

const StyledInput = styled.input`
    background-clip: padding-box;
    background-color: transparent;
    border: 0;
    color: ${({ theme }) => theme.color.blueD};
    display: block;
    font-size: ${({ theme }) => theme.typo.body.fontSize}px;
    font-weight: 700;
    height: 32px;
    line-height: ${({ theme }) => theme.typo.body.lineHeight}px;
    padding: 0;
    text-align: center;
    width: 32px;

    &:disabled {
        color: ${({ theme }) => theme.color.greyD};
    }
`;
