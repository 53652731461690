import React, { useCallback, useMemo } from "react";
import { Button } from "@components/Button";
import { Card } from "@components/Card";
import { Flex } from "@components/Flex";
import { Input } from "@components/Input";
import { SeparatorDecoratorContainer, Box } from "@components/LayoutElements";
import { Popup } from "@components/Popup";
import SvgIcon32Coupon from "@components/svg/Icon32Coupon";
import { Text } from "@components/Text";
import { Tooltip } from "@components/Tooltip";
import { Loctool, LoctoolMessage } from "@i18n/i18n";
import { useForm } from "react-hook-form";
import { GraphQLClientError } from "@api/graphql/GraphQLClient";
import { useDispatch, useSelector } from "react-redux";
import { Api, Gql } from "@api/Api";
import { ApplicationState } from "@redux/reducers";
import { useTheme } from "styled-components";
import SvgIcon32X from "@components/svg/Icon32X";
import { FormHelpers } from "@utils/FormHelpers";
import { ContractActions } from "@redux/actions/contractActions";
import { Validator } from "@utils/Validator";

type Inputs = {
    couponCode: string;
};

export const CouponInput = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const contract = useSelector((state: ApplicationState) => state.contract);
    const appliedCoupons = contract?.pricing?.couponApplied ?? [];
    const {
        watch,
        register,
        handleSubmit,
        setError,
        formState: { errors, isSubmitting, isValid },
        setValue,
    } = useForm<Inputs>({
        defaultValues: {
            couponCode: appliedCoupons.length > 0 ? appliedCoupons[0].couponCode : "",
        },
        mode: "onChange",
    });
    const couponCode = watch("couponCode");
    const isFormatCorrect = Validator.couponCode(couponCode);

    const couponError = useMemo(() => FormHelpers.getError(errors.couponCode), [errors.couponCode]);

    const updateCouponCode = useCallback(
        async (couponCode: string | null) => {
            if (!contract) {
                return;
            }
            if (couponCode === null) {
                setValue("couponCode", "");
            }
            try {
                const result = await Api.updateCouponCode(contract.id, {
                    address: contract.accountData.address,
                    city: contract.accountData.city,
                    countryCode: contract.accountData.countryCode,
                    dateOfBirth: contract.accountData.dateOfBirth,
                    destinationCategoryId: contract.travelData.destinationCategoryId,
                    firstDayOfTravel: contract.travelData.firstDayOfTravel,
                    languageCode: contract.accountData.languageCode,
                    lastDayOfTravel: contract.travelData.lastDayOfTravel,
                    name: {
                        firstName: contract.accountData.name.firstName,
                        lastName: contract.accountData.name.lastName,
                    },
                    travelMembers: contract.travelData.travelMembers.map((travelMember): Gql.TravelMemberInputWL => {
                        return {
                            id: travelMember.id,
                            firstName: travelMember.firstName,
                            lastName: travelMember.lastName,
                            dateOfBirth: travelMember.dateOfBirth,
                        };
                    }),
                    zipCode: contract.accountData.zipCode,
                    couponCode: couponCode || undefined,
                });
                dispatch(ContractActions.update(result));
            } catch (error) {
                if (error instanceof GraphQLClientError) {
                    setError("couponCode", { type: "api", message: error.message });
                }
            }
        },
        [contract, dispatch, setError, setValue]
    );

    const onSubmit = useCallback(
        async (data: Inputs) => {
            await updateCouponCode(data.couponCode);
        },
        [updateCouponCode]
    );

    return (
        <Card.Wrapper as={SeparatorDecoratorContainer} $style={{ marginTop: 16 }}>
            <Text as="h2" $fontSize="text16" $style={{ margin: 0, padding: "12px 56px 12px 16px" }}>
                <Flex.Container as="span" $alignItems="center" $gutterMargin={16}>
                    <Flex.Item as="span" $shrink="shrink" $gutterMargin={16}>
                        <SvgIcon32Coupon />
                    </Flex.Item>

                    <Flex.Item as="span" id="coupon-heading" $shrink="auto" $gutterMargin={16}>
                        <LoctoolMessage id="page.paymentSummary.coupon.title" />
                    </Flex.Item>
                </Flex.Container>
            </Text>

            {appliedCoupons.length > 0 ? (
                <Box $style={{ padding: 16 }}>
                    <Flex.Container as="dl" $gutterMargin={8} $style={{ margin: 0 }}>
                        <Flex.Item as="dt" $shrink="auto" $gutterMargin={8}>
                            {Loctool.formatMessage({ id: "page.paymentSummary.coupon.applied" })}
                        </Flex.Item>

                        <Flex.Item as="dd" $shrink="shrink" $gutterMargin={8}>
                            <Box $style={{ color: theme.color.greenN, fontWeight: 700, textAlign: "right" }}>-{appliedCoupons[0].discountPercent}%</Box>
                        </Flex.Item>
                    </Flex.Container>

                    <Box $style={{ position: "absolute", top: 12, right: 16 }}>
                        <Button.TextBlueD
                            btnLabel={Loctool.formatMessage({ id: "page.paymentSummary.coupon.remove" })}
                            icon={<SvgIcon32X />}
                            isCircle
                            btnSize={32}
                            onClick={() => {
                                updateCouponCode(null);
                            }}
                        />
                    </Box>
                </Box>
            ) : (
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Box $style={{ padding: "8px 16px" }}>
                        <Flex.Container $alignItems="center" $gutterMargin={8}>
                            <Flex.Item as="label" id="i-code-label" htmlFor="i-code" $shrink={112} $gutterMargin={8}>
                                <LoctoolMessage id="page.paymentSummary.coupon.form.code.label" />
                            </Flex.Item>

                            <Flex.Item $shrink="auto" $gutterMargin={8}>
                                <Input
                                    id="i-code"
                                    placeholder={Loctool.formatMessage({ id: "page.paymentSummary.coupon.form.code.placeholder" })}
                                    aria-describedby="i-code-error"
                                    autoComplete="off"
                                    $hasError={!!errors.couponCode}
                                    {...register("couponCode")}
                                />
                            </Flex.Item>

                            <Box $style={{ position: "absolute", top: 12, right: 16 }}>
                                <Popup
                                    arrowElement={<Tooltip.Arrow />}
                                    toggleElement={
                                        // Note: btnLabel="", but aria-labelledby not empty string
                                        <Button.HollowBlueD
                                            btnLabel=""
                                            aria-labelledby="coupon-heading"
                                            icon={
                                                <Text as="span" $fontSize="text16" $style={{ display: "block" }}>
                                                    ?
                                                </Text>
                                            }
                                            isCircle
                                            btnSize={32}
                                        />
                                    }
                                    popperOptions={{
                                        placement: "bottom-end",
                                        modifiers: [
                                            {
                                                name: "offset",
                                                options: {
                                                    offset: [24, 0],
                                                },
                                            },
                                        ],
                                    }}
                                >
                                    <Tooltip.Container>
                                        <LoctoolMessage id="page.paymentSummary.coupon.form.tooltip" />
                                    </Tooltip.Container>
                                </Popup>
                            </Box>
                        </Flex.Container>

                        <Box id="i-code-error" aria-live="assertive" $style={couponError ? { paddingTop: 12, color: theme.color.redN } : {}}>
                            {couponError}
                        </Box>
                    </Box>

                    <Box $style={{ padding: 16 }}>
                        <Box $style={{ maxWidth: 288, margin: "0 auto" }} $styleMedium={{ maxWidth: 248 }}>
                            <Button.Primary
                                type="submit"
                                btnLabel={<LoctoolMessage id="page.paymentSummary.coupon.submit" />}
                                isExpanded
                                disabled={isSubmitting || !isFormatCorrect || !isValid}
                            />
                        </Box>
                    </Box>
                </form>
            )}
        </Card.Wrapper>
    );
};
