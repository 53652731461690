import * as React from "react";
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgIcon32Wheelchair({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg width={32} height={32} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" focusable={false} aria-hidden={true} aria-labelledby={titleId} {...props}>
            {title ? <title id={titleId}>{title}</title> : null}
            <g fill="none" fillRule="evenodd">
                <path stroke="currentColor" d="M7.5 9.5h15v11h-15z" />
                <circle stroke="currentColor" fill="#FFF" cx={10} cy={20} r={7.5} />
                <path fill="currentColor" d="M7 4h1v6H7zM25 20h1v6h-1z" />
                <path fill="currentColor" d="M8 4v1H2V4zM30 26v1h-5v-1zM26 20v1h-6v-1z" />
            </g>
        </svg>
    );
}

export default SvgIcon32Wheelchair;
