import React from "react";
import styled from "styled-components";
import { Theme } from "@style/Theme";
import { Box } from "@components/LayoutElements";
import { Flex } from "@components/Flex";
import { Text } from "@components/Text";
import { withRouter, RouteComponentProps } from "react-router";

export enum ButtonVariant {
    primary = "primary",
    secondary = "secondary",
    hollow = "hollow",
    text = "text",
}

type ButtonColor = {
    background?: keyof typeof Theme.color;
    borderColor?: keyof typeof Theme.color;
    borderColorOverlay?: keyof typeof Theme.color;
    labelColor: keyof typeof Theme.color;
};

type ButtonProps = {
    btnLabel: string | JSX.Element;
    color: ButtonColor;
    activeColor?: ButtonColor;
    disabledColor?: ButtonColor;
    variant: ButtonVariant;
    icon?: React.ReactElement;
    iconPosition?: "left" | "right";
    isCircle?: boolean;
    isExpanded?: boolean;
    btnSize?: 48 | 40 | 32;
} & React.ButtonHTMLAttributes<HTMLButtonElement> &
    React.AnchorHTMLAttributes<HTMLAnchorElement>;

class ButtonComponentClass extends React.Component<ButtonProps & RouteComponentProps> {
    renderButtonContent = () => {
        const { btnLabel, icon, iconPosition, variant } = this.props;

        const ButtonTextComponent = (
            <Text as="span" $fontSize="text16" $style={{ display: "block" }}>
                {btnLabel}

                {/* Note: placeholder if fontWeight needed to be changed in CSS pseudo-classes. (Ex. :hover) */}
                {variant === ButtonVariant.text && !this.props.href && (
                    <Box as="span" aria-hidden="true" $style={{ fontWeight: 700, display: "block", height: 0, overflow: "hidden" }}>
                        {btnLabel}
                    </Box>
                )}
            </Text>
        );

        if (icon) {
            if (!iconPosition) {
                return (
                    <Box as="span" $style={{ display: "block" }}>
                        <span className="show-for-sr">{btnLabel}</span>

                        {icon}
                    </Box>
                );
            }

            if (iconPosition === "right") {
                return (
                    <Flex.Container as="span" $alignItems="center" $style={{ paddingLeft: 24, paddingRight: 16 }}>
                        <Flex.Item as="span" $shrink="auto" $style={{ textAlign: "right" }}>
                            {ButtonTextComponent}
                        </Flex.Item>

                        <Flex.Item as="span" $shrink="shrink" $style={{ marginLeft: 4 }}>
                            {icon}
                        </Flex.Item>
                    </Flex.Container>
                );
            }

            return (
                <Flex.Container as="span" $alignItems="center" $style={{ paddingLeft: 16, paddingRight: 24 }}>
                    <Flex.Item as="span" $shrink="shrink">
                        {icon}
                    </Flex.Item>

                    <Flex.Item as="span" $shrink="auto" $style={{ marginLeft: 4, textAlign: "left" }}>
                        {ButtonTextComponent}
                    </Flex.Item>
                </Flex.Container>
            );
        }

        return ButtonTextComponent;
    };

    private onClick = (e: React.MouseEvent<HTMLAnchorElement>): void => {
        this.props.onClick && this.props.onClick(e);
        if (this.props.href) {
            e.preventDefault();
            if (this.props.target === "_blank") {
                window.open(this.props.href, this.props.target);
            } else {
                this.props.history.push(this.props.href);
            }
        }
    };

    render() {
        const { color, activeColor, disabledColor, icon, iconPosition, isCircle, isExpanded, btnSize, variant, ...props } = this.props;
        return (
            <StyledButton
                $color={color}
                $activeColor={activeColor}
                $disabledColor={disabledColor}
                $hasIcon={!!icon}
                $isIconOnly={!!icon && !iconPosition}
                $isCircle={!!isCircle}
                $isDisabled={!!props.disabled}
                $isExpanded={!!isExpanded}
                $btnSize={btnSize}
                $variant={variant}
                as={props.href ? "a" : undefined}
                type={props.href ? undefined : props.type ?? "button"}
                {...props}
                onClick={this.onClick}
            >
                <ButtonContentWrapper>{this.renderButtonContent()}</ButtonContentWrapper>
            </StyledButton>
        );
    }
}

const ButtonComponent = withRouter(ButtonComponentClass);

const ButtonContentWrapper = styled.span`
    /* Note: fontSize and lineHeight overdefined in order to prevent unintended inheritance. */
    font-size: ${props => (typeof props.theme.typo.body.fontSize === "number" ? `${props.theme.typo.body.fontSize}px` : props.theme.typo.body.fontSize)};
    line-height: 1;
    position: relative;
    user-select: none;
    z-index: 1;
`;

const StyledButton = styled.button<{
    $color: ButtonColor;
    $activeColor?: ButtonColor;
    $disabledColor?: ButtonColor;
    $hasIcon: boolean;
    $isIconOnly: boolean;
    $isCircle: boolean;
    $isExpanded: boolean;
    $isDisabled: boolean;
    $btnSize?: number;
    $variant: ButtonVariant;
}>`
    align-items: center;
    background-color: ${({ theme, $color }) => ($color.background ? theme.color[$color.background] : "")};
    border-radius: ${({ $isCircle }) => ($isCircle ? "50%" : "2px")};
    box-shadow: ${({ theme, $color }) => ($color.borderColor ? `inset 0px 0px 0px 1px ${theme.color[$color.borderColor]}` : "")};
    color: ${({ theme, $color }) => theme.color[$color.labelColor]};
    cursor: pointer;
    display: inline-flex;
    height: ${({ $btnSize }) => ($btnSize ? `${$btnSize}px` : "40px")};
    justify-content: center;
    overflow: hidden;
    position: relative;
    text-decoration: none;
    vertical-align: bottom;
    z-index: 1;

    &::before {
        border-radius: inherit;
        bottom: 0;
        left: 0;
        pointer-events: none;
        position: absolute;
        right: 0;
        top: 0;
    }

    ${p =>
        p.$isExpanded
            ? `
        display: flex;
        width: 100%;
    `
            : ""}

    ${p =>
        p.$hasIcon
            ? `
            min-width: ${p.$btnSize ? `${p.$btnSize}px` : "40px"};
            `
            : `
        padding-left: 24px;
        padding-right: 24px;
            `}

    &:active,
    &:hover {
        background-color: ${({ theme, $activeColor }) => ($activeColor ? theme.color[$activeColor.background] : "")};
        color: ${({ theme, $activeColor }) => ($activeColor ? theme.color[$activeColor.labelColor] : "")};

        ${({ theme, $activeColor }) =>
            $activeColor?.borderColorOverlay
                ? `
            &::before {
                box-shadow: inset 0px 0px 0px 3px ${theme.color[$activeColor.borderColorOverlay]};
                content: "";
            }
        `
                : ""}

        ${({ $variant }) =>
            $variant === ButtonVariant.text
                ? `
            ${Text} {
                font-weight: 700;
            }

            a& {
                ${Text} {
                    font-weight: inherit;
                }
            }
        `
                : ""}
    }

    &:disabled {
        ${p =>
            p.$isDisabled
                ? `
        cursor: not-allowed;

        ${Text} {
            font-weight: inherit;
        }

        &::before {
            content: normal;
        }

        ${
            p.$disabledColor
                ? `
        background-color: ${p.$disabledColor.background ? p.theme.color[p.$disabledColor.background] : p.theme.color.greyL};
        box-shadow: ${p.$disabledColor.borderColor ? `inset 0px 0px 0px 1px ${p.theme.color[p.$disabledColor.borderColor]}` : ""};
        color: ${p.theme.color[p.$disabledColor.labelColor]};
                `
                : `
        background-color: ${p.theme.color.greyL};
        color: ${p.theme.color.greyN};
        `
        }
    `
                : ""}
    }
`;

const PrimaryButton = styled(ButtonComponent).attrs(() => ({
    variant: ButtonVariant.primary,
    color: { background: "greenN", labelColor: "white" },
    activeColor: { background: "customSpray", borderColorOverlay: "greenN", labelColor: "blueD" },
}))``;

const SecondaryButton = styled(ButtonComponent).attrs(() => ({
    variant: ButtonVariant.secondary,
    color: { background: "blueN", labelColor: "white" },
    activeColor: { background: "white", borderColorOverlay: "greyD", labelColor: "blueD" },
    disabledColor: { borderColor: "greyN", labelColor: "greyN" },
}))``;

const TextButton = styled(ButtonComponent).attrs(() => ({
    variant: ButtonVariant.text,
    color: { labelColor: "blueN" },
    disabledColor: { borderColor: "greyN", labelColor: "greyN" },
}))``;

const TextBlueDButton = styled(ButtonComponent).attrs(() => ({
    variant: ButtonVariant.text,
    color: { labelColor: "blueD" },
    disabledColor: { borderColor: "greyN", labelColor: "greyN" },
}))``;

const HollowButton = styled(ButtonComponent).attrs(() => ({
    variant: ButtonVariant.hollow,
    color: { borderColor: "greyN", labelColor: "blueN" },
    activeColor: { borderColorOverlay: "greyD", labelColor: "blueD" },
    disabledColor: { labelColor: "greyN" },
}))``;

const HollowBlueDButton = styled(ButtonComponent).attrs(() => ({
    variant: ButtonVariant.hollow,
    color: { borderColor: "greyN", labelColor: "blueD" },
    activeColor: { borderColorOverlay: "greyD" },
    disabledColor: { labelColor: "greyN" },
}))``;

export const Button = {
    Component: ButtonComponent,
    Primary: PrimaryButton,
    Secondary: SecondaryButton,
    Text: TextButton,
    TextBlueD: TextBlueDButton,
    Hollow: HollowButton,
    HollowBlueD: HollowBlueDButton,
    Styled: StyledButton,
    ButtonContentWrapper,
};
