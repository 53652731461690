/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApolloClient, ApolloQueryResult, ApolloError, QueryOptions, MutationOptions } from "apollo-client";
import { ApolloLink } from "apollo-link";
import { createHttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import { onError, ErrorResponse } from "apollo-link-error";
import { FetchResult } from "@apollo/client";
import { Loctool } from "@i18n/Loctool";

const httpLink = createHttpLink({ uri: `${process.env.REACT_APP_API_URL || ""}` });

const errorLink = onError((_: ErrorResponse) => {
    return;
});

const operationHeaderLink = new ApolloLink((operation: any, forward: any) => {
    operation.setContext({
        headers: {
            ...operation.getContext().headers,
            "X-GQLOperation": operation.operationName,
        },
    });

    return forward(operation);
});

const parseErrorMessageFromGraphQLError = (gqlError: string) => {
    const regex = /(\[.*\]\s*)?(.+)/;
    const result = regex.exec(gqlError);
    if (result === null || result.length !== 3 || typeof result[2] === "undefined") {
        return gqlError;
    }
    return result[2];
};

export class GraphQLClientError extends Error {
    code: number;
    intlMessage: string;
    // tslint:disable-next-line: variable-name
    __proto__: GraphQLClientError;

    constructor(code: number, message: string) {
        super(message);
        this.name = this.constructor.name;
        this.code = code;
        this.constructor = GraphQLClientError;
        this.__proto__ = GraphQLClientError.prototype;
        if (typeof Error.captureStackTrace === "function") {
            Error.captureStackTrace(this, this.constructor);
        } else {
            this.stack = new Error(message).stack;
        }
        this.message = message;
        this.intlMessage = Loctool.formatMessage({ id: `errors.api.${message}`, defaultMessage: Loctool.formatMessage({ id: "errors.api" }) });
    }
}

class GraphQLClient {
    static isFirstRequest = true;

    static client = new ApolloClient({
        link: errorLink.concat(operationHeaderLink).concat(httpLink),
        cache: new InMemoryCache(),
        defaultOptions: {
            watchQuery: {
                fetchPolicy: "no-cache",
                errorPolicy: "ignore",
            },
            query: {
                fetchPolicy: "no-cache",
                errorPolicy: "all",
            },
        },
    });

    static async mutate<R, V = {}>(options: MutationOptions<R, V>): Promise<R> {
        try {
            const response = await GraphQLClient.client.mutate<R, V>(options);
            return GraphQLClient.getResult<R>(response);
        } catch (error) {
            if (error instanceof GraphQLClientError) {
                throw error;
            }
            throw GraphQLClient.handleErrors(error as Error);
        }
    }

    static async query<R, V = {}>(options: QueryOptions<V>): Promise<R> {
        try {
            const response: ApolloQueryResult<R> = await GraphQLClient.client.query<R>(options);
            return GraphQLClient.getResult<R>(response);
        } catch (error) {
            if (error instanceof GraphQLClientError) {
                throw error;
            }
            throw GraphQLClient.handleErrors(error as Error);
        }
    }

    private static getResult<R>(response: ApolloQueryResult<R> | FetchResult<R> | any): R {
        if (response.errors && response.errors.length > 0) {
            throw GraphQLClient.handleErrors(response.errors[0]);
        }

        return response.data;
    }

    /**
     * Handles GraphQL client errors
     * @param {*} error Error
     */
    private static handleErrors(error: Error): Error {
        if (error instanceof ApolloError && error.graphQLErrors?.length > 0) {
            return new GraphQLClientError(400, error.graphQLErrors[0].message ? parseErrorMessageFromGraphQLError(error.graphQLErrors[0].message) : "unknown");
        } else if (typeof error === "object" && error.message) {
            return new GraphQLClientError(400, parseErrorMessageFromGraphQLError(error.message));
        }
        return error;
    }
}

export default GraphQLClient;
