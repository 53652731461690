import { Api } from "@api/Api";
import * as DateFns from "date-fns";

class ServerDateClass {
    private lastServerDate = 0;
    private lastSyncDate = 0;
    private syncDelay: number = 10 * 60 * 1000;
    private syncInterval: number | null = null;
    private syncing = false;
    private debugDateSet: number | null = null;
    private debugDateSetAt: number | null = null;

    public startSync = () => {
        this.syncInterval = window.setInterval(this.syncDateFromServer, this.syncDelay);
    };

    public stopSync = () => {
        if (this.syncInterval) {
            clearInterval(this.syncInterval);
        }
    };

    public setDebugDate = (dateString: string | null) => {
        if (dateString !== null) {
            this.debugDateSet = DateFns.parseISO(dateString).getTime();
            this.debugDateSetAt = Date.now();
            if (typeof window.appRef !== "undefined" && window.appRef !== null) {
                window.appRef.setState({ currentKey: window.appRef.state.currentKey + 1 });
            }
            return;
        }
        this.debugDateSet = null;
        this.debugDateSetAt = null;
    };

    public clearDebugDate = () => {
        this.debugDateSet = null;
        this.debugDateSetAt = null;
        if (typeof window.appRef !== "undefined" && window.appRef !== null) {
            window.appRef.setState({ currentKey: window.appRef.state.currentKey + 1 });
        }
    };

    public syncDateFromServer = async () => {
        if (this.syncing) {
            console.debug("Server date already being synced...");
            return;
        }
        this.syncing = true;
        Api.getServerTime()
            .then((time: string) => {
                const parsedDate = DateFns.parseISO(time);
                this.syncing = false;
                console.debug(`Server date synced: ${parsedDate}`);
                this.setServerDate(parsedDate);
            })
            .catch(error => {
                this.syncing = false;
                console.debug("Error syncing server time", error);
            });
    };

    private setServerDate(serverDate: Date) {
        if (!DateFns.isValid(serverDate)) {
            console.debug("Invalid server date sent: ", serverDate);
            return;
        }
        this.lastServerDate = serverDate.getTime();
        this.lastSyncDate = new Date().getTime();
    }

    public getDate(): Date {
        if (process.env.REACT_APP_FEATURE_TIME_TRAVEL_ENABLED === "true" && this.debugDateSet !== null && this.debugDateSetAt !== null) {
            const diff = Date.now() - (this.debugDateSetAt || 0);
            const date = new Date(this.debugDateSet + diff);
            console.debug("Returning DEBUG date - " + DateFns.format(date, "yyyy. MM. dd. HH:mm:ss"));
            return date;
        }
        if (this.lastServerDate === 0 || this.lastSyncDate === 0) {
            console.debug("Server date not synced, returning client date");
            return new Date();
        }
        if (Date.now() - this.lastSyncDate > this.syncDelay) {
            console.debug("Server date out of sync");
            this.syncDateFromServer();
            return new Date();
        }
        const diff = Date.now() - this.lastSyncDate;
        return new Date(this.lastServerDate + diff);
    }
}

export const ServerDate = new ServerDateClass();
