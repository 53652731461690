import { LegalDocument } from "@api/graphql/types";

export class Helpers {
    public static getLegalDocumentPdfLink(legalDocument: LegalDocument | null): string {
        if (!legalDocument?.url) {
            console.warn(`File url not available for ${legalDocument?.category} legal document`);
            return window.location.href;
        }
        const fileUrl = encodeURIComponent(`${process.env.REACT_APP_DOCUMENT_URL_BASE}${legalDocument.url}`);
        return `${process.env.REACT_APP_PUBLIC_URL ? `${process.env.REACT_APP_PUBLIC_URL}/pdfviewer/` : "/pdfviewer/"}?file=${fileUrl}`;
    }

    public static getLocationParameterByName(name: string, url: string): string | null {
        name = name.replace(/[[]]/g, "\\$&");
        const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
        const results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return "";
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }

    public static formatPhoneNumber(prefix: string, phoneNumber: string) {
        return `${prefix}${phoneNumber}`;
    }
}
