import styled from "styled-components";
import { bp, BreakPoint } from "@style/Theme";
import { Box } from "@components/LayoutElements";
import { Flex } from "@components/Flex";

export const FlexToTable = styled(Box)<{ $bp: keyof typeof BreakPoint }>`
    ${({ $bp }) => `
        ${bp[$bp]} {
            th${Flex.Item},
            td${Flex.Item} {
                display: table-cell;
            }

            tr${Flex.Container} {
                display: table-row;
            }
        }
    `}
`;
