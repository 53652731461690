/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import Downshift, { ControllerStateAndHelpers, DownshiftState, StateChangeOptions } from "downshift";
import { Input, InputStyles } from "@components/Input";
import styled from "styled-components";
import { Box, BoxProps } from "@components/LayoutElements";
import { Flex } from "@components/Flex";
import { LoctoolMessage } from "@i18n/i18n";

export interface AutocompleteItem {
    title: string;
    titleInfo?: React.ReactNode;
    value: string;
}

interface Props {
    id: string;
    items: AutocompleteItem[];
    value?: string;
    name: string;
    defaultValue?: string;
    placeholder: string;
    onChange: (event: { target: { name: string; value: string } }) => void;
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
    maxLength?: number;
    textOnly?: boolean;
    variant?: "select";
    selectListWrapperBoxProps?: BoxProps;
    className?: string;
}

class AutocompleteInput extends React.Component<Props> {
    public focus = () => {
        const inputEl = document.getElementById(this.props.id);
        if (inputEl) {
            // @ts-ignore
            const temp = inputEl.value;
            // @ts-ignore
            inputEl.value = "";
            // @ts-ignore
            inputEl.value = temp;
            document.getElementById(this.props.id)?.focus();
        }
    };

    public get name() {
        return this.props.name;
    }

    private stateReducer = (state: DownshiftState<any>, changes: StateChangeOptions<any>): Partial<StateChangeOptions<any>> => {
        switch (changes.type) {
            case Downshift.stateChangeTypes.mouseUp:
            case Downshift.stateChangeTypes.blurInput:
            case Downshift.stateChangeTypes.touchEnd:
                return { ...changes, inputValue: state.inputValue };
            default:
                return { ...changes };
        }
    };

    public render() {
        const props = this.props;
        const initialHighlightedIndex = props.items.findIndex(i => i.value === props.value);
        return (
            <Downshift
                onChange={selectedItem => {
                    if (selectedItem) {
                        props.onChange({ target: { name: props.name, value: selectedItem.value } });
                    } else {
                        console.warn("Autocomplete selectedItem is null");
                    }
                }}
                itemToString={(item: AutocompleteItem | null) => (item ? item.value : "")}
                onInputValueChange={(inputValue: string, downShiftState: ControllerStateAndHelpers<AutocompleteItem>) => {
                    // NOTE: in prod build type is a number - __autocomplete_blur_button__ is 14
                    if (!["__autocomplete_mouseup__", "__autocomplete_blur_button__", 14].includes((downShiftState as any).type)) {
                        props.onChange({ target: { name: props.name, value: props.textOnly ? inputValue.replace(/\d/g, "") : inputValue } });
                    }
                }}
                inputValue={props.value}
                initialInputValue={props.defaultValue}
                initialHighlightedIndex={initialHighlightedIndex}
                defaultHighlightedIndex={initialHighlightedIndex}
                stateReducer={this.stateReducer}
                id={props.id}
            >
                {({
                    getInputProps,
                    getItemProps,
                    getMenuProps,
                    isOpen,
                    inputValue,
                    highlightedIndex,
                    selectedItem,
                    openMenu,
                    getRootProps,
                    getToggleButtonProps,
                }: ControllerStateAndHelpers<AutocompleteItem>) => {
                    const { ref: innerRef, ...inputProps } = getInputProps({});
                    const currentValue = selectedItem || props.items.find(i => i.title.toLowerCase() === (inputValue ?? "").toLowerCase());
                    const filteredItems =
                        props.variant === "select"
                            ? props.items
                            : props.items.filter((item: AutocompleteItem): boolean => item.title.toLowerCase().indexOf((inputValue ?? "").toLowerCase()) === 0);

                    return (
                        <div {...getRootProps(undefined, { suppressRefError: true })}>
                            {props.variant === "select" ? (
                                <>
                                    <InputStyles.Select
                                        as="button"
                                        type="button"
                                        {...getToggleButtonProps()}
                                        onFocus={() => {
                                            !isOpen && openMenu();
                                        }}
                                        onClick={e => {
                                            e.stopPropagation();
                                        }}
                                    >
                                        {currentValue ? currentValue.title : <LoctoolMessage id="common.pleaseSelect" />}
                                    </InputStyles.Select>

                                    <input
                                        ref={innerRef as any}
                                        {...inputProps}
                                        id={props.id}
                                        className={`show-for-sr ${props.className ?? ""}`}
                                        placeholder={props.placeholder}
                                        defaultValue={props.defaultValue}
                                        maxLength={props.maxLength}
                                        readOnly
                                        tabIndex={-1}
                                    />
                                </>
                            ) : (
                                <Input
                                    ref={innerRef as any}
                                    {...inputProps}
                                    id={props.id}
                                    onBlur={e => {
                                        if (inputProps.onBlur) {
                                            inputProps.onBlur(e);
                                        }
                                        if (props.onBlur) {
                                            props.onBlur(e);
                                        }
                                    }}
                                    placeholder={props.placeholder}
                                    defaultValue={props.defaultValue}
                                    maxLength={props.maxLength}
                                    className={`${props.className ?? ""} ${inputProps.className ?? ""}`}
                                />
                            )}

                            {isOpen && filteredItems.length > 0 && (
                                <DownShiftSelectListTransformWrapper>
                                    <Box
                                        $style={{
                                            overflow: "hidden",
                                            borderRadius: 2,
                                            minWidth: 224,
                                            position: "absolute",
                                            top: "100%",
                                            ...this.props.selectListWrapperBoxProps?.$style,
                                        }}
                                        $styleSmall={{
                                            ...this.props.selectListWrapperBoxProps?.$styleSmall,
                                        }}
                                        $styleMedium={{
                                            ...this.props.selectListWrapperBoxProps?.$styleMedium,
                                        }}
                                    >
                                        <DownShiftSelectList {...getMenuProps()}>
                                            {filteredItems.map((item, index) => (
                                                <DownShiftSelectListElement
                                                    key={item.value}
                                                    highlighted={highlightedIndex === index}
                                                    selected={item.value === this.props.value}
                                                    {...getItemProps({
                                                        key: item.title,
                                                        index,
                                                        item,
                                                    })}
                                                >
                                                    {item.titleInfo ? (
                                                        <Flex.Container>
                                                            <Flex.Item $shrink={44}>{item.value}</Flex.Item>
                                                            <Flex.Item $shrink="auto">
                                                                <Box $style={{ fontWeight: 400 }}>{item.titleInfo}</Box>
                                                            </Flex.Item>
                                                        </Flex.Container>
                                                    ) : (
                                                        item.value
                                                    )}
                                                </DownShiftSelectListElement>
                                            ))}
                                        </DownShiftSelectList>
                                    </Box>
                                </DownShiftSelectListTransformWrapper>
                            )}
                        </div>
                    );
                }}
            </Downshift>
        );
    }
}

const DownShiftSelectListTransformWrapper = styled.div`
    position: relative;
    z-index: 2;
`;

const DownShiftSelectList = styled.ul`
    background-color: ${({ theme }) => theme.color.white};
    border-radius: 2px;
    border: 1px solid ${({ theme }) => theme.color.greyN};
    list-style-type: none;
    margin-bottom: 0;
    margin-top: 0;
    /* Note: maxHeight === heights of listelements + borderTopWidth + borderBottomWidth */
    max-height: ${4 * 56 + 2}px;
    overflow: auto;
    padding-left: 0;
`;

const DownShiftSelectListElement = styled.li<{ highlighted?: boolean; selected?: boolean }>`
    background-color: ${props => (props.highlighted ? props.theme.color.greyL : "")};
    box-shadow: inset 0 -1px 0 0 ${({ theme }) => theme.color.greyN};
    cursor: pointer;
    font-weight: ${props => (props.selected ? 700 : 400)};
    padding: 16px;
`;

export default AutocompleteInput;
