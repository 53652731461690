/* eslint-disable @typescript-eslint/no-explicit-any */
export class Gtm {
    public static pushClientId(clientId: string) {
        Gtm.pushEvent({ event: "emailActivation", clientId });
    }

    public static pushFormError(formErrorData: { form: string; field: string; error: string }): void {
        Gtm.pushEvent({ event: "formError", product: "travel", formErrorData });
    }

    public static pushCoverageGroupDetails(coverageGroup: string): void {
        Gtm.pushEvent({ event: "coverageGroupDetails", product: "travel", coverageGroup });
    }

    public static pushPaymentSucceed(data: { orderId?: string | null; price?: number; email: string; phoneNumber: string; userId: string | null }) {
        Gtm.pushEvent({
            event: "paymentSuccess",
            product: "travel",
            paymentSuccessData: {
                transactionId: data.orderId, // GTM expect orderId as transactionId ¯\_(ツ)_/¯
                products: [
                    {
                        name: "TRAVEL",
                        price: data.price,
                        type: "new",
                        coupon: undefined,
                        currency: "HUF",
                    },
                ],
            },
            userData: {
                email: data.email,
                phone_number: data.phoneNumber,
                userId: data.userId,
            },
        });
    }

    public static pushPaymentFailed(data: { orderId: string | null; error?: string }) {
        Gtm.pushEvent({
            event: "paymentFailed",
            product: "travel",
            paymentFailedData: {
                transactionId: data.orderId, // GTM expect orderId as transactionId ¯\_(ツ)_/¯
                paymentError: data.error,
            },
        });
    }

    private static pushEvent(event: any): void {
        if (process.env.NODE_ENV !== "production") {
            console.log("dataLayer.push", event);
        } else {
            window.dataLayer.push(event);
        }
    }

    public static getClientId(): string | undefined {
        if (typeof window !== "undefined" && typeof window.ga === "function" && typeof window.ga.getAll === "function") {
            const tracker = window.ga.getAll()[0];
            if (tracker && typeof tracker.get === "function") {
                return tracker.get("clientId");
            }
        }

        return undefined;
    }
}
