import React from "react";
import { AppBar } from "./AppBar";
import { Box } from "./LayoutElements";

export const EmptyPage = () => {
    return (
        <Box $style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
            <AppBar />
        </Box>
    );
};
