import { AppStateActionTypes } from "@redux/actions/appStateActions";
import { ReduxAction } from "@redux/actions/ReduxAction";

export type Notification = {
    id: number;
    message: string;
    type: "error" | "success";
    timeout?: number;
};

export type AppStateReducerType = {
    loaderCount: number;
    notifications: Notification[];
};

const initialState: AppStateReducerType = {
    loaderCount: 1, // app starts with loading
    notifications: [],
};

export default function (state: AppStateReducerType = initialState, action: ReduxAction): AppStateReducerType {
    switch (action.type) {
        case AppStateActionTypes.ADD_LOADING:
            return { ...state, loaderCount: state.loaderCount + 1 };
        case AppStateActionTypes.REMOVE_LOADING:
            return { ...state, loaderCount: state.loaderCount - 1 || 0 };
        case AppStateActionTypes.ADD_NOTIFICATION:
            return { ...state, notifications: [...state.notifications, action.payload.notification] };
        case AppStateActionTypes.REMOVE_NOTIFICATION: {
            const notifications = [...state.notifications].filter(n => n.id !== action.payload.id);
            return { ...state, notifications };
        }
        default:
            return state;
    }
}
