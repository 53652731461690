import * as React from "react";
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgIcon32GlassDamage({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg width={32} height={32} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" focusable={false} aria-hidden={true} aria-labelledby={titleId} {...props}>
            {title ? <title id={titleId}>{title}</title> : null}
            <g fill="none" fillRule="evenodd">
                <rect stroke="currentColor" x={4.5} y={4.5} width={23} height={23} rx={1} />
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M23 4.5L20 9h5l-3 5" />
                <path fill="currentColor" d="M11 5h1v22h-1z" />
            </g>
        </svg>
    );
}

export default SvgIcon32GlassDamage;
