import * as React from "react";
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgLogoPaypal({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg width={66} height={46} viewBox="0 0 66 46" fill="none" xmlns="http://www.w3.org/2000/svg" focusable={false} aria-hidden={true} aria-labelledby={titleId} {...props}>
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M28.442 12h6.824c1.922 0 4.202.063 5.727 1.407 1.018.899 1.553 2.33 1.43 3.867-.42 5.21-3.536 8.13-7.716 8.13h-3.366c-.572 0-.952.379-1.114 1.407l-.939 5.974c-.06.388-.229.616-.535.644h-4.204c-.466 0-.632-.357-.51-1.13l3.027-19.165c.121-.768.541-1.134 1.376-1.134z"
                fill="#1B3D92"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M32.83 18h6.818c3.66 0 5.038 1.853 4.825 4.58-.35 4.494-3.068 6.979-6.672 6.979h-1.82c-.494 0-.827.326-.961 1.214l-.78 5.152c-.052.335-.227.531-.492.555h-4.275c-.402 0-.545-.307-.44-.974l2.61-16.528c.105-.663.468-.978 1.188-.978z"
                fill="#00A2D3"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M30 26.52l1.192-7.542c.104-.663.467-.978 1.187-.978h6.817c1.129 0 2.04.176 2.755.5-.685 4.638-3.685 7.215-7.612 7.215h-3.366c-.443 0-.774.227-.973.805z"
                fill="#1B2E7F"
            />
        </svg>
    );
}

export default SvgLogoPaypal;
