import { createStore, applyMiddleware, compose, Middleware } from "redux";
import reducers from "./reducers/index";
import { persistStore } from "redux-persist";
import { DebugUtils } from "@utils/DebugUtils";
import { enableBatching } from "redux-batched-actions";

const middlewares: Middleware[] = [];

const composeEnhancers = (DebugUtils.isDevToolsEnabled() && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
const enhancer = composeEnhancers(applyMiddleware(...middlewares));

const store = createStore(enableBatching(reducers), enhancer);

const persistor = persistStore(store);

export { persistor, store };
