import React from "react";
import { useTheme } from "styled-components";
import { Box } from "@components/LayoutElements";
import { Flex } from "@components/Flex";
import { Text } from "@components/Text";
import { Gql } from "@api/Api";
import { LoctoolMessage } from "@i18n/i18n";

type Props = {
    transactionResult: Gql.TransactionResult;
};

export const PaymentDetails = ({ transactionResult }: Props) => {
    const theme = useTheme();

    return (
        <Box $style={{ marginTop: 56, borderRadius: 16, boxShadow: `inset 0px 0px 0px 1px ${theme.color.blueL}` }} $styleMedium={{ marginTop: 32, paddingBottom: 16 }}>
            <Text as="h2" $fontSize="text16" $style={{ margin: 0, padding: 16, fontWeight: 700, boxShadow: `inset 0px -1px 0px ${theme.color.blueL}` }}>
                <LoctoolMessage id="page.paymentResult.details.title" />
            </Text>

            <Flex.Container as="dl" $flexWrap="wrap" $style={{ margin: 0 }}>
                <Flex.Item
                    $shrink={1}
                    $style={{ padding: 16, boxShadow: `0px -1px 0px ${theme.color.blueL}` }}
                    // Note: $order!
                    $bpMedium={{ $shrink: 1 / 2, $order: 1 }}
                    $styleMedium={{ boxShadow: "none" }}
                >
                    <Text as="dt">
                        <LoctoolMessage id="page.paymentResult.details.message" />
                    </Text>

                    <Text as="dd" $fontSize="text12" $style={{ margin: 0, color: theme.color.blueL, fontStyle: "italic" }}>
                        {transactionResult.resultMessage}
                    </Text>
                </Flex.Item>

                <Flex.Item
                    $shrink={1}
                    $style={{ padding: 16, boxShadow: `0px -1px 0px ${theme.color.blueL}` }}
                    // Note: $order!
                    $bpMedium={{ $shrink: 1 / 2, $order: 3 }}
                    $styleMedium={{ boxShadow: "none" }}
                >
                    <Text as="dt">
                        <LoctoolMessage id="page.paymentResult.details.orderId" />
                    </Text>

                    <Text as="dd" $fontSize="text12" $style={{ margin: 0, color: theme.color.blueL, fontStyle: "italic" }}>
                        {transactionResult.orderId}
                    </Text>
                </Flex.Item>

                <Flex.Item
                    $shrink={1}
                    $style={{ padding: 16, boxShadow: `0px -1px 0px ${theme.color.blueL}` }}
                    // Note: $order!
                    $bpMedium={{ $shrink: 1 / 2, $order: 2 }}
                    $styleMedium={{ boxShadow: "none" }}
                >
                    <Text as="dt">
                        <LoctoolMessage id="page.paymentResult.details.transactionId" />
                    </Text>

                    <Text as="dd" $fontSize="text12" $style={{ margin: 0, color: theme.color.blueL, fontStyle: "italic" }}>
                        {transactionResult.transactionId}
                    </Text>
                </Flex.Item>

                <Flex.Item
                    $shrink={1}
                    $style={{ padding: 16, boxShadow: `0px -1px 0px ${theme.color.blueL}` }}
                    // Note: $order!
                    $bpMedium={{ $shrink: 1 / 2, $order: 4 }}
                    $styleMedium={{ boxShadow: "none" }}
                >
                    <Text as="dt">
                        <LoctoolMessage id={`enum.providerName.${transactionResult.providerName?.toLowerCase()}`} />{" "}
                        <LoctoolMessage id="page.paymentResult.details.providerTransactionId" />
                    </Text>

                    <Text as="dd" $fontSize="text12" $style={{ margin: 0, color: theme.color.blueL, fontStyle: "italic" }}>
                        {transactionResult.providerTransactionId}
                    </Text>
                </Flex.Item>
            </Flex.Container>
        </Box>
    );
};
