import React from "react";
import { InputHTMLAttributes } from "react";
import styled from "styled-components";
import { Flex } from "@components/Flex";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    id: string;
    name: string;
}

export const Radio: React.FC<Props> = ({ id, name, children, ...props }) => {
    return (
        <>
            <StyledInput id={id} className="show-for-sr" name={name} type="radio" {...props} />

            <Flex.Container as="label" htmlFor={id} $display="inline-flex">
                <StyledRadio as="span" $shrink={32} />

                {children && (
                    <StyledChildrenWrapper as="span" $shrink="auto">
                        {children}
                    </StyledChildrenWrapper>
                )}
            </Flex.Container>
        </>
    );
};

const StyledChildrenWrapper = styled(Flex.Item)`
    display: block;
    padding-left: 16px;
    user-select: none;
`;

const StyledRadio = styled(Flex.Item).attrs(() => ({ ["aria-hidden"]: true }))`
    border-radius: 50%;
    box-shadow: inset 0px 0px 0px 1px ${({ theme }) => theme.color.blueN};
    color: ${({ theme }) => theme.color.greyD};
    cursor: pointer;
    display: block;
    height: ${({ $shrink }) => $shrink}px;
    position: relative;

    &::before,
    &::after {
        border-radius: inherit;
        bottom: 8px;
        box-shadow: inset 0px 0px 0px 1px currentColor;
        content: "";
        left: 8px;
        pointer-events: none;
        position: absolute;
        right: 8px;
        top: 8px;
    }
`;

const StyledInput = styled.input`
    &:checked {
        & + label {
            ${StyledRadio} {
                color: ${({ theme }) => theme.color.blueN};

                &::before {
                    background-color: currentColor;
                }
            }
        }
    }

    &:disabled {
        & + label {
            ${StyledRadio} {
                box-shadow: inset 0px 0px 0px 1px ${({ theme }) => theme.color.greyD};
                color: ${({ theme }) => theme.color.greyD};
                cursor: not-allowed;
            }
        }
    }
`;
