import * as React from "react";
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgIcon32Fire({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg width={32} height={32} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" focusable={false} aria-hidden={true} aria-labelledby={titleId} {...props}>
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M16.006 2.005l.39.3-.004.005-.396-.305h.01zm.486 1.245C16.356 9.522 14.529 14.5 12 14.5c-1.222 0-2.265-1.119-3.05-3.038C7.312 14.073 6.5 16.258 6.5 18a9.5 9.5 0 0019 0c0-3.356-2.996-8.298-9.008-14.75z"
                stroke="currentColor"
                fill="none"
                fillRule="evenodd"
            />
        </svg>
    );
}

export default SvgIcon32Fire;
