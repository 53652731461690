import React, { useCallback } from "react";
import { ThemeContext } from "styled-components";
import { Box, Container } from "@components/LayoutElements";
import { Text } from "@components/Text";
import { Button } from "@components/Button";
import { Checkbox } from "@components/Checkbox";
import SvgIcon32Notify from "@components/svg/Icon32Notify";
import { LegalDocuments } from "@utils/LegalDocuments";
import { LoctoolMessage } from "@i18n/i18n";
import { ContractActions } from "@redux/actions/contractActions";
import { useDispatch } from "react-redux";
import { useContractCreate } from "@utils/hooks/useContractCreate";
import { Path } from "@utils/Path";
import { useHistory } from "react-router";
import { GraphQLClientError } from "@api/graphql/GraphQLClient";
import { AppStateActions } from "@redux/actions/appStateActions";

export const OverviewConsent = () => {
    const themeContext = React.useContext(ThemeContext);
    const [termsAndConditionsChecked, setTermsAndConditionsChecked] = React.useState(false);
    const [dataProcessingNoticeChecked, setDataProcessingNoticeChecked] = React.useState(false);
    const [uniqaMarketingPolicyChecked, setUniqaMarketingPolicyChecked] = React.useState(false);
    const [cherryHubMarketingPolicyChecked, setCherryHubMarketingPolicyChecked] = React.useState(false);

    const dispatch = useDispatch();
    const history = useHistory();
    const contractCreate = useContractCreate(uniqaMarketingPolicyChecked, cherryHubMarketingPolicyChecked);
    const handleSubmit = useCallback(
        async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
            e.preventDefault();
            try {
                const contract = await contractCreate();
                dispatch(ContractActions.update(contract));
                history.push(Path.phoneNumberVerification);
            } catch (error) {
                if (error instanceof GraphQLClientError) {
                    dispatch(AppStateActions.addNotification(error.intlMessage, "error"));
                }
            }
        },
        [contractCreate, dispatch, history]
    );

    const isNextDisabled = !termsAndConditionsChecked || !dataProcessingNoticeChecked;
    return (
        <Box $style={{ backgroundColor: themeContext.color.white, boxShadow: `inset 0px 1px 0px ${themeContext.color.greyD}`, paddingTop: 16, paddingBottom: 32 }}>
            <Container $maxWidth={502} $px={16}>
                <Box as={SvgIcon32Notify} $style={{ marginLeft: "auto", marginRight: "auto" }} />

                <Text as="h2" $fontSize="text16" $style={{ margin: 16, fontWeight: 400, textAlign: "center" }}>
                    <LoctoolMessage id="page.overview.consent.warning" />
                </Text>

                <form onSubmit={handleSubmit}>
                    <fieldset>
                        <Box
                            as="legend"
                            $style={{
                                borderTopLeftRadius: 2,
                                borderTopRightRadius: 2,
                                boxShadow: `inset 0px 0px 0px 1px ${themeContext.color.greyN}`,
                                padding: 16,
                                fontWeight: 700,
                            }}
                        >
                            <LoctoolMessage id="common.travelInsurance" />
                        </Box>

                        <Box
                            $style={{
                                borderBottomLeftRadius: 2,
                                borderBottomRightRadius: 2,
                                boxShadow: `inset 0px 0px 0px 1px ${themeContext.color.greyN}`,
                                padding: "12px 16px",
                                position: "relative",
                                top: -1,
                            }}
                        >
                            <Checkbox
                                id="c-terms-and-conditions"
                                checked={termsAndConditionsChecked}
                                required
                                onChange={() => setTermsAndConditionsChecked(!termsAndConditionsChecked)}
                            >
                                <LegalDocuments.TravelTermsAndIpid />
                            </Checkbox>
                        </Box>

                        <Box $style={{ padding: "12px 16px" }}>
                            <Checkbox
                                id="c-data-processing-notice"
                                checked={dataProcessingNoticeChecked}
                                onChange={() => setDataProcessingNoticeChecked(!dataProcessingNoticeChecked)}
                            >
                                <LegalDocuments.PrivacyPolicy />
                            </Checkbox>
                        </Box>

                        <Box $style={{ padding: "12px 16px" }}>
                            <Checkbox
                                id="c-cherryhub-marketing"
                                checked={cherryHubMarketingPolicyChecked}
                                onChange={() => setCherryHubMarketingPolicyChecked(!cherryHubMarketingPolicyChecked)}
                            >
                                <LegalDocuments.CherryHubMarketingPolicy />
                            </Checkbox>
                        </Box>

                        <Box $style={{ padding: "12px 16px" }}>
                            <Checkbox id="c-marketing" checked={uniqaMarketingPolicyChecked} onChange={() => setUniqaMarketingPolicyChecked(!uniqaMarketingPolicyChecked)}>
                                <LegalDocuments.UniqaMarketingPolicy />
                            </Checkbox>
                        </Box>
                    </fieldset>

                    <Text as="p" id="submit-desc" $fontSize="text12" $style={{ margin: 16, fontStyle: "italic", textAlign: "center" }}>
                        <LoctoolMessage id="page.overview.consent.submitDescription" />
                    </Text>

                    <Box $style={{ marginTop: 32 }}>
                        <Box $style={{ maxWidth: 288, margin: "0 auto" }}>
                            <Button.Primary
                                type="submit"
                                btnLabel={<LoctoolMessage id="page.overview.next" />}
                                aria-describedby="submit-desc"
                                isExpanded
                                disabled={isNextDisabled}
                            />
                        </Box>
                    </Box>
                </form>
            </Container>
        </Box>
    );
};
