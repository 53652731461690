import React, { useMemo } from "react";
import styled, { ThemeContext } from "styled-components";
import { hexToRGBA } from "@style/Theme";
import { Box } from "@components/LayoutElements";
import { UseFormRegisterReturn } from "react-hook-form";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    id: string;
    $hasError?: boolean;
    register?: UseFormRegisterReturn;
    numberOnly?: boolean;
}

export const Input = React.forwardRef(({ id, $hasError, register, numberOnly, ...props }: Props, ref) => {
    const themeContext = React.useContext(ThemeContext);
    const onChange = useMemo(() => {
        const _onChange = register?.onChange ?? props.onChange;
        if (_onChange && numberOnly) {
            return (event: React.ChangeEvent<HTMLInputElement>) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                event.target.value = event.target.value.replace(/[^0-9]/g, "");
                _onChange(event);
            };
        }
        return _onChange;
    }, [numberOnly, props.onChange, register?.onChange]);
    return (
        <StyledInput
            as="input"
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ref={ref}
            id={id}
            type="text"
            $style={{ boxShadow: `inset 0px 0px 0px 1px ${$hasError ? themeContext.color.redN : themeContext.color.greyN}` }}
            {...register}
            {...props}
            onChange={onChange}
        />
    );
});

const StyledInput = styled(Box)`
    appearance: none;
    background-clip: padding-box;
    background-color: ${({ theme }) => theme.color.white};
    border-radius: 3px;
    border: 0;
    caret-color: currentColor;
    color: ${({ theme }) => theme.color.blueD};
    display: block;
    filter: none;
    font-size: ${({ theme }) => (typeof theme.typo.body.fontSize === "number" ? `${theme.typo.body.fontSize}px` : theme.typo.body.fontSize)};
    line-height: ${({ theme }) => (typeof theme.typo.body.lineHeight === "number" ? `${theme.typo.body.lineHeight}px` : theme.typo.body.lineHeight)};
    padding: 8px;
    width: 100%;

    &::placeholder {
        color: ${({ theme }) => theme.color.greyD};
    }

    &:placeholder-shown {
        text-overflow: ellipsis;
    }

    &:focus {
        box-shadow: inset 0px 0px 0px 1px ${({ theme }) => theme.color.blueL}, 0px 0px 5px 0 ${({ theme }) => hexToRGBA(theme.color.blueL, 0.5)};
    }

    &:disabled {
        background-color: ${({ theme }) => theme.color.greyL};
        box-shadow: none;
        color: ${({ theme }) => theme.color.greyD};
        cursor: not-allowed;
    }

    &:read-only {
        box-shadow: none;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        -webkit-text-fill-color: ${({ theme }) => theme.color.blueD} !important;
        transition: background-color 5500s ease-in-out 0s;
    }

    &::-webkit-calendar-picker-indicator {
        display: none !important;
    }
`;

const StyledSelect = styled(StyledInput)`
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIGZpbGw9Im5vbmUiIHZlcnNpb249IjEuMSIgdmlld0JveD0iMCAwIDMyIDMyIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogPHBhdGggZD0ibTEwLjg3NCAxNCA0LjUgNCA0LjUtNCIgc3Ryb2tlPSIjMDAzNjY3IiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=");
    background-position: right 4px;
    background-repeat: no-repeat;
    box-shadow: inset 0px 0px 0px 1px ${({ theme }) => theme.color.greyN};
    padding-right: 32px;
    text-align: left;
    text-overflow: ellipsis;

    &:read-only {
        box-shadow: inset 0px 0px 0px 1px ${({ theme }) => theme.color.greyN};
    }

    &:focus {
        box-shadow: inset 0px 0px 0px 1px ${({ theme }) => theme.color.blueL}, 0px 0px 5px 0 ${({ theme }) => hexToRGBA(theme.color.blueL, 0.5)};
    }
`;

export const InputStyles = {
    Input: StyledInput,
    Select: StyledSelect,
};
