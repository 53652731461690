import React, { useCallback, useRef } from "react";
import AriaModal, { AriaModalProps, RequiredAriaTypes } from "react-aria-modal";
import { useTheme } from "styled-components";

type Props = { titleText: string } & AriaModalProps & RequiredAriaTypes;

export const Modal = ({ children, ...props }: Props) => {
    const theme = useTheme();
    const modalRef = useRef<AriaModal>(null);

    const getApplicationNode = useCallback(() => {
        return document.getElementById("root")!;
    }, []);

    // NOTE: "You must use either titleId or titleText, but not both."
    return (
        <AriaModal ref={modalRef} {...props} getApplicationNode={getApplicationNode} underlayStyle={{ backgroundColor: theme.color.blueXL, zIndex: theme.zIndex.modal }}>
            {children}
        </AriaModal>
    );
};
