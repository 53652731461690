import * as React from "react";
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgIcon32PhysicalDamage({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg width={32} height={32} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" focusable={false} aria-hidden={true} aria-labelledby={titleId} {...props}>
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M16.573 5.401l9 6.3a1 1 0 01.427.82V26a1 1 0 01-1 1H7a1 1 0 01-1-1V12.52a1 1 0 01.427-.819l9-6.3a1 1 0 011.146 0zm-.573.82l-9 6.3V26h18V12.52l-3.706-2.594a.2.2 0 00-.281.053l-1.754 2.632a.25.25 0 00.208.389h4.202a.5.5 0 01.392.811l-.062.065-12.603 11.027a.5.5 0 01-.797-.552l.039-.081L15 17h-4.018a.5.5 0 01-.404-.794l6.52-8.968a.2.2 0 00-.047-.281L16 6.22zm2.037 1.426l-5.959 8.194a.1.1 0 00.081.159h3.724a.5.5 0 01.429.757l-3.179 5.296L22.338 14h-4.273a.5.5 0 01-.416-.777l2.657-3.988-2.269-1.588z"
                fill="currentColor"
                fillRule="evenodd"
            />
        </svg>
    );
}

export default SvgIcon32PhysicalDamage;
