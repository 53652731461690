import React, { useCallback, useState } from "react";
import { Box } from "@components/LayoutElements";
import { Button } from "@components/Button";
import { useDispatch, useSelector } from "react-redux";
import { useFieldArray, useForm, useWatch } from "react-hook-form";
import { SessionSelectors } from "@redux/selectors/SessionSelectors";
import { ObjectUtils } from "@utils/ObjectUtils";
import { SessionActions, Passenger } from "@redux/actions/sessionActions";
import { ApplicationState } from "@redux/reducers";
import { useHistory } from "react-router";
import { Path } from "@utils/Path";
import { PassengerCard } from "./PassengerCard";
import { LoctoolMessage } from "@i18n/i18n";
import { PassengerToRemove, RemovePassengerModal } from "./RemovePassengerModal";
import { GtmUtils } from "@utils/gtm/GtmUtils";
import { Api } from "@api/Api";
import { AppStateActions } from "@redux/actions/appStateActions";

type Inputs = {
    adults: Passenger[];
    children: Passenger[];
};

export const PassengersForm = () => {
    const passengers = useSelector(SessionSelectors.passengers);
    const isMeTraveling = useSelector((state: ApplicationState) => state.session.areYouTraveling);
    const dispatch = useDispatch();
    const history = useHistory();
    const {
        register,
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<Inputs>({
        defaultValues: {
            adults: ObjectUtils.clone(passengers.adults),
            children: ObjectUtils.clone(passengers.children),
        },
        mode: "onTouched",
    });
    const [passengerToRemove, setPassengerToRemove] = useState<PassengerToRemove | null>(null);
    const [adultsCanBeInsured, setAdultsCanBeInsured] = useState<boolean[]>(Array.from({ length: passengers.adults.length }).map(() => true));
    const [childrenCanBeInsured, setChildrenCanBeInsured] = useState<boolean[]>(Array.from({ length: passengers.children.length }).map(() => true));
    const adults = useFieldArray({ name: "adults", control });
    const children = useFieldArray({ name: "children", control });
    const adultValues = useWatch({ control, name: "adults" });
    const childValues = useWatch({ control, name: "children" });

    const onSubmit = useCallback(
        async (data: Inputs) => {
            try {
                const toApiCall = (p: Passenger) => Api.canPersonBeInsured({ firstName: p.firstName, lastName: p.lastName, dateOfBirth: p.dateOfBirth });
                const newAdultsCanBeInsured = await Promise.all(data.adults.map(toApiCall));
                const newChildrenCanBeInsured = await Promise.all(data.children.map(toApiCall));
                setAdultsCanBeInsured(newAdultsCanBeInsured);
                setChildrenCanBeInsured(newChildrenCanBeInsured);
                if (newAdultsCanBeInsured.some(a => !a) || newChildrenCanBeInsured.some(c => !c)) {
                    return;
                }
            } catch (error) {
                return;
            }
            dispatch(SessionActions.updatePassengers(data.adults, data.children));
            history.push(Path.paymentSettings);
        },
        [dispatch, history]
    );

    const onRemove = useCallback(
        (isAdult: boolean, index: number) => () => {
            setPassengerToRemove({ isAdult, index, isImmediate: isAdult ? !adultsCanBeInsured[index] : !childrenCanBeInsured[index] });
        },
        [adultsCanBeInsured, childrenCanBeInsured]
    );

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                {adults.fields.map((field, index) => {
                    return (
                        <PassengerCard
                            key={field.id}
                            isAdult={true}
                            index={index}
                            register={register}
                            errors={errors.adults?.[index]}
                            onRemove={onRemove(true, index)}
                            removeEnabled={adults.fields.length + children.fields.length > (isMeTraveling ? 0 : 1)}
                            memberValues={adultValues[index]}
                            canBeInsured={adultsCanBeInsured[index]}
                        />
                    );
                })}

                {children.fields.map((field, index) => {
                    return (
                        <PassengerCard
                            key={field.id}
                            isAdult={false}
                            index={index}
                            register={register}
                            errors={errors.children?.[index]}
                            onRemove={onRemove(false, index)}
                            removeEnabled={adults.fields.length + children.fields.length > (isMeTraveling ? 0 : 1)}
                            memberValues={childValues[index]}
                            canBeInsured={childrenCanBeInsured[index]}
                        />
                    );
                })}
                <Box $style={{ marginTop: 32 }} $styleMedium={{ marginTop: 48 }}>
                    <Box $style={{ maxWidth: 256 + 2 * 16, margin: "0 auto", paddingLeft: 16, paddingRight: 16 }} $styleMedium={{ maxWidth: 228 + 2 * 16 }}>
                        <Button.Primary type="submit" className={GtmUtils.className.nextButton} btnLabel={<LoctoolMessage id="page.passengers.next" />} isExpanded />
                    </Box>
                </Box>
            </form>

            <RemovePassengerModal
                passengerToRemove={passengerToRemove}
                removePassenger={() => {
                    if (!passengerToRemove) {
                        return;
                    }
                    if (passengerToRemove.isAdult) {
                        adults.remove(passengerToRemove.index);
                    } else {
                        children.remove(passengerToRemove.index);
                    }

                    if (adults.fields.length + children.fields.length - 1 === 0) {
                        dispatch(AppStateActions.reset());
                    }
                }}
                onCloseClick={() => setPassengerToRemove(null)}
            />
        </>
    );
};
