import React, { useCallback, useMemo } from "react";
import { Flex } from "@components/Flex";
import { InputStyles } from "@components/Input";
import { Modifier } from "react-day-picker";
import { DateUtils } from "@utils/DateUtils";

interface Props {
    disabledDays?: Modifier;
    date: Date;
    onChange: (date: Date) => void;
    getMonths: () => string[];
}

export const YearMonthForm = ({ date, disabledDays, onChange, getMonths }: Props) => {
    const monthNames = useMemo(() => getMonths().map((month: string) => month[0].toUpperCase() + month.slice(1)), [getMonths]);
    const years = useMemo(() => {
        const modifier = disabledDays as { before?: Date; after?: Date };
        if (modifier && modifier.before && modifier.after) {
            const minYear = modifier.before.getFullYear();
            const maxYear = modifier.after.getFullYear() + 1;
            return Array.from({ length: maxYear - minYear }).map((_, index) => minYear + index);
        } else {
            return Array.from({ length: DateUtils.startOfToday().getFullYear() - 1900 }).map((_, index) => 1900 + index);
        }
    }, [disabledDays]);

    const handleChange = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (e: any): void => {
            const { year, month } = e.target.form;
            onChange(new Date(year.value, month.value));
        },
        [onChange]
    );

    return (
        <form className="DayPicker-Caption">
            <Flex.Container $gutterMargin={8} $style={{ marginTop: -4, padingRight: 80 }}>
                <Flex.Item $shrink={3 * 40 - 4} $gutterMargin={8}>
                    <InputStyles.Select as="select" name="month" onChange={handleChange} value={date.getMonth()}>
                        {monthNames.map((month, i) => (
                            <option key={month} value={i}>
                                {month}
                            </option>
                        ))}
                    </InputStyles.Select>
                </Flex.Item>

                <Flex.Item $shrink="shrink" $gutterMargin={8}>
                    <InputStyles.Select as="select" name="year" onChange={handleChange} value={date.getFullYear()}>
                        {years.map(year => (
                            <option key={year} value={year}>
                                {year}
                            </option>
                        ))}
                    </InputStyles.Select>
                </Flex.Item>
            </Flex.Container>
        </form>
    );
};
