import React, { PureComponent } from "react";
import { Loctool } from "./Loctool";

interface Props {
    value: number;
    format?: string;
}

class FormattedNumber extends PureComponent<Props> {
    render(): React.ReactNode {
        const { value, format } = this.props;
        return Loctool.formatNumber(value, { format });
    }
}

export default FormattedNumber;
