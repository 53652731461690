import React, { useEffect, useRef } from "react";
import { ThemeContext } from "styled-components";
import { Box } from "@components/LayoutElements";
import { Flex } from "@components/Flex";
import { Button } from "@components/Button";
import { Card } from "@components/Card";
import { CalculatorSimpleInput } from "@components/calculator/CalculatorSimpleInput";
import SvgIcon32AddressHome from "@components/svg/Icon32AddressHome";
import { useHistory } from "react-router";
import { Api, Gql } from "@api/Api";
import { SubmitHandler, useForm } from "react-hook-form";
import { SessionActions } from "@redux/actions/sessionActions";
import { ApplicationState } from "@redux/reducers";
import { Path } from "@utils/Path";
import { useSelector, useDispatch } from "react-redux";
import { Validator } from "@utils/Validator";
import { FormHelpers } from "@utils/FormHelpers";
import { useCities } from "@utils/hooks/useCities";
import { useAddresses } from "@utils/hooks/useAddresses";
import { Loctool, LoctoolMessage } from "@i18n/i18n";
import AutocompleteInput, { AutocompleteItem } from "@components/AutocompleteInput";
import { GtmUtils } from "@utils/gtm/GtmUtils";
import { GtmHooks } from "@utils/gtm/GtmHooks";

type Inputs = {
    zipCode: string;
    city: string;
    address: string;
    countryCode: Gql.CountryCode;
};

type Props = {
    onBlacklisted: () => void;
};

export const HomeAddressForm = ({ onBlacklisted }: Props) => {
    const themeContext = React.useContext(ThemeContext);
    const { travelOffer, accountData, homeAddressData } = useSelector((state: ApplicationState) => state.session);
    const dispatch = useDispatch();
    const history = useHistory();
    const {
        watch,
        register,
        setValue,
        handleSubmit,
        formState: { errors },
    } = useForm<Inputs>({
        defaultValues: {
            zipCode: homeAddressData.zipCode,
            city: homeAddressData.city,
            address: homeAddressData.address,
            countryCode: homeAddressData.countryCode,
        },
        mode: "onTouched",
    });
    const firstLoad = useRef(true);
    const onSubmit: SubmitHandler<Inputs> = async data => {
        try {
            const canBeInsured = await Api.canAccountBeInsured({
                firstName: accountData.firstName,
                lastName: accountData.lastName,
                dateOfBirth: accountData.dateOfBirth,
                address: data.address,
                zipCode: data.zipCode,
            });
            if (!canBeInsured) {
                onBlacklisted();
                return;
            }
        } catch (error) {
            return;
        }
        dispatch(SessionActions.updateHomeAddressData(data));
        if (travelOffer.adultsCount > 0 && !Validator.passengerAdultDateOfBirth(accountData.dateOfBirth)) {
            history.push(Path.areYouTraveling);
        } else {
            dispatch(SessionActions.updateAreYouTraveling(false));
            history.push(Path.passengers);
        }
    };

    const zipCode = watch("zipCode");
    const city = watch("city");
    const address = watch("address");
    const currentCountryCode = watch("countryCode");

    const cities = useCities(zipCode);
    useEffect(() => {
        if (!firstLoad.current) {
            if (cities.length === 0) {
                setValue("city", "");
            } else if (cities.length === 1) {
                if (cities[0] !== city) {
                    setValue("city", cities[0]);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cities, setValue]);

    const addresses = useAddresses(zipCode, city);
    useEffect(() => {
        if (!firstLoad.current) {
            setValue("address", "");
        }
    }, [zipCode, setValue]);

    useEffect(() => {
        // Prevent override defaultValue for unregistered cities
        setTimeout(() => {
            firstLoad.current = false;
        }, 1000);
    }, []);

    GtmHooks.useHookFormError("contract-HomeAddress", { ...errors });
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Card.Wrapper as="fieldset">
                <Box as="legend" $style={{ boxShadow: `inset 0px -1px 0px ${themeContext.color.greyN}` }}>
                    <Flex.Container as="span" $style={{ padding: "12px 16px" }}>
                        <Flex.Item as="span" $shrink="shrink" $style={{ marginRight: 16 }}>
                            <SvgIcon32AddressHome />
                        </Flex.Item>

                        <Flex.Item as="span" $shrink="auto" $alignSelf="center">
                            <LoctoolMessage id="page.homeData.form.title" />
                        </Flex.Item>
                    </Flex.Container>
                </Box>

                <Box $styleMedium={{ padding: "12px 0 12px 8px" }}>
                    <CalculatorSimpleInput
                        label={<LoctoolMessage id="page.homeData.form.zipCode.label" />}
                        error={FormHelpers.getError(errors.zipCode)}
                        placeholder={Loctool.formatMessage({ id: "page.homeData.form.zipCode.placeholder" })}
                        autoComplete="postal-code"
                        numberOnly
                        maxLength={4}
                        name="zipCode"
                        register={register("zipCode", { validate: Validator.zipCode })}
                        className={GtmUtils.className.inputs.zipCode}
                    />

                    {zipCode && !Validator.zipCode(zipCode) && !errors.zipCode && (
                        <Box $style={{ padding: "8px 16px" }} $styleMedium={{ paddingTop: 4, paddingBottom: 4 }}>
                            <Flex.Container $gutterMargin={8} $bpMedium={{ $gutterMargin: 16 }}>
                                <Flex.Item
                                    as="label"
                                    htmlFor="city"
                                    id="city-label"
                                    $shrink={112}
                                    $alignSelf="center"
                                    $gutterMargin={8}
                                    $style={{ marginTop: -4, marginBottom: -4 }}
                                    $bpMedium={{ $shrink: 133, $gutterMargin: 16 }}
                                >
                                    <LoctoolMessage id="page.homeData.form.city.label" />
                                </Flex.Item>

                                <Flex.Item $shrink="auto" $gutterMargin={8} $bpMedium={{ $shrink: "auto", $gutterMargin: 16 }} $style={{ position: "relative" }}>
                                    <AutocompleteInput
                                        id="city"
                                        items={cities.map((city: string): AutocompleteItem => {
                                            return { title: city, value: city };
                                        })}
                                        {...register("city", { validate: Validator.city })}
                                        value={city}
                                        maxLength={100}
                                        placeholder={Loctool.formatMessage({ id: "page.homeData.form.city.placeholder" })}
                                        textOnly={true}
                                        variant={cities.length > 1 ? "select" : undefined}
                                        selectListWrapperBoxProps={{ $style: { right: 0 }, $styleMedium: { left: 0 } }}
                                        className={GtmUtils.className.inputs.city}
                                    />
                                </Flex.Item>
                            </Flex.Container>

                            <Box
                                id="city-error"
                                aria-live="assertive"
                                $style={FormHelpers.getError(errors.city) ? { paddingTop: 8, paddingBottom: 16, color: themeContext.color.redN } : {}}
                            >
                                {FormHelpers.getError(errors.city)}
                            </Box>
                        </Box>
                    )}

                    <Box $style={{ padding: "8px 16px" }} $styleMedium={{ paddingTop: 4, paddingBottom: 4 }}>
                        <Flex.Container $gutterMargin={8} $bpMedium={{ $gutterMargin: 16 }}>
                            <Flex.Item
                                as="label"
                                htmlFor="address"
                                id="address-label"
                                $shrink={112}
                                $alignSelf="center"
                                $gutterMargin={8}
                                $style={{ marginTop: -4, marginBottom: -4 }}
                                $bpMedium={{ $shrink: 133, $gutterMargin: 16 }}
                            >
                                <LoctoolMessage id="page.homeData.form.address.label" />
                            </Flex.Item>

                            <Flex.Item $shrink="auto" $gutterMargin={8} $bpMedium={{ $shrink: "auto", $gutterMargin: 16 }} $style={{ position: "relative" }}>
                                <AutocompleteInput
                                    id="address"
                                    items={addresses.map((street: string): AutocompleteItem => {
                                        return { title: street, value: street };
                                    })}
                                    {...register("address", { validate: Validator.address })}
                                    placeholder={Loctool.formatMessage({ id: "page.homeData.form.address.placeholder" })}
                                    maxLength={100}
                                    value={address}
                                    selectListWrapperBoxProps={{ $style: { right: 0 }, $styleMedium: { left: 0 } }}
                                    className={GtmUtils.className.inputs.address}
                                />
                            </Flex.Item>
                        </Flex.Container>

                        <Box
                            id="streetAddress-error"
                            aria-live="assertive"
                            $style={FormHelpers.getError(errors.address) ? { paddingTop: 8, paddingBottom: 16, color: themeContext.color.redN } : {}}
                        >
                            {FormHelpers.getError(errors.address)}
                        </Box>
                    </Box>

                    <CalculatorSimpleInput
                        label={<LoctoolMessage id="page.homeData.form.countryCode.label" />}
                        error={FormHelpers.getError(errors.countryCode)}
                        placeholder={Loctool.formatMessage({ id: "page.homeData.form.countryCode.placeholder" })}
                        autoComplete="country"
                        disabled
                        name="countryCode"
                        value={Loctool.formatMessage({ id: `common.countries.${currentCountryCode}` })}
                        className={GtmUtils.className.inputs.countryCode}
                    />
                </Box>
            </Card.Wrapper>

            <Box $style={{ marginTop: 32 }}>
                <Box $style={{ maxWidth: 440 + 2 * 16, margin: "0 auto", paddingLeft: 16, paddingRight: 16 }}>
                    <Button.Primary type="submit" className={GtmUtils.className.nextButton} btnLabel={<LoctoolMessage id="page.homeData.form.next" />} isExpanded />
                </Box>
            </Box>
        </form>
    );
};
