// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
// TODO: types

// Note: https://itnext.io/diy-react-popups-with-popper-js-2-c76ff6b7fc5d
import React from "react";
import { createPopper } from "@popperjs/core";
import type { Modifier, Options } from "@popperjs/core";
import FocusTrap, { FocusTrapOptions } from "focus-trap-react";
import styled from "styled-components";
import { Box } from "@components/LayoutElements";
import { Portal } from "@components/Portal";
import { Tooltip } from "@components/Tooltip";

interface Props {
    arrowElement?: React.ReactNode;
    toggleElement: React.ReactNode;
    popperOptions?: Omit<Partial<Options>, "modifiers"> & {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        modifiers?: Array<Partial<Modifier<any, any>>>;
    };
    focusTrapOptions?: FocusTrapOptions;
    children?: React.ReactNode;
    $fullWidth?: boolean;
}

interface State {
    isOpened: boolean;
}

export class Popup extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.triggerRef = React.createRef();
        this.popupRef = React.createRef();

        this.state = {
            isOpened: false,
        };
    }

    componentDidMount() {
        this.popper = createPopper(this.triggerRef.current, this.popupRef.current, {
            placement: this.props.popperOptions?.placement || "bottom-start",
            strategy: this.props.popperOptions?.strategy || "fixed",
            modifiers: [...(this.props.popperOptions?.modifiers || [])],
        });
    }

    componentWillUnmount() {
        this.popper.destroy();
    }

    public open = () => {
        this.setState(
            state => {
                return {
                    isOpened: !state.isOpened,
                };
            },
            () => {
                this.popper.forceUpdate();
            }
        );
    };

    public close = () => {
        this.setState({ isOpened: false });
    };

    render() {
        const { isOpened } = this.state;

        return (
            <React.Fragment>
                <Box as="span" ref={this.triggerRef} $style={{ display: "block" }}>
                    {React.cloneElement(this.props.toggleElement, {
                        onClick: this.open,
                    })}
                </Box>

                <Portal>
                    <StyledPopup ref={this.popupRef} $isOpened={isOpened} $fullWidth={this.props.$fullWidth}>
                        {/* Note: ["data-popper-arrow"] must be the first child. */}
                        {this.props.arrowElement &&
                            React.cloneElement(this.props.arrowElement, {
                                ["data-popper-arrow"]: true,
                            })}

                        {isOpened && (
                            <React.Fragment>
                                <FocusTrap
                                    focusTrapOptions={{
                                        onDeactivate: this.close,
                                        clickOutsideDeactivates: true,
                                        ...this.props.focusTrapOptions,
                                    }}
                                >
                                    <div>{this.props.children}</div>
                                </FocusTrap>
                            </React.Fragment>
                        )}
                    </StyledPopup>
                </Portal>
            </React.Fragment>
        );
    }
}

const StyledPopup = styled.span<{ $isOpened: boolean; $fullWidth: boolean }>`
    display: block;
    pointer-events: none;
    width: ${({ $fullWidth }) => ($fullWidth ? "100%" : "")};
    z-index: ${({ theme }) => theme.zIndex.tooltip};

    &[data-popper-placement^="top"] {
        ${Tooltip.Container} {
            margin-bottom: 8px;
        }

        ${Tooltip.Arrow} {
            bottom: 0;

            &::before {
                transform: translate(0, -3px) rotate(45deg);
            }
        }
    }

    &[data-popper-placement^="right"] {
        ${Tooltip.Container} {
            margin-left: 8px;
        }

        ${Tooltip.Arrow} {
            left: 0;

            &::before {
                transform: translate(3px, 0) rotate(45deg);
            }
        }
    }

    &[data-popper-placement^="bottom"] {
        ${Tooltip.Container} {
            margin-top: 8px;
        }

        ${Tooltip.Arrow} {
            top: 0;

            &::before {
                transform: translate(0, 3px) rotate(45deg);
            }
        }
    }

    &[data-popper-placement^="left"] {
        ${Tooltip.Container} {
            margin-right: 8px;
        }

        ${Tooltip.Arrow} {
            right: 0;

            &::before {
                transform: translate(-3px, 0) rotate(45deg);
            }
        }
    }

    ${({ $isOpened }) =>
        $isOpened
            ? `
    ${Tooltip.Arrow} {
        visibility: visible
    }
    `
            : ""}
`;
