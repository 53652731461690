import * as React from "react";
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgIcon32VehicleAssistance({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg width={29} height={22} viewBox="0 0 29 22" fill="none" xmlns="http://www.w3.org/2000/svg" focusable={false} aria-hidden={true} aria-labelledby={titleId} {...props}>
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M9.83 20a3.001 3.001 0 01-5.66 0H2a1 1 0 01-1-1v-4a1 1 0 011-1h27v5a1 1 0 01-1 1h-2.17a3.001 3.001 0 01-5.66 0H9.83zM28 15H2v4h2.878l.236.667a2.001 2.001 0 003.772 0L9.122 19h11.756l.235.667a2.001 2.001 0 003.774 0l.235-.667H28v-4z"
                fill="#445591"
            />
            <path
                d="M16 4h3.735a4 4 0 013.43 1.942L25 9h2a2 2 0 012 2v3.023a1 1 0 01-1 1H16a1 1 0 01-1-1V5a1 1 0 011-1zm0 1v9.023h12V11a1 1 0 00-1-1h-2.566l-2.126-3.543A3 3 0 0019.735 5H16zM7 22a3 3 0 100-6 3 3 0 000 6zm0-1a2 2 0 110-4 2 2 0 010 4zM23 22a3 3 0 100-6 3 3 0 000 6zm0-1a2 2 0 110-4 2 2 0 010 4zM4 0v6a1 1 0 01-1-1V1a1 1 0 011-1zM9 0H4v1h5V0z"
                fill="#445591"
            />
            <path
                d="M5 0h4.323a1 1 0 01.928.629L16 15h-6L5 0zm1.387 1l4.334 13h3.802l-5.2-13H6.387zM18 1a2 2 0 012 2v2h-4V3a2 2 0 012-2zm1 3V3a1 1 0 10-2 0v1h2zM3 5.035A3.5 3.5 0 113.5 12C1.737 12 .243 10.696 0 9v-.5a.5.5 0 011 0V9c.232 1.141 1.29 2 2.5 2a2.5 2.5 0 000-5 .5.5 0 01-.5-.5v-.465z"
                fill="#445591"
            />
        </svg>
    );
}

export default SvgIcon32VehicleAssistance;
