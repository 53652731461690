import { Api } from "@api/Api";
import { Validator } from "@utils/Validator";
import { useCallback, useEffect, useState } from "react";

export const useAddresses = (zipCode: string, city: string) => {
    const [addresses, setAddresses] = useState<string[]>([]);

    const updateAddresses = useCallback(async (zipCode: string, city: string) => {
        try {
            const result = await Api.getStreets(zipCode, city);
            setAddresses(result);
        } catch (error) {
            setAddresses([]);
        }
    }, []);

    useEffect(() => {
        if (zipCode && city && !Validator.zipCode(zipCode) && !Validator.city(city)) {
            updateAddresses(zipCode, city);
        } else {
            setAddresses([]);
        }
    }, [zipCode, city, updateAddresses]);

    return addresses;
};
