export class GtmUtils {
    public static readonly className = {
        privacyPolicy: "gtmTravelCalculator-Cta-PrivacyPolicy",
        complaint: "gtmTravelCalculator-Cta-ComplaintHandlingPolicy",
        email: "gtmTravelCalculator-Cta-Email",
        phoneNumber: "gtmTravelCalculator-Cta-PhoneNumber",
        nextButton: "gtmTravelCalculator-Cta-Proceed",

        destination: {
            eu: "gtmTravelCalculator-Destination-EU",
            nonEu: "gtmTravelCalculator-Destination-NonEU",
            america: "gtmTravelCalculator-Destination-America",
        },
        tooltip: {
            destination: "gtmTravelCalculator-Help-Destination",
        },
        coverageButton: "gtmCalculatorTravel-Help-Coverage",

        inputs: {
            email: "gtmTravelContract-Input-Email",
            phoneNumberPrefix: "gtmTravelContract-Input-PhonePrefix",
            phoneNumber: "gtmTravelContract-Input-PhoneNumber",
            firstName: "gtmTravelContract-Input-Firstname",
            lastName: "gtmTravelContract-Input-Lastname",
            dateOfBirth: "gtmTravelContract-Input-BirthDate",
            zipCode: "gtmTravelContract-Input-AddressZipCode",
            city: "gtmTravelContract-Input-AddressCity",
            address: "gtmTravelContract-Input-AddressDetails",
            countryCode: "gtmTravelContract-Input-AddressCountry",
            areYouTravel: "gtmTravelContract-Input-Insured",
            passengerFirstName: "gtmTravelContract-Input-InsuredFirstname",
            passengerLastName: "gtmTravelContract-Input-InsuredLastname",
            passengerDateOfBirth: "gtmTravelContract-Input-InsuredBirthDate",
        },
    };
}
