/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createContract
// ====================================================

export interface createContract_CreateWLHuOffer_accountData_name {
  __typename: "PersonNameWL";
  firstName: string;
  lastName: string;
}

export interface createContract_CreateWLHuOffer_accountData {
  __typename: "AccountDataWL";
  email: string;
  emailValidated: any | null;
  name: createContract_CreateWLHuOffer_accountData_name;
  countryCode: CountryCode;
  languageCode: LanguageCode;
  city: string;
  address: string;
  zipCode: string;
  dateOfBirth: any;
  phoneNumber: string;
  phoneValidated: any | null;
}

export interface createContract_CreateWLHuOffer_travelData_travelMembers {
  __typename: "TravelMemberWL";
  id: any | null;
  firstName: string;
  lastName: string;
  dateOfBirth: any;
}

export interface createContract_CreateWLHuOffer_travelData {
  __typename: "TravelDataWL";
  firstDayOfTravel: any;
  lastDayOfTravel: any;
  destinationCategoryId: any;
  travelMembers: createContract_CreateWLHuOffer_travelData_travelMembers[];
}

export interface createContract_CreateWLHuOffer_pricing_price {
  __typename: "AmountWithCurrency";
  amount: any;
}

export interface createContract_CreateWLHuOffer_pricing_priceWithoutDiscount {
  __typename: "AmountWithCurrency";
  amount: any;
}

export interface createContract_CreateWLHuOffer_pricing_discount {
  __typename: "AmountWithCurrency";
  amount: any;
}

export interface createContract_CreateWLHuOffer_pricing_couponApplied {
  __typename: "CouponApplied";
  couponCode: string;
  discountAmount: any | null;
  discountPercent: any | null;
}

export interface createContract_CreateWLHuOffer_pricing {
  __typename: "Pricing";
  price: createContract_CreateWLHuOffer_pricing_price;
  priceWithoutDiscount: createContract_CreateWLHuOffer_pricing_priceWithoutDiscount;
  discount: createContract_CreateWLHuOffer_pricing_discount;
  couponApplied: createContract_CreateWLHuOffer_pricing_couponApplied[];
}

export interface createContract_CreateWLHuOffer {
  __typename: "FullDataWL";
  id: any;
  accountData: createContract_CreateWLHuOffer_accountData;
  travelData: createContract_CreateWLHuOffer_travelData;
  pricing: createContract_CreateWLHuOffer_pricing | null;
}

export interface createContract {
  /**
   * create white label account with offer
   */
  CreateWLHuOffer: createContract_CreateWLHuOffer;
}

export interface createContractVariables {
  createOfferDataWL: InputCreateOfferDataWL;
  custLegal: LegalDocumentRelationActionWLInput[];
  travelLegal: LegalDocumentRelationActionWLInput[];
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: sendOrResendSMSCode
// ====================================================

export interface sendOrResendSMSCode_sendOrResendSMSCode {
  __typename: "VoidType";
  code: number;
  message: string | null;
}

export interface sendOrResendSMSCode {
  /**
   * send or resend phone validation sms code
   */
  sendOrResendSMSCode: sendOrResendSMSCode_sendOrResendSMSCode;
}

export interface sendOrResendSMSCodeVariables {
  offerId: any;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: validatePhone
// ====================================================

export interface validatePhone_validatePhone {
  __typename: "VoidType";
  code: number;
  message: string | null;
}

export interface validatePhone {
  /**
   * validate phone number
   */
  validatePhone: validatePhone_validatePhone;
}

export interface validatePhoneVariables {
  offerId: any;
  code: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updatePhone
// ====================================================

export interface updatePhone_updatePhone {
  __typename: "VoidType";
  code: number;
  message: string | null;
}

export interface updatePhone {
  /**
   * update phone number
   */
  updatePhone: updatePhone_updatePhone;
}

export interface updatePhoneVariables {
  offerId: any;
  phoneNumber: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: sendOrResendEmailValidationEmail
// ====================================================

export interface sendOrResendEmailValidationEmail_sendOrResendEmailValidationEmail {
  __typename: "VoidType";
  code: number;
  message: string | null;
}

export interface sendOrResendEmailValidationEmail {
  /**
   * send or resend email validation email
   */
  sendOrResendEmailValidationEmail: sendOrResendEmailValidationEmail_sendOrResendEmailValidationEmail;
}

export interface sendOrResendEmailValidationEmailVariables {
  offerId: any;
  clientId: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateEmail
// ====================================================

export interface updateEmail_updateEmail {
  __typename: "VoidType";
  code: number;
  message: string | null;
}

export interface updateEmail {
  /**
   * update phone number
   */
  updateEmail: updateEmail_updateEmail;
}

export interface updateEmailVariables {
  offerId: any;
  email: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: validateEmail
// ====================================================

export interface validateEmail_validateEmail {
  __typename: "VoidType";
  code: number;
  message: string | null;
}

export interface validateEmail {
  /**
   * validate user email
   */
  validateEmail: validateEmail_validateEmail;
}

export interface validateEmailVariables {
  offerId: any;
  code: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: pay
// ====================================================

export interface pay {
  /**
   * start a payment
   */
  pay: string;
}

export interface payVariables {
  offerId: any;
  payableAmount: AmountWithCurrencyInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: getTransactionResult
// ====================================================

export interface getTransactionResult_getTransactionResult {
  __typename: "TransactionResult";
  transactionId: string | null;
  resultCode: TransactionStatus;
  resultMessage: string | null;
  orderId: string | null;
  providerTransactionId: string | null;
  providerName: string | null;
  userId: string | null;
}

export interface getTransactionResult {
  /**
   * get the result of the payment
   */
  getTransactionResult: getTransactionResult_getTransactionResult;
}

export interface getTransactionResultVariables {
  offerId: any;
  transactionId: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateOffer
// ====================================================

export interface updateOffer_updateOffer {
  __typename: "VoidType";
  code: number;
  message: string | null;
}

export interface updateOffer {
  /**
   * update user data
   */
  updateOffer: updateOffer_updateOffer;
}

export interface updateOfferVariables {
  offerId: any;
  updateOfferDataWL: InputUpdateOfferDataWL;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: appData
// ====================================================

export interface appData_accountLegalDocuments {
  __typename: "LegalDocumentWL";
  category: legalDocumentCategory;
  interaction: LegalDocumentInteraction;
  version: number;
  effectiveFrom: any;
  effectiveUntil: any | null;
  name: string | null;
  url: string | null;
}

export interface appData_nonDomestic {
  __typename: "LegalDocumentWL";
  category: legalDocumentCategory;
  interaction: LegalDocumentInteraction;
  version: number;
  effectiveFrom: any;
  effectiveUntil: any | null;
  name: string | null;
  url: string | null;
}

export interface appData_env {
  __typename: "Env";
  /**
   * Name of environment variable
   */
  name: string;
  /**
   * Value (serialized to string)
   */
  value: string;
}

export interface appData {
  accountLegalDocuments: appData_accountLegalDocuments[];
  nonDomestic: appData_nonDomestic[];
  /**
   * Get environment variables
   * 
   * @auth None
   */
  env: appData_env[];
}

export interface appDataVariables {
  at?: any | null;
  countryCode: CountryCode;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: legalDocuments
// ====================================================

export interface legalDocuments_accountLegalDocuments {
  __typename: "LegalDocumentWL";
  category: legalDocumentCategory;
  interaction: LegalDocumentInteraction;
  version: number;
  effectiveFrom: any;
  effectiveUntil: any | null;
  name: string | null;
  url: string | null;
}

export interface legalDocuments_nonDomestic {
  __typename: "LegalDocumentWL";
  category: legalDocumentCategory;
  interaction: LegalDocumentInteraction;
  version: number;
  effectiveFrom: any;
  effectiveUntil: any | null;
  name: string | null;
  url: string | null;
}

export interface legalDocuments {
  accountLegalDocuments: legalDocuments_accountLegalDocuments[];
  nonDomestic: legalDocuments_nonDomestic[];
}

export interface legalDocumentsVariables {
  at?: any | null;
  countryCode: CountryCode;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: calculateTravelTariff
// ====================================================

export interface calculateTravelTariff_calculateTravelTariff {
  __typename: "AmountWithCurrency";
  amount: any;
}

export interface calculateTravelTariff {
  /**
   * calculate travel tariff 
   */
  calculateTravelTariff: calculateTravelTariff_calculateTravelTariff | null;
}

export interface calculateTravelTariffVariables {
  firstDayOfTravel: any;
  lastDayOfTravel: any;
  residencyCode: ResidencyCode;
  destinationCategoryId: any;
  adultsNumber: any;
  childrenNumber: any;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: travelCoverage
// ====================================================

export interface travelCoverage_travelCoverage_category {
  __typename: "coverageCategory";
  name: string;
  iconName: string;
  coverageCategorySlug: string;
}

export interface travelCoverage_travelCoverage_coverages {
  __typename: "coverage";
  shortName: string;
  limit: string;
  linkTo: string[];
}

export interface travelCoverage_travelCoverage {
  __typename: "coverageCategoryWithCoverages";
  category: travelCoverage_travelCoverage_category;
  coverages: travelCoverage_travelCoverage_coverages[];
}

export interface travelCoverage {
  travelCoverage: travelCoverage_travelCoverage[];
}

export interface travelCoverageVariables {
  languageCode: LanguageCode;
  residencyCode: ResidencyCode;
  from?: any | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getCitiesByZipCode
// ====================================================

export interface getCitiesByZipCode_streets {
  __typename: "StreetsAndCities";
  cities: string[];
}

export interface getCitiesByZipCode {
  /**
   * Get cities and streets based on zip code
   * 
   * Cities without any streets are also returned.
   * Can be filtered by initials of city name, street name and country code
   * 
   * No authentication required
   */
  streets: getCitiesByZipCode_streets;
}

export interface getCitiesByZipCodeVariables {
  zipCode: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getStreets
// ====================================================

export interface getStreets_streets_streets {
  __typename: "Street";
  /**
   * name of street
   */
  streetWithSuffix: string;
}

export interface getStreets_streets {
  __typename: "StreetsAndCities";
  streets: getStreets_streets_streets[];
}

export interface getStreets {
  /**
   * Get cities and streets based on zip code
   * 
   * Cities without any streets are also returned.
   * Can be filtered by initials of city name, street name and country code
   * 
   * No authentication required
   */
  streets: getStreets_streets;
}

export interface getStreetsVariables {
  zipCode: string;
  city: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getContract
// ====================================================

export interface getContract_getOffer_accountData_name {
  __typename: "PersonNameWL";
  firstName: string;
  lastName: string;
}

export interface getContract_getOffer_accountData {
  __typename: "AccountDataWL";
  email: string;
  emailValidated: any | null;
  name: getContract_getOffer_accountData_name;
  countryCode: CountryCode;
  languageCode: LanguageCode;
  city: string;
  address: string;
  zipCode: string;
  dateOfBirth: any;
  phoneNumber: string;
  phoneValidated: any | null;
}

export interface getContract_getOffer_travelData_travelMembers {
  __typename: "TravelMemberWL";
  id: any | null;
  firstName: string;
  lastName: string;
  dateOfBirth: any;
}

export interface getContract_getOffer_travelData {
  __typename: "TravelDataWL";
  firstDayOfTravel: any;
  lastDayOfTravel: any;
  destinationCategoryId: any;
  travelMembers: getContract_getOffer_travelData_travelMembers[];
}

export interface getContract_getOffer_pricing_price {
  __typename: "AmountWithCurrency";
  amount: any;
}

export interface getContract_getOffer_pricing_priceWithoutDiscount {
  __typename: "AmountWithCurrency";
  amount: any;
}

export interface getContract_getOffer_pricing_discount {
  __typename: "AmountWithCurrency";
  amount: any;
}

export interface getContract_getOffer_pricing_couponApplied {
  __typename: "CouponApplied";
  couponCode: string;
  discountAmount: any | null;
  discountPercent: any | null;
}

export interface getContract_getOffer_pricing {
  __typename: "Pricing";
  price: getContract_getOffer_pricing_price;
  priceWithoutDiscount: getContract_getOffer_pricing_priceWithoutDiscount;
  discount: getContract_getOffer_pricing_discount;
  couponApplied: getContract_getOffer_pricing_couponApplied[];
}

export interface getContract_getOffer {
  __typename: "FullDataWL";
  id: any;
  accountData: getContract_getOffer_accountData;
  travelData: getContract_getOffer_travelData;
  pricing: getContract_getOffer_pricing | null;
}

export interface getContract {
  /**
   * Get offer
   */
  getOffer: getContract_getOffer;
}

export interface getContractVariables {
  offerId: any;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: time
// ====================================================

export interface time {
  /**
   * Server time
   * 
   * @auth None
   */
  time: any;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: canAccountBeInsured
// ====================================================

export interface canAccountBeInsured {
  /**
   * Checks whether the specified insuree can be insured
   */
  canAccountBeInsured: boolean;
}

export interface canAccountBeInsuredVariables {
  firstName: string;
  lastName: string;
  dateOfBirth: any;
  zipCode: string;
  address: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: canPersonBeInsured
// ====================================================

export interface canPersonBeInsured {
  /**
   * Checks whether the specified insuree can be insured
   */
  canPersonBeInsured: boolean;
}

export interface canPersonBeInsuredVariables {
  firstName: string;
  lastName: string;
  dateOfBirth: any;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Amount
// ====================================================

export interface Amount {
  __typename: "AmountWithCurrency";
  amount: any;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BackendEnvironment
// ====================================================

export interface BackendEnvironment {
  __typename: "Env";
  /**
   * Name of environment variable
   */
  name: string;
  /**
   * Value (serialized to string)
   */
  value: string;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Contract
// ====================================================

export interface Contract_accountData_name {
  __typename: "PersonNameWL";
  firstName: string;
  lastName: string;
}

export interface Contract_accountData {
  __typename: "AccountDataWL";
  email: string;
  emailValidated: any | null;
  name: Contract_accountData_name;
  countryCode: CountryCode;
  languageCode: LanguageCode;
  city: string;
  address: string;
  zipCode: string;
  dateOfBirth: any;
  phoneNumber: string;
  phoneValidated: any | null;
}

export interface Contract_travelData_travelMembers {
  __typename: "TravelMemberWL";
  id: any | null;
  firstName: string;
  lastName: string;
  dateOfBirth: any;
}

export interface Contract_travelData {
  __typename: "TravelDataWL";
  firstDayOfTravel: any;
  lastDayOfTravel: any;
  destinationCategoryId: any;
  travelMembers: Contract_travelData_travelMembers[];
}

export interface Contract_pricing_price {
  __typename: "AmountWithCurrency";
  amount: any;
}

export interface Contract_pricing_priceWithoutDiscount {
  __typename: "AmountWithCurrency";
  amount: any;
}

export interface Contract_pricing_discount {
  __typename: "AmountWithCurrency";
  amount: any;
}

export interface Contract_pricing_couponApplied {
  __typename: "CouponApplied";
  couponCode: string;
  discountAmount: any | null;
  discountPercent: any | null;
}

export interface Contract_pricing {
  __typename: "Pricing";
  price: Contract_pricing_price;
  priceWithoutDiscount: Contract_pricing_priceWithoutDiscount;
  discount: Contract_pricing_discount;
  couponApplied: Contract_pricing_couponApplied[];
}

export interface Contract {
  __typename: "FullDataWL";
  id: any;
  accountData: Contract_accountData;
  travelData: Contract_travelData;
  pricing: Contract_pricing | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CoverageItem
// ====================================================

export interface CoverageItem {
  __typename: "coverage";
  shortName: string;
  limit: string;
  linkTo: string[];
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: LegalDocument
// ====================================================

export interface LegalDocument {
  __typename: "LegalDocumentWL";
  category: legalDocumentCategory;
  interaction: LegalDocumentInteraction;
  version: number;
  effectiveFrom: any;
  effectiveUntil: any | null;
  name: string | null;
  url: string | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: TransactionResult
// ====================================================

export interface TransactionResult {
  __typename: "TransactionResult";
  transactionId: string | null;
  resultCode: TransactionStatus;
  resultMessage: string | null;
  orderId: string | null;
  providerTransactionId: string | null;
  providerName: string | null;
  userId: string | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: VoidType
// ====================================================

export interface VoidType {
  __typename: "VoidType";
  code: number;
  message: string | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * Country codes
 */
export enum CountryCode {
  CZ = "CZ",
  DE = "DE",
  HU = "HU",
  RO = "RO",
  SK = "SK",
}

/**
 * Currencies
 */
export enum Currency {
  CHF = "CHF",
  CZK = "CZK",
  EUR = "EUR",
  HUF = "HUF",
  JPY = "JPY",
  RON = "RON",
  USD = "USD",
}

/**
 * Language code
 */
export enum LanguageCode {
  CS = "CS",
  DE = "DE",
  EN = "EN",
  HU = "HU",
  RO = "RO",
  SK = "SK",
}

/**
 * Legal document required user interaction types
 */
export enum LegalDocumentInteraction {
  NEED_TO_ACCEPT = "NEED_TO_ACCEPT",
  NONE = "NONE",
  NOTIFY = "NOTIFY",
}

/**
 * Legal document relation action types usable by clients
 */
export enum LegalDocumentRelAction {
  ACCEPTED = "ACCEPTED",
  NOTIFIED = "NOTIFIED",
  REJECTED = "REJECTED",
}

/**
 * Available ISO 3166-1 alpha-2 country codes of supported residencies.
 */
export enum ResidencyCode {
  CZ = "CZ",
  DE = "DE",
  HU = "HU",
  RO = "RO",
  SK = "SK",
}

/**
 * Transaction statuses
 */
export enum TransactionStatus {
  CANCELED = "CANCELED",
  ERROR = "ERROR",
  FunctionNotImplemented = "FunctionNotImplemented",
  InactiveProvider = "InactiveProvider",
  InactiveStore = "InactiveStore",
  MissingParameter = "MissingParameter",
  MissingRemoteAddress = "MissingRemoteAddress",
  OPEN = "OPEN",
  PENDING = "PENDING",
  PaymentLinkCancelDenied = "PaymentLinkCancelDenied",
  PaypalrestError = "PaypalrestError",
  SUCCESSFUL = "SUCCESSFUL",
  TIMEOUT = "TIMEOUT",
  UNSUCCESFUL = "UNSUCCESFUL",
  UnauthorizedAccess = "UnauthorizedAccess",
  UnauthorizedRemoteAddress = "UnauthorizedRemoteAddress",
  UnknownParameter = "UnknownParameter",
  UnknownPaymentLink = "UnknownPaymentLink",
  UnknownProvider = "UnknownProvider",
  UnknownProviderForStore = "UnknownProviderForStore",
  UnknownReferenceTransaction = "UnknownReferenceTransaction",
  UnknownStore = "UnknownStore",
  UnknownTransaction = "UnknownTransaction",
  WrongApikey = "WrongApikey",
  WrongParameter = "WrongParameter",
  WrongProviderSettings = "WrongProviderSettings",
  WrongReferenceTransactionStatus = "WrongReferenceTransactionStatus",
}

export enum legalDocumentCategory {
  ANNOUNCEMENT = "ANNOUNCEMENT",
  CHERRISK_PRIVACY_POLICY = "CHERRISK_PRIVACY_POLICY",
  COMPLAINT = "COMPLAINT",
  GENERAL_CONTRACT_INFORMATION = "GENERAL_CONTRACT_INFORMATION",
  HEALTH_DATA = "HEALTH_DATA",
  INSURANCE_INTERMEDIARY_INFO = "INSURANCE_INTERMEDIARY_INFO",
  IPID = "IPID",
  MARKETING_POLICY = "MARKETING_POLICY",
  PRIVACY_POLICY = "PRIVACY_POLICY",
  PROTOCOL_OF_ADVICE = "PROTOCOL_OF_ADVICE",
  REGISTRATION_POLICY = "REGISTRATION_POLICY",
  SCALE_MARKETING_POLICY = "SCALE_MARKETING_POLICY",
  TERMS = "TERMS",
  WL_HU_CLAIM_SETTLEMENT_NOTICE = "WL_HU_CLAIM_SETTLEMENT_NOTICE",
  WL_HU_COMPLAINT = "WL_HU_COMPLAINT",
  WL_HU_COOKIE_POLICY = "WL_HU_COOKIE_POLICY",
  WL_HU_IPID = "WL_HU_IPID",
  WL_HU_MARKETING_POLICY = "WL_HU_MARKETING_POLICY",
  WL_HU_PRIVACY_POLICY = "WL_HU_PRIVACY_POLICY",
  WL_HU_SCALE_MARKETING_POLICY = "WL_HU_SCALE_MARKETING_POLICY",
  WL_HU_TERMS = "WL_HU_TERMS",
}

export interface AmountWithCurrencyInput {
  amount: any;
  currency: Currency;
}

export interface InputCreateOfferDataWL {
  email: string;
  phoneNumber: string;
  name: InputPersonNameWL;
  countryCode: CountryCode;
  languageCode: LanguageCode;
  city: string;
  address: string;
  zipCode: string;
  dateOfBirth: any;
  firstDayOfTravel: any;
  lastDayOfTravel: any;
  destinationCategoryId: number;
  travelMembers: TravelMemberInputWL[];
  couponCode?: string | null;
}

export interface InputPersonNameWL {
  firstName: string;
  lastName: string;
}

export interface InputUpdateOfferDataWL {
  name: InputPersonNameWL;
  countryCode: CountryCode;
  languageCode: LanguageCode;
  city: string;
  address: string;
  zipCode: string;
  dateOfBirth: any;
  firstDayOfTravel: any;
  lastDayOfTravel: any;
  destinationCategoryId: number;
  travelMembers: TravelMemberInputWL[];
  couponCode?: string | null;
}

export interface LegalDocumentRelationActionWLInput {
  category: legalDocumentCategory;
  version: number;
  countryCode: CountryCode;
  action: LegalDocumentRelAction;
}

export interface TravelMemberInputWL {
  id?: any | null;
  temporalyId?: number | null;
  firstName: string;
  lastName: string;
  dateOfBirth: any;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
