import React, { useCallback, useEffect, useLayoutEffect } from "react";
import { AppBar } from "@components/AppBar";
import { Box } from "@components/LayoutElements";
import { ApplicationState } from "@redux/reducers";
import { Path } from "@utils/Path";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory, useRouteMatch } from "react-router";
import { useTheme } from "styled-components";
import { Api } from "@api/Api";
import { ContractActions } from "@redux/actions/contractActions";
import { GraphQLClientError } from "@api/graphql/GraphQLClient";
import { batchActions } from "redux-batched-actions";
import { AppStateActions } from "@redux/actions/appStateActions";
import { Loctool } from "@i18n/Loctool";
import { Helpers } from "@utils/Helpers";
import { Gtm } from "@utils/gtm/Gtm";

interface RouteParams {
    activationCode?: string;
}

const EmailActivationPage = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const contract = useSelector((state: ApplicationState) => state.contract);
    const match = useRouteMatch<RouteParams>();
    const history = useHistory();
    const activationCode = match.params.activationCode;

    const validateEmail = useCallback(
        async (offerId: string, activationCode: string): Promise<void> => {
            try {
                const contract = await Api.getContract(offerId);
                if (contract.accountData.emailValidated) {
                    dispatch(batchActions([ContractActions.update(contract), AppStateActions.addNotification("Email-cím már aktiválva van!", "success")]));
                    return;
                }
                dispatch(ContractActions.update(contract));
                const newContract = await Api.validateEmail(offerId, activationCode);
                dispatch(
                    batchActions([ContractActions.update(newContract), AppStateActions.addNotification(Loctool.formatMessage({ id: "page.emailActivation.succeed" }), "success")])
                );
            } catch (error) {
                if (error instanceof GraphQLClientError) {
                    dispatch(AppStateActions.addNotification(Loctool.formatMessage({ id: "page.emailActivation.failed" }), "error"));
                }
                history.push(Path.calculatorIndex);
            }
        },
        [dispatch, history]
    );

    const clientId = Helpers.getLocationParameterByName("clientId", history.location.search);
    useLayoutEffect(() => {
        if (clientId) {
            Gtm.pushClientId(clientId);
        } else {
            console.warn("clientId not found");
        }
        dispatch(AppStateActions.addLoading());
    }, [dispatch, clientId]);

    useEffect(() => {
        if (activationCode) {
            const [offerId, code] = activationCode.split(":");
            validateEmail(offerId, code);
        }
    }, [activationCode, validateEmail]);

    if (contract && contract.accountData.emailValidated) {
        return <Redirect to={Path.paymentSummary} />;
    }

    return (
        <Box $style={{ minHeight: "100vh", backgroundColor: theme.color.white }}>
            <AppBar />
        </Box>
    );
};

export default EmailActivationPage;
