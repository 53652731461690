import * as React from "react";
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgLogoMastercard({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg width={66} height={46} viewBox="0 0 66 46" fill="none" xmlns="http://www.w3.org/2000/svg" focusable={false} aria-hidden={true} aria-labelledby={titleId} {...props}>
            {title ? <title id={titleId}>{title}</title> : null}
            <path d="M38.16 14.525H27.659v16.917H38.16V14.525z" fill="#FF5F00" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M28.74 22.985a10.687 10.687 0 014.166-8.457c-4.473-3.467-10.896-2.962-14.755 1.16a10.65 10.65 0 000 14.598c3.86 4.121 10.282 4.626 14.755 1.16a10.687 10.687 0 01-4.167-8.46z"
                fill="#EB001B"
            />
            <path
                d="M49.536 30.082v-.703h.167v-.145h-.397v.145h.157v.703h.073zm.77 0v-.848h-.12l-.14.606-.14-.606h-.12v.848h.087v-.642l.13.552h.09l.13-.552v.642h.083z"
                fill="#F79E1B"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M50.558 22.985c0 4.12-2.385 7.877-6.142 9.677a11.034 11.034 0 01-11.51-1.22 10.705 10.705 0 004.168-8.458c0-3.301-1.536-6.42-4.168-8.46a11.034 11.034 0 0111.51-1.219c3.757 1.8 6.142 5.558 6.142 9.677v.003z"
                fill="#F79E1B"
            />
        </svg>
    );
}

export default SvgLogoMastercard;
