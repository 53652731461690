import * as React from "react";
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgIcon32NoIncome({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg width={32} height={32} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" focusable={false} aria-hidden={true} aria-labelledby={titleId} {...props}>
            {title ? <title id={titleId}>{title}</title> : null}
            <g fill="none" fillRule="evenodd">
                <rect stroke="currentColor" x={4.5} y={7.5} width={23} height={16} rx={2} />
                <rect stroke="currentColor" x={7.5} y={11.5} width={5} height={4} rx={1} />
                <path stroke="currentColor" strokeLinecap="round" d="M3 27.456L28.456 2" />
                <rect fill="currentColor" x={7} y={19} width={10} height={1} rx={0.5} />
                <rect fill="currentColor" x={20} y={19} width={4} height={1} rx={0.5} />
            </g>
        </svg>
    );
}

export default SvgIcon32NoIncome;
