import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { ThemeContext } from "styled-components";
import { Box, UL } from "@components/LayoutElements";
import { Flex } from "@components/Flex";
import { Button } from "@components/Button";
import { Accordion } from "@components/Accordion";
import { Card } from "@components/Card";
import { FlexToTable } from "@components/FlexToTable";
import { Svg } from "@components/svg";
import { Coverage } from "@api/graphql/CustomTypes";
import { useSelector } from "react-redux";
import { LegalDocumentSelectors } from "@redux/selectors/LegalDocumentSelectors";
import { Gql } from "@api/Api";
import { LoctoolMessage } from "@i18n/i18n";
import { Helpers } from "@utils/Helpers";
import { Gtm } from "@utils/gtm/Gtm";

interface Props {
    coverages: Coverage[];
}

const DANGEROUS_SPORTS_SLUG = "wl_sport_travel";

export const CoverageList = ({ coverages }: Props) => {
    const themeContext = React.useContext(ThemeContext);
    const termDocument = useSelector(LegalDocumentSelectors.getDocumentByCategory(Gql.legalDocumentCategory.WL_HU_TERMS));

    const dangerousSportsCoverage = useMemo(() => {
        const result = coverages.find(c => c.slug === DANGEROUS_SPORTS_SLUG);
        if (!result) {
            console.warn("Dangerous sports coverage not found!");
        }
        return result;
    }, [coverages]);

    const coverageItems = useMemo(() => {
        const getIcon = (iconName: string, coverageName: string) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore;
            const icon = Svg[`Icon32${iconName.slice(4)}`] ?? Svg[`Icon32${iconName}`];
            if (!icon) {
                console.warn("Icon not found for line", coverageName, ` (Icon32${iconName.slice(4)})`);
            }
            return icon || Svg.Icon32Etc;
        };

        return coverages
            .filter(c => c.slug !== DANGEROUS_SPORTS_SLUG)
            .map((coverage, i) => {
                const Icon = getIcon(coverage.iconName, coverage.name);
                return (
                    <Box as="li" key={coverage.name} $style={i > 0 ? { marginTop: -1 } : {}}>
                        <Accordion
                            id={coverage.name}
                            headingLevel={2}
                            headingText={
                                <Box as="span" $style={{ display: "block", paddingTop: 4, paddingBottom: 4, fontWeight: 700 }}>
                                    {coverage.name}
                                </Box>
                            }
                            headingIconElement={<Icon />}
                            variant="white"
                            noBorderradius={i > 0}
                            onOpen={() => {
                                Gtm.pushCoverageGroupDetails(coverage.slug);
                            }}
                        >
                            <FlexToTable $bp="medium">
                                <Card.Table>
                                    <tbody>
                                        {coverage.items.map((coverageItem, index) => {
                                            return <CoverageItem key={index} coverageItem={coverageItem} />;
                                        })}
                                    </tbody>
                                </Card.Table>
                            </FlexToTable>
                        </Accordion>
                    </Box>
                );
            });
    }, [coverages]);

    return (
        <Card.Wrapper $style={{ backgroundColor: themeContext.color.greyL }}>
            <UL cssReset>{coverageItems}</UL>

            {dangerousSportsCoverage && dangerousSportsCoverage.items.length > 0 && (
                <Link
                    to={{ pathname: Helpers.getLegalDocumentPdfLink(termDocument) }}
                    target="_blank"
                    component={React.forwardRef((props, ref) => (
                        <Box
                            ref={ref}
                            as="a"
                            $style={{
                                position: "relative",
                                display: "block",
                                marginTop: -1,
                                boxShadow: `inset 0px 0px 0px 1px ${themeContext.color.greyN}`,
                                padding: `16px ${16 + 32 + 16}px 16px 16px`,
                                backgroundColor: themeContext.color.white,
                            }}
                            {...props}
                        >
                            <Box as="span" $style={{ display: "block", fontWeight: 700 }}>
                                <LoctoolMessage id="page.travelCoverages.comments" />
                            </Box>

                            <Box as="span" $style={{ display: "block" }}>
                                {dangerousSportsCoverage.name}
                            </Box>

                            <Box as="span" $style={{ display: "block", position: "absolute", top: 16, right: 16 }}>
                                <Svg.Icon32ArrowForward />
                            </Box>
                        </Box>
                    ))}
                />
            )}

            <Box
                $style={{
                    marginTop: -1,
                    boxShadow: `inset 0px 0px 0px 1px ${themeContext.color.greyN}`,
                    padding: 16,
                    backgroundColor: themeContext.color.white,
                    textAlign: "center",
                }}
            >
                <Link
                    to={{ pathname: Helpers.getLegalDocumentPdfLink(termDocument) }}
                    target="_blank"
                    component={React.forwardRef((props, ref) => (
                        <Button.Text ref={ref} btnLabel={<LoctoolMessage id={`enum.legalDocumentCategory.${Gql.legalDocumentCategory.WL_HU_TERMS}`} />} {...props} />
                    ))}
                />
            </Box>
        </Card.Wrapper>
    );
};

const CoverageItem = ({ coverageItem }: { coverageItem: Gql.CoverageItem }) => {
    return (
        <Flex.Container as="tr" $flexWrap="wrap">
            <Flex.Item as="th" $style={{ width: "100%" }} $styleMedium={{ width: "50%" }} scope="row">
                <Box $style={{ paddingRight: 16 - 4 }} $styleMedium={{ paddingRight: 0 }}>
                    {coverageItem.shortName}
                </Box>
            </Flex.Item>

            <Flex.Item as="td" $style={{ width: "100%", marginTop: -32 }} $styleMedium={{ width: "50%", marginTop: 0 }}>
                <Box $style={{ paddingLeft: 16 - 4, fontWeight: 700 }} $styleMedium={{ marginTop: 0, paddingLeft: 0 }}>
                    {coverageItem.limit}
                </Box>
            </Flex.Item>
        </Flex.Container>
    );
};
