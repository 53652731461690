import { Gql } from "@api/Api";
import { ActionsUnion, createAction } from "./actionHelpers";

export enum ContractActionTypes {
    UPDATE = "ContractAction/UPDATE",
}

export const ContractActions = {
    update: (contract: Gql.Contract | null) => createAction(ContractActionTypes.UPDATE, { contract }),
};

export type ContractActions = ActionsUnion<typeof ContractActions>;
