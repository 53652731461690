import React from "react";
import styled from "styled-components";
import { Box, SeparatorDecoratorContainer } from "@components/LayoutElements";
import { Flex } from "@components/Flex";
import { Button } from "@components/Button";
import { Card } from "@components/Card";
import { Radio } from "@components/Radio";
import { PaymentOptionLogo } from "@components/PaymentOptions";
import { LoctoolMessage } from "@i18n/i18n";
import { useHistory } from "react-router";
import { Path } from "@utils/Path";

export const PaymentSettingsForm = () => {
    const history = useHistory();
    const [value, setValue] = React.useState("option-1");

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        history.push(Path.overview);
    };

    return (
        <form onSubmit={handleSubmit}>
            <Card.Wrapper as="fieldset">
                <SeparatorDecoratorContainer>
                    <Box $style={{ padding: "12px 16px" }}>
                        <Flex.Container as="span" $flexWrap="wrap" $gutterMargin={16}>
                            <Flex.Item as="span" $shrink={1} $style={{ marginLeft: 8, marginRight: 8 }} $bpMedium={{ $shrink: "auto" }}>
                                <Radio id="r-01" name="radio-group" value="option-1" checked={value === "option-1"} onChange={e => setValue(e.target.value)}>
                                    <Box as="span" $style={{ display: "block", paddingTop: 4, paddingBottom: 4 }}>
                                        <LoctoolMessage id="page.paymentSettings.bankcard" />
                                    </Box>
                                </Radio>
                            </Flex.Item>

                            {/* Note: first PaymentLogoWrapperItem has special $styles */}
                            <PaymentLogoWrapperItem as="span" $shrink={32} $style={{ marginLeft: 32 + 16 + 8, marginRight: 8 }} $styleMedium={{ marginLeft: 8 }}>
                                <PaymentOptionLogo.Visa />
                            </PaymentLogoWrapperItem>

                            <PaymentLogoWrapperItem as="span" $shrink={32} $style={{ marginLeft: 8, marginRight: 8 }}>
                                <PaymentOptionLogo.MasterCard />
                            </PaymentLogoWrapperItem>

                            <PaymentLogoWrapperItem as="span" $shrink={32} $style={{ marginLeft: 8, marginRight: 8 }}>
                                <PaymentOptionLogo.Maestro />
                            </PaymentLogoWrapperItem>
                        </Flex.Container>
                    </Box>

                    {/* <Box $style={{ padding: "12px 16px" }}>
                        <Flex.Container as="span" $flexWrap="wrap" $gutterMargin={16}>
                            <Flex.Item as="span" $shrink={1} $style={{ marginLeft: 8, marginRight: 8 }} $bpMedium={{ $shrink: "auto" }}>
                                <Radio id="r-02" name="radio-group" value="option-2" checked={value === "option-2"} onChange={e => setValue(e.target.value)}>
                                    <Box as="span" $style={{ display: "block", paddingTop: 4, paddingBottom: 4 }}>
                                        Other option
                                    </Box>
                                </Radio>
                            </Flex.Item>

                            Note: first PaymentLogoWrapperItem has special $styles
                            <PaymentLogoWrapperItem as="span" $shrink={32} $style={{ marginLeft: 32 + 16 + 8, marginRight: 8 }} $styleMedium={{ marginLeft: 8 }}>
                                <PaymentOptionLogo.PayPal />
                            </PaymentLogoWrapperItem>
                        </Flex.Container>
                    </Box>

                    <Box $style={{ padding: "12px 16px" }}>
                        <Radio id="r-03" name="radio-group" value="option-3" checked={value === "option-3"} onChange={e => setValue(e.target.value)}>
                            <Box as="span" $style={{ display: "block", paddingTop: 4, paddingBottom: 4 }}>
                                Other option without logos
                            </Box>
                        </Radio>
                    </Box>
                    */}
                </SeparatorDecoratorContainer>
            </Card.Wrapper>

            <Box $style={{ marginTop: 64 }} $styleMedium={{ marginTop: 28 }}>
                <Box $style={{ maxWidth: 288, margin: "0 auto" }} $styleMedium={{ maxWidth: 248 }}>
                    <Button.Primary type="submit" btnLabel={<LoctoolMessage id="page.paymentSettings.next" />} isExpanded />
                </Box>
            </Box>
        </form>
    );
};

const PaymentLogoWrapperItem = styled(Flex.Item)`
    height: ${({ $shrink }) => $shrink}px;
    overflow: hidden;
    position: relative;

    > svg {
        height: 100%;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
    }
`;
