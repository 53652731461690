/* eslint-disable @typescript-eslint/no-explicit-any */
import { MessageFormatElement } from "react-intl";

declare type PrimitiveType = string | number | boolean | null | undefined | Date;
declare type FormatXMLElementFn<T, R = string | T | Array<string | T>> = (parts: Array<string | T>) => R;

export type LoctoolValues<T = any, R = string | T | Array<string | T>> =
    | Record<string, PrimitiveType | T | FormatXMLElementFn<string, string> | FormatXMLElementFn<T, R>>
    | undefined;

export type LoctoolDescriptor = {
    id: string;
    description?: string;
    defaultMessage?: string | MessageFormatElement[];
};

export interface IntlMessageObject {
    [key: string]: string;
}

export enum Locale {
    hu = "hu",
}
