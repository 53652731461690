import React, { useMemo } from "react";
import { LoctoolValues, LoctoolMessage } from "@i18n/i18n";
import { ValidatorMessage } from "@utils/Validator";
import { ErrorMessage } from "./ErrorMessage";

interface Props {
    id: string;
    error?: ValidatorMessage | null;
    values?: LoctoolValues;
}

export const ValidationErrorMessage = ({ id, error, values }: Props) => {
    const message = useMemo(() => {
        if (error) {
            return <LoctoolMessage id={`enum.validationMessage.${error}`} values={values} />;
        }
        return null;
    }, [error, values]);

    return <ErrorMessage id={id} message={message} />;
};
