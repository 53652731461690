import React from "react";
import { useTheme } from "styled-components";
import { Box, Container } from "@components/LayoutElements";
import { Text } from "@components/Text";
import { AppBar } from "@components/AppBar";
import SvgIcon64Notify from "@components/svg/Icon64Notify";
import SvgLogoUniqaFlat from "@components/svg/LogoUniqaFlat";

interface Props {}

export const Page500 = (props: Props) => {
    const theme = useTheme();

    return (
        <>
            <AppBar $justifyContent="flex-start" />

            <Container $maxWidth={504} $px={16} $style={{ paddingTop: 48, paddingBottom: 48 }} $styleMedium={{ paddingTop: 40 }}>
                <Box
                    $style={{ backgroundColor: theme.color.white, borderRadius: 2, padding: "56px 16px 312px", textAlign: "center", position: "relative", overflow: "hidden" }}
                    $styleMedium={{ paddingTop: 52, paddingBottom: 120 }}
                >
                    <Box
                        as={SvgIcon64Notify}
                        color={theme.color.blueL}
                        $style={{ margin: "0 auto 16px", opacity: 0.2 }}
                        $styleMedium={{ width: 88, height: 88, marginBottom: 36 }}
                    />

                    <Text as="h1" $fontFamily="firaSans" $fontSize="text24" $style={{ marginTop: 8, marginBottom: 8, fontWeight: 600 }}>
                        Hiba az oldalon
                    </Text>

                    <Box as="p" $style={{ marginTop: 8, marginBottom: 8 }}>
                        <Box as="span" $style={{ display: "block" }} $styleMedium={{ display: "inline" }}>
                            Hiba történt.
                        </Box>{" "}
                        <Box as="span" $style={{ display: "block" }} $styleMedium={{ display: "inline" }}>
                            Kérjük, próbálkozzon újra.
                        </Box>
                    </Box>

                    <Box
                        $style={{ position: "absolute", left: "100%", bottom: -2, transform: "translateX(-208px)", color: theme.color.blueN, opacity: 0.1 }}
                        $styleMedium={{ bottom: -80, transform: "translateX(-184px)" }}
                    >
                        <SvgLogoUniqaFlat />
                    </Box>
                </Box>
            </Container>
        </>
    );
};
