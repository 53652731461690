import React, { useMemo } from "react";
import { ThemeContext } from "styled-components";
import { Box } from "@components/LayoutElements";
import { Flex } from "@components/Flex";
import { Button } from "@components/Button";
import { Card } from "@components/Card";
import { Input } from "@components/Input";
import { CalculatorSimpleInput } from "@components/calculator/CalculatorSimpleInput";
import SvgIcon32Adult from "@components/svg/Icon32Adult";
import { useForm, SubmitHandler } from "react-hook-form";
import { FormHelpers } from "@utils/FormHelpers";
import { ErrorMessage } from "@components/ErrorMessage";
import { Validator, ValidatorConstants } from "@utils/Validator";
import { LoctoolMessage, LoctoolHTMLMessage, Loctool } from "@i18n/i18n";
import { CalculatorDateInput } from "@components/input/date/CalculatorDateInput";
import { ApplicationState } from "@redux/reducers";
import { DateUtils } from "@utils/DateUtils";
import { Path } from "@utils/Path";
import { useSelector, useDispatch } from "react-redux";
import { SessionActions } from "@redux/actions/sessionActions";
import { useHistory } from "react-router";
import { PhoneNumberUtils } from "@utils/PhoneNumberUtils";
import AutocompleteInput, { AutocompleteItem } from "@components/AutocompleteInput";
import { GtmUtils } from "@utils/gtm/GtmUtils";
import { GtmHooks } from "@utils/gtm/GtmHooks";

type Inputs = {
    email: string;
    firstName: string;
    lastName: string;
    phoneNumberPrefix: string;
    phoneNumber: string;
    dateOfBirth: string;
};

export const AccountDataForm = () => {
    const themeContext = React.useContext(ThemeContext);
    const { accountData } = useSelector((state: ApplicationState) => state.session);
    const dispatch = useDispatch();
    const history = useHistory();
    const {
        watch,
        register,
        handleSubmit,
        getValues,
        setFocus,
        setError,
        formState: { errors },
    } = useForm<Inputs>({
        defaultValues: {
            email: accountData.email,
            firstName: accountData.firstName,
            lastName: accountData.lastName,
            phoneNumberPrefix: accountData.phoneNumberPrefix || "+36",
            phoneNumber: accountData.phoneNumber,
            dateOfBirth: accountData.dateOfBirth ?? "",
        },
        mode: "onTouched",
    });

    const prefix = watch("phoneNumberPrefix");
    const rule = useMemo(() => PhoneNumberUtils.getRuleByPrefix(prefix), [prefix]);
    const phoneNumberPrefixRegister = register("phoneNumberPrefix", { validate: Validator.phoneNumberPrefix });
    const phoneNumberPrefixItems = useMemo(
        () =>
            PhoneNumberUtils.phoneNumberRules.map((rule): AutocompleteItem => {
                return {
                    title: `+${rule.prefix}`,
                    value: `+${rule.prefix}`,
                    titleInfo: <LoctoolMessage id={`common.countries.${rule.countryCode}`} />,
                };
            }),
        []
    );

    const onSubmit: SubmitHandler<Inputs> = async data => {
        dispatch(SessionActions.updateAccountData({ ...data }));
        history.push(Path.homeAddress);
    };

    GtmHooks.useHookFormError("contract-PersonalData", { ...errors });
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Card.Wrapper as="fieldset">
                <Box as="legend" $style={{ boxShadow: `inset 0px -1px 0px ${themeContext.color.greyN}` }}>
                    <Flex.Container as="span" $style={{ padding: "12px 16px" }}>
                        <Flex.Item as="span" $shrink="shrink" $style={{ marginRight: 16 }}>
                            <SvgIcon32Adult />
                        </Flex.Item>

                        <Flex.Item as="span" $shrink="auto" $alignSelf="center">
                            <LoctoolMessage id="page.accountData.form.title" />
                        </Flex.Item>
                    </Flex.Container>
                </Box>

                <Box $styleMedium={{ padding: "12px 0 12px 8px" }}>
                    <CalculatorSimpleInput
                        label={<LoctoolMessage id="page.accountData.form.email.label" />}
                        placeholder={Loctool.formatMessage({ id: "page.accountData.form.email.placeholder" })}
                        inputMode="email"
                        autoComplete="email"
                        name="email"
                        register={register("email", { validate: Validator.email })}
                        error={FormHelpers.getError(errors.email)}
                        className={GtmUtils.className.inputs.email}
                    />

                    <Box $style={{ padding: "8px 16px" }} $styleMedium={{ paddingTop: 4, paddingBottom: 4 }}>
                        <Flex.Container $gutterMargin={8} $bpMedium={{ $gutterMargin: 16 }}>
                            <Flex.Item
                                as="label"
                                htmlFor="i-telNational"
                                $shrink={112}
                                $alignSelf="center"
                                $gutterMargin={8}
                                $style={{ marginTop: -4, marginBottom: -4 }}
                                $bpMedium={{ $shrink: 133, $gutterMargin: 16 }}
                            >
                                <LoctoolHTMLMessage id="page.accountData.form.phoneNumber.label" />
                            </Flex.Item>

                            <Flex.Item $shrink="auto" $gutterMargin={8} $bpMedium={{ $shrink: "auto", $gutterMargin: 16 }}>
                                <Flex.Container $gutterMargin={8}>
                                    <Flex.Item $shrink={70} $gutterMargin={8}>
                                        <label htmlFor="i-CountryCode" className="show-for-sr">
                                            <LoctoolMessage id="page.accountData.form.phoneNumberPrefix.label" />
                                        </label>

                                        <AutocompleteInput
                                            id="phoneNumberPrefix"
                                            items={phoneNumberPrefixItems}
                                            {...phoneNumberPrefixRegister}
                                            value={prefix}
                                            onChange={(event: { target: { name: string; value: string } }) => {
                                                phoneNumberPrefixRegister.onChange(event);
                                                setError("phoneNumber", {});
                                                setFocus("phoneNumber");
                                            }}
                                            placeholder={Loctool.formatMessage({ id: "page.accountData.form.phoneNumberPrefix.placeholder" })}
                                            variant="select"
                                            selectListWrapperBoxProps={{ $style: { left: -112 }, $styleSmall: { left: -56 } }}
                                            className={GtmUtils.className.inputs.phoneNumberPrefix}
                                        />
                                    </Flex.Item>

                                    <Flex.Item $shrink="auto" $gutterMargin={8}>
                                        <Input
                                            id="i-telNational"
                                            aria-describedby="i-telNational-error"
                                            $hasError={!!FormHelpers.getError(errors.phoneNumber)}
                                            placeholder={Loctool.formatMessage({ id: "page.accountData.form.phoneNumber.placeholder" })}
                                            inputMode="tel"
                                            autoComplete="tel-national"
                                            numberOnly
                                            maxLength={rule?.maxLength}
                                            name="phoneNumber"
                                            className={GtmUtils.className.inputs.phoneNumber}
                                            register={register("phoneNumber", {
                                                validate: Validator.phoneNumber(() => {
                                                    return getValues().phoneNumberPrefix;
                                                }),
                                            })}
                                        />
                                    </Flex.Item>
                                </Flex.Container>
                            </Flex.Item>
                        </Flex.Container>

                        <ErrorMessage
                            id="i-telCountryCode-error"
                            message={FormHelpers.getError(errors.phoneNumberPrefix || errors.phoneNumber, undefined, { min: rule?.minLength, max: rule?.maxLength })}
                        />
                    </Box>

                    <CalculatorSimpleInput
                        label={<LoctoolMessage id="page.accountData.form.lastName.label" />}
                        placeholder={Loctool.formatMessage({ id: "page.accountData.form.lastName.placeholder" })}
                        autoComplete="family-name"
                        name={"lastName"}
                        error={FormHelpers.getError(errors.lastName)}
                        register={register("lastName", { validate: Validator.accountName })}
                        className={GtmUtils.className.inputs.lastName}
                    />

                    <CalculatorSimpleInput
                        label={<LoctoolMessage id="page.accountData.form.firstName.label" />}
                        placeholder={Loctool.formatMessage({ id: "page.accountData.form.firstName.placeholder" })}
                        autoComplete="given-name"
                        error={FormHelpers.getError(errors.firstName)}
                        name={"firstName"}
                        register={register("firstName", { validate: Validator.accountName })}
                        className={GtmUtils.className.inputs.firstName}
                    />

                    <CalculatorDateInput
                        id="i-dateOfBirth"
                        label={<LoctoolMessage id="page.accountData.form.dateOfBirth.label" />}
                        placeholder={Loctool.formatMessage({ id: "page.accountData.form.dateOfBirth.placeholder" })}
                        disabledDays={{ before: ValidatorConstants.accountDateOfBirthMax(), after: ValidatorConstants.accountDateOfBirthMin() }}
                        {...register("dateOfBirth", { validate: Validator.accountDateOfBirth })}
                        error={FormHelpers.getError(errors.dateOfBirth)}
                        defaultSelectedDate={DateUtils.format(DateUtils.subYears(DateUtils.startOfToday(), 30))}
                        defaultValue={accountData.dateOfBirth ?? undefined}
                        className={GtmUtils.className.inputs.dateOfBirth}
                    />
                </Box>
            </Card.Wrapper>

            <Box $style={{ marginTop: 32 }}>
                <Box $style={{ maxWidth: 440 + 2 * 16, margin: "0 auto", paddingLeft: 16, paddingRight: 16 }}>
                    <Button.Primary type="submit" className={GtmUtils.className.nextButton} btnLabel={<LoctoolMessage id="page.accountData.next" />} isExpanded />
                </Box>
            </Box>
        </form>
    );
};
