import React, { useState, useCallback, useEffect } from "react";
import { Box, Container } from "@components/LayoutElements";
import { AppBar } from "@components/AppBar";
import { Footer } from "@components/Footer";
import { Flex } from "@components/Flex";
import { Button } from "@components/Button";
import { ButtonAsRadio } from "@components/ButtonAsRadio";
import { CalculatorHeadingSteps } from "@components/calculator/CalculatorHeadingSteps";
import SvgIcon32Tick from "@components/svg/Icon32Tick";
import SvgIcon32X from "@components/svg/Icon32X";
import { Path } from "@utils/Path";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "@redux/reducers";
import { SessionActions } from "@redux/actions/sessionActions";
import { useHistory } from "react-router";
import { Loctool, LoctoolMessage } from "@i18n/i18n";
import { SessionSelectors } from "@redux/selectors/SessionSelectors";
import { Redirect } from "react-router-dom";
import { Validator } from "@utils/Validator";
import { GtmUtils } from "@utils/gtm/GtmUtils";

const AreYouTravelingPage = () => {
    const { adultsCount, childrenCount } = useSelector((state: ApplicationState) => state.session.travelOffer);
    const accountData = useSelector((state: ApplicationState) => state.session.accountData);
    const defaultAreYouTraveling = useSelector((state: ApplicationState) => state.session.areYouTraveling);
    const stepsCount = useSelector(SessionSelectors.getStepsCount);
    const dispatch = useDispatch();
    const history = useHistory();
    const [areYouTraveling, setAreYouTraveling] = useState<boolean>(defaultAreYouTraveling);
    const stat = useSelector(SessionSelectors.getStat);

    const onAreYouTravelingChange = useCallback(
        (value: boolean) => () => {
            setAreYouTraveling(value);
        },
        []
    );

    const onSubmit = useCallback(
        (event: React.FormEvent<HTMLFormElement>): void => {
            event.preventDefault();
            dispatch(SessionActions.updateAreYouTraveling(areYouTraveling));
            history.push(!areYouTraveling || adultsCount + childrenCount > 1 ? Path.passengers : Path.paymentSettings);
        },
        [adultsCount, areYouTraveling, childrenCount, dispatch, history]
    );

    useEffect(() => {
        if (Validator.passengerAdultDateOfBirth(accountData.dateOfBirth)) {
            dispatch(SessionActions.updateAreYouTraveling(false));
            history.replace(Path.passengers);
        }
    }, [accountData.dateOfBirth, dispatch, history]);

    if (!stat.isCalculationDone) {
        return <Redirect to={Path.calculatorIndex} />;
    }
    if (!stat.isAccountDataDone) {
        return <Redirect to={Path.accountData} />;
    }

    return (
        <Box $style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
            <AppBar />

            <Container as="main" $maxWidth={504} $px={16} $style={{ width: "100%" }}>
                <CalculatorHeadingSteps
                    currentStep={3}
                    stepsCount={stepsCount}
                    text={<LoctoolMessage id={adultsCount === 1 ? "page.areYouTraveling.title.onlyMe" : "page.areYouTraveling.title"} />}
                    backLink={Path.homeAddress}
                />

                <Box as="form" $style={{ marginTop: 42 }} $styleSmall={{ marginTop: 56 }} onSubmit={onSubmit}>
                    <Box as="fieldset" $styleSmall={{ maxWidth: 244, margin: "0 auto" }}>
                        <Box as="legend" className="show-for-sr">
                            <LoctoolMessage id="page.areYouTraveling.form.title" />
                        </Box>

                        <Flex.Container $gutterMargin={16} $justifyContent="space-between">
                            <Flex.Item $shrink="shrink" $gutterMargin={16} $style={{ maxWidth: 136, textAlign: "center" }} $styleMedium={{ maxWidth: 80 }}>
                                <ButtonAsRadio
                                    id="r-01"
                                    name="radio-group"
                                    $isVerticalOnly
                                    radioIcon={<SvgIcon32X />}
                                    value={Loctool.formatMessage({ id: "common.no" })}
                                    checked={!areYouTraveling}
                                    onChange={onAreYouTravelingChange(false)}
                                    className={GtmUtils.className.inputs.areYouTravel}
                                >
                                    <Box as="span" $style={{ display: "block" }}>
                                        <LoctoolMessage
                                            id={adultsCount === 1 ? "page.areYouTraveling.form.areYouTraveling.false.onlyMe" : "page.areYouTraveling.form.areYouTraveling.false"}
                                        />
                                    </Box>
                                </ButtonAsRadio>
                            </Flex.Item>

                            <Flex.Item $shrink="shrink" $gutterMargin={16} $style={{ maxWidth: 136 }} $styleMedium={{ maxWidth: 80 }}>
                                <ButtonAsRadio
                                    id="r-02"
                                    name="radio-group"
                                    $isVerticalOnly
                                    radioIcon={<SvgIcon32Tick />}
                                    value={Loctool.formatMessage({ id: "common.yes" })}
                                    checked={areYouTraveling === true}
                                    onChange={onAreYouTravelingChange(true)}
                                    className={GtmUtils.className.inputs.areYouTravel}
                                >
                                    <Box as="span" $style={{ display: "block" }}>
                                        <LoctoolMessage
                                            id={adultsCount === 1 ? "page.areYouTraveling.form.areYouTraveling.true.onlyMe" : "page.areYouTraveling.form.areYouTraveling.true"}
                                        />
                                    </Box>
                                </ButtonAsRadio>
                            </Flex.Item>
                        </Flex.Container>
                    </Box>

                    <Box $style={{ marginTop: 72 }} $styleMedium={{ marginTop: 32 }}>
                        <Box $style={{ maxWidth: 288 + 2 * 16, margin: "0 auto", paddingLeft: 16, paddingRight: 16 }} $styleSmall={{ maxWidth: 244 + 2 * 16 }}>
                            <Button.Primary type="submit" className={GtmUtils.className.nextButton} btnLabel={<LoctoolMessage id="page.areYouTraveling.next" />} isExpanded />
                        </Box>
                    </Box>
                </Box>
            </Container>

            <Footer />
        </Box>
    );
};

export default AreYouTravelingPage;
