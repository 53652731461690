import * as React from "react";
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgLogoUniqaHorizontal({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg width={140} height={26} viewBox="0 0 140 26" fill="none" xmlns="http://www.w3.org/2000/svg" focusable={false} aria-hidden={true} aria-labelledby={titleId} {...props}>
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.533 24.428H0l1.552-2.773h7.95a17.27 17.27 0 004.032 2.773zM16.514 4.301c-.437.368-3.234 5.82.206 10.62.968 1.35 2.386 3 4.046 3.967 1.728 1.006 2.882 1.26 3.62 1.462 1.306.354 3.327.504 5.93.643 2.107.114 6.422.14 7.177.138l.803.003 1.847 3.53s-6.271.03-9.183-.129c-3.28-.177-5.825-.364-7.467-.811-.934-.254-2.388-.576-4.564-1.84-2.09-1.219-4.236-3.349-5.349-5.126-2.682-4.286-2.008-7.166-1.656-8.577.764-1.065 1.754-2.087 2.82-2.845.773-.56 1.256-.778 1.77-1.035z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.854 24.207c-.796.154-1.572.223-2.297.221-2.652-.005-5.149-1.25-7.21-2.843-2.472-1.907-4.282-4.563-4.74-7.455-.065-.335-.097-.677-.145-1.155-.184-1.98.099-4.469 1.486-6.851 1.196-2.06 2.982-3.844 5.922-5.077C15.099.53 17.733-.048 20.784.765c3.206.886 6.007 2.882 7.808 5.5 0 0-2.763-3.604-7.495-4.016a9.29 9.29 0 00-.64-.035c-2.337-.047-4.57.76-6.434 2.102A12.027 12.027 0 0010.98 7.46c-.044.067-.188.279-.377.614l-.055.103a12.506 12.506 0 00-1.53 5.797c-.042 3.207 1.393 5.521 1.93 6.302.013.018 1.349.924 3.367 1.308.818.157 1.59.184 2.246.164.555.45 1.138.862 1.744 1.213.98.572 1.825.963 2.55 1.245z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M26.01 5.414c1.745 1.039 4.255 4.3 4.369 7.26.186 2.826-1.229 5.587-2.171 6.938-1.53-.11-2.69-.247-3.493-.468-.251-.068-.55-.14-.908-.252a11.52 11.52 0 003.246-5.344c1.043-3.737.014-6.277-1.042-8.134zM48.457 4.867h3.76v11.117c0 3.672 2.188 5.873 5.06 5.873 2.902 0 5.058-2.2 5.058-5.873V4.867h3.762v11.916c0 4.63-3.46 8.583-8.82 8.583-5.361 0-8.82-3.953-8.82-8.583V4.867zM86.972 24.935V4.866H83.25v12.52L72.335 4.867h-2.032v20.036h3.72V12.433l10.891 12.502h2.058zM91.42 24.9h3.863V4.869H91.42V24.9zM120.047 14.885c0-5.89-4.805-10.661-10.733-10.661-5.926 0-10.732 4.771-10.732 10.66 0 5.886 4.806 10.66 10.732 10.66 2.256 0 4.346-.69 6.074-1.871l1.749 1.754 2.448-2.456-1.686-1.689a10.576 10.576 0 002.148-6.397zm-10.733 7.313c-3.851 0-6.969-3.276-6.969-7.313 0-4.043 3.118-7.313 6.969-7.313 3.851 0 6.968 3.27 6.968 7.313a7.52 7.52 0 01-.998 3.772l-2.309-2.316-2.448 2.458 2.359 2.364a6.68 6.68 0 01-3.572 1.035zM135.882 24.901H140l-8.508-20.033h-2.587l-8.732 20.033h4.005l1.573-3.774h8.605l1.526 3.774zm-8.761-7.068l3.001-7.206 2.907 7.206h-5.908z"
                fill="currentColor"
            />
        </svg>
    );
}

export default SvgLogoUniqaHorizontal;
