import { Gql } from "@api/Api";
import { AppStateActionTypes } from "@redux/actions/appStateActions";
import { ContractActionTypes } from "@redux/actions/contractActions";
import { ReduxAction } from "@redux/actions/ReduxAction";

export type ContractReducerType = Gql.Contract | null;

const initialState: ContractReducerType = null;

export default function (state: ContractReducerType = initialState, action: ReduxAction): ContractReducerType {
    switch (action.type) {
        case ContractActionTypes.UPDATE:
            return action.payload.contract;
        case AppStateActionTypes.RESET:
            return initialState;
        default:
            return state;
    }
}
