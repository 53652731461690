import { gql } from "@apollo/client";
import { ContractFragment } from "./fragments/ContractFragment";
import { TransactionResultFragment } from "./fragments/TransactionResultFragment";
import { VoidTypeFragment } from "./fragments/VoidTypeFragment";

export const Mutations = Object.freeze({
    createContract: gql`
        mutation createContract(
            $createOfferDataWL: InputCreateOfferDataWL!
            $custLegal: [LegalDocumentRelationActionWLInput!]!
            $travelLegal: [LegalDocumentRelationActionWLInput!]!
        ) {
            CreateWLHuOffer(createOfferDataWL: $createOfferDataWL, custLegal: $custLegal, travelLegal: $travelLegal) {
                ...Contract
            }
        }
        ${ContractFragment}
    `,

    sendOrResendSMSCode: gql`
        mutation sendOrResendSMSCode($offerId: Id!) {
            sendOrResendSMSCode(offerId: $offerId) {
                ...VoidType
            }
        }
        ${VoidTypeFragment}
    `,

    validatePhone: gql`
        mutation validatePhone($offerId: Id!, $code: String!) {
            validatePhone(offerId: $offerId, code: $code) {
                ...VoidType
            }
        }
        ${VoidTypeFragment}
    `,

    updatePhone: gql`
        mutation updatePhone($offerId: Id!, $phoneNumber: String!) {
            updatePhone(offerId: $offerId, phoneNumber: $phoneNumber) {
                ...VoidType
            }
        }
        ${VoidTypeFragment}
    `,

    sendOrResendEmailValidationEmail: gql`
        mutation sendOrResendEmailValidationEmail($offerId: Id!, $clientId: String!) {
            sendOrResendEmailValidationEmail(offerId: $offerId, clientId: $clientId) {
                ...VoidType
            }
        }
        ${VoidTypeFragment}
    `,

    updateEmail: gql`
        mutation updateEmail($offerId: Id!, $email: String!) {
            updateEmail(offerId: $offerId, email: $email) {
                ...VoidType
            }
        }
        ${VoidTypeFragment}
    `,

    validateEmail: gql`
        mutation validateEmail($offerId: Id!, $code: String!) {
            validateEmail(offerId: $offerId, code: $code) {
                ...VoidType
            }
        }
        ${VoidTypeFragment}
    `,

    pay: gql`
        mutation pay($offerId: Id!, $payableAmount: AmountWithCurrencyInput!) {
            pay(offerId: $offerId, payableAmount: $payableAmount)
        }
    `,

    getTransactionResult: gql`
        mutation getTransactionResult($offerId: Id!, $transactionId: String!) {
            getTransactionResult(offerId: $offerId, transactionId: $transactionId) {
                ...TransactionResult
            }
        }
        ${TransactionResultFragment}
    `,

    updateOffer: gql`
        mutation updateOffer($offerId: Id!, $updateOfferDataWL: InputUpdateOfferDataWL!) {
            updateOffer(offerId: $offerId, updateOfferDataWL: $updateOfferDataWL) {
                ...VoidType
            }
        }
        ${VoidTypeFragment}
    `,
});
