import { Gql } from "@src/api/Api";
import { LegalDocumentActionTypes } from "@redux/actions/legalDocumentActions";
import { ReduxAction } from "@redux/actions/ReduxAction";

export type LegalDocumentReducerType = {
    effectiveFrom: string | null;
    data: Gql.LegalDocument[];
};

const initialState: LegalDocumentReducerType = {
    effectiveFrom: null,
    data: [],
};

export default function (state: LegalDocumentReducerType = initialState, action: ReduxAction): LegalDocumentReducerType {
    switch (action.type) {
        case LegalDocumentActionTypes.UPDATE_LEGAL_DOCUMENTS:
            return { effectiveFrom: action.payload.effectiveFrom, data: [...action.payload.legalDocuments] };
        default:
            return state;
    }
}
