import * as React from "react";
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgIcon32AddressHome({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" focusable={false} aria-hidden={true} aria-labelledby={titleId} {...props}>
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M16.497 4.267c.453 0 .905.146 1.282.44l8.008 6.228c.51.396.807 1.004.807 1.649v13.594c0 .576-.234 1.099-.612 1.477-.378.378-.9.612-1.477.612H8.489c-.577 0-1.1-.234-1.477-.612a2.082 2.082 0 01-.612-1.477V12.584c0-.645.298-1.253.806-1.649l8.008-6.228c.377-.294.83-.44 1.283-.44z"
                stroke="currentColor"
            />
            <path
                d="M16.497 19.563c.769 0 1.465.311 1.97.815.504.504.815 1.2.815 1.97v5.919h-5.57v-5.92c0-.768.311-1.465.815-1.969a2.777 2.777 0 011.97-.816z"
                stroke="currentColor"
            />
        </svg>
    );
}

export default SvgIcon32AddressHome;
