import { BackendEnvironment } from "@api/graphql/types";
import { BackendEnvironmentActionTypes } from "@redux/actions/backendEnvironmentActions";
import { ReduxAction } from "@redux/actions/ReduxAction";

export type BackendEnvironmentReducerType = BackendEnvironment[];

const initialState: BackendEnvironmentReducerType = [];

export default function (state: BackendEnvironmentReducerType = initialState, action: ReduxAction): BackendEnvironmentReducerType {
    switch (action.type) {
        case BackendEnvironmentActionTypes.UPDATE_BACKEND_ENVIRONMENTS:
            return [...action.payload.backendEnvironments];
        default:
            return state;
    }
}
