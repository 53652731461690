import * as React from "react";
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgIcon32BrokenPipe({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg width={32} height={32} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" focusable={false} aria-hidden={true} aria-labelledby={titleId} {...props}>
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d="M16.584 16.544l.1.083.268.254c2.365 2.275 3.548 4.148 3.548 5.619a4.5 4.5 0 11-9 0c0-1.526 1.272-3.483 3.815-5.873a1 1 0 011.27-.083zm-.584.811l-.257.245c-2.184 2.1-3.243 3.772-3.243 4.9a3.5 3.5 0 007 0c0-1.081-.976-2.668-2.99-4.654l-.247-.24-.263-.25zM29.5 5a.5.5 0 110 1H16.934l-1.896 2.845A.1.1 0 0015.12 9H19a.5.5 0 01.424.765l-.05.067L16.557 13H29.5a.5.5 0 110 1h-27a.5.5 0 110-1h12.719l2.52-2.834a.1.1 0 00-.076-.166H14a.5.5 0 01-.457-.705l.041-.072L15.731 6H2.5a.5.5 0 010-1h27z"
                fill="currentColor"
                fillRule="evenodd"
            />
        </svg>
    );
}

export default SvgIcon32BrokenPipe;
